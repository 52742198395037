import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Service from '../services/MeetingService';
import { AlertNetworkError } from "../components/Alert";

import './CalculationDictionaries.css';
import CalculationDictionaryWords from "./CalculationDictionaryWords";
import ClassNames from "classnames";
import CalculationDictionaryPopup from "./CalculationDictionaryPopup";

class CalculationDictionary extends Component {

  render() {
    return (
      <tr className={ ClassNames({ 'current': (this.props.id === this.props.selectId) }) }
          onClick={ e => { this.props.onClick(e, this.props.id) } } >
        <td className="name">{ this.props.name }</td>
        <td className="num_of_words"><div className="count">{ this.props.num_of_words }/100</div></td>
        <td className="functions">
          <div>
            { this.getEditButton() }
          </div>
        </td>
      </tr>
      );
  }

  getEditButton() {
    return (
        <button className="icon-pencil button-icon" disabled={ this.props.prepared } onClick={ e => { this.props.popupUpdateDictionary(e, this.props.id) } }/>
    );
  }
}

CalculationDictionary.PropType = {
  id: PropTypes.number,
  selectId: PropTypes.number,
  name: PropTypes.string,
  num_of_words: PropTypes.number,
  onClick: PropTypes.func,
  popupUpdateDictionary: PropTypes.func
};

class CalculationDictionaries extends Component {

  constructor(props) {
    super(props);

    this.state = {
      details: null,
      id: ''
    };
  }

  componentDidMount() {
    this.getCurrentPage();
  }

  getCurrentPage() {
    this.props.handlers.showProgress();
    const service = new Service();
    service.calculationDictionaries()
      .finally(() => { this.props.handlers.hideProgress(); })
      .subscribe(
        payload => {
          this.setState({
            details: payload.data
          });
          if (payload.data.length === 0) {
            this.alertNoData();
          }
        },
        error => {
          this.props.handlers.error(error);
        }
    );
  }

  calculationDictionaries() {
    const calculationDictionaries = [];
    this.state.details.forEach(calculationDictionary => {
      const num_of_words = calculationDictionary.words_count ? calculationDictionary.words_count.aggregate : 0;
      calculationDictionaries.push(
        <CalculationDictionary
          key={ calculationDictionary.id }
          id={ calculationDictionary.id }
          selectId={ this.state.id }
          name={ calculationDictionary.name }
          num_of_words={ num_of_words }
          onClick={ this.transitWords.bind(this) }
          popupUpdateDictionary={ this.popupUpdateDictionary.bind(this) }
        />);
    });
    return calculationDictionaries;
  }

  details() {
    if (this.state.details) {
      if (this.state.details.length > 0) {
        return (
          <div>
            <table>
              <thead>
                <tr>
                  <th className="name">名前</th>
                  <th className="num_of_words">単語数</th>
                  <th className="functions"/>
                </tr>
              </thead>
              <tbody>
                { this.calculationDictionaries() }
              </tbody>
            </table>
          </div>
        );
      }
    }
    return null;
  }

  calculationDictionaryWords(){
    if (this.state.id) {
      let current_dictionary_name = this.getDictionaryName(this.state.id);
      return <CalculationDictionaryWords
        id={ this.state.id }
        dictionaryName={ current_dictionary_name }
        onChange={ this.getCurrentPage.bind(this) }
        handlers={ this.props.handlers }
      />;
    }
    return null;
  }

  render() {
    return (
      <div className="calculation-dictionaries">
        <div className="calculation-dictionaries-main">
          <div className="calculation-dictionaries-header">
            <div className="title">分析対象単語帳</div>
          </div>
          { this.details() }
        </div>
        <div className="calculation-dictionary-words">
          { this.calculationDictionaryWords() }
        </div>
      </div>
    );
  }

  transitWords(event, id) {
    this.setState({id: id});
  }

  popupUpdateDictionary(event, id) {
    event.preventDefault();
    event.stopPropagation();
    this.props.handlers.showPopup(<CalculationDictionaryPopup
        id={ id }
        title={ '単語帳の名前変更' }
        close={ this.closePopup.bind(this) }
        error={ this.props.handlers.error } />);
  }

  closePopup() {
    this.props.handlers.hidePopup();
    this.getCurrentPage();
  }

  alertNoData() {
    this.props.handlers.showAlert(
      <AlertNetworkError okay={ this.props.handlers.hideAlert }/>
    );
  }

  getDictionaryName(id) {
    const detail = this.state.details.find(detail => detail.id === id);
    return detail.name;
  }
}

CalculationDictionaries.PropType = {
  handlers: PropTypes.object
};

export default CalculationDictionaries;
