import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './MeetingHistory.css';
import Service from '../services/MeetingService';
import Moment from 'moment';

export default class MeetingHistory extends Component {
    constructor(props) {
        super(props);

        let nowDate = Moment();
        this.state = {
            customer_data: null,
            history_data: null,
            year: nowDate.year(),
            //Momentの月は0始まり
            momentStyleMonth: nowDate.month(),
        };
    }

    componentDidMount() {
        this.props.handlers.showProgress();
        this.getCustomerData(this.state.year, this.state.momentStyleMonth);
        this.getHistoryData(this.state.year, this.state.momentStyleMonth);
    }

    tryHideProgress() {
        if(this.state.customer_data === null) {
            return;
        }
        if(this.state.history_data === null) {
            return;
        }
        this.props.handlers.hideProgress();
    }

    getCustomerData(year, momentStyleMonth) {
        let service = new Service();
        service.customerStatistics(year, momentStyleMonth + 1).subscribe(
            payload => {
                this.setState({
                    customer_data: payload,
                });
                this.tryHideProgress();
            },
            error => {
                console.log(error);
                this.props.handlers.hideProgress();
                this.props.handlers.error(error);
            }
        );
    }

    getHistoryData(year, momentStyleMonth) {
        let service = new Service();
        service.meetingHistory(year, momentStyleMonth + 1).subscribe(
            payload => {
                this.setState({
                    history_data: payload,
                });
                this.tryHideProgress();
            },
            error => {
                console.log(error);
                this.props.handlers.hideProgress();
                this.props.handlers.error(error);
            }
        );
    }

    getHistoryTable() {
        if (this.state.history_data === null) {
            return null;
        }
        if (this.state.history_data.length === 0) {
            return (<div className="no-data">会議データなし</div>);
        }
        let list = [];
        let index = 0;

        this.state.history_data.forEach($item => {
           let [hour, minute] = this.getHourAndMinute($item['usage_time']);
           let hour_length = ('' + hour).length;
           let slice_length = (hour_length > 2) ? hour_length : 2;
           let usage_string = ('0' + hour).slice(-slice_length) + ':' + ('0' + minute).slice(-2);
          let date = Moment($item['start_at']).format('YYYY/MM/DD');
          let time = Moment($item['start_at']).format('HH：mm') + ' 〜 ' + Moment($item['end_at']).format('HH：mm');

          list.push(<tr key={index}>
               <td>{$item['creator_name']}</td>
               <td>{$item['dept'] }</td>
               <td>{date} {time}</td>
               <td>{ usage_string }</td>
               <td>{$item['restart'] ? '再開' : '新規' }</td>
               <td>{$item['room'] }</td>
           </tr>);
           index += 1;
        });

        return(
            <table>
                <thead>
                <tr className="odd">
                    <th className="column1">作成者</th>
                    <th className="dept">所属</th>
                    <th className="start_at">日時</th>
                    <th className="usage_string">会議時間</th>
                    <th className="restart">新規/再開</th>
                    <th className="room">場所</th>
                </tr>
                </thead>
                <tbody>
                {list}
                </tbody>
            </table>
        );
    }

    getHourAndMinute(source_minute) {
        let hour = Math.floor(source_minute / 60);
        let minute = source_minute - hour * 60;

        return [hour, minute];
    }

    render() {
        return (
            <div className="meeting-history">
                {this.details()}
            </div>
        );
    }

    onClickNextMonth(event) {
        event.preventDefault();
        let currentDate = Moment().year(this.state.year).month(this.state.momentStyleMonth);
        currentDate.add(1, 'months');
        this.setState({
            year: currentDate.year(),
            momentStyleMonth: currentDate.month(),
            customer_data: null,
            history_data: null,
        });
        this.getCustomerData(currentDate.year(), currentDate.month());
        this.getHistoryData(currentDate.year(), currentDate.month());
    }

    onClickPreviousMonth(event) {
        event.preventDefault();
        let currentDate = Moment().year(this.state.year).month(this.state.momentStyleMonth);
        currentDate.add(-1, 'months');
        this.setState({
            year: currentDate.year(),
            momentStyleMonth: currentDate.month(),
            customer_data: null,
            history_data: null,
        });
        this.getCustomerData(currentDate.year(), currentDate.month());
        this.getHistoryData(currentDate.year(), currentDate.month());
    }

    details() {
        if (!this.state.customer_data) {
            return (
                <div/>
            );
        }
        let [meeting_hour] = this.getHourAndMinute(this.state.customer_data.total_meeting_time);

        return (
                <div className="meeting-history-body">
                    <div className="month-navigation">
                        <button onClick={this.onClickPreviousMonth.bind(this)} className="btnicn">
                            <div className="icon-arrow-alt-circle-left"/>
                        </button>
                        <div>
                            {this.state.year}年 {this.state.momentStyleMonth + 1}月
                        </div>
                        <button onClick={this.onClickNextMonth.bind(this)} className="btnicn">
                            <div className="icon-arrow-alt-circle-right"/>
                        </button>
                    </div>
                    <div className="card-area">
                        <div className="card">
                            <div className="card-title">会議回数(再開含む)
                            </div>
                            <div className="card-body">
                                <div/>
                                <div>
                                    {this.state.history_data ? this.state.history_data.length : ''}
                                </div>
                                <div>回</div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-title">会議時間
                            </div>
                            <div className="card-body">
                                <div/>
                                <div>
                                    {meeting_hour}
                                </div>
                                <div>時間</div>
                            </div>
                        </div>
                    </div>
                    <div className="history-area">
                        { this.getHistoryTable() }
                    </div>
                </div>
        );
    }

}

MeetingHistory.PropType = {
    location: PropTypes.object,
    handlers: PropTypes.object,
};
