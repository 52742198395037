import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Service from '../services/MeetingService';
import {authorizations, authorizations_ss} from './AccountPopup';

import './UserPopup.css';

export function fieldToLabel(field) {
  var names = {
    email: 'email',
    sei: '姓',
    mei: '名',
    dept: '所属',
    user_name: '表示名',
    authorization: '権限',
    authorization_ss: 'SS権限'
  }
  return names[field];
}

class UserPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      user: {
        email: '',
        sei: '',
        mei: '',
        dept: '',
        user_name: '',
        authorization: '',
        authorization_ss: '-1'
      },
      errors: {
        sei: null,
        mei: null,
        dept: null,
        user_name: null,
        email: null
      }
    };
  }

  componentDidMount() {
    if (this.props.user_id) {
      this.props.handlers.showProgress();
      new Service().user(this.props.user_id).subscribe(
        payload => {
          // console.log(payload);
          // console.log(payload.email);
          // let user_info = JSON.parse(JSON.stringify(payload)); // コピー(一旦JSON形式に変換して、またオブジェクトに戻す)
          this.setState({
              user: payload
          });
          this.props.handlers.hideProgress();
        },
        error => {
          this.props.handlers.hideProgress();
          this.props.handlers.error(error);
        }
      );
    }
  }

  submit(event) {
    event.preventDefault();

    this.props.handlers.showProgress();

    var form = new FormData();
    form.append('email', this.state.user.email);
    form.append('sei', this.state.user.sei);
    form.append('mei', this.state.user.mei);
    form.append('dept', this.state.user.dept);
    form.append('user_name', this.state.user.user_name);
    form.append('authorization', this.state.user.authorization);
    form.append('authorization_ss', this.state.user.authorization_ss);

    var service = new Service();

    if (this.props.user_id) {
      service.updateUser(this.props.user_id, form)
        .finally(() => { this.props.handlers.hideProgress(); })
        .subscribe(
          payload => {
            if (this.errorsToState(payload)) {
              return;
            }
            this.props.close();
          },
          error => {
            this.props.handlers.error(error);
          }
        );
    } else {
      service.storeUser(form)
        .finally(() => { this.props.handlers.hideProgress(); })
        .subscribe(
          payload => {
            if (this.errorsToState(payload)) {
              return;
            }
            this.props.close();
          },
          error => {
            this.props.handlers.error(error);
          }
        );
    }
  }

  cancel(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.close();
  }

  okayButton() {
    return this.props.user_id ? '変更する' : '招待する';
  }

  emailElement() {
    if(this.props.user_id) {
        return(
          <div>
            <label>email</label>
            <span>{ this.state.user.email }</span>
          </div>
        );
    }
    return (
      <div>
        <label className="required">email</label>
        <input maxLength={256} ref="email" type="email" value={ this.state.user.email } onChange={ e => { this.onChangeText(e, 'email') } } autoFocus={!this.props.user_id} />
      </div>
    );
  }

  explanationText() {
    return this.props.user_id ? '' : (<div className="desc">招待すると、ご登録いただいたアドレス宛にパスワード設定メールを送信します。</div>)
  }

  authorizationOptions() {
    let authorization = sessionStorage.getItem('authorization');
    let array = authorizations();
    var options = [];

    console.log("authorizationOptions");
    console.log(sessionStorage);
    console.log("is_ss");
    console.log(this.props.is_ss);

    if(!this.props.user_id) {
        options.push(<option key="0" value="">権限を選択してください。</option>);
    }
    for (let i = 0; i < array.length; ++i) {
      if (authorization < i) {
        break;
      }
      options.push(<option key={ i+1 } value={ i }>{ array[i] }</option>);
    }
    return options;
  }

  authorizationOptionsSS() {
    //let authorization_ss = sessionStorage.getItem('authorization_ss');
    let array = authorizations_ss();
    var options = []

    if(!this.props.user_id) {
      options.push(<option key="0" value="">権限を選択してください。</option>);
    }
    for (let i = 0; i < array.length; ++i) {
      // if (authorization_ss < i +  1) {
      //   break;
      // }
      options.push(<option key={ i } value={ i-1 }>{ array[i] }</option>);
    }
    return options;
  }

  onChangeText(e, item) {
    var user = this.state.user;
    user[item] = e.target.value;
    this.setState({ user: user });
  }

  onChangeAuthorization(e) {
    var user = this.state.user;
    user.authorization = e.target.value;
    this.setState({ user: user });
  }

  onChangeAuthorizationSS(e) {
    var user = this.state.user;
    user.authorization_ss = e.target.value;
    this.setState({ user: user });
  }

  isAuthorizationSS() {
    // console.log("isAuthorizationSS");
    // console.log(this.props.detail.customer.is_ss);
    // if(this.props.detail.customer.is_ss === 1) {
    //   return (
    //       <div>
    //         <label className="required">SS権限</label>
    //         <div className="select">
    //           <select value={ this.state.user.authorization_ss } onChange={ e => { this.onChangeAuthorizationSS(e) } }>
    //             { this.authorizationOptionsSS() }
    //           </select>
    //           <button tabIndex="-1" className="button-select" ><div className="icon-inverted-triangle"></div></button>
    //         </div>
    //       </div>
    //   );
    // }else {
      // 1.顧客情報が取得できない場合
      // 2.顧客設定が「SSを利用しない（is_ss = 0）」の場合
      // 3.顧客設定が「SSを利用しない（is_ss = 1）」でも会議ステータスが「終了（status = -1）」の場合
      // 上記3パターンはSS依頼ボタンを表示しない
      return (
          <div title="SS権限" style={{ display: 'none' }} />
      );
    // }
  }

  render() {
    return(
      <div className="user-popup">
        <div className="title">{ this.props.title }</div>
        <div className="inner">
          <form>
            { this.emailElement() }
            { this.errorMessage('email') }
            <div className="name">
              <div className="sei">
                <label className="required">姓</label>
                <input maxLength={30} ref="sei" type="text" value={ this.state.user.sei } onChange={ e => { this.onChangeText(e, 'sei') } } autoFocus={this.props.user_id}/>
                { this.errorMessage('sei') }
              </div>
              <div className="mei">
                <label className="required">名</label>
                <input maxLength={30} ref="mei" type="text" value={ this.state.user.mei } onChange={ e => { this.onChangeText(e, 'mei') } } />
                { this.errorMessage('mei') }
              </div>
            </div>
            <div>
              <label className="required">表示名</label>
              <input maxLength={8} ref="user_name" type="text" value={ this.state.user.user_name } onChange={ e => { this.onChangeText(e, 'user_name') } } />
            </div>
            { this.errorMessage('user_name') }
            <div>
              <label>所属</label>
              <input maxLength={100} ref="dept" type="text" value={ this.state.user.dept } onChange={ e => { this.onChangeText(e, 'dept') } } />
            </div>
            { this.errorMessage('dept') }
            <div>
              <label className="required">権限</label>
              <div className="select">
                <select value={ this.state.user.authorization } onChange={ e => { this.onChangeAuthorization(e) } }>
                  { this.authorizationOptions() }
                </select>
                <button tabIndex="-1" className="button-select" ><div className="icon-inverted-triangle"></div></button>
              </div>
            </div>
            { this.errorMessage('authorization') }
            {this.isAuthorizationSS()}
          </form>
          { this.explanationText() }
        </div>
        <div className="buttons">
          <div>
            <div className="button-area">
              <button className="button-text" onClick={ e => { this.cancel(e) } } >キャンセル</button>
              <button className="button-1" onClick={ e => { this.submit(e) } } >{ this.okayButton() }</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  errorsToState(payload) {
    if (payload.error === 'validation') {
      delete(payload.error);
      var errors = [];
      Object.keys(payload).forEach(key => {
        var error = payload[key][0];
        errors[key] = error.split('.')[1];
      });
      this.setState({ errors: errors });
      return true;
    }
    return false;
  }

  errorMessage(field) {
    var error = this.state.errors[field];
    if (error) {
      var label = fieldToLabel(field);
      var messages = {
        email: `${ label }の形式が誤っています。`,
        email_for_customer: `${ label }に不正なドメインが含まれています。`,
        unique: `その${ label }は既に存在します。`,
        required: `${ label }は必須入力です。`,
        min: `${ label }は6文字以上で入力してください。`
      }
      return <div className="error">{ messages[error] }</div>;
    }
    return null;
  }

}

UserPopup.PropType = {
  user_id: PropTypes.number,
  is_ss: PropTypes.number,
  title: PropTypes.string,
  close:PropTypes.func,
  handlers: PropTypes.object
};

export default UserPopup;
