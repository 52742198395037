import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './MeetingTimePicker.css';
import Moment from 'moment';

export default class MeetingTimePicker extends Component {
    constructor(props) {
        super(props);

        let hour;
        let minute;
        let second;

        if(this.props.time == null) {
            [hour, minute, second] = this.getResetInputContents();
        } else {
            hour = this.props.time.hour();
            minute = this.getMinuteString(this.props.time.minute());
            second = this.getSecondString(this.props.time.second());
        }

        this.state = {
            // 時刻の初期値
            // nullの場合は初期値なし、もしくはAPIから取得して遅延設定待ち
            initial_time: this.props.time,
            // 時。直接キーボードで入力している場合は数値以外もありうる。
            hour: hour,
            // 分。直接キーボードで入力している場合は数値以外もありうる。
            minute: minute,
            // 秒。直接キーボードで入力している場合は数値以外もありうる。
            second: second,
        };

    }

    componentDidMount() {
        this.adjustMinute(this.state.minute);
        this.adjustSecond(this.state.second);
    }

    /**
     * 外部からのプロパティ変更時処理
     *
     * 時間は非同期で後から設定されるケースもあるため、外部からのプロパティ変更時には処理する。
     * this.props.onChangeTimeコールバック呼び出し時にも呼ばれるため、initial_timeがセットされたら、それ以降は処理しない。
     * 
     *
     * @param nextProps
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        //初期タイムが設定済みなら処理しない
        if(this.state.initial_time !== null) {
             return;
        }

        //新しいtimeがnullなら以降は処理しない
        if(nextProps.time === null) {
             return;
        }

        let time = nextProps.time;
        let hour = time.hour();
        let minute = this.getMinuteString(time.minute());
        let second = this.getSecondString(time.second());

        this.adjustMinute(minute);
        this.adjustSecond(second);

        this.setState({initial_time:time, hour: hour, minute: minute, second: second});
    }

    optionNull() {
        return (<option>--</option>);
    }

    getMinuteString(minute) {
        let regex = new RegExp(/^[0-9]+$/);

        //入力内容をチェックして、内容が正しくなければ'--'に変更
        if(regex.test(minute) === false) {
            return '--';
        } else if (this.isValidTime(minute, 'mm') === false) {
            return '--';
        }

        if(minute < 10) {
            return '0' + Number(minute);
        }

        return minute;
    }

    getSecondString(second) {
        let regex = new RegExp(/^[0-9]+$/);

        //入力内容をチェックして、内容が正しくなければ'--'に変更
        if(regex.test(second) === false) {
            return '--';
        } else if (this.isValidTime(second, 'ss') === false) {
            return '--';
        }

        if(second < 10) {
            return '0' + Number(second);
        }

        return second;
    }


    hourOptionList() {
        let list = [];
        for (let i = 0; i <= 23; i++) {
            if (i <= 9) {
                list.push(<option key={i} value={i}>{i}</option>);
            } else {
                list.push(<option key={i} value={i}>{i}</option>);
            }
        }
        return list;
    }

    minuteOptionList() {
        let list = [];
        for (let i = 0; i <= 59; i += this.props.intervalOfMinutesOption) {
            if (i <= 9) {
                list.push(<option key={100 + i} value={"0" + i}>0{i}</option>);
            } else {
                list.push(<option key={100 + i} value={i}>{i}</option>);
            }
        }
        return list;
    }

    secondOptionList() {
        if (this.props.notUseSecond) {
            return null;
        }

        let list = [];
        list.push(' : ');
        for (let i = 0; i <= 59; i++) {
            if (i <= 9) {
                list.push(<option key={200 + i} value={"0" + i}>0{i}</option>);
            } else {
                list.push(<option key={200 + i} value={i}>{i}</option>);
            }
        }
        return list;
    }

    render() {
        return (
            <div className="meeting-timepicker">
                <div className="select">
                    <select ref="hour" onChange={e => {
                        this.onChangeSelect(e, 'HH')
                    }} value={this.state.hour} disabled={this.props.disabled}>
                        {this.optionNull()}
                        {this.hourOptionList()}
                    </select>
                    <button tabIndex="-1" className="button-select" disabled={this.props.disabled}>
                        <div className={ this.props.disabled ? "icon-inverted-triangle-disabled" : "icon-inverted-triangle" }/>
                    </button>
                </div>
                &nbsp;:&nbsp;
                <div className="select">
                    <select ref="minute" onChange={e => {
                        this.onChangeSelect(e, 'mm')
                    }} value={this.state.minute} disabled={this.props.disabled}>
                        {this.optionNull()}
                        {this.minuteOptionList()}
                    </select>
                    <button tabIndex="-1" className="button-select" disabled={this.props.disabled}>
                        <div className={ this.props.disabled ? "icon-inverted-triangle-disabled" : "icon-inverted-triangle" }/>
                    </button>
                </div>
                <div className={this.props.notUseSecond ? 'hidden' : ''}>&nbsp;:&nbsp;</div>
                <div className={this.props.notUseSecond ? 'hidden' : 'select'}>
                    <select ref="second" onChange={e => {
                        this.onChangeSelect(e, 'ss')
                    }} value={this.state.second} disabled={this.props.disabled}>
                        {this.optionNull()}
                        {this.secondOptionList()}
                    </select>
                    <button tabIndex="-1" className="button-select" disabled={this.props.disabled}>
                        <div className={ this.props.disabled ? "icon-inverted-triangle-disabled" : "icon-inverted-triangle" }/>
                    </button>
                </div>
            </div>
        );
    }

    isHour(hour) {
        return hour !== '' && !isNaN(hour) && hour >= 0 && hour < 24;
    }

    isMinute(minute) {
        return minute !== '' && !isNaN(minute) && minute >= 0 && minute < 60;
    }

    isSecond(second) {
        return second !== '' && !isNaN(second) && second >= 0 && second < 60;
    }

    /**
     * 値チェック
     *
     * 数値かつ範囲内の場合のみtrueを返す。それ以外はfalse
     *
     * @param value
     * @param item
     * @returns {*}
     */
    isValidTime(value, item) {
        switch (item) {
            case 'HH':
                return this.isHour(value);
            case 'mm':
                return this.isMinute(value);
            case 'ss':
                return this.isSecond(value);
            default:
                break;
        }
        return false;
    }

    /**
     * 分コンボボックスのフォーカス位置調整
     *
     * 内部判定でisNaN関数を使っていて、あまり正確には判定できないので注意。
     *
     * @param minute
     */
    adjustMinute(minute) {
        const offset = 1;

        if(isNaN(minute) || minute % this.props.intervalOfMinutesOption) {
            this.refs.minute.selectedIndex = -1;
        } else {
            this.refs.minute.selectedIndex = (Number(minute) / this.props.intervalOfMinutesOption) + offset;
        }
    }

    /**
     * 秒コンボボックスのフォーカス位置調整
     *
     * 内部判定でisNaN関数を使っていて、あまり正確には判定できないので注意。
     *
     * @param second
     */
    adjustSecond(second) {
        if (this.props.notUseSecond) {
            return;
        }

        const offset = 1;
        if(isNaN(second)) {
            this.refs.second.selectedIndex = -1;
        } else {
            this.refs.second.selectedIndex = Number(second) + offset;
        }
    }


    /**
     * 時・分・秒のコンボボックス変更時
     *
     * @param event
     * @param item
     */
    onChangeSelect(event, item) {
        this.commit(event, item);
    }

    commit(event, item) {
        let hour = this.state.hour;
        let minute = this.state.minute;
        let second = this.state.second;
        let value = event.target.value;

        switch (item) {
            case 'HH':
                hour = value;
                //入力内容に基づく補完処理
                if(minute === '--') {
                    minute = '00';
                }
                if(second === '--') {
                    second = '00';
                }
                if(value === '--') {
                    [hour, minute, second] = this.getResetInputContents();
                }
                break;
            case 'mm':
                minute = value;
                //入力内容に基づく補完処理
                if(second === '--') {
                    second = '00';
                }
                if(value === '--') {
                    [hour, minute, second] = this.getResetInputContents();
                }
                break;
            case 'ss':
                //入力内容に基づく補完処理
                second = value;
                if(value === '--') {
                    [hour, minute, second] = this.getResetInputContents();
                }
                break;
            default:
                break;
        }

        this.adjustMinute(minute);
        this.adjustSecond(second);

        //すべて正しい内容の場合のみ、時刻を通知。そうでなければnullを通知
        if (this.isValidTime(hour, 'HH') && this.isValidTime(minute, 'mm') && this.isValidTime(second, 'ss')) {
            this.props.onChangeTime(Moment().hour(hour).minute(minute).second(second));
        } else {
            this.props.onChangeTime(null);
        }

      this.setState({hour: hour, minute: minute, second: second});
    }

    getResetInputContents() {
        let hour = '--';
        let minute = '--';
        let second = this.props.notUseSecond ? '00' : '--';

        return [hour, minute, second];
    }
}

MeetingTimePicker.PropType = {
    onChangeTime:PropTypes.func,
    disabled: PropTypes.bool,
    intervalOfMinutesOption: PropTypes.number,
    notUseSecond: PropTypes.bool,
};
