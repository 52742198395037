import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Observable } from 'rx';

import Service from '../services/MeetingService';
import { authorization, isAdmin, getMeEmail } from './AccountPopup';
import UserPopup, { fieldToLabel } from './UserPopup';
import { AlertOneButton, AlertTwoButton } from '../components/Alert';
import {apiUrl, basename, customerSettings} from './utilities';
import PaginationBar from "../components/PaginationBar";

import './Users.css';
import ClassNames from "classnames";

class OptionMenu extends Component {

  constructor(props) {
    super(props);

    this.state = {
      toggle: false,
    };
    this.bounce = false;
  }

  componentDidMount() {
    this.handler = this.onClickOutside.bind(this);
    document.addEventListener('click', this.handler);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handler);
  }

  onClickOutside(event) {
    if (!this.bounce && this.state.toggle) {
      this.toggleSelectBox(event);
    }
    this.bounce = false;
  }

  toggleSelectBox(event) {
    const toggle = this.state.toggle;
    this.setState({ toggle: !this.state.toggle });
    return toggle;
  }

  onClickButton(event) {
    event.stopPropagation();
    event.preventDefault();
    this.shield();
    this.toggleSelectBox(event);
  }

  shield() {
    this.bounce = true;
  }

  onEditGroup(e) {
    this.setState({
      toggle: false,
    });
    this.props.onEditGroup(e);
  }

  onDeleteGroup(e) {
    this.setState({
      toggle: false,
    });
    this.props.onDeleteGroup(e);
  }

  render() {
    const iconClass = ClassNames({
      'icon-option': true,
      'button-option-icon': true,
      'selected': this.state.toggle
    });

    return (
      <div id={ this.props.id } className="option-menu">
        <div className={ iconClass } onClick={ this.onClickButton.bind(this) }/>
        <div className="menus" hidden={ !this.state.toggle }>
          <div className="edit-group" onClick={ e => { this.onEditGroup(e) }}>編集する</div>
          <div className="delete-group" onClick={ e => { this.onDeleteGroup(e) }}>削除する</div>
        </div>
      </div>
    );
  }
}

OptionMenu.PropType = {
  id: PropTypes.number,
  onEditGroup:PropTypes.func,
  onDeleteGroup:PropTypes.func
};

class CommonUsersPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dragging: false,
      message: 'ここにCSVファイルをドラッグしてください。',
      filename: null,
      file: null,
    }
  }

  onDragEnter(event) {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      dragging: true,
      message: 'ここにCSVファイルをドロップしてください。'
    });
  }

  onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      dragging: true,
    });
  }

  onDragLeave(event) {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      dragging: false,
      message: 'ここにCSVファイルをドラッグしてください。'
     });
  }

  onDrop(event) {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      dragging: false,
    });
    let files = event.dataTransfer.files;
    if (files.length > 0) {
      var file = files[0];
      // IE11: does not set the file to file.files property
      this.setState({ filename: file.name, file: file });
    }
  }

  onTapSelectFile(event) {
    this.refs.file.click();
  }

  onChangeFile(event) {
    this.setState({filename: basename(event.target.value)});
  }

  message() {
    return <p>{ this.state.message}</p>;
  }

  uploadFile() {
    if (this.state.filename) {
      return <div className="upload-file">{ this.state.filename }</div>
    }
    return null;
  }

  canNotTapImport() {
    return this.state.filename == null;
  }

  onTapCancel() {
    this.props.close(null)
  }

  onTapImport() {
    let file = this.state.file || this.refs.file.files[0];
    this.upload(file);
  }

}

class UploadUsersPopup extends CommonUsersPopup {

  render() {
    var draggingStyles = this.state.dragging ? 'drag-and-drop enter' : 'drag-and-drop';

    return(
        <div className="users-upload-csv-file">
          <div className="title">メンバーCSVインポート</div>
          <div className="inner">
            <div className="explanation">メンバーのCSVファイルをインポートします。</div>
            <div className={ draggingStyles }
                 onDrop={ this.onDrop.bind(this) }
                 onDragEnter={ this.onDragEnter.bind(this) }
                 onDragOver={ this.onDragOver.bind(this) }
                 onDragLeave={ this.onDragLeave.bind(this) }　>
              { this.message() }
              <div className="select-file">
                <div>または</div>
                <button className="btnpt2" onClick={ this.onTapSelectFile.bind(this) } >ファイルを選択</button>
              </div>
            </div>
            { this.uploadFile() }
          </div>
          <div className="buttons">
            <button className="btnpt1" onClick={ this.onTapCancel.bind(this) } >キャンセル</button>
            <input type="file" ref="file" accept=".csv" onChange={ this.onChangeFile.bind(this) } />
            <button className="btnpt4" onClick={ this.onTapImport.bind(this) } disabled={ this.canNotTapImport() } >インポートする</button>
          </div>
        </div>
    );
  }

  upload(file) {
    var form = new FormData();
    form.append('csvfile', file);
    this.props.handlers.showProgress();

    new Service().uploadUsers(form)
        .finally(() => { this.props.handlers.hideProgress(); })
        .subscribe(
            payload => {
              this.props.close(payload);
            },
            error => {
              this.props.close();
              this.props.handlers.error(error);
            }
        );
  }
}

UploadUsersPopup.PropType = {
  close:PropTypes.func,
  handlers: PropTypes.object
};

class DeleteUsersPopup extends CommonUsersPopup {

  render() {
    var draggingStyles = this.state.dragging ? 'drag-and-drop enter' : 'drag-and-drop';

    return(
        <div className="users-upload-csv-file">
          <div className="title">メンバーCSV一括削除</div>
          <div className="inner">
            <div className="explanation">CSVファイルを使ってメンバーを一括削除します。</div>
            <div className={ draggingStyles }
                 onDrop={ this.onDrop.bind(this) }
                 onDragEnter={ this.onDragEnter.bind(this) }
                 onDragOver={ this.onDragOver.bind(this) }
                 onDragLeave={ this.onDragLeave.bind(this) }　>
              { this.message() }
              <div className="select-file">
                <div>または</div>
                <button className="btnpt2" onClick={ this.onTapSelectFile.bind(this) } >ファイルを選択</button>
              </div>
            </div>
            { this.uploadFile() }
          </div>
          <div className="buttons">
            <button className="btnpt1" onClick={ this.onTapCancel.bind(this) } >キャンセル</button>
            <input type="file" ref="file" accept=".csv" onChange={ this.onChangeFile.bind(this) } />
            <button className="btnpt4" onClick={ this.onTapImport.bind(this) } disabled={ this.canNotTapImport() } >一括削除する</button>
          </div>
        </div>
    );
  }

  upload(file) {
    var form = new FormData();
    form.append('csvfile', file);
    this.props.handlers.showProgress();

    new Service().deleteUsers(form)
        .finally(() => { this.props.handlers.hideProgress(); })
        .subscribe(
            payload => {
              this.props.close(payload);
            },
            error => {
              this.props.close();
              this.props.handlers.error(error);
            }
        );
  }
}

DeleteUsersPopup.PropType = {
  close:PropTypes.func,
  handlers: PropTypes.object
};

class ResultsUploadUsersPopup extends Component {

  errorMessage(field, error) {
    let label = fieldToLabel(field);
    if (label) {
      var messages = {
        email: `${ label }の形式が誤っています。`,
        email_for_customer: `${ label }に不正なドメインが含まれています。`,
        unique: `その${ label }は既に存在します。`,
        required: `${ label }は必須入力です。`,
        max: `${ label }の値が範囲外です。`,
        confirmed: `${ label }が確認のものと一致しません。`,
        password: 'パスワードが誤っています。',
        exists: `その${ label }のメンバーは存在しません。もしくは、${ label }がCSV内で重複しています。`,
        is_meeting_member: '開催中の会議参加者です。該当会議を終了後、メンバー削除を行ってください。'
      };
      return `${messages[error]}`;
    } else { // all
      return '項目に過不足があります。'
    }
  }

  errors() {
    var rows = [];
    this.props.results.lines.forEach(line => {
      var message = '';
      Object.keys(line).forEach(key => {
        if (key !== 'no') {
          message += this.errorMessage(key, line[key]);
        }
      });
      rows.push(<div key={ line.no }>{ line.no }: { message }</div>)
    });
    return rows;
  }

  results() {
    if (this.props.results.error === 'validation') {
      if (this.props.results.file) {
        return (
          <div className="inner">
            <div>CSVファイルではありません。</div>
          </div>
        );
      } else {
        return (
          <div className="inner">
            <div>CSVファイルにエラーがあります（{ this.props.results.lines.length }/{ this.props.results.total }）。</div>
            <div className="errors">
              { this.errors() }
            </div>
            <div>CSVファイルを修正して、再度アップロードしてください。</div>
          </div>
        );
      }
    } else {
      return <div className="inner">CSVファイルの処理を完了しました（{ this.props.results.total }件）。</div>
    }
  }

  render() {
    return(
      <div className="users-results-upload">
        <div className="title">{ this.props.title }</div>
        { this.results() }
        <div className="buttons">
          <button className="btnpt4" onClick={ this.props.okay } >OK</button>
        </div>
      </div>
    );
  }
}

ResultsUploadUsersPopup.PropType = {
  title: PropTypes.string,
  results: PropTypes.array,
  okay:PropTypes.func
};

class User extends Component {

  render() {
    return (
      <tr>
        <td className="name">{ this.props.detail.sei }&nbsp;{ this.props.detail.mei }</td>
        <td className="email">
          <div>
            { this.props.detail.email }
            { this.getSendMailButton()}
          </div>
        </td>
        <td className="disp">{ this.props.detail.user_name }</td>
        <td className="dept">{ this.props.detail.dept }</td>
        <td className="authority">{ authorization(this.props.detail.authorization) }</td>
        <td className="functions">
          <div>
            { this.getOptionButton() }
          </div>
        </td>
      </tr>
      );
  }

  getOptionButton() {
    if (isAdmin() && this.props.detail.email !== getMeEmail()) {
      return (
        <OptionMenu
          onEditGroup={ e => { this.props.popupEditUser(this.props.detail) } }
          onDeleteGroup={ e => { this.props.popupDeleteUser(this.props.detail) } }
        />
      );
    }
  }

    getSendMailButton() {
        if (isAdmin() && this.props.detail.email !== getMeEmail()) {
            return (
                <button className="icon-send-mail button-icon" onClick={ e => { this.props.popupSendResetMail(this.props.detail) } } ></button>
            );
        }
    }
}

User.PropType = {
  detail: PropTypes.object,
    popupDeleteUser:PropTypes.func,
    popupEditUser:PropTypes.func,
    popupSendResetMail:PropTypes.func,
};

class Users extends Component {

  constructor(props) {
    super(props);

    this.state = {
      total: 0,
      per_page: 20,
      current: 0,
      last: 0,
      details: undefined
    };
  }

  componentDidMount() {
    this.getCurrentPage();
  }

  getCurrentPage(page = null) {

    new Service().users(page === null ? this.state.current : page).subscribe(
      payload => {
        this.setState({
          total: payload.total,
          per_page: payload.per_page,
          current: payload.current_page,
          last: payload.last_page,
          details: payload.data
        });
      },
      error => {
        this.props.handlers.error(error);
      }
    );
  }

  users() {
    var users = [];
    this.state.details.forEach(user => {
      users.push(<User key={ user.id } detail={ user }
                       popupDeleteUser={ this.popupDeleteUser.bind(this) }
                       popupEditUser={ this.popupEditUser.bind(this) }
                       popupSendResetMail={ this.popupSendResetMail.bind(this) } />);
    });
    return users;
  }

  details() {
    if (this.state.details) {
     if (this.state.details.length > 0) {
        return (
          <div className="data">
            <div  className="data-detail" ref={ node => this.dataDetail = node } >
              <table>
                <thead>
                <tr>
                  <th className="name">名前</th>
                  <th className="email">メールアドレス</th>
                  <th className="disp">表示名</th>
                  <th className="dept">所属</th>
                  <th className="authority">権限</th>
                  <th className="functions"></th>
                </tr>
                </thead>
                <tbody>
                  { this.users() }
                </tbody>
              </table>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <p>メンバーを追加して下さい。</p>
            </div>
          );
      }
    }
    return null;
  }

  isCreateUserEnabled() {
    if(isAdmin()) {
      return true;
    }
    let settings = customerSettings();
    if(settings.general_users_can_create_users === 1) {
      return true;
    }

    return false;
  }

  getCreateUserButton() {
    if(this.isCreateUserEnabled()) {
        return (
            <button className="button-1" onClick={ this.popupCreateUser.bind(this) } >メンバーを招待</button>
        );
    }

    return null;
  }

  render() {
    return (
      <div className="users">
        <div className="header">
          <div className="title">メンバー一覧</div>
          <div className="operations">
            { this.getCreateUserButton() }
            { this.getUserCSVImportButton() }
            { this.getUserCSVDownloadButton() }
            { this.getUserCSVDeleteButton() }
          </div>
        </div>
        { this.details() }
        <PaginationBar
            total={ this.state.total }
            per_page={ this.state.per_page }
            current={ this.state.current }
            last={ this.state.last }
            onClick={ this.getCurrentPage.bind(this) }
        />
      </div>
    );
  }

  getUserCSVImportButton() {
    if (isAdmin()) {
      return (
        <button className="button-2" onClick={ this.popupUploadUsers.bind(this) } >CSVインポート</button>
      );
    }
    return null;
  }

  getUserCSVDownloadButton() {
    if (isAdmin()) {
      return (
          <button className="button-2" onClick={ this.downloadUsers.bind(this) } >CSVエクスポート</button>
      );
    }
    return null;
  }

  getUserCSVDeleteButton() {
    if (isAdmin()) {
      return (
          <button className="button-2" onClick={ this.popupDeleteUsers.bind(this) } >CSV一括削除</button>
      );
    }
    return null;
  }

  popupCreateUser() {
    this.props.handlers.showPopup(<UserPopup title={ '新規メンバーを招待' } close={ this.closeUserPopup.bind(this) } handlers={ this.props.handlers } />);
  }

    popupEditUser(detail) {
        this.props.handlers.showPopup(<UserPopup title={'メンバーを編集'}
                                                 close={this.closeUserPopup.bind(this)}
                                                 handlers={this.props.handlers}
                                                 user_id={detail.id}/>);
    }

  closeUserPopup() {
    this.props.handlers.hidePopup();
    this.getCurrentPage();
  }

  popupUploadUsers() {
    this.props.handlers.showPopup(<UploadUsersPopup close={ this.closeUploadUsersPopup.bind(this) } handlers={ this.props.handlers } />);
  }

  downloadUsers(event) {
    event.preventDefault();
    const url = apiUrl(`users/download`);
    window.location.href = url;
  }

  popupDeleteUsers() {
    this.props.handlers.showPopup(<DeleteUsersPopup close={ this.closeDeleteUsersPopup.bind(this) } handlers={ this.props.handlers } />);
  }

  closeUploadUsersPopup(results) {
    this.props.handlers.hidePopup();
    if (results) {
      this.props.handlers.showPopup(<ResultsUploadUsersPopup results={ results } title={"メンバーCSVインポート"} okay={ this.closeResultUploadUsersPopup.bind(this) } />);
    }
  }

  closeDeleteUsersPopup(results) {
    this.props.handlers.hidePopup();
    if (results) {
      this.props.handlers.showPopup(<ResultsUploadUsersPopup results={ results } title={"メンバーCSV一括削除"} okay={ this.closeResultUploadUsersPopup.bind(this) } />);
    }
  }

  closeResultUploadUsersPopup() {
    this.props.handlers.hidePopup();
    this.getCurrentPage();
  }

  popupDeleteUser(detail) {
    const message = `${detail.user_name}を削除しますか？`;
    this.props.handlers.showPopup(
      <AlertTwoButton
        title="メンバー削除"
        message={ message }
        cancel={ this.props.handlers.hidePopup }
        okay={ this.checkAndDeleteUser(detail.id) }
        okayButtonText="削除する"
      />
    );
  }

    confirmDeleteEditingUser(id) {
    this.props.handlers.showPopup(
      <AlertTwoButton title="メンバー削除" message="議事録編集中のメンバーです。議事録の編集を終了し、メンバーを削除しますか？"
                      cancel={ this.props.handlers.hidePopup } okay={ this.deleteUser(id) }  okayButtonText="削除する" />
    );
  }

    popupSendResetMail(detail) {
        const message = `「${detail.user_name}」にパスワードリセット用メールを送付しますか？`;
        this.props.handlers.showPopup(
            <AlertTwoButton
              title="メール送信確認"
              message={ message }
              cancel={ this.props.handlers.hidePopup }
              okay={ this.sendResetMail(detail.email) }
              okayButtonText="送信する"
            />
        );
    }

    sendResetMail(email) {
        return () => {
            var form = new FormData();
            form.append('email', email);

            this.props.handlers.showProgress();
            new Service().sendPasswordResetMail(form)
                .finally(() => { this.props.handlers.hideProgress(); })
                .subscribe(
                    payload => {
                        this.props.handlers.hidePopup();
                        console.log(payload);
                        this.props.handlers.showPopup(
                            <AlertOneButton title="メール送信完了"
                                            message="パスワードリセット用メールを送信しました。"
                                            okay={ this.props.handlers.hidePopup }
                            />
                        );
                    },
                    error => {
                        this.props.handlers.hidePopup();
                        console.log(error);
                        this.props.handlers.showPopup(
                            <AlertOneButton title="ネットワークエラー"
                                            message="パスワードリセット用メールの送信に失敗しました。"
                                            okay={ this.props.handlers.hidePopup }
                            />
                        );
                    }
                );
        }
    }

    alertLockedUser(error) {
    var message = '';
    if (error === 'progress') {
      message = "開催中の会議に出席しているメンバーのため、削除できません。";
    }
    this.props.handlers.showPopup(
      <AlertOneButton title="メンバー削除" message={ message } okay={ this.props.handlers.hidePopup } />
    );
  }

  checkAndDeleteUser(id) {
    return () => {
      let published = new Service().isUserEditingMinute(id).share();

      // if the user is editing a minute
      published
        .filter(function (val, idx, obs) {
          return val.editing;
        })
        .subscribe(
          payload => {
            this.props.handlers.hidePopup();
            this.confirmDeleteEditingUser(id);
          },
          error => {
            this.props.handlers.hidePopup();
            this.props.handlers.error(error);
          }
        );

      // unless the user is editing a minute
      published
        .catch(function (e) {
          // prevent to handle a same error.
          return Observable.empty();
        })
        .filter(function (val, idx, obs) {
          return ! val.editing;
        })
        .flatMap(function (x, i) {
          return new Service().deleteUser(id);
        })
        .subscribe(
          payload => {
            this.props.handlers.hidePopup();
            this.getCurrentPage();
          },
          error => {
            this.props.handlers.hidePopup();
            if (error.response.status === 423) { // locked
              error.response.json().then(body => {
                this.alertLockedUser(body["error"]);
              });
            } else {
              this.props.handlers.error(error);
            }
          }
        );
    }
  }

  deleteUser(id) {
    return () => {
      new Service().deleteUser(id).subscribe(
        payload => {
          this.props.handlers.hidePopup();
          this.getCurrentPage();
        },
        error => {
          this.props.handlers.hidePopup();
          if (error.response.status === 423) { // locked
            error.response.json().then(body => {
              this.alertLockedUser(body["error"]);
            });
          } else {
            this.props.handlers.error(error);
          }
        }
      );
    };
  }
}

export default Users;
