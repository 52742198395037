import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Service from '../services/MeetingService';
import Spinner from "react-md-spinner";
import { AlertTwoButton } from '../components/Alert';
import WordPopup from './WordPopup';
import UploadWordsPopup, { ResultsUploadWordsPopup } from './UploadWordsPopup';
import { apiUrl, customerSettings } from './utilities';
import { isAdmin } from './AccountPopup';
import PaginationBar from "../components/PaginationBar";

import './Words.css';

class Word extends Component {

  render() {
    return (
      <tr>
        <td className="word">{ this.props.word }</td>
        <td className="yomi">{ this.props.yomi }</td>
        <td className="category">{ this.props.category }</td>
        <td className="functions">
          <div>
            <button style={{ visibility: this.props.canEdit ? 'visible' : 'hidden' }} className="icon-pencil btnicn" onClick={ e => { this.props.popupUpdateWord(e, this.props.id) } } />
            <button style={{ visibility: this.props.canEdit ? 'visible' : 'hidden' }} className="icon-trash btnicn" onClick={ e => { this.props.popupDeleteWord(e, this.props) } } />
          </div>
        </td>
      </tr>
      );
  }
}

Word.PropType = {
  id: PropTypes.number,
  word: PropTypes.string,
  yomi: PropTypes.string,
  category: PropTypes.string,
  popupUpdateWord:PropTypes.func,
  popupDeleteWord:PropTypes.func,
  canEdit: PropTypes.number
};

export class Words extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dictonary_prepared: null,
      total: 0,
      per_page: 20,
      current: 0,
      last: 0,
      details: null,
      id: this.props.id,
    };
  }

    canEditWord() {
        if(isAdmin()) {
            return true;
        }

        if(this.state.dictonary_prepared === null) {
            return false;
        }

        if(this.state.dictonary_prepared) {
            let settings = customerSettings();
            if(settings.general_users_can_edit_common_dictionary === 1) {
                return true;
            }
        } else {
            return true;
        }

        return false;
    }

    isButtonEnabled() {
        if(this.state.total >= 500) {
            return false;
        }
        return this.canEditWord();
    }

  render() {
    return (
      <div className="words">
        <div className="words-header">
          <div className="words-title">{ this.props.dictionaryName }</div>
          <button disabled={ !this.isButtonEnabled() } className="button-1" onClick={ this.popupCreateWord.bind(this) } >単語を追加</button>
          <button disabled={ !this.isButtonEnabled() } className="button-2" onClick={ this.popupUploadWords.bind(this) } >CSVインポート</button>
          <button disabled={ this.state.total === 0 } className="button-2" onClick={ this.downloadWords.bind(this) } >CSVエクスポート</button>
        </div>
        { this.details() }
        <PaginationBar
            total={ this.state.total }
            per_page={ this.state.per_page }
            current={ this.state.current }
            last={ this.state.last }
            onClick={ this.getCurrentPage.bind(this) }
        />
      </div>
    );
  }

  loadWords() {
    this.props.handlers.showProgress();
    let service = new Service();
    service.dictionary(this.props.id)
        .subscribe(
            payload => {
              this.props.handlers.hideProgress();
              this.setState({
                dictonary_prepared: payload.prepared
              });
              this.getCurrentPage();
            },
            error => {
              this.props.handlers.hideProgress();
              this.props.handlers.error(error);
            }
        );
  }

  componentDidMount() {
    this.loadWords();
  }

  componentDidUpdate() {
    if (this.props.id !== this.state.id) {
      this.setState({
        id: this.props.id,
        current: 0
      });
      this.loadWords();
    }
  }

  getCurrentPage(page = null) {

    new Service().words(this.props.id, page === null ? this.state.current : page).subscribe(
      payload => {
        this.setState({
          total: payload.total,
          per_page: payload.per_page,
          current: payload.current_page,
          last: payload.last_page,
          details: payload.data
        });
      },
      error => {
        this.props.handlers.error(error);
      }
    );
  }

  words() {
    var words = [];
    this.state.details.forEach(word => {
      words.push(
        <Word
          key={ word.id }
          id={ word.id }
          word={ word.word }
          yomi={ word.yomi }
          category={ word.category.name }
          popupUpdateWord={ this.popupUpdateWord.bind(this) }
          popupDeleteWord={ this.popupDeleteWord.bind(this) }
          canEdit={ this.canEditWord() }
        />);
    });
    return words;
  }

  details() {
    if (this.state.details) {
     if (this.state.details.length > 0) {
        return (
          <div className="data">
            <table>
              <thead>
                <tr>
                  <th className="word">表記</th>
                  <th className="yomi">読み</th>
                  <th className="category">カテゴリー</th>
                  <th className="functions"/>
                </tr>
              </thead>
              <tbody>
                { this.words() }
              </tbody>
            </table>
          </div>
        );
      } else {
        return (
          <div>
            <p>登録済み単語はありません。</p>
            </div>
          );
      }
    }
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  popupCreateWord(event) {
    event.preventDefault();
    this.props.handlers.showPopup(<WordPopup title={ '単語の追加' } dictionaryId={ this.props.id } close={ this.closePopup.bind(this) } error={ this.props.handlers.error } />);
  }

  popupUpdateWord(event, id) {
    event.preventDefault();
    this.props.handlers.showPopup(<WordPopup title={ '単語の変更' } dictionaryId={ this.props.id } id={ id } close={ this.closePopup.bind(this) } error={ this.props.handlers.error } />);
  }

  popupDeleteWord(event, detail) {
    event.preventDefault();
    const message = `「${detail.word}」を削除しますか？`;
    this.props.handlers.showPopup(
      <AlertTwoButton title="単語の削除" message={ message } cancel={ this.props.handlers.hidePopup } okay={ this.deleteWord(this.props.id, detail.id) } okayButtonText="削除する" />
    );
  }

  deleteWord(dictonary_id, id) {
    return () => {
      let service = new Service();

      service.deleteWord(dictonary_id, id).subscribe(
        payload => {
          this.props.handlers.hidePopup();
          this.props.onChange();
          this.getCurrentPage();
        },
        error => {
          this.props.handlers.error(error);
        }
      );
    };
  }

  popupUploadWords(event) {
    this.props.handlers.showPopup(<UploadWordsPopup id={ this.props.id } close={ this.closeUploadWordsPopup.bind(this) } handlers={ this.props.handlers } />);
  }

  closeUploadWordsPopup(results) {
    this.props.handlers.hidePopup();
    if (results) {
      this.props.handlers.showPopup(<ResultsUploadWordsPopup results={ results } okay={ this.closeResultUploadWordsPopup.bind(this) } />);
    }
  }

  closeResultUploadWordsPopup() {
    this.props.handlers.hidePopup();
    this.getCurrentPage();
  }

  closePopup() {
    this.props.handlers.hidePopup();
    this.props.onChange();
    this.getCurrentPage();
  }

  downloadWords(event) {
    event.preventDefault();
    const url = apiUrl(`dictionaries/${this.props.id}/words/download`);
    window.location.href = url;
  }

}

Words.PropType = {
  id: PropTypes.number,
  dictionaryName: PropTypes.string,
  onChange:PropTypes.func,
  handlers: PropTypes.object,
};


export default Words;
