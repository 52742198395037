import React, { Component } from 'react';
import FormData from 'form-data';
import Service from '../services/MeetingService';

import './AccountPopup.css';

export function authorizations() {
  return ['一般', '管理者'];
}

export function authorizations_ss() {
  return ['無効', '一般', '管理者'];
}

export function authorization(val) {
  return ['一般', '管理者', 'スーパーユーザー'][val];
}

export function isAdmin() {
  return +sessionStorage.getItem('authorization') === 1;
}

export function getMeEmail() {
  return sessionStorage.getItem('email');
}

class AccountPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      user: {
        sei: '',
        mei: '',
        user_name: '',
        dept: '',
        email: '',
        authorization: 0,
        authorization_ss: -1,
        password: '',
        new_password: '',
        new_password_confirmation: '',
        customer: {
          name: ''
        }
      },
      errors: {
        sei: null,
        mei: null,
        user_name: null,
        dept: null,
        password: null,
        new_password: null
      }
    };
  }

  cancel(event) {
    event.preventDefault();
    this.props.handlers.hidePopup();
  }

  submit(event) {
    event.preventDefault();

    var form = new FormData();
    form.append('sei', this.state.user.sei);
    form.append('mei', this.state.user.mei);
    form.append('user_name', this.state.user.user_name);
    form.append('dept', this.state.user.dept);
    if (this.state.user.new_password) {
      if (this.state.user.password) {
        form.append('password', this.state.user.password);
      }
      if (this.state.user.new_password) {
        form.append('new_password', this.state.user.new_password);
      }
      if (this.state.user.new_password_confirmation) {
        form.append('new_password_confirmation', this.state.user.new_password_confirmation);
      }
    }

    new Service().updateMe(form)
      .subscribe(
        payload => {
          if (this.errorsToState(payload)) {
            return;
          }
          this.props.handlers.hidePopup();
          this.props.handlers.userInfo(payload);
        },
        error => {
          this.props.handlers.hidePopup();
          this.props.handlers.error(error);
        }
      );
  }

  componentDidMount() {

    new Service().me().subscribe(
      payload => {
        this.setState({ user: payload });
      },
      error => {
        this.props.handlers.error(error);
      }
    );
  }

  authorization() {
    return ['一般', '管理者'][this.state.user.authorization];
  }

  authorization_ss() {
    return ['無効', '一般', '管理者'][this.state.user.authorization_ss];
  }

  errorsToState(payload) {
    if (payload.error === 'validation') {
      delete(payload.error);
      var errors = [];
      Object.keys(payload).forEach(key => {
        var error = payload[key][0];
        errors[key] = error.split('.')[1];
      });
      this.setState({ errors: errors });
      return true;
    }
    return false;
  }

  fieldToLabel(field) {
    let names = {
      sei: '姓',
      mei: '名',
      user_name: '表示名',
      dept: '所属',
      password: 'パスワード',
      new_password: '新しいパスワード'
    }
    return names[field];
  }

  errorMessage(field) {
    let error = this.state.errors[field];
    if (error) {
      let label = this.fieldToLabel(field);
      let messages = {
        required: `${ label }は必須入力です。`,
        required_with: `${ label }を入力してください。`,
        min: `${ label }は6文字以上で入力してください。`,
        confirmed: `${ label }が確認のものと一致しません。`,
        password: 'パスワードが誤っています。',
        regex: `${ label }に使用できない文字が含まれています。`,
      }
      return <div><div className="error">{ messages[error] }</div></div>;
    }
    return null;
  }

  changeText(e, item) {
    var user = this.state.user;
    user[item] = e.target.value;
    this.setState({ user: user });
  }

  render() {
    return (
      <div className="account-popup">
        <div className="title">ユーザー情報編集</div>
        <div className="inner">
          <form>
            <div><label>顧客名</label>{ this.state.user.customer.name }</div>
            <div><label>権限</label>{ authorization(this.state.user.authorization) }</div>
            <div><label>email</label>{ this.state.user.email }</div>
            <div className="sei-mei">
              <div className="sei">
                <div><label className="required">姓</label><input maxLength={30} ref="name" type="text" value={ this.state.user.sei } onChange={ e => { this.changeText(e, 'sei') } } autoFocus /></div>
                { this.errorMessage('sei') }
              </div>
              <div className="mei">
                <div><label className="required">名</label><input maxLength={30} ref="name" type="text" value={ this.state.user.mei } onChange={ e => { this.changeText(e, 'mei') } } /></div>
                { this.errorMessage('mei') }
              </div>
            </div>
            <div><label className="required">表示名</label><input maxLength={8} ref="name" type="text" value={ this.state.user.user_name } onChange={ e => { this.changeText(e, 'user_name') } }  /></div>
            { this.errorMessage('user_name') }
            <div><label>所属</label><input maxLength={100} ref="name" type="text" value={ this.state.user.dept } onChange={ e => { this.changeText(e, 'dept') } } /></div>
            { this.errorMessage('dept') }
            <div><label>パスワード</label><input maxLength={64} ref="password" type="password" onChange={ e => { this.changeText(e, 'password')} } /></div>
            { this.errorMessage('password') }
            <div><label>新しいパスワード</label><input maxLength={64} ref="new_password" type="password" onChange={ e => { this.changeText(e, 'new_password')} } /></div>
            { this.errorMessage('new_password') }
            <div><label>パスワードの確認</label><input maxLength={64} ref="new_password_confirmation" type="password" onChange={ e => { this.changeText(e, 'new_password_confirmation')} } /></div>
          </form>
        </div>
        <div className="buttons">
          <button className="button-text" type="button" onClick={ e => { this.cancel(e) } }>キャンセル</button>
          <button className="button-1" type="button" onClick={ e => { this.submit(e) } }>変更する</button>
        </div>
      </div>
    );

  }
}

export default AccountPopup;
