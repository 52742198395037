import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import ClassNames from 'classnames';

import Service from '../services/MeetingService';
import Minute from './Minute';
import CommentTab from './CommentTab';
import MemoTab from './MemoTab';
import {AlertOneButton, AlertTwoButton} from '../components/Alert';
import MeetingPopup from './MeetingPopup';
import MeetingTimeEditorPopup from './MeetingTimeEditorPopup';
import MeetingGuestRenamePopup from './MeetingGuestRenamePopup';
import {apiUrl, getSearchParamsForReactRouter, isChromeIOSBrowser, isIEBrowser, routePath2href} from './utilities';
import {setPersonalSettingInfo} from "./Login";
import Agenda from './Agenda';

import 'moment/locale/ja';
import './MeetingEditor.css';
import Auth from "../components/Auth";
import {Observable} from "rx";

import Const from "../components/Const";

export default class MeetingEditor extends Component {

  constructor(props) {
    super(props);

    Moment.locale('ja');

    this.state = {
      id: null,
      title: '',
      start_date: null,
      estimated_start_at: null,
      estimated_end_at: null,
      start_at: null,
      end_at: null,
      room: '',
      members: [],
      engine: '',
      dictionary: '',
      documents: [],
      use_base_time: false,
      base_time: null,
      minute: {
        disabled: true,
        dirty: false,
        existed: false,
        updated_at: null
      },
      isSidebarOpen: true,
      isMeetingMinuteOpen: false,
      isMemoOpen: false,
      isSavingMinute: false,
      isBroadcaster: false,
      isMeetingDatetimeOpen: true,
      isRoomOpen: true,
      isMembersOpen: true,
      isDocumentsOpen: true,
      isEngineOpen: true,
      isDictionaryOpen: true,
      meetingDataLoaded: false,
      customerDataLoaded: false,
      personalSettingsLoaded: false,
      minutes_type: null,
      agenda: {
        next_date: null,
        next_start_at: null,
        next_end_at: null,
        overall_comment: '',
        updated_at: null,
        agenda_parts: []
      },
      memoOrder: 'desc',
      updated_at: null,
      hasError: false
    };
    this.onBeforeUnload = this.onBeforeUnload.bind(this);
  }

  componentWillUnmount() {
    if (typeof window !== undefined) {
      window.removeEventListener('beforeunload', this.onBeforeUnload);
    }
    this.unsubscribe();
  }

  /**
   * 購読開始。
   */
  subscribe() {
    if (!this.subscriber) {
      this.subscriber = Observable.interval(3000).subscribe(
          () => {
            if(this.state.hasError) {
              // 広域プログレスサークルを表示している場合、エラー時には即解除
              this.props.handlers.hideGlobalProgressIfNeeded();
              return;
            }
            this.reload();
          }
      );
    }

  }

  /**
   * 購読停止。
   */
  unsubscribe() {
    if (this.subscriber) {
      this.subscriber.dispose();
    }
  }

  onBeforeUnload(event) {
    if (this.isStartedEditingMinute() && this.areYouEditingMinute()) {
      event.returnValue = '議事録編集中ですが、このページを閉じてよいですか？';
    }
  }

  componentDidMount() {
    if (typeof window !== undefined) {
      window.addEventListener('beforeunload', this.onBeforeUnload, false);
    }
    this.props.handlers.setVisibledNavi(false);
    
    let meeting_id = getSearchParamsForReactRouter().get("id");

    const service = new Service();
    service.customer()
      .subscribe(
        customer => {
          this.setState({
            customerDataLoaded: true,
            isBroadcaster: (customer.is_broadcaster === 1),
            canTranslate: (customer.can_translate !== 0)
          });
        },
        error => {
          console.log(error);
          this.props.handlers.error(error);
        }
      );
      this.reload(false, meeting_id);
      service.personalSettings().subscribe(
          payload => {
            setPersonalSettingInfo(payload);
            this.setState({
                personalSettingsLoaded : true,
            });
          },
          error => {
              console.log(error);
              this.props.handlers.hideProgress();
              this.props.handlers.error(error);
          }
      );
  }

  getAgendaParts(meeting) {
    let agenda_parts = [];
    if (meeting.agenda && meeting.agenda.agenda_parts) {
      agenda_parts = meeting.agenda.agenda_parts.map(parts => {
        return {
          id: parts.id,
          title: parts.title,
          presenter: parts.presenter,
          time_required: parts.time_required,
          status: parts.status,
          start_at: parts.start_at ? Moment(parts.start_at) : null,
          end_at: parts.end_at ? Moment(parts.end_at) : null,
          usage_time: parts.usage_time,
          person_in_charge: parts.person_in_charge,
          deadline: parts.deadline ? Moment(parts.deadline) : null,
          decision: parts.decision,
          comment: parts.comment,
          display_order: parts.display_order
        };
      });
    }
    return agenda_parts;
  }

  participatingMembers(isParticipataing) {
    return this.state.members.filter(member => member.is_participating === isParticipataing)
        .map(member => {
          if ((member.authorization & Const.PERMISSION_CODE_GUEST) !== 0) {
            return member.user_name + Const.GUEST_NAME_SUFFIX;
          }
          return member.user_name;
        }).join(' / ');
  }


  downloadDocument(event, documentId) {
    event.preventDefault();
    window.open(apiUrl(`meetings/${this.state.id}/documents/${documentId}/download`));
  }

  documents() {
    const documents = [];
    this.state.documents.forEach((document, index, array) => {
      documents.push(
        <div key={ document.id } className="download-document button-2-gray" onClick={ e => { this.downloadDocument(e, document.id) }}>
          <div>{ document.name }</div>
          <div className="icon-download-small"/>
        </div>);
    });
    return documents;
  }

  formatMeetingDatetime(startDatetime, endDatetime) {
    let dateStr = '---- / -- / --';
    let timeStr = '-- : --';
    let output;
    if (startDatetime) {
      output = startDatetime.format('YYYY/MM/DD') + '　開始' + startDatetime.format('HH:mm');
      if (endDatetime) {
        output += '　〜　終了' + endDatetime.format('HH:mm');
      } else {
        output += '　〜　終了' + timeStr;
      }

    } else {
      output = dateStr + '　開始' + timeStr + '　〜　終了' + timeStr;
    }
    return output;
  }

  isStartedMeeting() {
    return this.state.start_at !== null;
  }

  isFinishedMeeting() {
    return this.state.end_at !== null;
  }

  canStartMeeting() {
    if(this.isBroadcaster()) {
      //会議開始時間がない、会議終了時刻がない -> true  会議開始前(会議開始はできる)
      //会議開始時間がない、会議終了時刻がある -> true  想定外
      return !this.state.start_at;
    }
    //会議開始時間がない、会議終了時刻がない -> true  会議開始前(会議開始はできる)
    //会議開始時間がない、会議終了時刻がある -> true  想定外
    //会議開始時間がある、会議終了時刻がない -> false 会議中(会議開始はできない)
    //会議開始時間がある、会議終了時刻がある -> true  会議終了済み(会議再開できる)
    return !this.state.start_at || this.state.end_at;
  }

  dataLoaded() {
    if(this.state.meetingDataLoaded === false) {
      return false;
    }
    if(this.state.customerDataLoaded === false) {
        return false;
    }
    return this.state.personalSettingsLoaded !== false;
  }

  canStartOrFinishMeeting() {
    return !this.state.start_at || !this.state.end_at;
  }

  isStartedEditingMinute() {
    return this.state.minute 
        && this.state.minute.start_at
        && !this.state.minute.end_at;
  }

  isEndedEditingMinute() {
    return this.state.minute && this.state.minute.end_at;
  }

  isBroadcaster() {
    return this.state.isBroadcaster;
  }

  canStartEditingMinute() {
    return true;
  }

  areYouEditingMinute() {
    const id = sessionStorage.getItem('id');
    return this.state.minute && this.state.minute.start_at && (id !== null && this.state.minute.editor && +id === this.state.minute.editor.id);
  }

  canFinishEditingMinute() {
    return this.areYouEditingMinute() && !this.state.minute.dirty
  }

  acquiredEditingMinute(minute) {
    return minute !== null && minute.end_at === null;
  }

  canSaveEditingMinute() {
    // 過去のIE対応ではIEではdiv要素でonInputイベントが発生しないため、下記のような処理になっていたようだった。
    // if (isIEBrowser()) {
    //   return this.isStartedEditingMinute() && this.areYouEditingMinute();
    // }
    //
    // Minute.jsを確認したところ、onKeyupでもonChangeを呼ぶ実装になっており、dirtyフラグを立てる部分も
    // 問題なさそうなため、IE固有処理はコメントアウトした。
    // 動作確認した限りではカーソル移動、Ctrlキーでもkeyupと判断されるが許容する。
    return this.isStartedEditingMinute() && this.areYouEditingMinute() && this.state.minute.dirty;
  }

  canDownloadMinute() {
    return this.state.minute.existed;
  }

  onChangeMinute(html) {
    this.setState({ minute: Object.assign({}, this.state.minute, { minute: html, dirty: true }) });
  }

  onChangeAgenda(agenda) {
    this.setState({
      minute: Object.assign({}, this.state.minute, { dirty: true }),
      agenda: Object.assign({}, this.state.agenda, agenda)
    });
  }

  /**
   * ペースト時に呼ばれる関数(現時点ではD&D時には呼ばない。「表示されている発話をコピー」時のみ呼ばれる)
   */
  onPasteToMinute() {
    console.log(this.refs.minute);
    let instance = this.refs.minute.getDecoratedComponentInstance();
    if(instance) {
      instance.emitChange();
    }
  }

  editorName(editor) {
    if (editor == null) {
      return null;
    }
    return editor.user_name;
  }

  minuteInfo() {
    let information;
    if (this.isStartedEditingMinute()) {
      if (this.areYouEditingMinute()) {
        if (isChromeIOSBrowser() || this.state.minutes_type === 1) {
          return null;
        }

        information = <div className="no-border-information">
            <button onClick={ this.onCopyAndPasteAllComments.bind(this) } className="copy-button button-2-green">表示されている発話をコピー</button>
            </div>
      }
    } else if (this.isEndedEditingMinute()) {
      information = <div className="information">最終更新者：{ this.editorName(this.state.minute.editor) }</div>;
    }
    return (
      <div className="header">
        <div className="title">議事録</div>
        { information }
      </div>
    );
  }

  editingMinuteButton() {
    if (!this.isStartedEditingMinute()) {
      return (
        <button disabled={ !this.canStartEditingMinute() } onClick={ this.onClickStartFinishEditingButton.bind(this) } className="button-minute button-1-green">編集開始</button>
      );
    } else {
      return (
        <button disabled={ !this.areYouEditingMinute() } onClick={ this.onClickStartFinishEditingButton.bind(this) } className="button-minute button-2-green">編集終了</button>
      );
    }
  }

  time(time) {
    if (time && time.isValid()) {
      return time.format('HH:mm');
    }
    return '';
  }

  meetingTimes() {
    if (this.state.estimated_end_at) {
      return this.time(this.state.estimated_start_at) + '〜' + this.time(this.state.estimated_end_at);
    } else if (this.state.estimated_start_at) {
      return this.time(this.state.estimated_start_at) + '〜';
    }
    return '';
  }

  meetingDate() {
    if (this.state.start_date) {
      return this.state.start_date.format('YYYY年MM月DD日')
    }
    return '';
  }

  onClickMeetingDatetimeSwitch() {
    this.setState({isMeetingDatetimeOpen: !this.state.isMeetingDatetimeOpen});
  }
  onClickRoomSwitch() {
    this.setState({isRoomOpen: !this.state.isRoomOpen});
  }
  onClickMembersSwitch() {
    this.setState({isMembersOpen: !this.state.isMembersOpen});
  }
  onClickDocumentsSwitch() {
    this.setState({isDocumentsOpen: !this.state.isDocumentsOpen});
  }
  onClickEngineSwitch() {
    this.setState({isEngineOpen: !this.state.isEngineOpen});
  }
  onClickDictionarySwitch() {
    this.setState({isDictionaryOpen: !this.state.isDictionaryOpen});
  }

  renderGuestRenameButton() {
    if(this.getGuestMembers().length === 0) {
      return;
    }
    return (<button onClick={ this.onClickGuestRenameButton.bind(this) } className="button-guest-rename button-2">ゲスト名を編集する</button>);
  }

  sidebar() {
    if (this.state.isSidebarOpen) {
      let hiddenClassForCIOS = ClassNames({ 'hidden': isChromeIOSBrowser() });

      return (
        <div className="sidebar">
          <div className="switch"><button className="button-text" onClick={ this.onClickSidebarSwitch.bind(this) } ><div className="icon-open"/></button></div>
          <div className="header">
            <div className="title">会議情報</div>
          </div>
          <div className="info">
            <div className="section">
              <div className="label-area">
                <div className="label icon-clock-small">会議日時</div>
                <button className="button-text"
                        onClick={ this.onClickMeetingDatetimeSwitch.bind(this) }>
                  <div className={ ClassNames({"icon-left-arrow": true, "open": this.state.isMeetingDatetimeOpen, "closed": !this.state.isMeetingDatetimeOpen}) }/>
                </button>
              </div>
              <div className={ ClassNames({"data": true, "hidden": !this.state.isMeetingDatetimeOpen}) }>
                <span>{ this.meetingDate() }</span>
                <span>{ this.meetingTimes() }</span>
              </div>
            </div>
            <div className="section">
              <div className="label-area">
                <div className="label icon-place-small">場所</div>
                <button className="button-text"
                        onClick={ this.onClickRoomSwitch.bind(this) }>
                  <div className={ ClassNames({"icon-left-arrow": true, "open": this.state.isRoomOpen, "closed": !this.state.isRoomOpen}) }/>
                </button>
              </div>
              <div className={ ClassNames({"data": true, "hidden": !this.state.isRoomOpen}) }>
                <span>{ this.state.room }</span>
              </div>
            </div>
            <div className="section">
              <div className="label-area">
                <div className="label icon-users-small">参加者</div>
                <button className="button-text"
                        onClick={ this.onClickMembersSwitch.bind(this) }>
                  <div className={ ClassNames({"icon-left-arrow": true, "open": this.state.isMembersOpen, "closed": !this.state.isMembersOpen}) }/>
                </button>
              </div>
              <div className={ ClassNames({"data": true, "hidden": !this.state.isMembersOpen}) }>
                <span className="participating-label">参加中</span>
                <span className="member-data">{ this.participatingMembers(true) }</span>
                <span className="non-participating-label">ログインなし</span>
                <span className="member-data">{ this.participatingMembers(false) }</span>
                { this.renderGuestRenameButton() }
              </div>
            </div>
            <div className="section">
              <div className="label-area">
                <div className="label icon-attachment-small">配布資料</div>
                <button className="button-text"
                        onClick={ this.onClickDocumentsSwitch.bind(this) }>
                  <div className={ ClassNames({"icon-left-arrow": true, "open": this.state.isDocumentsOpen, "closed": !this.state.isDocumentsOpen}) }/>
                </button>
              </div>
              <div className={ ClassNames({"data": true, "hidden": !this.state.isDocumentsOpen}) }>
                <div className="documents">
                  { this.documents() }
                </div>
                <button hidden={ !this.state.documents || this.state.documents.length <= 0 } onClick={ this.downloadDocuments.bind(this) } className="button-download button-text">一括ダウンロード(ZIP)</button>
              </div>
            </div>
            <div className="section">
              <div className="label-area">
                <div className="label icon-microphone-small">音声認識エンジン</div>
                <button className="button-text"
                        onClick={ this.onClickEngineSwitch.bind(this) }>
                  <div className={ ClassNames({"icon-left-arrow": true, "open": this.state.isEngineOpen, "closed": !this.state.isEngineOpen}) }/>
                </button>
              </div>
              <div className={ ClassNames({"data": true, "hidden": !this.state.isEngineOpen}) }>
                <span>{ this.state.engine }</span>
              </div>
            </div>
            <div className="section">
              <div className="label-area">
                <div className="label icon-book-small">単語帳</div>
                <button className="button-text"
                        onClick={ this.onClickDictionarySwitch.bind(this) }>
                  <div className={ ClassNames({"icon-left-arrow": true, "open": this.state.isDictionaryOpen, "closed": !this.state.isDictionaryOpen}) }/>
                </button>
              </div>
              <div className={ ClassNames({"data": true, "hidden": !this.state.isDictionaryOpen}) }>
                <span>{ this.state.dictionary }</span>
              </div>
            </div>
          </div>
          <div className="operations">
            <div className={ hiddenClassForCIOS } hidden={ !this.isBroadcaster() }><button onClick={ this.onClickEditBaseTime.bind(this) } className="button-popup btnpt2">任意の会議開始日時を設定</button></div>
            <div className={ ClassNames({ 'hidden': isChromeIOSBrowser(), 'mail': true, 'icon-mail-small': true }) }><button onClick={ this.onClickClippingEmails.bind(this) } className="button-popup button-text">参加者のメールアドレス</button></div>
            <div className={ ClassNames({ 'hidden': isChromeIOSBrowser(), 'link': true, 'icon-link-small': true }) }><button onClick={ this.onClickClippingMeetingURL.bind(this) } className="button-popup button-text">このページのURL</button></div>
            <div><button onClick={ this.onClickPopupMeeting.bind(this) } className="button-edit button-2" disabled={ this.isStartedEditingMinute() && !this.areYouEditingMinute() }>会議情報を編集する</button></div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="sidebar closed">
          <div className="switch"><button className="button-text" onClick={ this.onClickSidebarSwitch.bind(this) } ><div className="icon-closed"/></button></div>
        </div>
      );
    }
  }

  meetingMinute() {
    if (isChromeIOSBrowser()) {
      return null;
    }

    return (
      <div className={ ClassNames({ 'meeting-minute': true, 'hidden': !this.state.isMeetingMinuteOpen }) }>
        <div className="switch">
          <button className="button-text" onClick={ this.onClickMeetingMinuteSwitch.bind(this) } ><div className="icon-open"/></button>
        </div>
        { this.minuteInfo() }
        { this.minuteBackground() }
        <div className={ ClassNames({ body: true, 'select': true } ) }>
            { this.minuteBody() }
        </div>
        <div className={ ClassNames({ operations: true }) }>
          <div>
            <div className="download-minute">
              <label className="button-download button-text2" disabled={ !this.canDownloadMinute() } onClick={ this.downloadMinute.bind(this) } >議事録を保存(Word)</label>
              <div className="button-icon-transparent icon-download-small" disabled={ !this.canDownloadMinute() } onClick={ this.downloadMinute.bind(this) }/>
            </div>
          </div>
          <div className="buttons">
            <button disabled={ !this.canSaveEditingMinute() } onClick={ this.onClickSaveMinute.bind(this) } className="button-minute button-1-green">保存する</button>
            { this.editingMinuteButton() }
          </div>
        </div>
      </div>
    );
  }

  minuteBody() {
    if (!this.isStartedEditingMinute() || this.areYouEditingMinute()) {
      if (this.state.minutes_type === 0) {
        return (
            <Minute
                ref="minute" html={this.state.minute.minute || ''}
                disabled={this.state.minute.disabled}
                onChange={this.onChangeMinute.bind(this)} // handle innerHTML change
                onError={this.alert.bind(this)}
            />
        );
      } else if (this.state.minutes_type === 1) {
        return (
            <Agenda
                ref="agenda"
                id={this.state.id}
                disabled={this.state.minute.disabled}
                agenda={ this.state.agenda }
                handlers={ this.props.handlers }
                canStartAgenda={ !this.canStartMeeting() }
                onError={ this.alert.bind(this) }
                onChange={this.onChangeAgenda.bind(this)}
                reload={this.reload.bind(this)}
            />
        );
      } else {
        return null;
      }
    }
  }

  startEndButton() {
    // 会議がSSへ依頼中の場合、会議の「開始（終了）」ボタンは表示させない
    if(this.state.status !== -2)
    {
      let classname = ClassNames({ 'button-stop': true, 'button-2': this.canStartMeeting(), 'button-2-red': !this.canStartMeeting() });
      let startEndButtonDisabled = !this.dataLoaded() || (this.isBroadcaster() && this.state.end_at);
      const button = this.canStartMeeting() ? <div><span>会議開始</span>
        <div className={startEndButtonDisabled ? "icon-start-small-disabled" : "icon-start-small"}/>
      </div> : <div><span>会議終了</span>
        <div className={startEndButtonDisabled ? "icon-stop-small-disabled" : "icon-stop-small"}/>
      </div>;
      return (<button onClick={ this.onClickStartFinishButton.bind(this) } className={ classname } disabled={ startEndButtonDisabled } >{ button }</button>);
    }
  }

  minuteBackground() {
    if (this.isStartedEditingMinute()) {
      if (!this.areYouEditingMinute()) {
        return (<div className="background">
          <div className="info">議事録は　<span>{ this.state.minute.editor.user_name }</span>　が編集中です。</div>
          <div className="hint">編集者以外の閲覧はできません。</div>
        </div>);
      }
    }

    if (this.state.minute && this.state.minute.minute && this.state.minute.minute.length > 0) {
      return null;
    }

    if (this.state.minutes_type !== 0) {
      return null;
    }

    return (
      <div className="background">
        <div className="hint">このエリアはテキスト入力ができます。<br />また、発話、写真をドラッグして<br />議事録にコピーすることも可能です。</div>

        {(() => {
          if (!this.isStartedEditingMinute() && !this.isEndedEditingMinute())
            return (
              <div className="functions">
                <div className="attention">編集を開始すると、他のユーザーは議事録の閲覧、編集ができなくなります。</div>
              </div>
            );
        })()}
      </div>
    );
  }

  render() {
    if(!Auth.isAuthenticated()) {
      return null;
    }

    let id = getSearchParamsForReactRouter().get("id");

    return (
      <div className="meeting-editor">
        <div className="header">
          <div className="logo">
            <div className="img-logo"/>
          </div>
          <div className="title">{ this.state.title }</div>
          <div className="meeting-time">
            {this.formatMeetingDatetime(this.state.start_at, this.state.end_at)}
          </div>
          { this.startEndButton() }
        </div>
        <div className="mainpane">
          { this.sidebar() }
          <div className={ ClassNames({ 'meeting-minute-closed': true, 'hidden': this.state.isMeetingMinuteOpen }) }>
            <div className={ ClassNames({ 'switch': true }) }>
              <button className="button-text" onClick={ this.onClickMeetingMinuteSwitch.bind(this) } ><div className="icon-closed"/></button>
            </div>
          </div>
          { this.meetingMinute() }
          <div className={ ClassNames({ 'memo-closed': true, 'closed': true, 'hidden': this.state.isMemoOpen }) }>
            <div className={ ClassNames({ 'switch': true }) }>
              <button className="button-text" onClick={ this.onClickMemoSwitch.bind(this) } ><div className="icon-closed"/></button>
            </div>
          </div>
          { this.meetingMemo() }
          <div className="comment">
            <CommentTab
              id={ id }
              ref="CommentTab"
              handlers={ this.props.handlers }
              paste={ this.onPasteToMinute.bind(this) }
              finished={ this.isFinishedMeeting() }
              enabled={ (this.areYouEditingMinute() && this.isStartedEditingMinute()) }
              isBroadcaster={ this.isBroadcaster()}
              minutesType={ this.state.minutes_type }
              canTranslate={ this.state.canTranslate }
              memoOrder={ () => { this.onChangeOrder(); }}
              status={ this.state.status }/>
          </div>
        </div>
      </div>
    );
  }

  meetingMemo() {
    if (isChromeIOSBrowser()) {
      return null;
    }

    let id = getSearchParamsForReactRouter().get("id");

    return (
        <div className={ ClassNames({ 'memo': true, 'hidden': !this.state.isMemoOpen }) }>
          <div className="switch">
            <button className="button-text" onClick={ this.onClickMemoSwitch.bind(this) } ><div className="icon-open"/></button>
          </div>
          <label className="memo-title">メモ</label>
          <MemoTab meeting_id={ id } meeting_date={ this.state.start_date } handlers={ this.props.handlers } order={ this.state.memoOrder}/>
        </div>
    );
  }

  alert(title, message) {
    this.props.handlers.showPopup(
      <AlertOneButton title={ title } message={ message } okay={ this.props.handlers.hidePopup } />
    );
  }

  onChangeOrder() {
    if(this.state.memoOrder === 'desc')
    {
      this.setState({memoOrder:'asc'})
    }
    else
    {
      this.setState({memoOrder:'desc'})
    }
  }

  onClickStartFinishButton(event) {
    event.preventDefault();

    if (this.canStartMeeting()) {
      // サーバー上の情報を更新するため、同期が終わるまで待つ。
      // MeetingEditor上では必ず強制リロードとセットで呼び出す必要がある。
      this.props.handlers.showGlobalProgress();

      new Service().startMeeting(this.state.id)
        .finally(() => {
          this.reload(true);
        })
        .subscribe(
          payload => {
            if (payload.error) {
              const messages = {
                available_num_of_concurrent_meetings: '同時開催会議数を超えるので、会議が開始できません。',
                available_num_of_concurrent_meeting_members: '同時会議参加者数を超えるので、会議が開始できません。',
                available_storage_amount: '当月の利用可能容量を超えているため、会議を開始できません。'
              };
              this.alert('会議開始', messages[payload.error]);
            } else {
              console.log(`start: ${payload.start_at} end: ${payload.end_at}`);
              this.setState({
                start_at: payload.start_at ? Moment(payload.start_at) : null,
                end_at: payload.end_at ? Moment(payload.end_at) : null
              });
            }
          },
          error => {
            console.log(error);
            this.props.handlers.error(error);
          }
        );

    } else {

      this.props.handlers.showPopup(
        <AlertTwoButton title="会議の終了" message="本当に会議を終了しますか？" cancel={ this.props.handlers.hidePopup } okay={ this.finishMeeting.bind(this) } />
      );
    }
  }

  finishMeeting() {
    // サーバー上の情報を更新するため、同期が終わるまで待つ
    // MeetingEditor上では必ず強制リロードとセットで呼び出す必要がある。
    this.props.handlers.showGlobalProgress();

    new Service().finishMeeting(this.state.id)
      .finally(() => {
        this.reload(true);
      })
      .subscribe(
        payload => {
          console.log(`start: ${payload.start_at} end: ${payload.end_at}`);
          this.setState({
            start_at: payload.start_at ? Moment(payload.start_at) : null,
            end_at: payload.end_at ? Moment(payload.end_at) : null
          });
          this.props.handlers.hidePopup();
        },
        error => {
          console.log(error);
          this.props.handlers.hidePopup();
          this.props.handlers.error(error);
        }
      );
  }

  onCopyAndPasteAllComments(event) {
    event.preventDefault();
    this.refs.CommentTab.onCopyAndPasteAllComments(event);
  }

  onClickStartFinishEditingButton(event) {
    event.preventDefault();
    if (!this.isStartedEditingMinute()) {
      new Service().startEditingMinute(this.state.id)
        .subscribe(
          payload => {
            this.setState({
              minute:
                Object.assign(
                  {},
                  this.state.minute,
                  {
                    disabled: !this.acquiredEditingMinute(payload),
                    start_at: payload.start_at ? Moment(payload.start_at) : null,
                    end_at: payload.end_at ? Moment(payload.end_at) : null,
                    editor: payload.editor,
                    minute: payload.minute || '',
                    updated_at: payload.updated_at
                  }
                )
            });
          },
          error => {
            console.log(error);
            this.props.handlers.error(error);
          }
        );
    } else {
      this.props.handlers.showPopup(
        <AlertTwoButton title="議事録編集の終了" message="保存ボタンを押していない場合、編集した内容は破棄されます。編集終了してもよろしいですか？" okay={ this.finishEditingMinute.bind(this) } cancel={ this.props.handlers.hidePopup } />
      );
    }
  }

  finishEditingMinute() {
    new Service().finishEditingMinute(this.state.id)
      .finally(() => {
        this.props.handlers.hidePopup();
      })
      .subscribe(
        payload => {
          this.setState({
            minute:
              Object.assign(
                {},
                this.state.minute,
                {
                  disabled: true,
                  dirty: false,
                  start_at: payload.start_at ? Moment(payload.start_at) : null,
                  end_at: payload.end_at ? Moment(payload.end_at) : null,
                  editor: payload.editor,
                  minute: payload.minute || '',
                  updated_at: payload.updated_at
                }
              )
          });
        },
        error => {
          console.log(error);
          this.props.handlers.error(error);
        }
      );
  }

  onClickSaveMinute(event) {
    event.preventDefault();

    // サーバー上の情報を更新するため、同期が終わるまで待つ
    // MeetingEditor上では必ず強制リロードとセットで呼び出す必要がある。
    this.props.handlers.showGlobalProgress();

    if (this.state.minutes_type === 0) {
      this.saveMinute();
    } else if (this.state.minutes_type === 1) {
      this.saveAgenda();
    }
  }

  saveMinute() {
    this.setState({isSavingMinute: true});

    const form = new FormData();
    form.append('minute', this.state.minute.minute);
    new Service().saveEditingMinute(this.state.id, form)
      .finally(() => {
        this.setState({isSavingMinute: false});
        this.reload(true);
      })
      .subscribe(
        payload => {
          this.setState({
            minute:
              Object.assign(
                {},
                this.state.minute,
                {
                  minute: payload.minute,
                  dirty: false,
                  disabled: !this.acquiredEditingMinute(payload),
                  existed: payload.minute !== null,
                  updated_at: payload.updated_at
                }
              )
          });
        },
        error => {
          console.log(error);
          this.props.handlers.error(error);
        }
      );
  }

  saveAgenda() {
    this.setState({isSavingMinute: true});
    const form = new FormData();
    form.append('next_date', this.state.agenda.next_date ? this.state.agenda.next_date.format('YYYY/MM/DD') : '');
    if (this.state.agenda.next_date && this.state.agenda.next_start_at) {
      form.append('next_start_at', this.state.agenda.next_date.format('YYYY/MM/DD') + this.state.agenda.next_start_at.format(' HH:mm'));
    } else {
      form.append('next_start_at', ''); // set null
    }
    if (this.state.agenda.next_date && this.state.agenda.next_end_at) {
      form.append('next_end_at', this.state.agenda.next_date.format('YYYY/MM/DD') + this.state.agenda.next_end_at.format(' HH:mm'));
    } else {
      form.append('next_end_at', ''); // set null
    }
    form.append('overall_comment', this.state.agenda.overall_comment);
    this.state.agenda.agenda_parts.forEach( (parts, index) => {
      form.append(`agenda_parts[${index}][id]`, parts.id);
      form.append(`agenda_parts[${index}][title]`, parts.title);
      form.append(`agenda_parts[${index}][presenter]`, parts.presenter);
      form.append(`agenda_parts[${index}][time_required]`, parts.time_required);
      form.append(`agenda_parts[${index}][person_in_charge]`, parts.person_in_charge);
      form.append(`agenda_parts[${index}][deadline]`, parts.deadline ? parts.deadline.format('YYYY/MM/DD') : '');
      form.append(`agenda_parts[${index}][decision]`, parts.decision);
      form.append(`agenda_parts[${index}][comment]`, parts.comment);
    });

    new Service().updateAgendas(this.state.id, form)
      .finally(() => {
        this.setState({isSavingMinute: false});
        this.reload(true);
      })
      .subscribe(
        payload => {
          this.setState({
            minute:
              Object.assign(
                {},
                this.state.minute,
                {
                  dirty: false
                }
              )
          });
        },
        error => {
          console.log(error);
          this.props.handlers.error(error);
        }
      );
  }

  meetingURL() {
    let href = routePath2href("/meeting?id=" + this.state.id);
    return `${window.location.protocol}//${window.location.host}/${href}`;
  }

  membersEmail() {
    let emails = '';
    let count = 0;
    this.state.members.forEach(member => {
      if ((member.authorization & Const.PERMISSION_CODE_GUEST) !== 0) {
        return;
      }
      if (count > 0) {
        emails += '; ';
      }
      emails += member.email;
      count += 1;
    });
    return emails;
  }

  getGuestMembers() {
    return this.state.members
        .filter(member => (member.authorization & Const.PERMISSION_CODE_GUEST) !== 0)
        .map(member => {
          return {
            user_id: member.id,
            user_name: member.user_name,
          };
        });
  }

  onClickPopupMeeting(event) {
    event.preventDefault();
    if (this.areYouEditingMinute()) {
      if (this.state.minutes_type === 0) {
        this.saveMinute();
      } else if (this.state.minutes_type === 1) {
        this.saveAgenda();
      }
    }
    this.popupUpdateMeeting(this.state.id)
  }

  popupUpdateMeeting(id) {
    this.props.handlers.showPopup(<MeetingPopup
        title={ '会議編集' }
        id={ id }
        type={ MeetingPopup.popupType.EDIT }
        close={ this.closeMeetingPopup.bind(this) }
        handlers={ this.props.handlers }
        canNotRemoveMembers={ this.isStartedMeeting() && !this.isFinishedMeeting() }
    />);
  }

  closeMeetingPopup() {
    this.props.handlers.hidePopup();
  }

  onClickGuestRenameButton(event) {
    event.preventDefault();
    this.showGuestRenamePopup(this.state.id)
  }

  showGuestRenamePopup(id) {
    this.props.handlers.showPopup(<MeetingGuestRenamePopup
        meeting_id={ id }
        close={ this.closeMeetingGuestRenamePopup.bind(this) }
        handlers={ this.props.handlers }
        guest_members={ this.getGuestMembers() }
    />);
  }

  closeMeetingGuestRenamePopup() {
    this.props.handlers.hidePopup();
  }

  onClickEditBaseTime(event) {
    event.preventDefault();
    this.props.handlers.showPopup(<MeetingTimeEditorPopup id={ this.state.id } close={ this.closeMeetingTimeEditorPopup.bind(this) } handlers={ this.props.handlers } start_at={ this.state.start_at } base_time={ this.state.base_time } use_base_time={ this.state.use_base_time } />);
  }

  closeMeetingTimeEditorPopup() {
    this.props.handlers.hidePopup();
  }

  onClickClippingEmails(event) {
    event.preventDefault();
    this.props.handlers.showPopup(<ClippingEmailsPopup emails={ this.membersEmail() } close={ this.props.handlers.hidePopup } />);
  }

  onClickClippingMeetingURL(event) {
    event.preventDefault();
    this.props.handlers.showPopup(<ClippingURLPopup url={ this.meetingURL() } close={ this.props.handlers.hidePopup } />);
  }

  downloadMinute(event) {
    event.preventDefault();
    window.open(apiUrl(`meetings/${this.state.id}/minute/download`));
  }

  downloadDocuments(event) {
    event.preventDefault();
    window.open(apiUrl(`meetings/${this.state.id}/documents/download`));
  }

  getParticipantList(members) {
    return members.filter(member => member.is_participating === true)
        .map(member => { return member.id });
  }

  reload(forced = false, meeting_id = this.state.id) {

    // 非同期処理
    new Service().meeting(meeting_id, true)
        .finally(() => {
          if(forced === true) {
            // 広域プログレスサークルを表示している場合、同期完了をトリガーに解除する
            this.props.handlers.hideGlobalProgressIfNeeded();
          }
        })
        .subscribe(
            meeting => {
              this.subscribe();

              const old_participant_list = this.getParticipantList(this.state.members);
              const new_participant_list = this.getParticipantList(meeting.members);
              // 参加中メンバーの差分を検出する。シンプルに比較するためJSONに変換してから比較
              const result = JSON.stringify(old_participant_list)
                  === JSON.stringify(new_participant_list);

              // 更新の判定を行い、更新が不要な場合はreturnする。
              if(result
                  && this.state.updated_at === meeting.updated_at
                  && (meeting.agenda === null || this.state.agenda.updated_at === meeting.agenda.updated_at)
                  && (meeting.minute === null || this.state.minute.updated_at === meeting.minute.updated_at)) {
                return;
              }
              // console.log('meeting info updated.');
              // console.log(this.state.agenda);
              // console.log(meeting.agenda);

              const members = meeting.members.map(member => {
                return {
                  id: member.id,
                  user_name: member.user_name,
                  email: member.email,
                  is_participating: member.is_participating,
                  authorization: member.authorization,
                };
              });
              const documents = meeting.documents.map(document => {
                return {id: document.id, name: document.name};
              });
              const agenda_parts = this.getAgendaParts(meeting);

              let minute = this.state.minute;
              if(meeting.minute
                  && this.state.minute.updated_at !== meeting.minute.updated_at) {
                // 先の更新判定で更新ありと判断されても、自由記述型の議事録は更新日時が異なる場合のみ反映する。
                // そうしないと、編集中の内容が消されてしまう。
                // 更新日時が異なれば、強制的にサーバー内容で上書きされる。
                // (更新日時が異なるのに何もしないと、先の更新判定が常にtrueになってしまうので注意。)

                // 議題登録型の場合は、サーバーから新しい情報を受け取っても、各入力欄の初期値が変わるだけのため、そのまま反映する。
                // 各入力欄の表示内容は編集していれば編集内容。編集していなければ初期値という中途半端な状態。
                // 議題数の増減は反映したいが中途半端感はあるため、どう整理するかは今後の課題。

                minute = Object.assign(
                    {},
                    this.state.minute,
                    meeting.minute,
                    {
                      dirty: false,
                      disabled: !this.acquiredEditingMinute(meeting.minute),
                      existed: (meeting.minute && meeting.minute.minute !== null)
                    }
                );
              }

              this.setState({
                // 元reload()メソッドには無い項目
                id: meeting.id,
                start_date: meeting.start_date ? Moment(meeting.start_date) : null,
                start_at: meeting.start_at ? Moment(meeting.start_at) : null,
                end_at: meeting.end_at ? Moment(meeting.end_at) : null,
                meetingDataLoaded: true,
                status: meeting.status,
                // 以降は、元reload()メソッドと共通の項目
                title: meeting.title,
                estimated_start_at: meeting.estimated_start_at ? Moment(meeting.estimated_start_at) : null,
                estimated_end_at: meeting.estimated_end_at ? Moment(meeting.estimated_end_at) : null,
                room: meeting.room,
                engine: meeting.engine.name,
                dictionary: meeting.dictionary ? meeting.dictionary.name : '',
                members: members,
                documents: documents,
                use_base_time: (meeting.use_base_time !== 0),
                base_time: meeting.base_time ? Moment(meeting.base_time) : null,
                minutes_type: meeting.minutes_type,
                agenda: {
                  next_date: ( meeting.agenda && meeting.agenda.next_date ) ? Moment(meeting.agenda.next_date) : null,
                  next_start_at: ( meeting.agenda && meeting.agenda.next_start_at ) ? Moment(meeting.agenda.next_start_at) : null,
                  next_end_at: ( meeting.agenda && meeting.agenda.next_end_at ) ? Moment(meeting.agenda.next_end_at) : null,
                  overall_comment: ( meeting.agenda && meeting.agenda.overall_comment ) ? meeting.agenda.overall_comment : '',
                  updated_at: ( meeting.agenda ) ? meeting.agenda.updated_at: null,
                  agenda_parts: agenda_parts
                },
                minute: minute,
                updated_at: meeting.updated_at,
                hasError: false
              });
            },
            error => {
              console.log(error);
              this.props.handlers.error(error);
              this.setState({
                hasError: true
              });
            }
        );
  }

  onClickSidebarSwitch() {
    this.setState({isSidebarOpen: !this.state.isSidebarOpen});
  }
  onClickMeetingMinuteSwitch() {
    this.setState({isMeetingMinuteOpen: !this.state.isMeetingMinuteOpen});
  }
  onClickMemoSwitch() {
    this.setState({isMemoOpen: !this.state.isMemoOpen});
  }
}

MeetingEditor.PropType = {
  handlers: PropTypes.object
};

class ClippingEmailsPopup extends Component {

  constructor(props) {
    super(props);
    this.copy = this.copy.bind(this);
    this.clip = this.clip.bind(this);
  }

  componentDidMount() {
    document.addEventListener('copy', this.copy);
    this.refs.email.select();
  }

  componentDidUnMount() {
    document.removeEventListener('copy', this.copy);
  }

  copy(event) {
    event.preventDefault();
    if (!isIEBrowser()) {
      event.clipboardData.setData('text/plain', this.props.emails);
    } else {
      window.clipboardData.setData('Text', this.props.emails);
    }
  }

  clip(event) {
    event.preventDefault();
    document.execCommand('copy');
    this.props.close();
  }

  render() {
    return(
      <div className="meeting-editor-emails-popup">
        <div className="title">会議参加者メールアドレス</div>
        <div className="inner">
          <textarea ref="email" value={ this.props.emails } readOnly>
          </textarea>
        </div>
        <div className="buttons">
          <button className="btnpt1" onClick={ this.props.close } >キャンセル</button>
          <button className="copy btnpt4" onClick={ this.clip } >クリップボードへコピー</button>
        </div>
      </div>
    );
  }
}

ClippingEmailsPopup.PropType = {
  close:PropTypes.func,
};

class ClippingURLPopup extends Component {

  constructor(props) {
    super(props);
    this.copy = this.copy.bind(this);
    this.clip = this.clip.bind(this);
  }

  componentDidMount() {
    document.addEventListener('copy', this.copy);
    this.refs.url.select();
  }

  componentDidUnMount() {
    document.removeEventListener('copy', this.copy);
  }

  copy(event) {
    event.preventDefault();
    if (!isIEBrowser()) {
      event.clipboardData.setData('text/plain', this.props.url);
    } else {
      window.clipboardData.setData('Text', this.props.url);
    }
  }

  clip(event) {
    event.preventDefault();
    document.execCommand('copy');
    this.props.close();
  }

  render() {
    return(
      <div className="meeting-editor-url-popup">
        <div className="title">会議のURL</div>
        <div className="inner">
          <textarea ref="url" value={ this.props.url } readOnly>
          </textarea>
        </div>
        <div className="buttons">
          <button className="btnpt1" onClick={ this.props.close } >キャンセル</button>
          <button className="copy btnpt4" onClick={ this.clip } >クリップボードへコピー</button>
        </div>
      </div>
    );
  }
}

ClippingEmailsPopup.PropType = {
  close:PropTypes.func,
};
