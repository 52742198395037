import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Service from '../services/MeetingService';
import MeetingStatisticsWordCloud from './MeetingStatisticsWordCloud';
import Auth from "../components/Auth";
import {getSearchParamsForReactRouter} from "./utilities";

import './MeetingExtraction.css';
import {Link} from "react-router-dom";

export default class MeetingExtraction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      meeting_extraction: null,
    };
    this.meeting_id = getSearchParamsForReactRouter().get("id");
 }

  componentDidMount() {
    if (typeof this.meeting_id !== 'undefined') {
      this.props.handlers.showProgress();
      this.getMeetingExtraction();
    }
  }

  getMeetingExtraction() {
    const service = new Service();
    service.meetingExtraction(this.meeting_id).subscribe(
      payload => {
        this.setState({
          meeting_extraction: payload,
        });
        this.props.handlers.hideProgress();
      },
      error => {
        console.log(error);
        this.props.handlers.hideProgress();
        this.props.handlers.error(error);
      }
    );
  }

  getTitle() {
    if (this.state.meeting_extraction == null) {
      return (
          <div/>
      );
    }

    let meetingTitle = this.state.meeting_extraction.meeting.title;
    const startAt = this.state.meeting_extraction.meeting.start_at;
    const endAt = this.state.meeting_extraction.meeting.end_at;
    if (startAt !== null) {
      meetingTitle += " " + startAt.substr(0, 16).replace(/-/g, "/") + " 〜 ";
      if (endAt !== null) {
        meetingTitle += " " + endAt.substr(11, 5);
      }
    }
    meetingTitle += " " + this.state.meeting_extraction.meeting.creator.user_name;
    return (
      <div className="title">{meetingTitle}</div>
    );
  }

  renderButton() {
    if (this.state.meeting_extraction == null) {
      return null;
    }

    return(
        <Link to={"/meeting-statistics?id=" + this.meeting_id}>
          <button className="button-1-green">メンバー分析</button>
        </Link>
    );
  }

  renderHeader() {
    return(
        <div className="header">
          {this.getTitle()}
          <div className="left-buttons">
            {this.renderButton()}
          </div>
        </div>
    );
  }

  renderWordCloud() {
    if (this.state.meeting_extraction == null) {
      return (
          <div/>
      );
    }
    if(this.state.meeting_extraction.data.length === 0) {
      return (
        <div className="no-data">
          データなし
        </div>
      );
    }
    return (
        <MeetingStatisticsWordCloud
            data={this.state.meeting_extraction.data}
        />
    );
  }

  renderScore() {
    if (this.state.meeting_extraction == null) {
      return (
          <div/>
      );
    }

    return(
        <div className="score-area">
          {this.renderScoreList()}
        </div>

    );
  }

  renderScoreList() {
    return(
        <div className="score-list">
          <label className="title">抽出単語一覧</label>
          <div className="score-list-detail">
            <table>
              <thead>
              <tr>
                <th className="text">単語</th>
                <th className="value">スコア</th>
                <th className="value">出現回数</th>
              </tr>
              </thead>
              <tbody>
              {this.renderScoreListBody()}
              </tbody>
            </table>
          </div>
        </div>
    );
  }

  renderScoreListBody() {
    if (this.state.meeting_extraction == null) {
      return null;
    }

    const speakers = [];
    this.state.meeting_extraction.data.forEach((item, index) => {
      let value = Math.floor(parseFloat(item.value) * 100) / 100;
      speakers.push(
          <tr key={index} className="item">
            <td className="text">{item.text}</td>
            <td className="value">{value}</td>
            <td className="count">{item.count}</td>
          </tr>
      );
    });
    return speakers;
  }

  render() {
    if(!Auth.isAuthenticated()) {
      return null;
    }

    return (
        <div className="meeting-extraction">
          {this.renderHeader()}
          <div className="scroll">
            {this.renderWordCloud()}
            {this.renderScore()}
          </div>
        </div>
    );
  }
}

MeetingExtraction.PropType = {
  handlers: PropTypes.object
};
