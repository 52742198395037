import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './Settings.css';
import {isAdmin} from "./AccountPopup";
import ClassNames from "classnames";
import CalculationDictionaries from "./CalculationDictionaries";
import MeetingInitialScore from "./MeetingInitialScore";
import MeetingHistory from "./MeetingHistory";
import CustomerSettings from "./CustomerSettings";
import PersonalSettings from "./PersonalSettings";
import Auth from "../components/Auth";

export default class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectMenu: ''
    };
  }

  componentDidMount() {
  }

  getPersonalSettingsLi() {
    const id = 'personal-settings';
    return (
        <li className={ ClassNames({ 'disabled': (this.state.selectMenu === id) })}
            onClick={ () => this.onClickMenu(id) }>
          <span>議事録への発言コピー設定</span>
        </li>
    );
  }

  getCustomerSettingsLi() {
    const id = 'customer-settings';
    if(isAdmin()) {
      return (
          <li className={ ClassNames({ 'disabled': (this.state.selectMenu === id) })}
              onClick={ () => this.onClickMenu(id) }>
            <span>メンバーの権限設定</span>
          </li>
      );
    }
    return null;
  }

  getMeetingHistoryLi() {
    const id = 'meeting-history';
    if(isAdmin()) {
      return (
          <li className={ ClassNames({ 'disabled': (this.state.selectMenu === id) })}
              onClick={ () => this.onClickMenu(id) }>
            <span>会議履歴</span>
          </li>
      );
    }
    return null;
  }

  getCalculationDictionariesLi() {
    const id = 'calculation-dictionaries';
    if(isAdmin()) {
      return (
          <li className={ ClassNames({ 'disabled': (this.state.selectMenu === id) })}
              onClick={ () => this.onClickMenu(id) }>
            <span>分析対象単語帳の編集</span>
          </li>
      );
    }
    return null;
  }

  getMeetingInitialScoreLi() {
    const id = 'meeting-initial-score';
    if(isAdmin()) {
      return (
          <li className={ ClassNames({ 'disabled': (this.state.selectMenu === id) })}
              onClick={ () => this.onClickMenu(id) }>
            <span>会議分析の初期配点設定</span>
          </li>
      );
    }
    return null;
  }

  onClickMenu(id){
    this.setState({'selectMenu': id});
  }

  mainPanel(){
    let data = null;
    switch (this.state.selectMenu) {
      case 'personal-settings':
        data = <PersonalSettings handlers={ this.props.handlers } />;
        break;
      case 'customer-settings':
        data = <CustomerSettings handlers={ this.props.handlers } />;
        break;
      case 'meeting-history':
        data = <MeetingHistory handlers={ this.props.handlers } />;
        break;
      case 'calculation-dictionaries':
        data = <CalculationDictionaries handlers={ this.props.handlers } />;
        break;
      case 'meeting-initial-score':
        data = <MeetingInitialScore handlers={ this.props.handlers } />;
        break;
      default:
        break;
    }
    return data;
  }

  render() {
    if(!Auth.isAuthenticated()) {
      return null;
    }

    return (
      <div className="settings">
        <div className="body">
          <div className="settings-left">
            <div className="settings-header">
              <div className="icon-nav-settings"/>
              <span>設定</span>
            </div>
            <ul>
              { this.getPersonalSettingsLi() }
              { this.getCustomerSettingsLi() }
              { this.getMeetingHistoryLi() }
              { this.getMeetingInitialScoreLi() }
              { this.getCalculationDictionariesLi() }
            </ul>
          </div>
          <div className="main-panel">{ this.mainPanel() }</div>
        </div>
      </div>
    );
  }
}

Settings.PropType = {
  location: PropTypes.object
};
