import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import Service from '../services/MeetingService';
import {AlertOneButton, AlertTwoButton} from '../components/Alert';
import MeetingPopup from './MeetingPopup';
import MeetingStatus from '../components/MeetingStatus';
import { Link } from 'react-router-dom';
import { isAdmin } from "./AccountPopup";
import PaginationBar from "../components/PaginationBar";
import SuperScriberPopup from './SuperScriberPopup';

import './Meetings.css';
import {routePath2href} from "./utilities";

class Meeting extends Component {

  date(start_date, start, end) {
    let date = "---";
    let time = "";
    if(start && end) {
      date = Moment(start_date).format('YYYY/MM/DD');
      time = Moment(start).format('HH：mm') + ' 〜 ' + Moment(end).format('HH：mm');
    } else if(start && !end) {
      date = Moment(start_date).format('YYYY/MM/DD');
      time = Moment(start).format('HH：mm') + ' 〜 ';
    } else if(!start && end) {
      date = Moment(start_date).format('YYYY/MM/DD');
      time = ' 〜 ' + Moment(end).format('HH：mm');
    }

    return (
      <div className="date">
        <div><span>{ date }</span></div>
        <div><span>{ time }</span></div>
      </div>
    );
  }

  status() {
    return (
      <MeetingStatus
        startAt={ this.props.detail.start_at }
        endAt={ this.props.detail.end_at }
        status={ this.props.detail.status }/>
    );
  }

  title() {
    if (this.isCreator() || this.isParticipants()) {
      return this.props.detail.title;
    }
    return '非表示';
  }

  editor() { // change to creator user_name
    if(this.props.detail.creator === null) {
      return '(削除済みユーザー)';
    }
    return this.props.detail.creator.user_name;
  }

  updatedAt() {
    return (
      <div>
        <div>{ Moment(this.props.detail.updated_at).format('YYYY/MM/DD') }</div>
        <div>{ Moment(this.props.detail.updated_at).format('HH:mm') }</div>
      </div>
    );
  }

  usageTime() {
    if (this.props.detail.usage_time) {
      return `${this.props.detail.usage_time}分`;
    }
    return '0分';
  }

  usageStorage() {
    if (this.props.detail.usage_storage) {
      return `${ (this.props.detail.usage_storage / 1024).toFixed() }MB`;
    }
    return '0MB';
  }

  isCreator() {
    const operator = Number(sessionStorage.getItem('id'));
    return operator === this.props.detail.creator_id;
  }

  isParticipants() {
    const operator = Number(sessionStorage.getItem('id'));
    return this.props.detail.members_count.findIndex(user => { return user.pivot.user_id === operator; }) >= 0;
  }

  canEdit() {
    // 会議中(1)、またはSS依頼中(2)は編集させない
    if((this.props.detail.status === 1) || (this.props.detail.status === -2)) {
      return false;
    }
    return (this.isCreator() || this.isParticipants());
  }

  canVisibleAnalysisButton() {
    if(this.props.detail.analysed_at === null) {
      return false;
    }
    // noinspection RedundantIfStatementJS
    // 会議終了(-1)、またはSS依頼中(2)は編集させない
    if((this.props.detail.status !== -1) && (this.props.detail.status !== -2)) {
      return false;
    }
    // noinspection RedundantIfStatementJS
    if(this.isParticipants() === false) {
      return false;
    }
    return true;
  }

  analysisButton() {
    let visibility = 'hidden';
    let url = "/meeting-statistics?id=" + this.props.detail.id;
    if(this.canVisibleAnalysisButton()) {
      visibility = 'visible';
    }
    return (
        <Link title="分析" to={url} onClick={this.onClickAnalysis.bind(this)} style={{ visibility : visibility }}>
          <div className="icon-chart-bar button-icon"/>
        </Link>
    );
  }

  superscriberButton() {
    if((this.props.detail.customer.is_ss === 1) && (this.props.detail.status === -1)) {
      return (
          <button title="SS依頼" onClick={this.onClickPopupSuperScriber.bind(this)} style={{visibility: 'visible'}}>
            <div className="icon-super-scribe button-icon"/>
          </button>
      );
    }else {
      // 1.顧客情報が取得できない場合
      // 2.顧客設定が「SSを利用しない（is_ss = 0）」の場合
      // 3.顧客設定が「SSを利用しない（is_ss = 1）」でも会議ステータスが「終了（status = -1）」の場合
      // 上記3パターンはSS依頼ボタンを表示しない
      return (
           <button title="SS依頼" style={{ display: 'none' }}/>
      );
    }
  }

  buttons() {
    if (this.canEdit()) {
      return (<div>
        {this.analysisButton()}
        {this.superscriberButton()}
        <button title="コピー" onClick={ this.onClickPopupCopyMeeting.bind(this) } ><div className="icon-content-copy button-icon"/></button>
        <button title="編集" onClick={ this.onClickPopupMeeting.bind(this) } ><div className="icon-pencil button-icon"/></button>
        <button title="削除" onClick={ this.onClickPopupDeleteMeeting.bind(this) } ><div className="icon-trash button-icon"/></button>
        </div>);
    } else if(isAdmin()) {
      if (this.props.detail.status === -2)
      {
        return(
            <button title="削除" style={{ display: 'none' }}/>
        );
      }
      else
      {
        return (<div>
          <button title="削除" onClick={ this.onClickPopupDeleteMeeting.bind(this) } className="button-icon"><div className="icon-trash button-icon"/></button>
        </div>);
      }
    }
    return null;
  }

  render() {
    const date = this.props.detail.start_at ?
        this.date(
            this.props.detail.start_date,
            this.props.detail.start_at,
            this.props.detail.end_at) :
        this.date(
            this.props.detail.start_date,
            this.props.detail.estimated_start_at,
            this.props.detail.estimated_end_at);
    return (
      <tr className="meeting" onClick={ this.onClickMeeting.bind(this) } >
        <td className="title">{ this.status() }<div>{ this.title() }</div></td>
        <td className="date"><div className="icon"></div>{ date }</td>
        <td className="room"><div className="icon"></div><div className="room">{ this.props.detail.room }</div></td>
        <td className="numofmembers">{ this.props.detail.members_count.length }人</td>
        <td className="editor">{ this.editor() }</td>
        <td className="updatedat">{ this.updatedAt() }</td>
        <td className="usagetime">{ this.usageTime() }</td>
        <td className="usageamount">{ this.usageStorage() }</td>
        <td className="functions">
          { this.buttons() }
        </td>
      </tr>
    );
  }

  onClickPopupSuperScriber(event) {
    event.stopPropagation();
    event.preventDefault();
    this.props.popupRequestSuperScriber(this.props.detail.id)
  }

  onClickPopupMeeting(event) {
    event.stopPropagation();
    event.preventDefault();
    this.props.popupUpdateMeeting(this.props.detail.id)
  }

  onClickPopupDeleteMeeting(event) {
    event.stopPropagation();
    event.preventDefault();
    this.props.popupDeleteMeeting(this.props.detail, this.canEdit() ? false : true)
  }

  onClickPopupCopyMeeting(event) {
    event.stopPropagation();
    event.preventDefault();
    this.props.popupCopyMeeting(this.props.detail.id)
  }

  onClickMeeting(event) {
    event.preventDefault();
    if (this.isParticipants()) {
      let url = "/" + routePath2href("/meeting?id=" + this.props.detail.id);
      window.open(url);
    } else {
      // TODO: ＠ポップアップ
      this.props.popupCannotDisplay()
    }
  }

    onClickAnalysis(event) {
        event.stopPropagation();
        //event.preventDefault();
    }
}

Meeting.PropType = {
  detail: PropTypes.object,
  popupRequestSuperScriber:PropTypes.func,
  popupUpdateMeeting:PropTypes.func,
  popupDeleteMeeting:PropTypes.func,
  popupCopyMeeting:PropTypes.func,
  popupCannotDisplay:PropTypes.func
};

export default class Meetings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      keywords: null,
      total: 0,
      per_page: 20,
      current: 0,
      last: 0,
      details: [],
      loaded: false
    };
    this.focus = this.focus.bind(this);
  }

  componentDidMount() {
    document.addEventListener('visibilitychange', this.focus, false);
    this.getCurrentPage();
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.focus);
  }

  focus(event) {
    this.getCurrentPage();
  }

  getCurrentPage(page = null) {

    this.props.handlers.showProgress();

    const service = new Service();

    service.meetings(page || this.state.current, this.state.keywords).subscribe(
      payload => {
        this.setState({
          total: payload.total,
          per_page: payload.per_page,
          current: payload.current_page,
          last: payload.last_page,
          details: payload.data,
          loaded: true
        });
        this.props.handlers.hideProgress();
      },
      error => {
        this.props.handlers.hideProgress();
        this.props.handlers.error(error);
      }
    );
  }

  meetings() {
    var meetings = [];
    this.state.details.forEach(meeting => {
      meetings.push(<Meeting
          key={ meeting.id }
          detail={ meeting }
          popupRequestSuperScriber={ this.popupRequestSuperScriber.bind(this) }
          popupUpdateMeeting={ this.popupUpdateMeeting.bind(this) }
          popupDeleteMeeting={ this.popupDeleteMeeting.bind(this) }
          popupCopyMeeting={ this.popupCopyMeeting.bind(this) }
          popupCannotDisplay={ this.popupCannotDisplay.bind(this) } />);
    });
    return meetings;
  }

    header() {
    return (
      <div className="meetings-header">
        <div className="meetings-title">会議一覧</div>
        <button onClick={ this.popupCreateMeeting.bind(this) } className="create button-1">会議を作成する</button>
        <div className="search">
          <input type="search" ref="keywords" onKeyDown={ this.onKeyDownKeywords.bind(this) } onChange={ this.onChangeKeywords.bind(this) } placeholder="タイトル、参加者の検索" />
          <button onClick={ this.onSearch.bind(this) } className="button-select"><div className="icon-search"></div></button>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.details.length > 0) {
      return (
        <div className="meetings">
          { this.header() }
          <div className="meetings-detail">
            <table>
              <thead>
              <tr>
                <th className="title">タイトル</th>
                <th className="date">会議日時</th>
                <th className="room">場所</th>
                <th className="numofmembers">参加人数</th>
                <th className="editor">会議作成者</th>
                <th className="updatedat">更新日時</th>
                <th className="usagetime">使用時間</th>
                <th className="usageamount">使用容量</th>
                <th className="functions"></th>
              </tr>
              </thead>
              <tbody>
              { this.meetings() }
              </tbody>
            </table>
          </div>
          <PaginationBar
              total={ this.state.total }
              per_page={ this.state.per_page }
              current={ this.state.current }
              last={ this.state.last }
              onClick={ this.getCurrentPage.bind(this) }
          />
        </div>
      );
    } else {
      if (this.state.keywords !== null && this.state.keywords !== ""){
        return (
          <div className="meetings">
            { this.header() }
            <div className="meetings-info">一致する会議は見つかりませんでした。</div>
          </div>
        );
      } else {
        return (
            <div className="meetings">
              { this.header() }
              <div className="meetings-info" hidden={!this.state.loaded}><p>会議を作成してください。</p></div>
            </div>
        );
      }
    }
  }

  popupDeleteMeeting(detail, force_delete) {
    if(force_delete) {
        const message = "参加権限の無い会議です。会議を強制削除しますか？";
        this.props.handlers.showPopup(
            <AlertTwoButton title="会議の強制削除" message={message} cancel={this.props.handlers.hidePopup}
                            okay={this.deleteMeeting(detail.id, true)} okayButtonText="削除する"/>
        );
        return;
    }
    const message = `「${detail.title}」を削除しますか？`;
    this.props.handlers.showPopup(
        <AlertTwoButton title="会議の削除" message={ message } cancel={ this.props.handlers.hidePopup } okay={ this.deleteMeeting(detail.id, false) } okayButtonText="削除する"/>
    );
  }

  popupCannotDisplay() {
    this.props.handlers.showPopup(
      <AlertOneButton title="会議に参加できません" message="この会議には参加できません。" okay={ this.props.handlers.hidePopup } />
    );
  }

  deleteMeeting(id, force_delete) {
    return () => {
      this.props.handlers.hidePopup();
      this.props.handlers.showProgress();
      let service = new Service();
      service.deleteMeeting(id, force_delete).subscribe(
        payload => {
          this.props.handlers.hideProgress();
          this.getCurrentPage();
        },
        error => {
          this.props.handlers.hideProgress();
          this.props.handlers.error(error);
        }
      );
    };
  }

  popupRequestSuperScriber(id) {
    this.props.handlers.showPopup(<SuperScriberPopup
        title={ 'SS依頼' }
        //type={ SuperScriberPopup.popupType.EDIT }
        id={ id }
        close={ this.closeSuperScribePopup.bind(this) }
        handlers={ this.props.handlers } />);
  }

  popupCreateMeeting() {
    this.props.handlers.showPopup(<MeetingPopup
        title={ '会議作成' }
        type={ MeetingPopup.popupType.CREATE }
        close={ this.closeMeetingPopup.bind(this) }
        handlers={ this.props.handlers } />);
  }

  popupUpdateMeeting(id) {
    this.props.handlers.showPopup(<MeetingPopup
        title={ '会議編集' }
        type={ MeetingPopup.popupType.EDIT }
        id={ id }
        close={ this.closeMeetingPopup.bind(this) }
        handlers={ this.props.handlers } />);
  }

  popupCopyMeeting(id) {
    this.props.handlers.showPopup(<MeetingPopup
        title={ '会議コピー' }
        type={ MeetingPopup.popupType.COPY }
        id={ id }
        close={ this.closeMeetingPopup.bind(this) }
        handlers={ this.props.handlers } />);
  }

  closeSuperScribePopup() {
    this.props.handlers.hidePopup();
    this.getCurrentPage();
  }

  closeMeetingPopup() {
    this.props.handlers.hidePopup();
    this.getCurrentPage();
  }

  onKeyDownKeywords(event) {
    if (event.which === 0xd) {
      event.preventDefault();
      this.getCurrentPage();
    }
  }

  onChangeKeywords(event) {
    event.preventDefault();
    const keywords = this.refs.keywords.value || null;
    this.setState({ keywords: keywords });
    if(keywords === null) {
      this.getCurrentPage('');
    }
  }

  onSearch(event) {
    event.preventDefault();
    this.getCurrentPage();
  }

}
