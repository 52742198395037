import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Shield.css';

class Shield extends Component {

  render() {
    if (this.props.visibled) {
      return (
        <div className="shield"></div>
      );
    } else {
      return null;
    }
  }
}

Shield.PropType = {
  visibled: PropTypes.bool
};

export default Shield;
