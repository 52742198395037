import React, {Component} from 'react';
import './PersonalSettings.css';
import Service from '../services/MeetingService';
import { setPersonalSettingInfo } from "./Login";
import PropTypes from 'prop-types';

export default class PersonalSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            personal_settings: null,
            isBroadcaster: false,
            customerDataLoaded: false,
        };
    }

    componentDidMount() {
        this.props.handlers.showProgress();
        this.getPersonalData();
        this.getCustomerData();
    }

    tryHideProgress() {
        if (this.state.personal_settings === null) {
            return;
        }
        if (this.state.customerDataLoaded === false) {
            return;
        }
        this.props.handlers.hideProgress();
    }

    getCustomerData() {
        const service = new Service();
        service.customer()
            .subscribe(
                customer => {
                    this.setState({
                        customerDataLoaded: true,
                        isBroadcaster: (customer.is_broadcaster === 1)
                    });
                    this.tryHideProgress();
                },
                error => {
                    console.log(error);
                    this.props.handlers.error(error);
                }
            );
    }

    getPersonalData() {
        let service = new Service();
        service.personalSettings().subscribe(
            payload => {
                this.setState({
                    personal_settings: payload,
                });
                this.tryHideProgress();
            },
            error => {
                console.log(error);
                this.props.handlers.hideProgress();
                this.props.handlers.error(error);
            }
        );
    }

    //noinspection JSUnusedLocalSymbols
    submit(event) {
        let form = new FormData();
        let settings = this.state.personal_settings;

        form.append('can_copy_and_paste_mark',
            settings.can_copy_and_paste_mark);
        form.append('can_copy_and_paste_time',
            settings.can_copy_and_paste_time);
        form.append('can_copy_and_paste_name',
            settings.can_copy_and_paste_name);
        form.append('can_copy_and_paste_comment',
            settings.can_copy_and_paste_comment);
        form.append('can_copy_and_paste_translation',
            settings.can_copy_and_paste_translation);

        let service = new Service();

        this.props.handlers.showProgress();
        //noinspection JSUnusedLocalSymbols
        service.updatePersonalSettings(form).subscribe(
            payload => {
                setPersonalSettingInfo(payload);
                this.props.handlers.hideProgress();
            },
            error => {
                console.log(error);
                this.props.handlers.hideProgress();
            }
        );
    }

    onClickCheckbox(event, item) {
        let value = event.target.checked ? 1 : 0;
        let settings = this.state.personal_settings;
        switch (item) {
            case 'can_copy_and_paste_mark':
                settings.can_copy_and_paste_mark = value;
                break;
            case 'can_copy_and_paste_time':
                settings.can_copy_and_paste_time = value;
                break;
            case 'can_copy_and_paste_name':
                settings.can_copy_and_paste_name = value;
                break;
            case 'can_copy_and_paste_comment':
                settings.can_copy_and_paste_comment = value;
                break;
            case 'can_copy_and_paste_translation':
                settings.can_copy_and_paste_translation = value;
                break;
            default:
                break;
        }
        this.setState({
            personal_settings: settings,
        });
    }

    render() {
        return (
            <div className="personal-settings">
                {this.details()}
            </div>
        );
    }

    details() {
        if (!this.state.personal_settings) {
            return (
                <div/>
            );
        }
        // 放送局向けの場合は、現状では設定できる項目がない
        if (this.state.isBroadcaster) {
            return (
                <div>
                    <div className="body">設定可能な項目はありません</div>
                </div>
            );
        }

        return (
            <div>
                <div className="body">
                    <div className="copy-item-area">
                        <div className="copy-item-title">発話コピーの対象</div>
                        <div className="copy-item-table">
                            <table>
                                <thead>
                                <tr>
                                    <th/>
                                    <th>コピー対象</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>重要マーク</td>
                                    <td>
                                        <label>
                                            <input type="checkbox"
                                                   className="btnpt2"
                                                   value={this.state.personal_settings.can_copy_and_paste_mark}
                                                   checked={this.state.personal_settings.can_copy_and_paste_mark}
                                                   onChange={e => {
                                                       this.onClickCheckbox(e, 'can_copy_and_paste_mark')
                                                   }}/>
                                            <div className="check"/>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>時刻</td>
                                    <td>
                                        <label>
                                            <input type="checkbox"
                                                   className="btnpt2"
                                                   value={this.state.personal_settings.can_copy_and_paste_time}
                                                   checked={this.state.personal_settings.can_copy_and_paste_time}
                                                   onChange={e => {
                                                       this.onClickCheckbox(e, 'can_copy_and_paste_time')
                                                   }}/>
                                            <div className="check"/>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>発言者名</td>
                                    <td>
                                        <label>
                                            <input type="checkbox"
                                                   className="btnpt2"
                                                   value={this.state.personal_settings.can_copy_and_paste_name}
                                                   checked={this.state.personal_settings.can_copy_and_paste_name}
                                                   onChange={e => {
                                                       this.onClickCheckbox(e, 'can_copy_and_paste_name')
                                                   }}/>
                                            <div className="check"/>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>発言エリア</td>
                                    <td>
                                        <label>
                                            <input type="checkbox"
                                                   className="btnpt2"
                                                   value={this.state.personal_settings.can_copy_and_paste_comment}
                                                   checked={this.state.personal_settings.can_copy_and_paste_comment}
                                                   onChange={e => {
                                                       this.onClickCheckbox(e, 'can_copy_and_paste_comment')
                                                   }}/>
                                            <div className="check"/>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>翻訳エリア</td>
                                    <td>
                                        <label>
                                            <input type="checkbox"
                                                   className="btnpt2"
                                                   value={this.state.personal_settings.can_copy_and_paste_translation}
                                                   checked={this.state.personal_settings.can_copy_and_paste_translation}
                                                   onChange={e => {
                                                       this.onClickCheckbox(e, 'can_copy_and_paste_translation')
                                                   }}/>
                                            <div className="check"/>
                                        </label>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="note">
                            ※設定ボタンを押下後、次に会議を開いたときに反映されます。<br/>
                            ※コピー対象を選択しない場合、コピー動作を行っても何もコピーされません。<br/>
                        </div>
                        <div className="buttons">
                            <button className="btnpt4" type="button" onClick={e => {
                                this.submit(e)
                            }}>設定
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

PersonalSettings.PropType = {
    handlers: PropTypes.object,
};
