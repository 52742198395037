import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Comments from './Comments';
import Pictures from './Pictures';

import './CommentTab.css';

class CommentTab extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selected: 'comments',
    };
    this.filter = 0;
    this.keywords = null;
    this.direction = 'desc';
    this.commentLengthFilter = true;
    this.commentLengthFilterDigit = 10;
  }

  render() {
    var isCommentsSelected = this.state.selected === 'comments';
    var content = isCommentsSelected ?
      <Comments
        ref="Comments"
        id={ this.props.id }
        handlers={ this.props.handlers }
        paste={ this.props.paste }
        finished={ this.props.finished }
        enabled={ this.props.enabled }
        isBroadcaster={ this.props.isBroadcaster }
        minutesType={ this.props.minutesType }
        canTranslate={ this.props.canTranslate }
        notifyParameter={ this.updateCommentsParameter.bind(this) }
        filter={ this.filter }
        keywords={ this.keywords }
        direction={ this.direction }
        commentLengthFilter={ this.commentLengthFilter }
        commentLengthFilterDigit={ this.commentLengthFilterDigit }
        memoOrder={ this.props.memoOrder }
        status={ this.props.status }
      />
      :
      <Pictures
        id={ this.props.id }
        handlers={ this.props.handlers }
        enabled={ this.props.enabled }
        notifyParameter={ this.updatePicturesParameter.bind(this) }
        filter={ this.filter }
      />;

    var commentsClass = classNames({
      'selected': isCommentsSelected,
      'selectable': !isCommentsSelected
    });
    var picturesClass = classNames({
      'selected': !isCommentsSelected,
      'selectable': isCommentsSelected
    });
    return (
      <div className="comment-tab">
        <div className="header">
          <div className={ commentsClass } onClick={ this.onClickComments.bind(this) }>発話</div>
          <div className={ picturesClass } onClick={ this.onClickPictures.bind(this) }>画像</div>
        </div>
        <div className="body">
          { content }
        </div>
      </div>
    );
  }

  // コンポーネント外からrefs経由で呼ばれる
  onCopyAndPasteAllComments(event) {
    this.refs.Comments.onCopyAndPasteAllComments(event);
  }

  onClickComments(event) {
    this.setState({ selected: 'comments' });
  }

  onClickPictures(event) {
    this.setState({ selected: 'pictures' });
  }

  updateCommentsParameter(filter, keywords, direction, commentLengthFilter, commentLengthFilterDigit) {
    // console.log("updateCommentsParameter filter=" + filter
    //     + " keywords=" + keywords
    //     + " direction=" + direction
    //     + " commentLengthFilter=" + commentLengthFilter
    //     + " commentLengthFilterDigit=" + commentLengthFilterDigit
    // );
    this.filter = filter;
    this.keywords = keywords;
    this.direction = direction;
    this.commentLengthFilter = commentLengthFilter;
    this.commentLengthFilterDigit = commentLengthFilterDigit;
  }

  updatePicturesParameter(filter, keywords, direction, commentLengthFilter, commentLengthFilterDigit) {
    // console.log("updatePicturesParameter filter=" + filter);
    this.filter = filter;
  }
}

CommentTab.PropType = {
  id: PropTypes.number,
  handlers: PropTypes.object,
  paste:PropTypes.func,
  finished: PropTypes.bool,
  // 自分が編集中かどうか
  enabled: PropTypes.bool,
  isBroadcaster: PropTypes.bool,
  minutesType: PropTypes.number,
  canTranslate: PropTypes.bool,
  memoOrder: PropTypes.func,
  status: PropTypes.number
};

export default CommentTab;
