import fetch from 'isomorphic-fetch';
import FormData from 'form-data';
import {Observable} from 'rx';
import {getSearchParamsForReactRouter} from "../containers/utilities";

export default class MeetingService {

  call(method, url, form = null) {

    var headers = { accept: 'application/json' };
    var session = sessionStorage.getItem('session');
    if (session) {
      headers['authorization'] = 'Bearer ' + session;
    }

    return Observable.fromPromise(
      fetch(
        window.location.origin + '/' + url,
        {
          method: method,
          headers: headers,
          body: form,
          mode: 'cors'
        }
      )
    )
    .flatMap(response => {
      if ((response.status >= 200 && response.status < 300) || response.status === 422) {
        return response.json();
      } else {
        console.log(response.statusText);
        var error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
    })
  }

  get(url) {
    return this.call('GET', url);
  }

  post(url) {
    return this.call('POST', url);
  }

  login(email, password) {
    var form = new FormData();
    form.append('email', email);
    form.append('password', password);
    return this.call('POST', 'api/login', form);
  }

  logout() {
    return this.call('POST', 'api/logout');
  }

  sendPasswordResetMail(form) {
    return this.call('POST', 'api/password/send', form);
  }

  resetPassword(form) {
    return this.call('POST', 'api/password/reset', form);
  }

  informations() {
    return this.call('GET', 'api/v1/informations');
  }

  me() {
    return this.call('GET', 'api/v1/me');
  }

  updateMe(form) {
    return this.call('POST', '/api/v1/me/update', form);
  }

  users(page) {
    return this.call('GET', `api/v1/users?page=${page}`);
  }

  user(id) {
    return this.call('GET', 'api/v1/users/' + id);
  }

  storeUser(form) {
    return this.call('POST', 'api/v1/users/store', form);
  }

  updateUser(id, form) {
    return this.call('POST', 'api/v1/users/' + id + '/update', form);
  }

  deleteUser(id) {
    return this.call('POST', 'api/v1/users/' + id + '/delete');
  }

  uploadUsers(form) {
    return this.call('POST', 'api/v1/users/upload', form);
  }

  deleteUsers(form) {
    return this.call('POST', 'api/v1/users/bulk_delete', form);
  }

  isUserEditingMinute(id) {
    return this.call('GET', `api/v1/users/${id}/is_editing_minute`);
  }

  customer() {
    return this.call('GET', 'api/v1/customer');
  }

  customerStatistics(year, month)
  {
    return this.call('GET', `api/v1/customer/statistics?year=${year}&month=${month}`)
  }

  customerSettings()
  {
      return this.call('GET', `api/v1/customer_settings`)
  }

  updateCustomerSettings(form)
  {
      return this.call('POST', `api/v1/customer_settings/update`, form)
  }

  personalSettings()
  {
    return this.call('GET', `api/v1/personal_settings`)
  }

  meetingHistory(year,  month) {
      return this.call('GET', `api/v1/meeting_history?year=${year}&month=${month}`);
  }

  updatePersonalSettings(form)
  {
    return this.call('POST', `api/v1/personal_settings/update`, form)
  }

  dictionaries(page) {
    return this.call('GET', `api/v1/dictionaries?page=${page}`);
  }

  dictionary(id) {
    return this.call('GET', `api/v1/dictionaries/${id}`);
  }

  storeDictionary(form) {
    return this.call('POST', 'api/v1/dictionaries/store', form);
  }

  updateDictionary(id, form) {
    return this.call('POST', `api/v1/dictionaries/${id}/update`, form);
  }

  deleteDictionary(id) {
    return this.call('POST', 'api/v1/dictionaries/' + id + '/delete');
  }

  isDictionaryUsed(id) {
    return this.call('GET', `api/v1/dictionaries/${id}/is_used`);
  }

  words(dictionary_id, page) {
    return this.call('GET', `api/v1/dictionaries/${dictionary_id}/words?page=${page}`);
  }

  word(dictionary_id, id) {
    return this.call('GET', `api/v1/dictionaries/${dictionary_id}/words/${id}`);
  }

  storeWord(dictionary_id, form) {
    return this.call('POST', `api/v1/dictionaries/${dictionary_id}/words/store`, form);
  }

  updateWord(dictionary_id, id, form) {
    return this.call('POST', `api/v1/dictionaries/${dictionary_id}/words/${id}/update`, form);
  }

  deleteWord(dictionary_id, id) {
    return this.call('POST', `api/v1/dictionaries/${dictionary_id}/words/${id}/delete`);
  }

  uploadWords(dictionary_id, form) {
    return this.call('POST', `api/v1/dictionaries/${dictionary_id}/words/upload`, form);
  }

  categories() {
    return this.call('GET', `api/v1/categories`);
  }

  engines() {
    return this.call('GET', `api/v1/engines`);
  }

  meetings(page, keywords) {
    var url = 'api/v1/meetings?';
    if (page !== null) {
      url += `page=${page}`;
    }
    if (keywords !== null) {
      if (page !== null) {
        url += '&';
      }
      url += `keywords=${encodeURIComponent(keywords)}`;
    }
    return this.call('GET', url);
  }

  meeting(id, including_guest) {
    let url = `api/v1/meetings/${id}`;
    if(including_guest) {
      url += '?including_guest=1'
    }
    return this.call('GET', url);
  }

  meeting_users(id) {
    let url = `api/v1/meetings/${id}/users`;
    return this.call('GET', url);
  }

  storeMeeting(form) {
    return this.call('POST', `api/v1/meetings/store`, form);
  }

  updateMeeting(id, form) {
    return this.call('POST', `api/v1/meetings/${id}/update`, form);
  }

  updateMeetingBaseTime(id, form) {
    return this.call('POST', `api/v1/meetings/${id}/update/base_time`, form);
  }

  deleteMeeting(id, force_delete) {
    return this.call('POST', 'api/v1/meetings/' + id + `/delete?force_delete=${force_delete}`);
  }

  searchMeetingUsers(exclusion_my_group_id=null) {
    let parameters = "";
    if(exclusion_my_group_id) {
      parameters = `?exclusion_my_group_id=${exclusion_my_group_id}`;
    }
    return this.call('GET', `api/v1/meetings/users/search${parameters}`);
  }

  documents(id) {
    return this.call('GET', `api/v1/meetings/${id}/documents`);
  }

  uploadDocument(id, form) {
    return this.call('POST', `api/v1/meetings/${id}/documents/upload`, form);
  }

  startMeeting(id) {
    return this.call('POST', `api/v1/meetings/${id}/start`);
  }

  finishMeeting(id) {
    return this.call('POST', `api/v1/meetings/${id}/finish`);
  }

  minute(id) {
    return this.call('GET', `api/v1/meetings/${id}/minute`);
  }

  startEditingMinute(id) {
    return this.call('POST', `api/v1/meetings/${id}/minute/start`);
  }

  saveEditingMinute(id, form) {
    return this.call('POST', `api/v1/meetings/${id}/minute/save`, form);
  }

  finishEditingMinute(id) {
    return this.call('POST', `api/v1/meetings/${id}/minute/finish`);
  }

  duplicateMinutePicture(meeting, picture) {
    return this.call('POST', `api/v1/meetings/${meeting}/minute/pictures/${picture}/duplicate`);
  }

  renameGuest(meeting_id, form) {
    return this.call('POST', `api/v1/meetings/${meeting_id}/rename_guest`, form);
  }

  /**
   * コメント取得
   * @param id   会議ID
   * @param from  コメント取得の開始ID。マイナスの場合はこのIDより先だけ取得(このIDを含まない)
   * @param mark
   * @param keywords
   * @param direction コメント取得のオーダー順(asc:昇順。新しいIDが下 desc:降順。新しいIDが上)
   * @param translate
   * @param commentLengthFilter
   * @param commentLengthFilterDigit
   * @param speakerSelectionFilter
   * @returns {*}
   */
  comments(id,
           from,
           mark,
           keywords,
           direction,
           translate,
           commentLengthFilter=false,
           commentLengthFilterDigit=0,
           speakerSelectionFilter=false) {
    var url = `api/v1/meetings/${id}/comments?from=${from}`;
    if (mark !== null) {
      url += `&mark=${mark}`;
    }
    if (keywords !== null) {
      url += `&keywords=${encodeURIComponent(keywords)}`;
    }
    if (direction !== null) {
      url += `&direction=${direction}`;
    }
    if (commentLengthFilter === true) {
      url += `&commentLengthFilter=${commentLengthFilterDigit}`;
    }
    if (speakerSelectionFilter === true) {
      url += `&speakerSelectionFilter=${speakerSelectionFilter}`;
    }
    if (translate !== null && translate !== '') {
      url += `&translate=${translate}`;
    }
    return this.call('GET', url);
  }

  text_comments(id,
                mark,
                keywords,
                translate,
                commentLengthFilter=false,
                commentLengthFilterDigit=0,
                speakerSelectionFilter=false) {
    var url = `api/v1/meetings/${id}/comments?text=plain`;
    if (mark !== null) {
      url += `&mark=${mark}`;
    }
    if (keywords !== null) {
      url += `&keywords=${keywords}`;
    }
    if (translate !== null && translate !== '') {
      url += `&translate=${translate}`;
    }
    if (commentLengthFilter === true) {
      url += `&commentLengthFilter=${commentLengthFilterDigit}`;
    }
    if (speakerSelectionFilter === true) {
      url += `&speakerSelectionFilter=${speakerSelectionFilter}`;
    }
    return this.call('GET', url);
  }

  comment_has_utterance(meeting_id, id, direction, mark, keywords) {
    var url = `api/v1/meetings/${meeting_id}/comment/${id}/utterance/${direction}?`;
    if (mark != null) {
      url += `&mark=${mark}`;
    }
    if (keywords != null) {
      url += `&keywords=${keywords}`;
    }
    console.log(url);
    return this.call('GET', url);
  }

  pictures(id, from) {
    return this.call('GET', `api/v1/meetings/${id}/pictures?from=${from}`);
  }

  updateComment(meeting_id, id, form) {
    return this.call('POST', `api/v1/meetings/${meeting_id}/comments/${id}/update`, form);
  }

  updateCommentByCommentDrop(comment_id) {
    const meeting_id = getSearchParamsForReactRouter().get("id");
    let form = new FormData();
    form.append('is_dropped', '1');
    this.updateComment(meeting_id, comment_id, form)
        .subscribe(
            // props.handlers等を引き回していない ＆ それほど重要ではないため、例外的にエラー処理なし。
            payload => {
            },
            error => {
            }
        );
  }

  deleteComment(meeting_id, id) {
    return this.call('POST', `api/v1/meetings/${meeting_id}/comments/${id}/delete`);
  }

  updateTranslatedComment(meeting_id, id, form) {
    return this.call('POST', `api/v1/meetings/${meeting_id}/translated_comments/${id}/update`, form);
  }

  updatePicture(meeting_id, id, form) {
    return this.call('POST', `api/v1/meetings/${meeting_id}/pictures/${id}/update`, form);
  }

  deletePicture(meeting_id, id) {
    return this.call('POST', `api/v1/meetings/${meeting_id}/pictures/${id}/delete`);
  }

  agendas(meeting_id) {
    return this.call('GET', `api/v1/meetings/${meeting_id}/agendas`);
  }

  updateAgendas(meeting_id, form) {
    return this.call('POST', `api/v1/meetings/${meeting_id}/agendas/update`, form);
  }

  startAgenda(meeting_id, agenda_id) {
    return this.call('POST', `api/v1/meetings/${meeting_id}/agendas/${agenda_id}/start`);
  }

  finishAgenda(meeting_id, agenda_id) {
    return this.call('POST', `api/v1/meetings/${meeting_id}/agendas/${agenda_id}/finish`);
  }

  myGroups(page,params) {
    let url = 'api/v1/my_groups?';
    if (page !== null) {
      url += `page=${page}`;
    }

    let i = 0;

    for (const key in params) {
      if ((i === 0 && page !== null) || i !== 0) {
        url += '&';
      }
      url += `${key}=${encodeURIComponent(params[key])}`
      i++;
    }

    return this.call('GET', url);
  }

  storeMyGroup(form) {
    return this.call('POST', `api/v1/my_groups/store`, form);
  }

  updateMyGroup(my_group_id, form) {
    return this.call('POST', `api/v1/my_groups/${my_group_id}/update`, form);
  }

  deleteMyGroup(my_group_id) {
    return this.call('POST', `api/v1/my_groups/${my_group_id}/delete`);
  }

  myGroup(my_group_id) {
    return this.call('POST', `api/v1/my_groups/${my_group_id}`);
  }

  myGroupUsers(my_group_id, page) {
    return this.call('GET', `api/v1/my_groups/${my_group_id}/users?page=${page}`);
  }

  storeMyGroupUsers(my_group_id, form) {
    return this.call('POST', `api/v1/my_groups/${my_group_id}/users/store`, form);
  }

  deleteMyGroupUsers(my_group_id, form) {
    return this.call('POST', `api/v1/my_groups/${my_group_id}/users/delete`, form);
  }

  calculationDictionaries() {
    return this.call('GET', `api/v1/calculation_dictionaries`);
  }

  calculationDictionary(dictionary_id) {
    return this.call('GET', `api/v1/calculation_dictionaries/${dictionary_id}`);
  }

  updatecalculationDictionary(id, form) {
    return this.call('POST', `api/v1/calculation_dictionaries/${id}/update`, form);
  }

  calculationDictionaryWords(dictionary_id, page) {
    return this.call('GET', `api/v1/calculation_dictionaries/${dictionary_id}/words?page=${page}`);
  }

  calculationDictionaryWord(dictionary_id, id) {
    return this.call('GET', `api/v1/calculation_dictionaries/${dictionary_id}/words/${id}`);
  }

  storecalCulationDictionaryWord(dictionary_id, form) {
    return this.call('POST', `api/v1/calculation_dictionaries/${dictionary_id}/words/store`, form);
  }

  updateCalculationDictionaryWord(dictionary_id, id, form) {
    return this.call('POST', `api/v1/calculation_dictionaries/${dictionary_id}/words/${id}/update`, form);
  }

  deleteCalculationDictionaryWord(dictionary_id, id) {
    return this.call('POST', `api/v1/calculation_dictionaries/${dictionary_id}/words/${id}/delete`);
  }

  meetingSettings(meeting_id) {
    return this.call('GET', `api/v1/meeting_settings/${meeting_id}`);
  }

  updateMeetingSettings(meeting_id, form) {
    return this.call('POST', `api/v1/meeting_settings/${meeting_id}/update`, form);
  }

  meetingStatistics(meeting_id) {
    return this.call('GET', `api/v1/statistics/meetings/${meeting_id}`);
  }

  meetingExtraction(meeting_id) {
    return this.call('GET', `api/v1/statistics/meetings/${meeting_id}/topic_extract`);
  }

  meetingsStatistics(page, params) {
    let url = 'api/v1/statistics/meetings?';
    if (page !== null) {
      url += `page=${page}`;
    }

    let i = 0;

    for (const key in params) {
      if ((i === 0 && page !== null) || i !== 0) {
        url += '&';
      }
      url += `${key}=${encodeURIComponent(params[key])}`
      i++;
    }

    return this.call('GET', url);
  }

  MyMemos(page, params) {
    let url = 'api/v1/my_memos?';
    if (page !== null) {
      url += `page=${page}`;
    }

    let i = 0;

    for (const key in params) {
      if ((i === 0 && page !== null) || i !== 0) {
        url += '&';
      }
      url += `${key}=${encodeURIComponent(params[key])}`
      i++;
    }

    return this.call('GET', url);
  }

  MyMemo(id) {
    return this.call('GET', `api/v1/my_memos/${id}`);
  }

  storeMyMemo(form) {
    return this.call('POST', `api/v1/my_memos/store`, form);
  }

  updateMyMemo(id, form) {
    return this.call('POST', `api/v1/my_memos/${id}/update`, form);
  }

  deleteMyMemo(id) {
    return this.call('POST', `api/v1/my_memos/${id}/delete`);
  }

  MeetingMemos(id, page, params) {
    var url = `api/v1/meetings/${id}/meeting_memos?`;
    if (page !== null) {
      url += `page=${page}`;
    }

    let i = 0;

    for (const key in params) {
      if ((i === 0 && page !== null) || i !== 0) {
        url += '&';
      }
      url += `${key}=${encodeURIComponent(params[key])}`
      i++;
    }

    return this.call('GET', url);
  }

  requestScribeMeeting(id, form) {
    return this.call('POST', `api/v1/ss/meetings/${id}/request`, form);
  }
}
