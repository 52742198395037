import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Service from '../services/MeetingService';

import './MyGroupPicker.css';
import 'react-datepicker/dist/react-datepicker.css';

export default class MyGroupPicker extends Component {

  constructor(props) {
    super(props);

    this.state = {
      toggle: props.isEmbedded || false,
      groups: [],
      users: [],
      selected: []
    };
    this.bounce = false;
  }

  componentDidMount() {
    if (!this.props.isEmbedded) {
      this.handler = this.onClickOutside.bind(this);
      document.addEventListener('click', this.handler);
    }

    const params = {};
    params['per_page'] = 999;

    //noinspection JSUnusedLocalSymbols
    new Service().myGroups(0,params)
      .subscribe(
        payload => {
          this.setState({groups: payload.data });
        },
        error => {
          this.alert();
        }
      );
  }

  componentWillUnmount() {
    if (!this.props.isEmbedded) {
      document.removeEventListener('click', this.handler);
    }
  }

  onClickOutside(event) {
    if (!this.bounce && this.state.toggle) {
      this.toggleSelectBox(event);
    }
    this.bounce = false;
  }

  //noinspection JSUnusedLocalSymbols
  toggleSelectBox(event) {
    this.setState({
      toggle: this.props.isEmbedded ? true : !this.state.toggle,
      selected : []
    });
  }

  onClickButton(event) {
    event.preventDefault();
    this.toggleSelectBox(event);
  }

  onClickAddButton(event) {
    event.preventDefault();
    let additional_members = [];

    this.state.selected.forEach(id => {
      let index = this.state.groups.findIndex(group => { return group.id === id; });
      this.state.groups[index].members.forEach(member => {
        if(this.hasMember(additional_members, member.id) === false
            && this.hasMember(this.props.members, member.id) === false) {
          additional_members.push(member);
        }
      });
    });
    this.props.onAddMembers(additional_members);

    this.onClickOutside(event);
  }

  hasMember(member_array, id) {
    let result = false;
    member_array.forEach(member => {
      if(member.id === id) {
        result = true;
      }
    });
    return result;
  }

  onClickCancelButton(event) {
    event.preventDefault();
    this.onClickOutside(event);
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  button() {
    if (this.props.isEmbedded) {
      return ''
    } else {
      return <button onClick={ this.onClickButton.bind(this) } disabled={ this.state.toggle } className="btnpt5">{ this.props.buttonTitle }</button>
    }
  }

  //noinspection JSUnusedLocalSymbols
  shield(event) {
    this.bounce = true;
  }

  options() {
    let options = [];
    this.state.groups.forEach(group => {
        options.push(<label key={ group.id } >
          <input type="checkbox"
                 className="btnpt2"
                 onChange={ e => { this.toggleOption(e, group.id) } }
                 checked={ this.isSelectedGroup(group.id) }
          />
          <div className="check"/>{ group.name }
          </label>);
    });
    if (options.length > 0) {
      return options;
    } else {
      return (
        <div>Myグループが未登録です。</div>
      );
    }
  }

  isSelectedGroup(id) {
    let index = this.state.selected.findIndex(selected => { return selected === id; });
    return index >= 0;
  }

  render() {
    return (
      <div className="my-group-picker" onClick={ this.shield.bind(this) } >
        { this.button() }
        <div className="select-box" style={{ display: this.state.toggle ? 'flex' : 'none' }} >
          <div>
            <div className="search">
              <div>Myグループ選択</div>
            </div>
            <div className="options" >{ this.options() }</div>
            <div className="operations">
              <button onClick={ this.onClickCancelButton.bind(this) } className="btnpt2"><div>キャンセル</div></button>
              <button onClick={ this.onClickAddButton.bind(this) } className="btnpt5"><div>追加する</div></button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  toggleOption(event, id) {
    // selected配列上のインデックス番号取得
    let index = this.state.selected.findIndex(selected => { return selected === id; });

    if (index >= 0) {
      // selected配列から除外する
      let updated_array = [
        ...this.state.selected.slice(0, index),
        ...this.state.selected.slice(index + 1)
      ];
      this.setState({ selected : updated_array });
    } else {
      // selected配列に加える
      let updated_array = this.state.selected.concat([id]);
      this.setState({ selected : updated_array });
    }
  }
}

MyGroupPicker.PropType = {
  members: PropTypes.array.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  isEmbedded: PropTypes.bool
};
