import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Service from '../services/MeetingService';
import { AlertTwoButton } from '../components/Alert';
import MyGroupPopup from './MyGroupPopup';
import PaginationBar from "../components/PaginationBar";

import './MyGroups.css';
import MyGroupUsers from "./MyGroupUsers";
import ClassNames from "classnames";

class OptionMenu extends Component {

  constructor(props) {
    super(props);

    this.state = {
      toggle: false,
    };
    this.bounce = false;
  }

  componentDidMount() {
    this.handler = this.onClickOutside.bind(this);
    document.addEventListener('click', this.handler);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handler);
  }

  onClickOutside(event) {
    if (!this.bounce && this.state.toggle) {
      this.toggleSelectBox(event);
    }
    this.bounce = false;
  }

  toggleSelectBox(event) {
    const toggle = this.state.toggle;
    this.setState({ toggle: !this.state.toggle });
    return toggle;
  }

  onClickButton(event) {
    event.stopPropagation();
    event.preventDefault();
    this.shield();
    this.toggleSelectBox(event);
  }

  shield() {
    this.bounce = true;
  }

  onEditGroup(e) {
    this.setState({
      toggle: false,
    });
    this.props.onEditGroup(e);
  }

  onDeleteGroup(e) {
    this.setState({
      toggle: false,
    });
    this.props.onDeleteGroup(e);
  }

  render() {
    const iconClass = ClassNames({
      'icon-option': true,
      'button-option-icon': true,
      'selected': this.state.toggle
    });

    return (
      <div id={ this.props.id } className="option-menu">
        <div className={ iconClass } onClick={ this.onClickButton.bind(this) }/>
        <div className="menus" hidden={ !this.state.toggle }>
          <div className="edit-group" onClick={ e => { this.onEditGroup(e) }}>編集する</div>
          <div className="delete-group" onClick={ e => { this.onDeleteGroup(e) }}>削除する</div>
        </div>
      </div>
    );
  }
}

OptionMenu.PropType = {
  id: PropTypes.number,
  onEditGroup:PropTypes.func,
  onDeleteGroup:PropTypes.func
};


class MyGroup extends Component {

  render() {
    return (
      <tr className={ ClassNames({ 'active': this.props.isActive })} onClick={ e => { this.props.onClick(e, this.props.id, this.props.name) } } >
        <td className="name">{ this.props.name }</td>
        <td className="counts"><span>{ this.props.counts }人</span></td>
        <td className="functions">
          <OptionMenu
            onEditGroup={ e => { this.props.popupUpdateMyGroup(e, this.props.id) } }
            onDeleteGroup={ e => { this.props.popupDeleteMyGroup(e, this.props) } }
          />
        </td>
      </tr>
      );
  }
}

MyGroup.PropType = {
  id: PropTypes.number,
  name: PropTypes.string,
  isActive: PropTypes.bool,
  onClick:PropTypes.func,
  popupUpdateMyGroup:PropTypes.func,
  popupDeleteMyGroup:PropTypes.func
};

class MyGroups extends Component {

  constructor(props) {
    super(props);

    this.state = {
      id: null,
      name: null,
      total: 0,
      per_page: 20,
      current: 0,
      last: 0,
      details: null
    };
    this.isGetData = false;
  }

  componentDidMount() {
    this.getCurrentPage();
  }

  getCurrentPage(page = null) {
    this.props.handlers.showProgress();

    let service = new Service();
    service.myGroups(page === null ? this.state.current : page).subscribe(
      payload => {
        this.props.handlers.hideProgress();
        this.isGetData = true;
        let id = this.state.id;
        let name = null;
        if (id && payload.data) {
          const current = payload.data.filter(item => item.id === id);
          if (current && current[0]) {
            name = current[0].name;
          } else {
            id = null;
          }
        }

        this.setState({
          id: id,
          name: name,
          total: payload.total,
          per_page: payload.per_page,
          current: payload.current_page,
          last: payload.last_page,
          details: payload.data
        });
      },
      error => {
        this.props.handlers.hideProgress();
        this.props.handlers.error(error);
      }
    );
  }

  myGroups() {
    let myGroups = [];
    this.state.details.forEach(item => {
      myGroups.push(
        <MyGroup
          key={ item.id }
          id={ item.id }
          name={ item.name }
          counts={ item.members ? item.members.length : 0 }
          isActive={ this.state.id === item.id }
          onClick={ this.onClickRow.bind(this) }
          popupUpdateMyGroup={ this.popupUpdateMyGroup.bind(this) }
          popupDeleteMyGroup={ this.popupDeleteMyGroup.bind(this) }
        />);
    });
    return myGroups;
  }

  details() {
    return (
      <div className="detail">
        <table>
          <tbody>
            { this.myGroups() }
          </tbody>
        </table>
      </div>
    );
  }

  myGroupData() {
    return (
      <div className="data">
        <div className="header">
          <div className="title">Myグループ</div>
          <button className="icon-plus button-rectangle-icon" onClick={ this.popupCreateMyGroup.bind(this) } />
        </div>
        { this.details() }
        <PaginationBar
            total={ this.state.total }
            per_page={ this.state.per_page }
            current={ this.state.current }
            last={ this.state.last }
            onClick={ this.getCurrentPage.bind(this) }
        />
      </div>
    );
  }

  myGroupNoData() {
    return (
      <div className="no-data">
        <div className="title">Myグループ</div>
        <div className="explanation">会議に招集するメンバーのグループを作成できます</div>
        <div className="buttons"><button className="button-1" onClick={ this.popupCreateMyGroup.bind(this) } >グループを作成</button></div>
      </div>
    );
  }

  myGroupUsers(){
    if (this.state.id) {
      return <MyGroupUsers
        id={ this.state.id }
        name={ this.state.name }
        onChange={ this.getCurrentPage.bind(this) }
        handlers={ this.props.handlers }
      />;
    }
    return null;
  }

  render() {
    return (
      <div className="my-groups">
        <div className="my-groups-left">
          { this.isGetData ? (this.state.details && this.state.details.length > 0 ? this.myGroupData() : this.myGroupNoData()) : null }
        </div>
        <div className="my-groups-right">
          { this.myGroupUsers() }
        </div>
      </div>
    );
  }

  popupCreateMyGroup(event) {
    event.preventDefault();
    this.props.handlers.showPopup(<MyGroupPopup title={ 'Myグループを作成' } close={ this.closePopup.bind(this) } error={ this.props.handlers.error } />);
  }

  popupUpdateMyGroup(event, id) {
    event.preventDefault();
    event.stopPropagation();
    this.props.handlers.showPopup(<MyGroupPopup id={ id } title={ 'Myグループを変更' } close={ this.closePopup.bind(this) } error={ this.props.handlers.error } />);
  }

  closePopup() {
    this.props.handlers.hidePopup();
    this.getCurrentPage();
  }

  popupDeleteMyGroup(event, detail) {
    event.preventDefault();
    event.stopPropagation();
    const message = `${detail.name}を削除しますか？`;
    this.props.handlers.showPopup(
      <AlertTwoButton
        title="Myグループの削除"
        message={ message }
        cancel={ this.props.handlers.hidePopup }
        okay={ this.deleteMyGroup(detail.id) }
        okayButtonText="削除する"
      />
    );
  }

  deleteMyGroup(id) {
    return () => {
      new Service().deleteMyGroup(id).subscribe(
        payload => {
          this.props.handlers.hidePopup();
          this.getCurrentPage();
        },
        error => {
          this.props.handlers.hidePopup();
          if (error.response.status === 423) { // locked
            this.alertLockedMyGroup();
          } else {
            this.props.handlers.error(error);
          }
        }
      );
    };
  }

  onClickRow(event, id, name) {
    event.preventDefault();
    this.setState({
      id: id,
      name: name
    });
  }
}

export default MyGroups;
