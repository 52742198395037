import React, { Component } from 'react'
import Service from '../services/MeetingService';
import Spinner from "react-md-spinner";

import './Login.css';
import {routePath2href} from "./utilities";

/*
 * like Redux
 */
const Reducer = (state = { session: null, waiting: false, message: '' }, action) => {

  var _state = Object.assign({}, state);

  switch (action.type) {
    case 'SUBMIT_LOGIN':
      _state.waiting = true;
      return _state;
    case 'GET_CUSTOMER_SETTINGS':
      _state.waiting = true;
      _state.session = action.session;
      return _state;
    case 'GET_PERSONAL_SETTINGS':
      _state.waiting = true;
      _state.session = action.session;
      return _state;
    case 'LOGIN_COMPLETED':
      _state.waiting = false;
      _state.session = action.session;
      return _state;
    case 'ERROR':
      _state.waiting = false;
      _state.session = null;
      _state.message = action.message;
      return _state;
    default:
      return _state;
  }
}

export function setUserInfo(user) {
    //sessionStorageには容量に上限があるため、利用には注意。
    //モバイル系で少ないものだと10MBらしい
    sessionStorage.setItem('id', user.id);
    //下記if文は旧サーバーを考慮した判定
    if(user.customer) {
        sessionStorage.setItem('customer_name', user.customer.name);
    }
    sessionStorage.setItem('username', user.user_name);
    sessionStorage.setItem('authorization', user.authorization);
    sessionStorage.setItem('authorization_ss', user.authorization_ss);
    sessionStorage.setItem('email', user.email);
}

export function unsetUserInfo() {
    sessionStorage.removeItem('session');
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('customer_name');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('authorization');
    sessionStorage.removeItem('authorization_ss');
    sessionStorage.removeItem('email');
}

export function setCustomerSettingInfo(settings) {
    sessionStorage.setItem('customer_settings', JSON.stringify(settings));
}

export function setPersonalSettingInfo(settings) {
    sessionStorage.setItem('personal_settings', JSON.stringify(settings));
}

function unsetCustomerSettingInfo() {
    sessionStorage.removeItem('personal_settings');
}

function unsetPersonalSettingInfo() {
    sessionStorage.removeItem('personal_settings');
}

/**
 * 全部のsessionStorage情報を削除
 *
 * 一応作ってみたけど、使い所は少なそう。
 * 設定情報がなくなると、getItem時にnullが返り、動作に支障をきたすため、注意が必要。
 */
export function unsetAllInfo() {
    unsetUserInfo();
    unsetCustomerSettingInfo();
    unsetPersonalSettingInfo();
}

class LoginPresenter {

  constructor(component) {
    this.component = component;
    this.component.state = Reducer(undefined, {});
    this.service = new Service();
    this.props = component.props;
  }

  dispatch(action) {
    this.component.setState(prevState => Reducer(prevState, action));
  }

  get_customer_settings(session) {
      this.dispatch({ type: 'GET_CUSTOMER_SETTINGS', session: session });
      this.service.customerSettings().subscribe(
          payload => {
              setCustomerSettingInfo(payload);
              this.get_personal_settings(payload.api_token);
          },
          error => {
              //API未対応バージョンサーバーの場合を考慮しない。
              //設定値を使う処理にも大きく影響するため、手動でF5リロードで対処してもらう。
              this.dispatch({type: 'ERROR', message: 'ネットワークエラーが発生しました。ネットワーク状況をお確かめの上再度操作して下さい。'});
          }
      );
  }

    get_personal_settings(session) {
        this.dispatch({ type: 'GET_PERSONAL_SETTINGS', session: session });
        this.service.personalSettings().subscribe(
            payload => {
                setPersonalSettingInfo(payload);

                this.dispatch({ type: 'LOGIN_COMPLETED', session: session });
              let from = sessionStorage.getItem('intended');
              if(from) {
                sessionStorage.removeItem('intended');
              } else {
                // こちらに進むケースは無いはず。以前から処理があったため残している
                from = window.location.origin + "/";
              }
              window.location.href = from;
            },
            error => {
                //API未対応バージョンサーバーの場合を考慮しない。
                //設定値を使う処理にも大きく影響するため、手動でF5リロードで対処してもらう。
                this.dispatch({type: 'ERROR', message: 'ネットワークエラーが発生しました。ネットワーク状況をお確かめの上再度操作して下さい。'});
            }
        );
    }

    login(email, password) {

    if (!email || !password) {
      this.dispatch({ type: 'ERROR', message: 'メールアドレス、パスワードは必須入力です。' });
      return;
    }

    this.dispatch({ type: 'SUBMIT_LOGIN', message: 'waiting' });

    this.service.login(email, password)
      .subscribe(
        payload => {
          sessionStorage.setItem('session', payload.api_token);
          setUserInfo(payload);
          this.get_customer_settings(payload.api_token);
        },
        error => {
          unsetAllInfo();

          if (error.response && error.response.status && ((error.response.status === 401) || (error.response.status === 403))) {
              // 401: メールアドレスまたはパスワードの間違い
              // 403: アカウントが無効
            this.dispatch({ type: 'ERROR', message: 'メールアドレスまたはパスワードが間違っています。' });
          } else if (error.response && error.response.status && error.response.status === 429) {
            this.dispatch({ type: 'ERROR', message: 'パスワードを一定回数間違えたため、60秒間ログインできません。' });
          } else {
            this.dispatch({ type: 'ERROR', message: 'ネットワークエラーが発生しました。ネットワーク状況をお確かめの上再度操作して下さい。' });
          }
        }
      );
  }
}

export default class Login extends Component {

  constructor(props) {
    super(props);
    this.presenter = new LoginPresenter(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    const email = this.refs.email.value;
    const password = this.refs.password.value;
    this.presenter.login(email, password);
  }

  redirectTo(url = '/') {
    this.props.history.push(url);
  }

  spinner() {
    if (this.state.waiting) {
      return (
          <div className="login-spinner">
            <Spinner size="80" />)
          </div>
      );
    }
    return null;
  }

  render() {
    return (
        <div className="login">
          <div className="login-left">
            <div className="login-left-top">
              <div className="login-logo">
                <div className="img-top-logo"/>
              </div>
            </div>
            <div className="login-left-bottom">
              <form onSubmit={ e => this.handleSubmit(e) }>
                <div className="login-login">ログイン</div>
                <div>メールアドレス</div>
                <div><input ref="email" type="email" autoFocus /></div>
                <div>パスワード</div>
                <div><input ref="password" type="password" /></div>
                <p className="error" style={{ textAlign: "center" }}>{ this.state.message }</p>
                <div><input className="button-1" type="submit" value="ログイン" disabled={ this.state.waiting } /></div>
                <div><a className="button-text" href={ routePath2href("/password/send") }>パスワードをお忘れの方</a></div>
              </form>
            </div>
          </div>
          <div className="login-right">
            <div className="img-login-background"/>
          </div>
          { this.spinner() }
        </div>
    );
  }
}
