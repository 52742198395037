import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import CustomDatePicker from '../components/CustomDatePicker';
import AgendaComment from './AgendaComment';

import './Agenda.css';
import ClassNames from "classnames";
import Service from "../services/MeetingService";
import MeetingTimePicker from "./MeetingTimePicker";

class UsageTime extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usageTime: this.props.usageTime
    };
  }

  render() {
    return (
      <span>{ this.usageTime(this.state.usageTime) }</span>
    );
  }

  usageTime(seconds) {
    if (seconds) {
      return Math.floor(seconds / 60 ) + '分';
    } else {
      return '0分';
    }
  }

  componentDidMount() {
    this.timerID = setInterval( () => this.setTime(),1000 )
  }

  componentWillUnmount() {
    clearInterval(this.timerID)
  }

  setTime() {
    if (this.props.currentIndex === this.props.index) {
      let usageTime = this.props.usageTime;
      if (this.props.startAt) {
        usageTime += Moment().diff(this.props.startAt, 'seconds');
      }
      if (this.state.usage !== usageTime) {
        this.setState({
          usageTime: usageTime
        });
      }
    }
  }
}

UsageTime.PropType = {
  index: PropTypes.number,
  currentIndex: PropTypes.number,
  usageTime: PropTypes.number,
  startAt: PropTypes.object
};

export default class Agenda extends Component {

  constructor(props) {
    super(props);

    const currentIndex = this.startAgendaIndex(this.props.agenda.agenda_parts);
    this.state = {
      agenda: this.copyAgenda( props.agenda ),
      currentIndex: currentIndex
    };
  }

  componentDidUpdate() {
    if (this.props.agenda.updated_at !== this.state.agenda.updated_at) {
      const agenda = this.copyAgenda( this.props.agenda);
      const currentIndex = this.startAgendaIndex(agenda.agenda_parts);
      this.setState({ agenda: agenda, currentIndex: currentIndex });
    }
  }

  copyAgenda(fromAgenda){
    const toAgenda = JSON.parse( JSON.stringify( fromAgenda ));
    toAgenda.next_date = toAgenda.next_date ? Moment(toAgenda.next_date) : null;
    toAgenda.next_start_at = toAgenda.next_start_at ? Moment(toAgenda.next_start_at) : null;
    toAgenda.next_end_at = toAgenda.next_end_at ? Moment(toAgenda.next_end_at) : null;
    toAgenda.agenda_parts.forEach(parts => {
      parts.deadline = parts.deadline ? Moment(parts.deadline) : null;
      parts.start_at = parts.start_at ? Moment(parts.start_at) : null;
      parts.end_at = parts.end_at ? Moment(parts.end_at) : null;
    });
    return toAgenda;
  }

  onChangeNextDay(date) {
    if (this.props.disabled) {
      return null;
    }
    const agenda = this.state.agenda;
    agenda.next_date = date;
    this.setState({ agenda: agenda });
    if (this.props.onChange) {
      this.props.onChange(agenda);
    }
  }

  onChangeText(val, item, index) {
    const agenda = this.state.agenda;
    agenda[item] = val;
    this.setState({ agenda: agenda });
    if (this.props.onChange) {
      this.props.onChange(agenda);
    }
  }

  onChangeAgendaText(val, item, index) {
    const agenda = Object.assign({}, this.state.agenda);
    const agenda_parts = agenda.agenda_parts;
    agenda_parts[index][item] = val;
    agenda.agenda_parts = agenda_parts;
    this.setState({ agenda: agenda });
    if (this.props.onChange) {
      this.props.onChange(agenda);
    }
  }

  onChangeDeadline (date, index) {
    if (this.props.disabled) {
      return null;
    }
    const agenda = Object.assign({}, this.state.agenda);
    const agenda_parts = agenda.agenda_parts;
    agenda_parts[index].deadline = date;
    agenda.agenda_parts = agenda_parts;
    this.setState({ agenda: agenda });
    if (this.props.onChange) {
      this.props.onChange(agenda);
    }
  }

  onChangeStartTime(time) {
    if (this.props.disabled) {
      return null;
    }
    const agenda = this.state.agenda;
    agenda.next_start_at = time;
    this.setState({ agenda: agenda });
    if (this.props.onChange) {
      this.props.onChange(agenda);
    }
  }

  onChangeEndTime(time) {
    if (this.props.disabled) {
      return null;
    }
    const agenda = this.state.agenda;
    agenda.next_end_at = time;
    this.setState({ agenda: agenda });
    if (this.props.onChange) {
      this.props.onChange(agenda);
    }
  }

  onClickStartFinishButton(e, index) {
    // サーバー上の情報を更新するため、同期が終わるまで待つ
    this.props.handlers.showGlobalProgress();

    e.preventDefault();
    const meeting = this.state.agenda;
    const agendaParts = meeting.agenda_parts[index];
    const isStart = this.canStartAgenda(index);
    let promise = null;
    if (isStart) {
      promise = new Service().startAgenda(this.props.id, agendaParts.id);
    } else {
      promise = new Service().finishAgenda(this.props.id, agendaParts.id);
    }
    promise
      .finally(() => {
        this.props.reload(true);
      })
      .subscribe(
        payload => {
          const agenda = Object.assign({}, this.state.agenda);
          const agenda_parts = agenda.agenda_parts;
          agenda_parts[index].start_at = payload.start_at ? Moment(payload.start_at) : null;
          agenda_parts[index].end_at = payload.end_at ? Moment(payload.end_at) : null;
          agenda_parts[index].usage_time = payload.usage_time ? Moment(payload.usage_time) : null;
          agenda.agenda_parts = agenda_parts;
          this.setState({ agenda: agenda, currentIndex: isStart ? index : null});
        },
        error => {
          this.props.handlers.error(error);
        }
      );
  }

  startAgendaIndex(agenda_parts) {
    for (let i = 0; i < agenda_parts.length; i++) {
      if (agenda_parts[i].start_at && !agenda_parts[i].end_at) {
        return i;
      }
    }
    return null;
  }

  canStartAgenda(index) {
    return !this.state.agenda.agenda_parts[index].start_at || this.state.agenda.agenda_parts[index].end_at;
  }

  startEndButton(index) {
    const button = this.canStartAgenda(index) ? '議題開始' : '議題終了';
    const classname = ClassNames({ 'button-stop': true, 'button-2': this.canStartAgenda(index), 'button-2-red': !this.canStartAgenda(index) });
    const startEndButtonDisabled = ((this.state.currentIndex !== null && this.state.currentIndex !== index) || this.props.disabled || !this.props.canStartAgenda);
    return (<button onClick={ e => { this.onClickStartFinishButton(e, index) } } className={ classname } disabled={ startEndButtonDisabled } >{ button }</button>);
  }

  escapeHTML(text) {
    const obj = document.createElement('pre');
    obj[typeof obj.textContent !== 'undefined'?'textContent':'innerText'] = text;
    return obj.innerHTML;
  }

  processText(text) {
    return this.escapeHTML(text).replace(/\n/g, '<br>');
  };

  requiredTime(agenda) {
    if(agenda.time_required) {
      return agenda.time_required + "分";
    }
    return "0分";
  }

  agendaParts() {
    const agenda_parts = [];
    this.state.agenda.agenda_parts.forEach((agenda, index) => {
      const classname = ClassNames({ 'current': (this.state.currentIndex === index), 'agenda': true });
      agenda_parts.push(
        <div key={ agenda.id } className={ classname }>
          <div className="agenda-header">
            <div className="agenda-title-button">
              <div className="agenda-title-area">
                <label>議題{ index+1 }</label>
                <div className="agenda-title" dangerouslySetInnerHTML={{ __html:  this.processText(agenda.title) }} />
              </div>
              <div className="agenda-button">
                { this.startEndButton(index) }
              </div>
            </div>
            <div className="agenda-presenter-time">
              <div className="agenda-presenter-area">
                <div className="icon-user-small agenda-icon"/>
                <div className="agenda-presenter" dangerouslySetInnerHTML={{ __html:  this.processText(agenda.presenter) }} />
              </div>
              <div className="agenda-time">
                <div className="icon-stopwatch-small agenda-icon"/>
                <UsageTime
                  index={index}
                  currentIndex={ this.state.currentIndex }
                  usageTime={ agenda.usage_time }
                  startAt={ agenda.start_at }
                />
                <span>/{ this.requiredTime(agenda) }</span>
              </div>
            </div>
          </div>
          <div className="agenda-body">
            <AgendaComment
              index={ index }
              item={ 'decision' }
              name={ '決定事項' }
              minRows={ 7 }
              maxLength={ 1024 }
              defaultValue={ agenda.decision }
              disabled={ this.props.disabled }
              onChangeText={ this.onChangeAgendaText.bind(this) }
              onError={ this.props.onError }
            />
            <AgendaComment
              index={ index }
              item={ 'comment' }
              name={ 'コメント' }
              minRows={ 7 }
              maxLength={ 1024 }
              defaultValue={ agenda.comment }
              disabled={ this.props.disabled }
              onChangeText={ this.onChangeAgendaText.bind(this) }
              onError={ this.props.onError }
            />
            <div className="agenda-row">
              <AgendaComment
                index={ index }
                item={ 'person_in_charge' }
                name={ '責任者' }
                maxLength={ 200 }
                defaultValue={ agenda.person_in_charge }
                disabled={ this.props.disabled }
                onChangeText={ this.onChangeAgendaText.bind(this) }
                onError={ this.props.onError }
              />
              <div className="agenda-deadline">
                <label>期限日</label>
                <CustomDatePicker
                    selected={ agenda.deadline }
                    onChange={ e => { this.onChangeDeadline(e, index) } }
                    disabled={ this.props.disabled }
                    forAgenda={ true }
                />
              </div>
            </div>
          </div>
        </div>
      );
    });
    return agenda_parts;
  }

  render() {
    if (!this.state.agenda.updated_at) {
      return null;
    }

    return (
      <div className="agendas">
        <div className="agenda-parts">
          { this.agendaParts() }
        </div>
        <div className="agenda-footer">
          <div className="next-date-area">
            <label>次回開催日</label>
            <div className="next-date">
              <CustomDatePicker
                  selected={ this.state.agenda.next_date }
                  onChange={ this.onChangeNextDay.bind(this) }
                  disabled={ this.props.disabled }
              />
            </div>
            <div className="next-date-time">
              <div>
                <MeetingTimePicker
                  time={ this.state.agenda.next_start_at }
                  notUseSecond={true}
                  intervalOfMinutesOption={5}
                  onChangeTime={ this.onChangeStartTime.bind(this) }
                  disabled={ this.props.disabled || !this.state.agenda.next_date }
                />
              </div>
              <span>〜</span>
              <div>
                <MeetingTimePicker
                  time={ this.state.agenda.next_end_at }
                  notUseSecond={true}
                  intervalOfMinutesOption={5}
                  onChangeTime={ this.onChangeEndTime.bind(this) }
                  disabled={ this.props.disabled || !this.state.agenda.next_date }
                />
              </div>
            </div>
          </div>
          <AgendaComment
            index={ 0 }
            item={ 'overall_comment' }
            name={ 'コメント' }
            minRows={ 7 }
            maxLength={ 1024 }
            defalutValue={ this.state.agenda.overall_comment }
            disabled={ this.props.disabled}
            onChangeText={ this.onChangeText.bind(this) }
            onError={ this.props.onError }
          />
        </div>
      </div>
    );
  }
};

Agenda.PropType = {
  disabled: PropTypes.bool,
  agenda: PropTypes.object,
  handlers: PropTypes.object,
  canStartAgenda: PropTypes.bool,
  onError:PropTypes.func,
  onChange:PropTypes.func,
  reload:PropTypes.func
};
