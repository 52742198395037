import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Service from '../services/MeetingService';

import './UserPicker.css';
import 'react-datepicker/dist/react-datepicker.css';

/**
 * ユーザー選択コンポーネント
 *
 * ユーザーの一覧だけでなく、追加ボタンやクリアボタンまで含んでしまっているため、
 * ユーザー選択コンポーネントがDOMから消えない。。。
 * そのため、キャンセルや別グループを選択したときに、
 * 常に中途半端に情報が残っており、注意が必要。
 *
 * 会議ポップアップとグループ編集の両方で使われているため注意。
 */
export default class UserPicker extends Component {

  constructor(props) {
    super(props);

    this.state = {
      toggle: props.isEmbedded || false,
      keywords: null,
      loaded: false,
      users: []
    };
    this.bounce = false;
    this.selected = [];
  }

  componentDidMount() {
    if (!this.props.isEmbedded) {
      // 他グループなどをコンポーネント外を選択したときに消すためにリスナー登録
      // (再実装するなら、ポップアップのように他の操作をさせないほうがよさそう)
      this.handler = this.onClickOutside.bind(this);
      document.addEventListener('click', this.handler);
    }

    this.updateMembers(this.props.exclusionMyGroupId);
  }

  /**
   * メンバーの更新。(外部からも呼ばれるので注意。)
   * @param group_id 会議ポップアップの場合は、undefined
   */
  updateMembers(group_id) {
    this.selected = [];
    new Service().searchMeetingUsers(group_id)
        .subscribe(
            payload => {
              this.setState({
                users: payload,
                loaded: true
              });
            },
            error => {
              this.alert();
            }
        );
  }

  componentWillUnmount() {
    if (!this.props.isEmbedded) {
      document.removeEventListener('click', this.handler);
    }
  }

  onClickOutside(event) {
    if (!this.bounce && this.state.toggle) {
      this.toggleSelectBox(event);
      this.selected = [];
    }
    this.bounce = false;
  }

  toggleSelectBox(event) {
    var toggle = this.state.toggle;
    this.setState({
      toggle: this.props.isEmbedded ? true : !this.state.toggle,
      keywords: null
    });

    return toggle;
  }

  /**
   * 実質はMyグループのメンバー追加ボタン。会議作成/編集ポップアップでは呼ばれない。
   * @param event
   */
  onClickButton(event) {
    event.preventDefault();
    this.toggleSelectBox(event);
  }

  /**
   * 追加するボタンのクリック
   * @param event
   */
  onClickAddButton(event) {
    event.preventDefault();
    var added_members = this.selected.map(id => {
      var index = this.state.users.findIndex(user => { return user.id === id; });
      return this.state.users[index];
    });
    this.props.onAddMembers(added_members);
    this.selected = [];
    this.onClickOutside(event);
  }

  /**
   * キャンセルボタンのクリック
   * @param event
   */
  onClickCancelButton(event) {
    event.preventDefault();
    this.onClickOutside(event);
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  button() {
    if (this.props.isEmbedded) {
      return ''
    } else {
      return <button onClick={ this.onClickButton.bind(this) }
                     disabled={ this.state.toggle }
                     className={ this.props.largeButton? "button-1 largeButton" : "button-1"}>{ this.props.buttonTitle }</button>
    }
  }

  onClickClearButton() {
    this.props.onClearMembers();
    this.selected = [];
  }

  clearButton() {
    if (this.props.canClear && this.props.members && this.props.members.length > 0) {
      return <button onClick={ this.onClickClearButton.bind(this) } className="clear btnpt2">クリア</button>
    }
    return null;
  }

  shield(event) {
    this.bounce = true;
  }

  options() {
    var options = [];
    this.state.users.forEach(user => {
      if (this.props.members.findIndex(member => { return member.id === user.id; }) < 0) {
        if (this.state.keywords === null || user.dept.match(this.state.keywords) || user.user_name.match(this.state.keywords)) {
          var username = user.user_name;
          if (user.dept.length > 0) {
            username = user.dept + "/" + username;
          }
          options.push(<label key={ user.id } ><input type="checkbox" className="btnpt2" onClick={ e => { this.toggleOption(e, user.id) } }/><div className="check"/><div>{ username }</div></label>);
        }
      }
    });
    if (options.length > 0) {
      return options;
    }
    if(this.state.loaded === false) {
      return (
          <div/>
      );
    }
    return (
        <div>一致するユーザが見つかりませんでした。</div>
    );
  }

  /**
   * this.state.toggle状況によって、selectedBox要素を返す。
   * displayによる切り替えではなく、nullを返す目的は２つ
   * ・Myグループの編集でグループを切り替えたときに、スクロール位置をトップに変更する。
   * ・Myグループの編集でグループを切り替えたときに、メンバーの選択状態をリセットする
   * @returns {*}
   */
  selectedBox() {
    if(!this.state.toggle) {
      return null;
    }
    return(
        <div className="select-box" style={{ display: this.state.toggle ? 'flex' : 'none' }} >
          <div>
            <div className="search">
              <input type="search" ref="keywords" placeholder="ユーザーの検索" onKeyDown={ this.onKeyDownKeywords.bind(this)}　onChange={ this.onChangeKeyword.bind(this) } />
              <button onClick={ this.onSearch.bind(this) } className="button-select"><div className="icon-search"></div></button>
            </div>
            <div className="options">{ this.options() }</div>
            <div className="operations">
              <button onClick={ this.onClickCancelButton.bind(this) } className="button-text"><div>キャンセル</div></button>
              <button onClick={ this.onClickAddButton.bind(this) } className="button-1"><div>追加する</div></button>
            </div>
          </div>
        </div>
    );
  }

  render() {
    return (
      <div className="user-picker" onClick={ this.shield.bind(this) } >
        { this.button() }
        { this.selectedBox() }
        { this.clearButton() }
      </div>
    );
  }

  toggleOption(event, id) {
    // selected配列上のインデックス番号取得
    var index = this.selected.findIndex(selected => { return selected === id; });

    if (index >= 0) {
      // selected配列から除外する
      this.selected = [
        ...this.selected.slice(0, index),
        ...this.selected.slice(index + 1)
      ];
    } else {
      // selected配列に加える
      this.selected.push(id);
    }
  }

  onKeyDownKeywords(event) {
    if (event.which === 0xd) {
      event.preventDefault();
      this.setState({ keywords: this.refs.keywords.value });
    }
  }

  onChangeKeyword(event) {
    event.preventDefault();
    const keywords = this.refs.keywords.value || null;
    if(keywords === null) {
      this.setState({ keywords: keywords });
      // 他の検索処理では、keywordsがnullの場合に明示的にリスト更新するケースがある。
      // 再描画時にリスト更新するため、手動によるリスト更新は不要
    }
  }

  onSearch(event) {
    event.preventDefault();
    this.setState({ keywords: this.refs.keywords.value });
  }
}

UserPicker.PropType = {
  // 除外するメンバー
  members: PropTypes.array.isRequired,
  canClear: PropTypes.bool.isRequired,
  // 実質はMyグループの「メンバーを追加」ボタンのタイトル
  buttonTitle: PropTypes.string.isRequired,
  largeButton: PropTypes.bool.isRequired,
  // 実質は会議ポップアップかどうか
  isEmbedded: PropTypes.bool,
  // 除外するMyグループのID
  exclusionMyGroupId: PropTypes.number
};
