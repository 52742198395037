import React, {Component} from 'react';
import './MemberMenu.css';
import ClassNames from "classnames";
import MyGroups from "./MyGroups";
import Users from "./Users";
import Auth from "../components/Auth";

export default class MemberMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectMenu: ''
    };
  }

  getLink(id, linkName) {
    return (
      <li
        className={ ClassNames({ 'disabled': (this.state.selectMenu === id) })}
        onClick={ () => this.onClickMenu(id) }>
        <span>{ linkName }</span>
      </li>
    );
  }

  onClickMenu(id){
    this.setState({'selectMenu': id});
  }

  mainPanel(){
    let data = null;
    switch (this.state.selectMenu) {
      case 'users':
        data = <Users handlers={ this.props.handlers } />;
        break;
      case 'my-groups':
        data = <MyGroups handlers={ this.props.handlers } />;
        break;
      default:
        break;
    }
    return data;
  }

  render() {
    if(!Auth.isAuthenticated()) {
      return null;
    }

    return (
      <div className="member-menu">
        <div className="member-menu-left">
          <div className="member-menu-header">
            <div className="icon-nav-member"/>
            <span>メンバー</span>
          </div>
          <ul>
            { this.getLink('users', 'メンバー一覧') }
            { this.getLink('my-groups', 'Myグループ') }
          </ul>
        </div>
        <div className="member-menu-right">{ this.mainPanel() }</div>
      </div>
    );
  }
}
