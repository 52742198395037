import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './MeetingScore.css';
import {isUnsignedIntegerString, zenkakuAlphanumeric2hankaku} from "../containers/utilities";

class MeetingScore extends Component {

  constructor(props) {
    super(props);

    this.state = {
      // 入力中の内容。数値以外も入りうる
      document_score_assignment_input: String(props.document_score_assignment),
      time_score_assignment_input: String(props.time_score_assignment),
      progress_score_assignment_input: String(props.progress_score_assignment),
      conclusion_score_assignment_input: String(props.conclusion_score_assignment),
      next_meeting_score_assignment_input: String(props.next_meeting_score_assignment),
      speaker_score_assignment_input: String(props.speaker_score_assignment),

      // 以降は数値の状態。外部から参照されるので注意
      document_score_assignment: props.document_score_assignment,
      time_score_assignment: props.time_score_assignment,
      progress_score_assignment: props.progress_score_assignment,
      conclusion_score_assignment: props.conclusion_score_assignment,
      next_meeting_score_assignment: props.next_meeting_score_assignment,
      speaker_score_assignment: props.speaker_score_assignment,

      errors: []
    }
  }

  render() {
    return (
      <div className="meeting-score-area">
        <form autoComplete="off">
          <div className="meeting-score-table">
            <table>
              <tbody>
                <tr className="score-row">
                  <td className="title">資料の事前登録</td>
                  <td className="desc">事前に資料を読み込む時間を準備できたかを採点します。<br/>登録した時間から算出します。</td>
                  <td className="score">
                    <div><input type="text"
                                autoFocus
                                value={this.state.document_score_assignment_input}
                                maxLength={3}
                                onChange={ e => { this.onChangeNumber(e, 'document_score_assignment') } }
                                className="text" /><label>点</label></div>
                  </td>
                </tr>
                <tr className="score-row">
                  <td className="title">開始と終了</td>
                  <td className="desc">設定した時間どおりに会議を開始・終了できたかを採点します。会議の開始と終了時間から算出します。</td>
                  <td className="score">
                    <div><input type="text"
                                value={this.state.time_score_assignment_input}
                                maxLength={3}
                                onChange={ e => { this.onChangeNumber(e, 'time_score_assignment') } }
                                className="text" /><label>点</label></div>
                  </td>
                </tr>
                <tr className="score-row">
                  <td className="title">議題の遂行</td>
                  <td className="desc">設定した時間どおりに議題を開始・終了できたかを採点します。議題の開始から終了までの時間から算出します。</td>
                  <td className="score">
                    <div><input type="text"
                                value={this.state.progress_score_assignment_input}
                                maxLength={3}
                                onChange={ e => { this.onChangeNumber(e, 'progress_score_assignment') } }
                                className="text" /><label>点</label></div>
                  </td>
                </tr>
                <tr className="score-row">
                  <td className="title">決定事項の共有</td>
                  <td className="desc">各議題の決定事項の設定有無から算出し、採点します。</td>
                  <td className="score">
                    <div><input type="text"
                                value={this.state.conclusion_score_assignment_input}
                                maxLength={3}
                                onChange={ e => { this.onChangeNumber(e, 'conclusion_score_assignment') } }
                                className="text" /><label>点</label></div>
                  </td>
                </tr>
                <tr className="score-row">
                  <td className="title">次回会議の設定</td>
                  <td className="desc">次回会議の設定の有無から算出し、採点します。</td>
                  <td className="score">
                    <div><input type="text"
                                value={this.state.next_meeting_score_assignment_input}
                                maxLength={3}
                                onChange={ e => { this.onChangeNumber(e, 'next_meeting_score_assignment') } }
                                className="text" /><label>点</label></div>
                  </td>
                </tr>
                <tr className="score-row">
                  <td className="title">発言者の割合</td>
                  <td className="desc">会議出席者のうち発言した人の割合を採点します。<br/>発言者/参加者で算出します。</td>
                  <td className="score">
                    <div><input type="text"
                                value={this.state.speaker_score_assignment_input}
                                maxLength={3}
                                onChange={ e => { this.onChangeNumber(e, 'speaker_score_assignment') } }
                                className="text" /><label>点</label></div>
                  </td>
                </tr>
                <tr className="total-row">
                  <td className="title" />
                  <td className="desc" />
                  <td className="total-score">
                    <div><label>合計：{this.getSumValue()}点</label></div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="errors">
              { this.errors() }
            </div>
          </div>
        </form>
      </div>
    );
  }

  onChangeNumber(e, item) {
    const inputValue = e.target.value;
    let newNumberValue = -1;

    if(this.isUnsignedIntegerScore(inputValue)) {
      newNumberValue = Number(zenkakuAlphanumeric2hankaku(inputValue));
    }

    switch (item) {
      case 'document_score_assignment':
        this.setState({
          document_score_assignment_input: inputValue,
          document_score_assignment: newNumberValue
        });
        break;
      case 'time_score_assignment':
        this.setState({
          time_score_assignment_input: inputValue,
          time_score_assignment: newNumberValue
        });
        break;
      case 'progress_score_assignment':
        this.setState({
          progress_score_assignment_input: inputValue,
          progress_score_assignment: newNumberValue
        });
        break;
      case 'conclusion_score_assignment':
        this.setState({
          conclusion_score_assignment_input: inputValue,
          conclusion_score_assignment: newNumberValue
        });
        break;
      case 'next_meeting_score_assignment':
        this.setState({
          next_meeting_score_assignment_input: inputValue,
          next_meeting_score_assignment: newNumberValue
        });
        break;
      case 'speaker_score_assignment':
        this.setState({
          speaker_score_assignment_input: inputValue,
          speaker_score_assignment: newNumberValue,
        });
        break;
      default:
        break;
    }
  }

  isUnsignedIntegerScore(value) {
    value = zenkakuAlphanumeric2hankaku(value);
    return isUnsignedIntegerString(value);
  }

  isAllUnsignedIntegerScore() {
    return this.isUnsignedIntegerScore(this.state.document_score_assignment_input) &&
        this.isUnsignedIntegerScore(this.state.time_score_assignment_input) &&
        this.isUnsignedIntegerScore(this.state.progress_score_assignment_input) &&
        this.isUnsignedIntegerScore(this.state.conclusion_score_assignment_input) &&
        this.isUnsignedIntegerScore(this.state.next_meeting_score_assignment_input) &&
        this.isUnsignedIntegerScore(this.state.speaker_score_assignment_input);
  }

  getSumValue() {
    if(this.isAllUnsignedIntegerScore() === false) {
      return '---';
    }

    return this.state.document_score_assignment +
      this.state.time_score_assignment +
      this.state.progress_score_assignment +
      this.state.conclusion_score_assignment +
      this.state.next_meeting_score_assignment +
      this.state.speaker_score_assignment;
  }

  validateScore(value, name, errors) {
    if(value === '') {
      errors.push(name + 'が入力されていません。');
      return;
    }
    value = zenkakuAlphanumeric2hankaku(value);
    if(isUnsignedIntegerString(value) === false) {
      errors.push(name + 'が数値以外の内容です。');
    }
  }

  /**
   * 外部からsubmit時に呼ばれる
   * @returns {boolean}
   */
  validate() {
    const errors = [];
    this.validateScore(this.state.document_score_assignment_input, '資料の事前登録', errors);
    this.validateScore(this.state.time_score_assignment_input, '開始と終了', errors);
    this.validateScore(this.state.progress_score_assignment_input, '議題の遂行', errors);
    this.validateScore(this.state.conclusion_score_assignment_input, '決定事項の共有', errors);
    this.validateScore(this.state.next_meeting_score_assignment_input, '次回会議の設定', errors);
    this.validateScore(this.state.speaker_score_assignment_input, '発言者の割合', errors);

    this.setState({ errors: errors });

    return errors.length <= 0;
  }

  errors() {
    if (this.state.errors.length > 0) {
      const rows = [];
      this.state.errors.forEach((message,i) => {
        rows.push(<div key={ i } className="error">{ message }</div>);
      });
      return rows;
    }
  }
}

MeetingScore.PropType = {
  document_score_assignment: PropTypes.number,
  time_score_assignment: PropTypes.number,
  progress_score_assignment: PropTypes.number,
  conclusion_score_assignment: PropTypes.number,
  next_meeting_score_assignment: PropTypes.number,
  speaker_score_assignment: PropTypes.number,
};

export default MeetingScore;
