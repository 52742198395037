import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './MeetingStatistics.css';
import Service from '../services/MeetingService';
import MeetingStatisticsScore from './MeetingStatisticsScore';
import MeetingStatisticsChart from './MeetingStatisticsChart';
import MeetingStatisticsRadar from './MeetingStatisticsRadar';
import MeetingIndividualScore from "./MeetingIndividualScore";
import Auth from "../components/Auth";
import {getSearchParamsForReactRouter} from "./utilities";
import Const from "../components/Const";

export default class MeetingStatistics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      meeting_statistics: null,
      canExtract: false,
    };

    this.meeting_id = getSearchParamsForReactRouter().get("id");

    this.color_list = [];

    this.color_list_base = [
      // カラー参照元 https://www.colordic.org/p/
      // 左から1,7,2,8,3,9,4,10,5,11,6,12の順に並び替え
      '#ffa3a3',
      '#a3ffff',
      '#ffa3d1',
      '#a3ffd1',
      '#ffa3ff',
      '#a3ffa3',
      '#d1a3ff',
      '#d1ffa3',
      '#a3a3ff',
      '#ffffa3',
      '#a3d1ff',
      '#ffd1a3',

      '#ffdbdb',
      '#dbffff',
      '#eddbff',
      '#edffdb',
      '#ffdbed',
      '#dbffed',
      '#dbdbff',
      '#ffffdb',
      '#ffdbff',
      '#dbffdb',
      '#dbedff',
    ];
 }

  componentDidMount() {
    if (typeof this.meeting_id !== 'undefined') {
      this.props.handlers.showProgress();
      this.getMeetingStatistics();
    }
  }

  getMeetingStatistics() {
    const service = new Service();
    service.meetingStatistics(this.meeting_id).subscribe(
      payload => {
        this.setState({
          meeting_statistics: payload,
        });
        this.props.handlers.hideProgress();
      },
      error => {
        console.log(error);
        this.props.handlers.hideProgress();
        this.props.handlers.error(error);
      }
    );
    service.customer()
        .subscribe(
            customer => {
              this.setState({
                canExtract: (customer.can_extract !== 0)
              });
            },
            error => {
              console.log(error);
              this.props.handlers.error(error);
            }
        );
  }

  onClickMeetingIndividualScore(event) {
    event.preventDefault();
    this.props.handlers.showPopup(
      <MeetingIndividualScore
        id={ this.meeting_id }
        close={ this.closeMeetingIndividualScore.bind(this) }
        handlers={ this.props.handlers }
      />);
  }

  closeMeetingIndividualScore() {
    this.props.handlers.hidePopup();
    this.getMeetingStatistics();
  }

  getTitle() {
    if (this.state.meeting_statistics == null) {
      return (
          <div/>
      );
    }

    var meetingTitle = this.state.meeting_statistics.meeting.title;
    const startAt = this.state.meeting_statistics.meeting.start_at;
    const endAt = this.state.meeting_statistics.meeting.end_at;
    if (startAt !== null) {
      meetingTitle += " " + startAt.substr(0, 16).replace(/-/g, "/") + " 〜 ";
      if (endAt !== null) {
        meetingTitle += " " + endAt.substr(11, 5);
      }
    }
    meetingTitle += " " + this.state.meeting_statistics.meeting.creator.user_name;
    return (
      <div className="title">{meetingTitle}</div>
    );
  }

  renderExtractButton() {
    // if(!this.state.canExtract || !this.state.meeting_statistics) {
    //   return null;
    // }
    // return(
    //     <Link to={"/meeting-extraction?id=" + this.meeting_id}>
    //       <button className="button-1">トピックワード分析</button>
    //     </Link>
    // );
    return null;
  }

  renderMeetingIndividualScoreButton() {
    if(!this.state.canExtract) {
      return null;
    }
    return(
        <button onClick={ this.onClickMeetingIndividualScore.bind(this) } className="button-2">会議配点を変更する</button>
    );
  }

  renderHeader() {
    return(
        <div className="header">
          {this.getTitle()}
          <div className="left-buttons">
            { this.renderExtractButton() }
          </div>
          <div className="right-buttons">
            { this.renderMeetingIndividualScoreButton() }
          </div>
        </div>
    );
  }

  renderMeetingStatistics() {
    if (this.state.meeting_statistics == null) {
      return (
          <div/>
      );
    }

    return(
        <div className="meeting-statistics-area">
          <label className="title">総合評価</label>
          <MeetingStatisticsScore
              current_page={0}
              last_page={0}
              meetings={[this.state.meeting_statistics.meeting]}
              getCurrentPage={this.getMeetingStatistics.bind(this)}
              showMeetingInfo={false}
          />
        </div>
    );
  }

  renderMember() {
    if (this.state.meeting_statistics == null) {
      return (
          <div/>
      );
    }

    return(
      <div className="member-area">
        {this.renderMemberList()}
        {this.renderMemberPercentage()}
      </div>

    );
  }

  renderMemberList() {
    let labels = this.state.meeting_statistics.table_labels;
    return(
      <div className="member-list">
        <label className="title">メンバー分析</label>
        <div className="member-list-detail">
          <table>
            <thead>
              <tr>
                <th className="name">名前</th>
                <th className="comment-total-time">{ labels[0] }</th>
                <th className="mark-count">{ labels[1] }</th>
                <th className="positive-count">{ labels[2] }</th>
                <th className="negative-count">{ labels[3] }</th>
                <th className="free-setting-count">{ labels[4] }</th>
              </tr>
            </thead>
            <tbody>
              {this.renderMemberListBody()}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  renderMemberListBody() {
    if (this.state.meeting_statistics == null) {
      return null;
    }

    const speakers = [];
    this.state.meeting_statistics.data.forEach((speaker, index) => {
      const colorIndex = index % this.color_list_base.length;
      const color = this.color_list_base[colorIndex];
      this.color_list.push(color);
      let user_name = speaker.user_name;
      if ((speaker.authorization & Const.PERMISSION_CODE_GUEST) !== 0) {
        user_name = speaker.user_name + Const.GUEST_NAME_SUFFIX;
      }

      speakers.push(
            <tr key={index} className="speaker">
              <td className="name"><span className="circle" style={{backgroundColor: color}}/>{user_name}</td>
              <td className="comment-total-time">{speaker.comment_total_time}分</td>
              <td className="mark-count">{speaker.mark_count}回</td>
              <td className="positive-count">{speaker.positive_count}回</td>
              <td className="negative-count">{speaker.negative_count}回</td>
              <td className="free-setting-count">{speaker.free_setting_count}回</td>
            </tr>
      );
    });
    return speakers;
  }

  renderMemberPercentage() {
    return(
      <div className="member-percentage">
        <label className="title">発言時間の割合</label>
        <div className="member-percentage-detail">
          <MeetingStatisticsChart data={this.state.meeting_statistics.data} colorList={this.color_list}/>
        </div>
      </div>
    );
  }

  renderMemberRadar() {
    if (this.state.meeting_statistics == null) {
      return (
          <div/>
      );
    }

    return(
      <div className="member-radar-area">
        <div className="member-radar">
          <label className="title">メンバー分析（チャート）</label>
          <MeetingStatisticsRadar
              data={this.state.meeting_statistics.data}
              labels={ this.state.meeting_statistics.radar_labels }
          />
        </div>
      </div>

    );
  }

  render() {
    if(!Auth.isAuthenticated()) {
      return null;
    }

    return (
        <div className="meeting-statistics">
          {this.renderHeader()}
          <div className="scroll">
            {this.renderMeetingStatistics()}
            {this.renderMember()}
            {this.renderMemberRadar()}
          </div>
        </div>
    );
  }
}

MeetingStatistics.PropType = {
  handlers: PropTypes.object
};
