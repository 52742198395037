import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import { apiUrl } from './utilities';

import './AudioPlayer.css';

class AudioPlayer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      seeking: false,
      paused: true,
      current: 0,
      duration: 0
    };
    this.utterance = null;
    this.pause = this.pause.bind(this);
    this.handleProgressDownload = this.handleProgressDownload.bind(this);
    this.handleTimeUpdate = this.handleTimeUpdate.bind(this);
    this.handleMediaEnd = this.handleMediaEnd.bind(this);
  }

  componentDidMount() {
    var audio = this.refs.audio;
    audio.addEventListener('progress', this.handleProgressDownload);
    audio.addEventListener('timeupdate', this.handleTimeUpdate);
    audio.addEventListener('ended', this.handleMediaEnd);
  }

  componentWillUnmount() {
    var audio = this.refs.audio;
    audio.removeEventListener('progress', this.handleProgress);
    audio.removeEventListener('timeupdate', this.handleTimeUpdate);
    audio.removeEventListener('ended', this.handleMediaEnd);
  }

  handleProgressDownload() {
  }

  handleTimeUpdate() {
    var audio = this.refs.audio;
    var state = {
      current: audio.currentTime,
      duration: audio.duration
    };

    if (audio.duration !== Infinity) {
      state['seeking'] = true;
    }
    this.setState(state);
  }

  handleMediaEnd() {
    if(this.props.simpleIf) {
      this.setState({ paused: true })
      this.stop();
      return;
    }
    if (this.refs.continuous.checked) {
      this.onPlayNextUtterance();
    } else {
      this.stop();
    }
  }

  render() {
    return (
      <div className={ ClassNames({ "audio-player": true, hidden: !this.props.hidden }) }>
        <audio ref="audio" preload="none">
          <source src="" type="audio/mp3" />
        </audio>
        { this.simpleButtons(false) }
        <div className="slider">
          <input
            type="range"
            disabled={ !this.state.seeking }
            min="0"
            max={ this.state.duration }
            value={ this.state.current }
            onChange={ this.onChangeCurrent.bind(this) }
            />
        </div>
        { this.Buttons() }
        { this.CloseButton() }
      </div>
    );
  }

  PrevUtteranceButton() {
    if(this.props.simpleIf) {
      return null;
    }
    return <div onClick={ this.onPlayPrevUtterance.bind(this) }><div className="icon-fast-rewind"/></div>
  }

  NextUtteranceButton() {
    if(this.props.simpleIf) {
      return null;
    }
    return <div onClick={ this.onPlayNextUtterance.bind(this) }><div className="icon-fast-forward"/></div>
  }

  RepeatCheckbox() {
    if(this.props.simpleIf) {
      return null;
    }
    return <div className="continuous"><label><input ref="continuous" type="checkbox"/>&nbsp;連続再生</label></div>
  }

  simpleButtons() {
    if(this.props.simpleIf === false) {
      return null;
    }
    return <div className="simple-buttons">{ this.playAndPauseButton(true) }</div>
  }

  CloseButton() {
    if (this.props.simpleIf === true) {
      return null;
    }
    return <div className="close-button-area" onClick={ this.onClickClose.bind(this) }>
        <div className="icon-times-solid button-icon"/>
        </div>
  }

  Buttons() {
    if(this.props.simpleIf === true) {
      return null;
    }
    return <div className="buttons">
      { this.PrevUtteranceButton() }
      { this.playAndPauseButton(false) }
      { this.NextUtteranceButton() }
      { this.RepeatCheckbox() }
    </div>
  }

  playAndPauseButton(forSimpleIf) {
    if(this.props.simpleIf !== forSimpleIf) {
      return null;
    }
    if (this.state.paused) {
      return <div onClick={ this.pause }><div className="icon-play button-icon"/></div>; // play
    } else {
      return <div onClick={ this.pause }><div className="icon-pause button-icon"/></div>; // pause
    }
  }

  onChangeCurrent(e) {
    if (this.state.current !== e.target.value) {
      this.setState({ current: e.target.value });
      this.refs.audio.currentTime = e.target.value;
    }
  }

  onPlayNextUtterance(event) {
    var comment = this.props.existCommentHasUtterance(this.utterance.comment_id, 'next');
    if (comment) {
      this.play(comment.utterance, true);
    } else {
      var task = this.props.loadUtteranceTask(this.utterance.comment_id, 'next');
      task.subscribe(
        payload => {
          this.play(payload.utterance, true);
        },
        error => {
          this.stop();
        }
      );
    }
  }

  onPlayPrevUtterance(event) {
    var comment = this.props.existCommentHasUtterance(this.utterance.comment_id, 'prev');
    if (comment) {
      this.play(comment.utterance, true);
    } else {
      var task = this.props.loadUtteranceTask(this.utterance.comment_id, 'prev');
      task.subscribe(
        payload => {
          this.play(payload.utterance, true);
        },
        error => {
          this.stop();
        }
      );
    }
  }

  setUtterance(utterance) {
    if (utterance && utterance.url) {
      this.refs.audio.src = apiUrl(utterance.url);
      this.refs.audio.load();
      this.setState({ current: 0, paused: true });
      this.utterance = utterance;
    }
  }

  play(utterance, scrollTo = false) {
    if (utterance && utterance.url) {
      this.refs.audio.src = apiUrl(utterance.url);
      this.refs.audio.load();
      this.refs.audio.play();
      this.setState({ current: 0, paused: false });
      this.props.onStartFinishUtterance(this.utterance ? this.utterance.comment_id : null, utterance.comment_id, scrollTo);
      this.utterance = utterance;
    }
  }

  onClickClose() {
    this.stop();
  }

  pause() {
    if (!this.refs.audio.paused) {
      this.refs.audio.pause();
    } else {
      this.refs.audio.play();
    }
    this.setState({ paused: this.refs.audio.paused })
  }

  stop() {
    this.refs.audio.pause();
    //IE11では再生後しかcurrentTimeを変更できないらしいので、必要かどうか判定してから変更する
    if (this.refs.audio.currentTime !== 0) {
      this.refs.audio.currentTime = 0;
    }
    this.utterance = null;
    this.props.onFinish();
  }
}

AudioPlayer.PropType = {
  hidden: PropTypes.bool,
  simpleIf: PropTypes.bool,
  loadUtteranceTask: PropTypes.func,
  onStartFinishUtterance: PropTypes.func,
  onFinish: PropTypes.func
};

export default AudioPlayer;
