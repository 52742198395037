export function originHost() {
  if (process.env.NODE_ENV !== 'production') {
    return 'http://localhost:8000';
  }
  return '';
}

export function absolutedUrl(url) {
  const token = encodeURIComponent(sessionStorage.getItem('session'));
  const origin = originHost();
  return `${origin}${url}?api_token=${token}`;
}

export function apiUrl(url) {
  const token = encodeURIComponent(sessionStorage.getItem('session'));
  const origin = originHost();
  return `${origin}/api/v1/${url}?api_token=${token}`;
}

/**
 * カスタマー設定の取得
 *
 * @returns {string | null}
 */
export function customerSettings() {
    return JSON.parse(sessionStorage.getItem('customer_settings'));
}

export function personalSettings() {
    return JSON.parse(sessionStorage.getItem('personal_settings'));
}

export function basename(path) {
  return path.replace( /\\/g, '/' ).replace( /.*\//, '' );
}

export function isIEBrowser() {
  return !!window.navigator.userAgent.match(/msie|trident|edge/i); // !! means that convert to bool
}

export function isChromeIOSBrowser() {
  // CriOSはiOS版Chrome、かつ、デフォルトのモバイルサイト表示時のUA文字列。
  // iOS版Chromeでも、PCサイト表示時にはCriOSは付与されない。
  // デバッグ時にはtrueにすることで、デスクトップ版ChromeでもiPad画面の再現が多少できる(相違点は多い)。
  return !!window.navigator.userAgent.match(/crios/i); // !! means that convert to bool
}

export function getFormattedTime(seconds) {
    let result = "";
    //時間計算
    let hour = Math.floor((seconds / 60) / 60);
    let min = Math.floor((seconds / 60) % 60);
    let sec = Math.floor(seconds % 60);
    //フォーマット

    if (hour > 0) {
        result += hour + "時間";

    }
    if (min > 0) {
        result += min + "分";
    }
    if (sec > 0) {
        result += sec + "秒";
    }
    if(hour === 0 && min === 0 && sec === 0) {
        result = '0秒';
    }
    return result;
}

export function getSearchParamsForReactRouter() {
  // 従来処理では、URLで#(hash)と?を同時に扱うケースがある。
  // 例1: 会議ID
  //   '/' + routePath2href("/meeting?id=" + this.state.id);
  //     → /#/meeting?id=22
  // 例2: トークン(php側。現状は2箇所)
  //   url('/#/password/setting', [], true) . '?token=' . $this->token)
  //     → /#/password/setting?token=aaaaaaaa
  //
  // URLで#の後に?を書くと、?はハッシュの一部として扱われるため、
  // そのままでは、searchParamsで処理できない。
  // そのため、加工してから処理する。
  let url = window.location.href;
  url = url.replace(/\?ei=[0-9]+/, '');
  url = url.replace('/#/', '/');
  return new URL(url).searchParams;
}

export function compareRoutePathForReactRouter(path) {
  // Routerの違いによって、ルートパスとURLの判定方法が異なるため、この関数で違いを吸収。
  // BrowserRouterの場合は、location.pathnameを使用する。
  let hash = window.location.hash;
  return hash === '#' + path;
}

export function routePath2href(path) {
  // Routerの違いによって、ルートパスとURLの判定方法が異なるため、この関数で違いを吸収。
  return '#' + path;
}

/**
 * 符号なし整数文字列かどうか
 * @param val
 * @returns {boolean}
 */
export function isUnsignedIntegerString(val){
  let regexp = new RegExp(/^[0-9]+$/);
  return regexp.test(val);
}

/**
 * 全角英数字を半角に変換
 * @param str
 * @returns {*}
 */
export function zenkakuAlphanumeric2hankaku(str) {
  return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  });
}