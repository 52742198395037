import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Service from '../services/MeetingService';
import { AlertTwoButton } from '../components/Alert';
import CustomDatePicker from '../components/CustomDatePicker';
import MyMemoPopup from './MyMemoPopup';
import './MyMemos.css';

import Moment from 'moment';
import PaginationBar from "../components/PaginationBar";
import Auth from "../components/Auth";

class MyMemo extends Component {

  render() {
    return (
      <div className="my_memo" onClick={ this.onClickMemo.bind(this) }>
        { this.setMeetingStatusAndMeetingTitle() }
        <div className="memo">
          { this.props.detail.memo }
        </div>
          <div className="my_memo_Bottom">
            { this.getEditButton() }
            { this.getDeleteButton() }
            <label className="date">{ this.props.detail.timestamp }</label>
          </div>
      </div>
    );
  }

  onClickMemo(event) {
      event.preventDefault();

      // メモから会議室は開かない仕様。将来的に開くかもしれないのでコメントアウト。
      // 会議室削除も考慮が必要。
      // if(this.props.detail.meeting_id !== 0) {
      //   window.open(`/#/meeting?id=${this.props.detail.meeting_id}`);
      // }
  }

  setMeetingStatusAndMeetingTitle()
  {
    if(( this.props.detail.is_public === 1 ) || this.props.detail.title !== null) {
      return (
        <div className="my_memo_top">
          { this.getPubliced(this.props.detail.is_public) }
          <label className="title">{ this.props.detail.title }</label>
        </div>
      );
    }
  }

  getPubliced(publiced) {
    if( publiced === 1 ) {
      return (
        <div className="publiced">公開</div>
      );
    }

    return null;
  }

  getEditButton() {

    return (
      <button className="button-icon-transparent icon-pencil-medium"
        onClick={
          e => {
            this.props.popupUpdateMyMemo(e, this.props.detail)
          }
        }
      />
    );
  }

  getDeleteButton() {
    return (
      <button className="button-icon-transparent icon-trash-medium"
        onClick={
          e => {
            this.props.popupDeleteMyMemo(e, this.props.detail)
          }
        }
      />
    );
  }

}

MyMemo.PropType = {
    id: PropTypes.number,
    meeting_id: PropTypes.number,
    user_id: PropTypes.number,
    name: PropTypes.string,
    memo: PropTypes.string,
    is_public: PropTypes.string,
    timestamp: PropTypes.datetime,
    title: PropTypes.string,
    popupUpdateWord:PropTypes.func,
    popupDeleteWord:PropTypes.func,
    canEdit: PropTypes.number
};

class MyMemos extends Component {

    constructor(props) {
        super(props);

        this.state = {
            meeting_publiced: "0",
            meeting_tying: "0",
            start_at: Moment().subtract(1, 'month'),
            end_at: Moment(),
            keywords: null,
            total: 0,
            per_page: 20,
            current: 0,
            last: 0,
            date_updated: false,
            details: null
        };
    }

    componentDidUpdate() {
        if(this.state.date_updated) {
            this.setState({
                date_updated: false
            });
            this.getCurrentPage();
        }
    }

    onChangeStartDate(date) {
        this.setState({
            date_updated: true,
            start_at: date
        });
    }

    onChangeEndDate(date) {
        this.setState({
            date_updated: true,
            end_at: date
        });
    }

    onChangeMeetingTying(event, SelectValue) {
        event.preventDefault();
        this.setState({
            meeting_tying: SelectValue,
            radio: SelectValue,
            date_updated: true
        });
    }

    onChangeMeetingPubliced(event, SelectValue) {
        event.preventDefault();
        this.setState({
            meeting_publiced: SelectValue,
            radio: SelectValue,
            date_updated: true
        });
    }

    componentDidMount() {
        this.getCurrentPage();
    }

    getCurrentPage(page = null) {
        this.props.handlers.showProgress();

        const params = {};

        if (this.state.meeting_publiced && this.state.meeting_publiced !== "") {
            params['is_public'] = this.state.meeting_publiced;
        }
        if (this.state.meeting_tying && this.state.meeting_tying !== "") {
            params['meeting_tying'] = this.state.meeting_tying;
        }
        if (this.state.keywords && this.state.keywords !== "") {
            params['keywords'] = this.state.keywords;
        }
        if (this.state.start_at && this.state.start_at !== "") {
            params['start_at'] = this.state.start_at.format('YYYY/MM/DD');
        }
        if (this.state.end_at && this.state.end_at !== "") {
            params['end_at'] = this.state.end_at.format('YYYY/MM/DD');
        }

        let service = new Service();
        service.MyMemos(page || this.state.current, params).subscribe(
            payload => {
                this.props.handlers.hideProgress();
                this.setState({
                    total: payload.total,
                    per_page: payload.per_page,
                    current: payload.current_page,
                    last: payload.last_page,
                    details: payload.data
                });
            },
            error => {
                this.props.handlers.hideProgress();
                this.props.handlers.error(error);
            }
        );
    }

    my_memos() {
        let my_memos = [];
        this.state.details.forEach(my_memo => {
            my_memos.push(
                <MyMemo
                    key={ my_memo.id }
                    detail={ my_memo }
                    popupUpdateMyMemo={ this.popupUpdateMyMemo.bind(this) }
                    popupDeleteMyMemo={ this.popupDeleteMyMemo.bind(this) }
                />);
        });
        return my_memos;
    }

    details() {
        if (this.state.details) {
            if (this.state.details.length > 0) {
                return (
                    <div className="memos">  { this.my_memos() } </div>
                );
            } else {
                return (
                    <div>
                        <p>一致するメモは見つかりませんでした。</p>
                    </div>
                );
            }
        }
        return null;
    }

    deleteMyMemo(id) {
        return () => {
            let service = new Service();

            service.deleteMyMemo(id).subscribe(
                payload => {
                    this.props.handlers.hidePopup();
                    this.getCurrentPage();
                },
                error => {
                    this.props.handlers.error(error);
                }
            );
        };
    }

    render() {
      if(!Auth.isAuthenticated()) {
        return null;
      }

      return (
            <div className="mymemos">
                <div className="header">
                    <div className="title">Ｍyメモ</div>
                    <button className="create button-1" onClick={ this.popupCreateMyMemo.bind(this) } >新規メモ登録</button>
                    <div className="Note">会議に紐づくメモは各会議画面から登録してください</div>
                    <div className="search">
                        <input type="search" ref="keywords"
                               onChange={ this.onChangeKeywords.bind(this) } placeholder="メモの検索" />
                        <button onClick={ this.onSearch.bind(this) } className="button-select"><div className="icon-search"></div></button>
                    </div>
                </div>
                <div className="search-items">
                    <div className="search_date">
                        <div className="title">登録日</div>
                        <div className="date-pickers" >
                          <CustomDatePicker
                              id="startDate"
                              selected={ this.state.start_at }
                              onChange={ this.onChangeStartDate.bind(this) }
                              disabled={ this.props.disabled }
                          />
                          <div className="hyphen">
                            <label className="select-label">〜</label>
                          </div>
                          <CustomDatePicker
                              id="endDate"
                              selected={ this.state.end_at }
                              onChange={ this.onChangeEndDate.bind(this) }
                              disabled={ this.props.disabled }
                          />
                        </div>
                    </div>
                    <div className="search_meeting">
                        <label className="title">会議紐づき</label>
                        <div>
                            <input className="radio_button"
                                   type="radio" name="meeting" value="0"
                                   id="meeting_all"
                                   onChange={(e) => this.onChangeMeetingTying(e, "0")}
                                   checked={ this.state.meeting_tying === "0" }
                            />
                            <label htmlFor="meeting_all" className="label_meeting">すべて</label>
                            <input className="radio_button"
                                   type="radio" name="meeting" value="1"
                                   id="meeting_tying"
                                   onChange={(e) => this.onChangeMeetingTying(e,"1") }
                                   checked={ this.state.meeting_tying === "1" }
                            />
                            <label htmlFor="meeting_tying" className="label_meeting">会議に紐づく</label>
                            <input className="radio_button"
                                   type="radio" name="meeting" value="2"
                                   id="meeting_not_tying"
                                   onChange={(e) => this.onChangeMeetingTying(e,"2") }
                                   checked={ this.state.meeting_tying === "2" }
                            />
                            <label htmlFor="meeting_not_tying" className="label_meeting">会議に紐づかない</label>
                        </div>
                    </div>
                    <div className="search_publiced">
                        <label className="title">公開/非公開</label>
                        <div>
                            <input className="radio_button"
                                   type="radio" name="publiced" value="0"
                                   id="publiced_all"
                                   onChange={(e) => this.onChangeMeetingPubliced(e, "0")}
                                   checked={ this.state.meeting_publiced === "0" }
                            />
                            <label htmlFor="publiced_all" className="label_publiced">すべて</label>
                            <input className="radio_button"
                                   type="radio" name="publiced" value="1"
                                   id="publiced_public"
                                   onChange={(e) => this.onChangeMeetingPubliced(e, "1")}
                                   checked={ this.state.meeting_publiced === "1" }
                            />
                            <label htmlFor="publiced_public" className="label_publiced">公開</label>
                            <input className="radio_button"
                                   type="radio" name="publiced" value="2"
                                   id="publiced_private"
                                   onChange={(e) => this.onChangeMeetingPubliced(e, "2")}
                                   checked={ this.state.meeting_publiced === "2" }
                            />
                            <label htmlFor="publiced_private" className="label_publiced">非公開</label>
                        </div>
                    </div>
                </div>
                { this.details() }
                <PaginationBar
                    total={ this.state.total }
                    per_page={ this.state.per_page }
                    current={ this.state.current }
                    last={ this.state.last }
                    onClick={ this.getCurrentPage.bind(this) }
                />
            </div>
        );
    }

    popupCreateMyMemo(event) {
        event.preventDefault();
        this.props.handlers.showPopup(<MyMemoPopup meeting_id={ 0 } title={ '新規メモ登録' } close={ this.closePopup.bind(this) } error={ this.props.handlers.error } />);
    }

    popupUpdateMyMemo(event, id) {
        event.preventDefault();
        event.stopPropagation();
        this.props.handlers.showPopup(<MyMemoPopup meeting_id={ id.meeting_id } tag_memo={ id } title={ 'メモ編集' } close={ this.closePopup.bind(this) } error={ this.props.handlers.error } />);
    }

    closePopup() {
        this.props.handlers.hidePopup();
        this.getCurrentPage();
    }

    popupDeleteMyMemo(event, detail) {
        event.preventDefault();
        event.stopPropagation();
        const message = `「${detail.memo}」を削除しますか？`;
        this.props.handlers.showPopup(
            <AlertTwoButton title="メモ削除" message={ message } cancel={ this.props.handlers.hidePopup } okay={ this.deleteMyMemo(detail.id) } okayButtonText="削除する" />
        );
    }

    onChangeKeywords(event) {
        event.preventDefault();
        const keywords = this.refs.keywords.value || null;
        this.setState({ keywords: keywords });
        if(keywords === null) {
            this.getCurrentPage('');
        }
    }

    onSearch(event) {
        event.preventDefault();
        this.getCurrentPage();
    }
}


export default MyMemos;
