import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Observable } from 'rx';
import Service from '../services/MeetingService';
import { AlertOneButton, AlertTwoButton } from '../components/Alert';
import DictionaryPopup from './DictionaryPopup';
import PaginationBar from "../components/PaginationBar";
import Words from "../containers/Words";
import Auth from '../components/Auth';

import './Dictionaries.css';
import ClassNames from "classnames";

class Dictionary extends Component {

  render() {
    return (
      <tr className={ ClassNames({ 'active': this.props.isActive })} onClick={ e => { this.props.onClick(e, this.props.id) } } >
        <td className="name">{ this.props.name }</td>
        <td className="num-of-words"><div className="num-label">{ this.props.numofwords }</div></td>
        <td className="functions">
          <div>
            { this.getEditButton() }
            { this.getDeleteButton() }
          </div>
        </td>
      </tr>
      );
  }

  getEditButton() {
    if (!this.props.prepared) {
      return (
        <button className="icon-pencil btnicn" disabled={ this.props.prepared } onClick={ e => { this.props.popupUpdateDictionary(e, this.props.id) } }/>
      );
    }
  }

  getDeleteButton() {
    if (!this.props.prepared) {
      return (
        <button className="icon-trash btnicn" disabled={ this.props.prepared } onClick={ e => { this.props.popupDeleteDictionary(e, this.props) } } />
      );
    }
  }

}

Dictionary.PropType = {
  id: PropTypes.number,
  name: PropTypes.string,
  numofwords: PropTypes.number,
  prepared: PropTypes.boolean,
  onClick:PropTypes.func,
  popupUpdateDictionary:PropTypes.func,
  popupDeleteDictionary:PropTypes.func
};

class Dictionaries extends Component {

  constructor(props) {
    super(props);

    this.state = {
      total: 0,
      per_page: 20,
      current: 0,
      last: 0,
      details: null,
      id: 0,
    };
  }

  componentDidMount() {
    this.getCurrentPage();
  }

  getCurrentPage(page = null) {
    let service = new Service();
    service.dictionaries(page === null ? this.state.current : page).subscribe(
      payload => {
        this.setState({
          total: payload.total,
          per_page: payload.per_page,
          current: payload.current_page,
          last: payload.last_page,
          details: payload.data
        });
      },
      error => {
        this.props.handlers.error(error);
      }
    );
  }

  dictionaries() {
    var dictionaries = [];
    this.state.details.forEach(dictionary => {
      const numofwords = dictionary.words_count ? dictionary.words_count.aggregate : 0;
      dictionaries.push(
        <Dictionary
          key={ dictionary.id }
          id={ dictionary.id }
          name={ dictionary.name }
          prepared={ dictionary.prepared }
          numofwords={ numofwords }
          isActive={ this.state.id === dictionary.id }
          onClick={ this.transitWords.bind(this) }
          popupUpdateDictionary={ this.popupUpdateDictionary.bind(this) }
          popupDeleteDictionary={ this.popupDeleteDictionary.bind(this) }
        />);
    });
    return dictionaries;
  }

  renderDictionaries() {
    if (this.state.details) {
     if (this.state.details.length > 0) {
        return (
            <table>
              <thead>
              </thead>
              <tbody>
              { this.dictionaries() }
              </tbody>
            </table>
        );
      } else {
        return (
            <p>単語帳は登録されていません。</p>
          );
      }
    }
    return null;
  }

  renderWords(){
    if (this.state.id) {
      let current_dictionary_name = this.getDictionaryName(this.state.id);
      return <Words
          id={ this.state.id }
          dictionaryName={ current_dictionary_name }
          onChange={ this.getCurrentPage.bind(this) }
          handlers={ this.props.handlers }
      />;
    }
    return null;
  }

  render() {
    if(!Auth.isAuthenticated()) {
      return null;
    }

    return (
      <div className="dictionaries">
        <div className="dictionaries-left">
          <div className="dictionaries-header">
            <h2 className="dictionaries-title">単語帳</h2>
            <button className="icon-plus button-rectangle-icon" onClick={ this.popupCreateDictionary.bind(this) } />
          </div>
          <div className="dictionaries-detail">
            { this.renderDictionaries() }
          </div>
          <PaginationBar
              total={ this.state.total }
              per_page={ this.state.per_page }
              current={ this.state.current }
              last={ this.state.last }
              onClick={ this.getCurrentPage.bind(this) }
          />
        </div>
        <div className="dictionaries-right">
          { this.renderWords() }
        </div>
      </div>
    );
  }

  popupCreateDictionary(event) {
    event.preventDefault();
    this.props.handlers.showPopup(<DictionaryPopup title={ '単語帳を作成' } close={ this.closePopup.bind(this) } error={ this.props.handlers.error } />);
  }

  popupUpdateDictionary(event, id) {
    event.preventDefault();
    event.stopPropagation();
    this.props.handlers.showPopup(<DictionaryPopup id={ id } title={ '単語帳の名前変更' } close={ this.closePopup.bind(this) } error={ this.props.handlers.error } />);
  }

  closePopup() {
    this.props.handlers.hidePopup();
    this.getCurrentPage();
  }

  popupDeleteDictionary(event, detail) {
    event.preventDefault();
    event.stopPropagation();
    const message = `「${detail.name}」を削除しますか？`;
    this.props.handlers.showPopup(
      <AlertTwoButton title="単語帳の削除" message={ message } cancel={ this.props.handlers.hidePopup } okay={ this.checkAndDeleteDictionary(detail.id) }  okayButtonText="削除する" />
    );
  }

  alertLockedDictionary() {
    this.props.handlers.showPopup(
      <AlertOneButton title="単語帳の削除" message="開催中の会議で利用している単語帳のため、削除できません。" okay={ this.props.handlers.hidePopup } />
    );
  }

  alertDictionaryIsUsed(id) {
    this.props.handlers.showPopup(
      <AlertTwoButton title="単語帳の削除" message="会議で利用している単語帳です。削除しますか？" cancel={ this.props.handlers.hidePopup } okay={ this.deleteDictionary(id) }  okayButtonText="削除する" />
    );
  }

  checkAndDeleteDictionary(id) {
    return () => {
      let published = new Service().isDictionaryUsed(id).share();

      // if the dictionary is used
      published
        .filter(function (val, idx, obs) {
          return val.used;
        })
        .subscribe(
          payload => {
            this.props.handlers.hidePopup();
            this.alertDictionaryIsUsed(id);
          },
          error => {
            this.props.handlers.hidePopup();
            this.props.handlers.error(error);
          }
        );

      // unless the dictionary is used
      published
        .catch(function (e) {
          // prevent to handle a same error.
          return Observable.empty();
        })
        .filter(function (val, idx, obs) {
          return ! val.used;
        })
        .flatMap(function (x, i) {
          return new Service().deleteDictionary(id);
        })
        .subscribe(
          payload => {
            this.props.handlers.hidePopup();
            if(id === this.state.id) {
              this.setState({
                id: 0,
              });
            }
            this.getCurrentPage();
          },
          error => {
            this.props.handlers.hidePopup();
            if (error.response.status === 423) { // locked
              this.alertLockedDictionary();
            } else {
              this.props.handlers.error(error);
            }
          }
        );
    };
  }

  deleteDictionary(id) {
    return () => {
      new Service().deleteDictionary(id).subscribe(
        payload => {
          this.props.handlers.hidePopup();
          this.getCurrentPage();
        },
        error => {
          this.props.handlers.hidePopup();
          if (error.response.status === 423) { // locked
            this.alertLockedDictionary();
          } else {
            this.props.handlers.error(error);
          }
        }
      );
    };
  }

  transitWords(event, id) {
    event.preventDefault();
    this.setState({
      id: id
    });
  }

  getDictionaryName(id) {
    let name = null;
    this.state.details.forEach(dictionary => {
      if(dictionary.id === id) {
        name = dictionary.name;
      }
    });
    return name;
  }
}


export default Dictionaries;
