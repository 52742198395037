import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MDSpinner from "react-md-spinner";

import './Progress.css';

export class Progress extends Component {

  render() {
   if (this.props.visibled) {
      return (
        <div className="progress">
          <MDSpinner size="80" />
        </div>
      );
    } else {
      return null;
    }
  }
}

Progress.PropType = {
  visibled: PropTypes.bool
};

export default Progress;
