import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Service from "../services/MeetingService";
import MyMemoPopup from './MyMemoPopup';
import './MemoTab.css';
import { AlertTwoButton } from '../components/Alert';

import Moment from "moment";
import {Observable} from "rx";
import MeetingTimePicker from "./MeetingTimePicker";
import PaginationBar from "../components/PaginationBar";

class Memo extends Component {

  render() {
    return (
        <div className="memo">
          <div className="parts_memo">
            { this.props.detail.memo }
          </div>
          <div className="parts_bottom">
            { this.getJumpButton() }
            { this.getEditButton() }
            { this.getDeleteButton() }
            <div className="parts_timestamp">
              { (Moment(this.props.detail.timestamp)).format("HH:mm:ss") }
            </div>
            <div className="parts_name">
              { this.props.detail.name }
            </div>
          </div>
        </div>
    )
  };

  getJumpButton() {

    /*
    const LoginUserID = sessionStorage.getItem('id');

    if(LoginUserID === this.props.detail.user_id) {
      return (


          <button className="icon-jump btnicn"
                  onClick={
                    e => {
                      this.props.popupUpdateMemo(e, this.props.detail)
                    }
                  }
          />
      );
    }
    */

    return (<div/>);
  }

  getEditButton() {

    if( (sessionStorage.getItem('id')) === this.props.detail.user_id.toString()) {

      return (
          <button className="button-icon-transparent icon-pencil-medium"
                  onClick={
                    e => {
                      this.props.popupUpdateMemo(e, this.props.detail)
                    }
                  }
          />
      );
    }
    return (<div/>);
  }

  getDeleteButton() {

    if( (sessionStorage.getItem('id')) === this.props.detail.user_id.toString()) {

      return (
          <button className="button-icon-transparent icon-trash-medium"
                  onClick={
                    e => {
                      this.props.popupDeleteMemo(e, this.props.detail)
                    }
                  }
          />
      );
    }
    return (<div/>);
  }
}

Memo.PropType = {
  id: PropTypes.number,
  meeting_id: PropTypes.number,
  user_id: PropTypes.number,
  name: PropTypes.string,
  memo: PropTypes.string,
  publiced: PropTypes.string,
  timestamp: PropTypes.datetime,
  title: PropTypes.string,
  popupUpdateWord:PropTypes.func,
  popupDeleteWord:PropTypes.func,
  canEdit: PropTypes.number
};

class MemoTab extends Component {

  constructor(props) {
    super(props);

    this.state = {
      meeting_id: this.props.meeting_id,
      meeting_date: this.props.meeting_date,
      start_at: null,
      end_at: null,
      keywords: null,
      total: 0,
      per_page: 20,
      current: 0,
      last: 0,
      date_updated: false,
      details: null,
      is_memo_open: true,
      is_self_only: false,
      hasError: false
    };
  }

  componentDidMount() {
    this.getCurrentPage();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  subscribe() {
    if (!this.subscriber) {
      this.subscriber = Observable.interval(5000).subscribe(
          () => {
            if(this.state.hasError) {
              return;
            }
            this.getCurrentPage();
          }
      );
    }
  }

  unsubscribe() {
    if (this.subscriber) {
      this.subscriber.dispose();
    }
  }

  getCurrentPage(page = null){

    const params = {};

    params['meeting_id'] = this.state.meeting_id;

    params['is_self'] = this.state.is_self_only;

    if (this.state.keywords && this.state.keywords !== "") {
      params['keywords'] = this.state.keywords;
    }

    if(this.state.start_at !== null) {
      params['start_at'] = this.state.start_at.format('HH:mm');
    }

    if(this.state.end_at !== null) {
      params['end_at'] = this.state.end_at.format('HH:mm');
    }

    params['per_page'] = this.state.per_page;
    params['order'] = this.props.order;

    let service = new Service();
    service.MeetingMemos(this.state.meeting_id,page || this.state.current, params).subscribe(
        payload => {
          this.subscribe();
          this.setState({
            total: payload.total,
            per_page: payload.per_page,
            current: payload.current_page,
            last: payload.last_page,
            details: payload.data,
            hasError: false
          });
        },
        error => {
          this.props.handlers.error(error);
          this.setState({
            hasError: true
          });
        }
    );
  }

  memos() {
    let memos = [];
    this.state.details.forEach(memo => {
      memos.push(
          <Memo
              key={ memo.id }
              detail={ memo }
              popupUpdateMemo={ this.popupUpdateMemo.bind(this) }
              popupDeleteMemo={ this.popupDeleteMemo.bind(this) }
          />);
    });
    return memos;
  }

  details() {
    if (this.state.details) {
      if (this.state.details.length > 0) {
        return this.memos();
      } else {
            if((this.state.keywords !== null && this.state.keywords !== "") || (this.state.start_at !== null) || (this.state.end_at !== null) || (this.state.is_self_only))
            {
              return (
              <div　className="title_no_memo">
                <label>一致するメモは見つかりませんでした。</label>
              </div>
              );
            }
            else {
              return (
              <div className="title_no_memo">
                <label>メモは登録されていません。</label>
              </div>
              );
            }
      }
    }
    return null;
  }

  render() {
    return (
      <div className="memo-tab">
        <div className="memo-tab-header">
          <div className="search">
            <input type="search" ref="keywords" onChange={ this.onChangeKeywords.bind(this) } placeholder="メモ内容、作成者" />
            <button onClick={ this.onSearch.bind(this) } className="button-select"><div className="icon-search"></div></button>
          </div>

          <div className="search-bottom">
            <div className="memo_filter">
              <label>
                <input type="checkbox" className="btnpt2"
                       checked={ this.state.is_self_only }
                       onChange={ e => { this.onClickCheckbox(e) } }
                />
                <div className="check"></div>
              </label>
              <label>自分のメモのみ</label>
            </div>

            <div className="searchDate">
              <div className="time">
                <MeetingTimePicker className="start_time" time={ this.state.start_at } notUseSecond={true} intervalOfMinutesOption={5} onChangeTime={ this.onChangeStartTime.bind(this) }  />
              </div>
              <span className="from-time">〜</span>
              <div className="time">
                <MeetingTimePicker className="end_time" time={ this.state.end_at } notUseSecond={true} intervalOfMinutesOption={5} onChangeTime={ this.onChangeEndTime.bind(this) }  />
              </div>
            </div>
          </div>
        </div>
        <div className="memo-tab-body">
          { this.details() }
        </div>
        <div className="memo-tab-footer">
          <PaginationBar
              total={ this.state.total }
              per_page={ this.state.per_page }
              current={ this.state.current }
              last={ this.state.last }
              onClick={ this.getCurrentPage.bind(this) }
          />
          <div className="memo-tab-footer-button-area">
            <button className="create button-1" onClick={ this.popupCreateMemo.bind(this) }>メモを追加</button>
          </div>
        </div>
      </div>
    );
  }

  onChangeStartTime(time) {
    var start_at = this.state.start_at;
    start_at = time;
    this.setState({ start_at: start_at });
  }

  onChangeEndTime(time) {
    var end_at = this.state.end_at;
    end_at = time;
    this.setState({ end_at: end_at });
  }

  componentDidUpdate() {
    if(this.state.date_updated) {
      this.setState({
        date_updated: false
      });
      this.getCurrentPage();
    }
  }

  onClickCheckbox(event) {
    event.preventDefault();
    var is_self_only = event.target.checked;
    this.setState({
        date_updated: true,
        is_self_only: is_self_only });
  }

  onChangeKeywords(event) {
    event.preventDefault();
    const keywords = this.refs.keywords.value || null;
    this.setState({ keywords: keywords });

    // 他の検索処理では、keywordsがnullの場合に明示的にリスト更新するケースがある。
    // メモについては、定期定期に再取得しているため手動更新は不要
  }

  onClickMemoSwitch() {
    this.setState({isMemoOpen: !this.state.isMemoOpen});
  }

  onSearch(event) {
    event.preventDefault();
    this.getCurrentPage();
  }

  popupCreateMemo(event) {
    event.preventDefault();
    this.props.handlers.showPopup(<MyMemoPopup meeting_id={ this.state.meeting_id } title={ '新規メモ登録' } close={ this.closePopup.bind(this) } error={ this.props.handlers.error } />);
  }

  popupUpdateMemo(event, tag_memo) {
    event.preventDefault();
    event.stopPropagation();
    this.props.handlers.showPopup(<MyMemoPopup tag_memo={ tag_memo } meeting_id={ this.state.meeting_id } title={ 'メモ編集' } close={ this.closePopup.bind(this) } error={ this.props.handlers.error } />);
  }

  closePopup() {
    this.props.handlers.hidePopup();
    this.getCurrentPage();
  }

  deleteMemo(id) {
    return () => {
      let service = new Service();

      service.deleteMyMemo(id).subscribe(
          payload => {
            this.props.handlers.hidePopup();
            this.getCurrentPage();
          },
          error => {
            this.props.handlers.error(error);
          }
      );
    };
  }

  popupDeleteMemo(event, tag_memo) {
    event.preventDefault();
    event.stopPropagation();
    const message = `「${tag_memo.memo}」を削除しますか？`;
    this.props.handlers.showPopup(
        <AlertTwoButton title="メモ削除" message={ message } cancel={ this.props.handlers.hidePopup } okay={ this.deleteMemo(tag_memo.id) } okayButtonText="削除する" />
    );
  }

  formatDate(date) {
    let dateStr = '---- / -- / --';
    let timeStr = '-- : --';
    if (date) {
      dateStr = date.format('YYYY/MM/DD');
      timeStr = date.format('HH:mm');
    }
    return (
        <span className="format-date">
        <span className="date">{ dateStr }</span>
        <span className="time">{ timeStr }</span>
      </span>
    );
  }
}

MemoTab.PropType = {
  id: PropTypes.number,
  meeting_id: PropTypes.number,
  meeting_date: PropTypes.string,
  user_id: PropTypes.number,
  name: PropTypes.string,
  memo: PropTypes.string,
  publiced: PropTypes.string,
  timestamp: PropTypes.datetime,
  title: PropTypes.string,
  popupUpdateWord:PropTypes.func,
  popupDeleteWord:PropTypes.func,
  canEdit: PropTypes.number,
  order: PropTypes.string
};

export default MemoTab;
