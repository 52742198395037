import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './MeetingStatus.css';

class MeetingStatus extends Component {

  render() {
    if (this.props.endAt != null) {
      if (this.props.status === -2) {
        return (
            <div className="meeting-status">
              <div className="ended">終了(SS依頼中)</div>
            </div>
        );
      }

      return (
        <div className="meeting-status">
          <div className="ended">終了</div>
        </div>
      );
    } else if (this.props.startAt != null) {
      return (
        <div className="meeting-status">
          <div className="started">開催中</div>
        </div>
      );
    } else {
      return null;
    }
  }
}

MeetingStatus.PropType = {
  startAt: PropTypes.object,
  endAt: PropTypes.object,
  status: PropTypes.object
};

export default MeetingStatus;
