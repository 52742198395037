import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './CustomerSettings.css';
import Service from '../services/MeetingService';
import { setCustomerSettingInfo } from "./Login";

export default class CustomerSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customer_settings: null
        };
    }

    componentDidMount() {
        this.props.handlers.showProgress();
        this.getCustomerData();
    }

    tryHideProgress() {
        if (this.state.customer_settings === null) {
            return;
        }
        this.props.handlers.hideProgress();
    }

    getCustomerData() {
        let service = new Service();
        service.customerSettings().subscribe(
            payload => {
                this.setState({
                    customer_settings: payload,
                });
                this.tryHideProgress();
            },
            error => {
                console.log(error);
                this.props.handlers.hideProgress();
                this.props.handlers.error(error);
            }
        );
    }

    //noinspection JSUnusedLocalSymbols
    submit(event) {
        let form = new FormData();
        let settings = this.state.customer_settings;

        form.append('general_users_can_create_users',
            settings.general_users_can_create_users);
        form.append('general_users_can_edit_common_dictionary',
            settings.general_users_can_edit_common_dictionary);

        let service = new Service();

        this.props.handlers.showProgress();
        //noinspection JSUnusedLocalSymbols
        service.updateCustomerSettings(form).subscribe(
            payload => {
                setCustomerSettingInfo(payload);
                this.props.handlers.hideProgress();
            },
            error => {
                console.log(error);
                this.props.handlers.hideProgress();
            }
        );
    }

    onClickCheckbox(event, item) {
        let value = event.target.checked ? 1 : 0;
        let settings = this.state.customer_settings;
        switch (item) {
            case 'general_users_can_create_users':
                // console.log('general_users_can_create_users');
                settings.general_users_can_create_users = value;
                break;
            case 'general_users_can_edit_common_dictionary':
                // console.log('general_users_can_edit_common_dictionary');
                settings.general_users_can_edit_common_dictionary = value;
                break;
            default:
                break;
        }
        this.setState({
            customer_settings: settings,
        });
    }

    render() {
        return (
            <div className="customer-settings">
                {this.details()}
            </div>
        );
    }

    details() {
        if (!this.state.customer_settings) {
            return (
                <div/>
            );
        }

        return (
            <div>
                <div className="body">
                    <div className="authority-area">
                        <div className="authority-title">権限設定</div>
                        <div className="authority-table">
                            <table>
                                <thead>
                                <tr>
                                    <th/>
                                    <th className="th-column2"/>
                                    <th>管理者ユーザー</th>
                                    <th>一般ユーザー</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>ユーザー</td>
                                    <td>作成</td>
                                    <td>
                                        {/*<label>*/}
                                            {/*<input type="checkbox"*/}
                                                   {/*className="btnpt2"*/}
                                                   {/*checked="true"*/}
                                                   {/*disabled="true"*/}
                                            {/*/>*/}
                                            {/*<div className="check"/>*/}
                                        {/*</label>*/}
                                        ○
                                    </td>
                                    <td>
                                        <label>
                                            <input type="checkbox"
                                                   className="btnpt2"
                                                   value={this.state.customer_settings.general_users_can_create_users}
                                                   checked={this.state.customer_settings.general_users_can_create_users}
                                                   onChange={e => {
                                                       this.onClickCheckbox(e, 'general_users_can_create_users')
                                                   }}/>
                                            <div className="check"/>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>共通単語帳</td>
                                    <td>編集</td>
                                    <td>
                                        {/*<label>*/}
                                            {/*<input type="checkbox"*/}
                                                   {/*className="btnpt2"*/}
                                                   {/*checked="true"*/}
                                                   {/*disabled="true"*/}
                                            {/*/>*/}
                                            {/*<div className="check"/>*/}
                                        {/*</label>*/}
                                        ○
                                    </td>
                                    <td>
                                        <label>
                                            <input type="checkbox"
                                                   className="btnpt2"
                                                   value={this.state.customer_settings.general_users_can_edit_common_dictionary}
                                                   checked={this.state.customer_settings.general_users_can_edit_common_dictionary}
                                                   onChange={e => {
                                                       this.onClickCheckbox(e, 'general_users_can_edit_common_dictionary')
                                                   }}/>
                                            <div className="check"/>
                                        </label>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="buttons">
                            <button className="btnpt4" type="button" onClick={e => {
                                this.submit(e)
                            }}>設定
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

CustomerSettings.PropType = {
    handlers: PropTypes.object,
};
