import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './MeetingIndividualScore.css';
import Service from '../services/MeetingService';
import MeetingScore from '../components/MeetingScore';

export default class MeetingIndividualScore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customer_settings: null,
      customerDataLoaded: false
    };
  }

  componentDidMount() {
    if (typeof this.props.id !== 'undefined') {
      this.props.handlers.showProgress();
      this.getMeetingSettings();
      this.getCustomerData();
    }
  }

  tryHideProgress() {
    if (this.state.customer_settings === null) {
      return;
    }
    if (this.state.customerDataLoaded === false) {
      return;
    }
    this.props.handlers.hideProgress();
  }

  getCustomerData() {
    const service = new Service();
    service.customer()
      .subscribe(
        customer => {
          this.setState({
            customerDataLoaded: true,
          });
          this.tryHideProgress();
        },
        error => {
          console.log(error);
          this.props.handlers.hideProgress();
          this.props.handlers.hidePopup();
          this.props.handlers.error(error);
        }
      );
  }

  getMeetingSettings() {
    const service = new Service();
    service.meetingSettings(this.props.id).subscribe(
      payload => {
        this.setState({
          customer_settings: payload,
        });
        this.tryHideProgress();
      },
      error => {
        console.log(error);
        this.props.handlers.hideProgress();
        this.props.handlers.hidePopup();
        this.props.handlers.error(error);
      }
    );
  }

  submit() {
    const state = this.refs.meetingScore.state;
    const form = new FormData();
    if(!this.refs.meetingScore.validate()) {
      return;
    }

    form.append('document_score_assignment',
      state.document_score_assignment);
    form.append('time_score_assignment',
      state.time_score_assignment);
    form.append('progress_score_assignment',
      state.progress_score_assignment);
    form.append('conclusion_score_assignment',
      state.conclusion_score_assignment);
    form.append('next_meeting_score_assignment',
      state.next_meeting_score_assignment);
    form.append('speaker_score_assignment',
      state.speaker_score_assignment);

    const service = new Service();

    this.props.handlers.showProgress();
    service.updateMeetingSettings(this.props.id, form).subscribe(
      payload => {
        this.props.handlers.hideProgress();
      },
      error => {
        console.log(error);
        this.props.handlers.hideProgress();
        this.props.handlers.hidePopup();
        this.props.handlers.error(error);
      }
    );

    if (this.props.close) {
      this.props.close();
    }
  }

  cancel() {
    this.props.handlers.hidePopup();
  }
  render() {
    return (
      <div className="meeting-individual-score">
        <div className="title">会議別の配点変更</div>
        <div className="meeting-individual-score-area">
          {this.details()}
        </div>
        <div className="buttons">
          <button className="cancel btnpt1" type="button" onClick={ this.cancel.bind(this) }>
            キャンセル
          </button>
          <button className="okay btnpt4" type="button" onClick={ this.submit.bind(this) }>
            変更する
          </button>
        </div>
      </div>
    );
  }

  details() {
    if (!this.state.customer_settings) {
      return (
        <div/>
      );
    }
    const settings = this.state.customer_settings;
    return(
      <MeetingScore
        ref='meetingScore'
        document_score_assignment={ settings.document_score_assignment }
        time_score_assignment={ settings.time_score_assignment }
        progress_score_assignment={ settings.progress_score_assignment }
        conclusion_score_assignment={ settings.conclusion_score_assignment }
        next_meeting_score_assignment={ settings.next_meeting_score_assignment }
        speaker_score_assignment={ settings.speaker_score_assignment }
      />
    );
  }
}

MeetingIndividualScore.PropType = {
  id: PropTypes.number,
  close:PropTypes.func,
  handlers: PropTypes.object
};
