import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './Help.css';
import ClassNames from "classnames";
import Auth from "../components/Auth";

export default class Help extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectMenu: '',
    };
  }

  componentDidMount() {
  }

  getLink(id, linkName) {
    return (
        <li
            className={ ClassNames({ 'disabled': (this.state.selectMenu === id) })}
            onClick={ () => this.onClickMenu(id) }>
          <span>{ linkName }</span>
        </li>
    );
  }

  onClickMenu(id){
    this.setState({'selectMenu': id});
  }

  static renderManualContent() {
    return (<div className="help-content">
      <div className="help-content-title">マニュアルダウンロード</div>
      <ul>
        <li><a href="./manual/MMマニュアル①設定編_Ver.5.pdf" target="_blank">MMマニュアル①設定編(pdf)
          <div className="icon-download button-icon"/>
        </a></li>
        <li><a href="./manual/MMマニュアル②会議編_Ver.9.pdf" target="_blank">MMマニュアル②会議編(pdf)
          <div className="icon-download button-icon"/>
        </a></li>
        <li><a href="./manual/MMマニュアル③議事録作成編_Ver.8.pdf" target="_blank">MMマニュアル③議事録作成編(pdf)
          <div className="icon-download button-icon"/>
        </a></li>
        <li><a href="./manual/MMマニュアル④会議分析編_Ver.5.pdf" target="_blank">MMマニュアル④会議分析編(pdf)
          <div className="icon-download button-icon"/>
        </a></li>
        <li><a href="./manual/mm_windows_mic_application_manual_32-bit_ver_1.pdf" target="_blank">MMマニュアル Windowsマイクアプリ 32-bit版(pdf)
          <div className="icon-download button-icon"/>
        </a></li>
        <li><a href="./manual/mm_windows_mic_application_manual_ver_8.pdf" target="_blank">MMマニュアル Windowsマイクアプリ(pdf)
          <div className="icon-download button-icon"/>
        </a></li>
      </ul>
    </div>);
  }

  static renderApplicationContent() {
    return (<div className="help-content">
      <div className="help-content-title">マイクアプリダウンロード</div>
      <ul>
        <li><a href="./others/MM_Windows_3_3_2.zip" target="_blank">Windows版マイクアプリ Ver.3.3.2 インストーラー(zip)
          <div className="icon-download button-icon"/>
        </a></li>
        <li><a href="./others/MM_Windows_2_3_0.zip" target="_blank">Windows版マイクアプリ 32-bit版 Ver.2.3.0 インストーラー(zip)
          <div className="icon-download button-icon"/>
        </a></li>
      </ul>
    </div>);
  }

  renderRight() {
    switch(this.state.selectMenu) {
      case 'manual':
        return Help.renderManualContent();
      case 'application':
        return Help.renderApplicationContent();
      default:
          return null;
    }
  }

  render() {
    if(!Auth.isAuthenticated()) {
      return null;
    }

    return (
        <div className="help-menu">
          <div className="help-menu-left">
            <div className="help-menu-header">
              <div title="Version 1.0.1" className="icon-nav-help"/>
              <span>ヘルプ</span>
            </div>
            <ul>
              { this.getLink('manual', 'マニュアルダウンロード') }
              { this.getLink('application', 'マイクアプリダウンロード') }
            </ul>
          </div>
          <div className="help-menu-right">
            { this.renderRight() }
          </div>
        </div>
    );
  }
}

Help.PropType = {
    location: PropTypes.object
};
