import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './SuperScriberPopup.scss';
import 'react-datepicker/dist/react-datepicker.css';

import Service from '../services/MeetingService';
import {AlertOneButton} from "../components/Alert";
import CustomDatePicker from "../components/CustomDatePicker";
import Moment from "moment";

export default class SuperScriberPopup extends Component {

  constructor(props) {

    super(props);

    // // 現在の日付を取得
    const currentDate = new Date();
    // // 明日の日付を計算
    const tomorrow = new Date();
    tomorrow.setDate(currentDate.getDate() + 1);

    this.state = {
        id: this.props.id,
        request_plan_status: '1',
        delivery_date_requester	: Moment(),
    };
  }

  componentDidMount() {
  }

  // SSの依頼処理を行う
  ss_request(event) {

      // リクエストボディを作成
      var form = new FormData();
      form.append('meeting_id', this.state.id);
      form.append('request_plan_status', this.state.request_plan_status);
      if (this.state.delivery_date_requester) {
          form.append('delivery_date_requester', this.state.delivery_date_requester.format('YYYY/MM/DD'));
      }

      var service = new Service();

      service.requestScribeMeeting(this.state.id, form)
          .subscribe(
              payload => {
                  if (this.errorsToState(payload)) {
                      return;
                  }
                  this.props.close();
              },
              error => {
                  this.props.close();
                  this.alert();
              }
          );
  }

  errorsToState(payload) {
    if (payload.error === 'validation') {
      delete(payload.error);
      var errors = [];
      Object.keys(payload).forEach(key => {
        var error = payload[key][0];
        errors[key] = error.split('.')[1];
      });
      this.setState({ errors: errors });
      return true;
    }
    return false;
  }

  alert(title = 'ネットワークエラー', message = '少し待ってから行うか、一旦ログアウトしてください。') {
    this.props.handlers.showAlert(
      <AlertOneButton title={ title } message={ message } okay={ this.props.handlers.hideAlert } />
    );
  }

  cancel(event) {
    this.props.handlers.hidePopup();
  }

  onChangePlan(e, SelectValue) {
      this.setState({ request_plan_status: SelectValue});
  }

  // 日付が今日以前の場合はdisableにする
  isEnabledPositiveButton() {
      //
      //return this.state.memo.length > 0;
  }

    onChangeRequestDate(date) {
      this.setState({ delivery_date_requester: date });
  }

  render() {
    return(
      <div className="superscriber-popup">
        <div className="title">{ this.props.title }</div>
        <div className="inner">
          <form className="form">
            <div className="content_date">
              <label className="item-title">納品希望日</label>
              <CustomDatePicker
                selected={ this.state.delivery_date_requester }
                onChange={ this.onChangeRequestDate.bind(this) }
                isClearable={ false }
              />
            </div>
            <div className="content_plan">
              <label className="item-title">依頼プラン</label>
              { this.setSSPlan() }
            </div>
          </form>
        </div>
        <div className="buttons">
          <button onClick={ this.cancel.bind(this) } className="cancel btnpt1" >キャンセル</button>
          <button onClick={ this.ss_request.bind(this) } className="okay btnpt4" >依頼する</button>

        </div>
      </div>
    );
  }

  // 依頼ボタンの disable を付随している
  //<button disabled={ !this.isEnabledPositiveButton() } onClick={ this.ss_request.bind(this) } className="okay btnpt4" >依頼する</button>

  setSSPlan() {
      return (
          <div>
            <input className="radio_button" disabled={false}
                   type="radio" name="plan" value="1"
                   defaultChecked={this.state.request_plan_status}
                   id="my-memo-pop-public"
                   onChange={ (e) => this.onChangePlan(e, 1)} />
            <label htmlFor="my-memo-pop-public" className="label_undisabled">書き起こし</label>
            <input className="radio_button" disabled={false}
                   type="radio" name="plan" value="2"
                   defaultChecked={!this.state.request_plan_status}
                   id="my-memo-pop-private"
                   onChange={ (e) => this.onChangePlan(e, 2)} />
            <label htmlFor="my-memo-pop-private" className="label_undisabled">要約</label>
          </div>
      );
  }
}

SuperScriberPopup.PropType = {
  id: PropTypes.number,
  type: PropTypes.number,
  title: PropTypes.string,
  canNotRemoveMembers: PropTypes.bool,
  close:PropTypes.func,
  handlers: PropTypes.object
};
