import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Service from '../services/MeetingService';

import './DictionaryPopup.css';

function fieldToLabel(field) {
  var names = {
    name: '単語帳名',
  }
  return names[field];
}

class DictionaryPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dictionary: {
        name: ''
      },
      errors: {
        name: null
      }
    };
  }

  componentDidMount() {

    if (this.props.id) {
      const service = new Service();
      service.dictionary(this.props.id)
        .subscribe(
          payload => {
            this.setState({
              dictionary: Object.assign({}, this.state.dictionary, { name: payload.name })
            });
          },
          error => {
            this.props.close();
            this.props.error(error);
          }
        );

    }
  }

  submit(event) {
    var form = new FormData();
    form.append('name', this.state.dictionary.name);

    var service = new Service();

    if (this.props.id) {
      service.updateDictionary(this.props.id, form)
        .subscribe(
          payload => {
            if (this.errorsToState(payload)) {
              return;
            }
            this.props.close();
          },
          error => {
            this.props.close();
            this.props.error(error);
          }
        );
    } else {
      service.storeDictionary(form)
        .subscribe(
          payload => {
            if (this.errorsToState(payload)) {
              return;
            }
            this.props.close();
          },
          error => {
            this.props.close();
            this.props.error(error);
          }
        );
    }
  }

  cancel(event) {
    this.props.close();
  }

  okayButton() {
    return this.props.id ? '変更する' : '作成する';
  }

  onChangeText(e, item) {
    var dictionary = this.state.dictionary;
    dictionary[item] = e.target.value;
    this.setState({ dictionary: dictionary });
  }

  isEnabledPositiveButton() {
    return this.state.dictionary.name.length > 0;
  }

  render() {
    return(
      <div className="dictionary-popup">
        <div className="title">{ this.props.title }</div>
        <div className="inner">
          <form>
            <div><label className="required">単語帳名</label><input autoFocus maxLength={30} ref="name" type="text" value={ this.state.dictionary.name } onChange={ e => { this.onChangeText(e, 'name') } } /></div>
            <div className="explanation"><label></label></div>
            { this.errorMessage('name') }
          </form>
        </div>
        <div className="buttons">
          <button className="button-text" onClick={ e => { this.cancel(e) } } >キャンセル</button>
          <button className="button-1" disabled={ !this.isEnabledPositiveButton() } onClick={ e => { this.submit(e) } } >{ this.okayButton() }</button>
        </div>
      </div>
    );
  }

  errorsToState(payload) {
    if (payload.error === 'validation') {
      delete(payload.error);
      var errors = [];
      Object.keys(payload).forEach(key => {
        var error = payload[key][0];
        errors[key] = error.split('.')[1];
      });
      this.setState({ errors: errors });
      return true;
    }
    return false;
  }

  errorMessage(field) {
    var error = this.state.errors[field];
    if (error) {
      var label = fieldToLabel(field);
      var messages = {
        unique: `その${ label }は既に存在します。`,
        required: `${ label }は必須入力です。`,
      }
      return <div><label></label><div className="error">{ messages[error] }</div></div>;
    }
    return null;
  }

}

DictionaryPopup.PropType = {
  id: PropTypes.number,
  title: PropTypes.string,
  close:PropTypes.func,
  error:PropTypes.func
};

export default DictionaryPopup;
