import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Informations.css';

export class Information extends Component {

  render() {
    return (
      <div className="information">
        <div className="date">{ this.props.publish_at }</div>
        <div className="title">{ this.props.title }</div>
        <div className="desc">{ this.props.desc }</div>
      </div>
    );
  }
}

Information.PropType = {
  title: PropTypes.string,
  desc: PropTypes.string,
  publish_at: PropTypes.string
};

class Informations extends Component {

  render() {
    if (this.props.details.length > 0) {
      return (
        <div className="informations">
          <h2 className="title">お知らせ</h2>
          { this.props.details }
        </div>
      );
    } else {
      return null;
    }
  }
}

Informations.PropType = {
  details: PropTypes.array
};

export default Informations;
