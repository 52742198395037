import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './CustomerStatistics.css';
import Service from '../services/MeetingService';
import MeetingStatisticsScore from './MeetingStatisticsScore';
import Moment from 'moment';
import CustomDatePicker from '../components/CustomDatePicker';
import Auth from "../components/Auth";


export default class CustomerStatistics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      meetings_statistics: null,
      current: 0,
      start_at: Moment().subtract(1, 'month'),
      end_at: Moment(),
      keyword: null,
      date_updated: false,
    };
    this.getCurrentPage();
  }

  componentDidUpdate() {
    if(this.state.date_updated) {
      this.setState({
        date_updated: false
      });
      this.getCurrentPage();
    }
  }

  onChangeStartDate(date) {
    this.setState({
      date_updated: true,
      start_at: date
    });
  }

  onChangeEndDate(date) {
    this.setState({
      date_updated: true,
      end_at: date
    });
  }

  onKeyDownKeyword(event) {
    if (event.which === 0xd) {
      event.preventDefault();
      this.getCurrentPage();
    }
  }

  onChangeKeyword(event) {
    event.preventDefault();
    const keyword = this.refs.keyword.value || null;
    this.setState({ keyword: keyword });
    if(keyword === null) {
        this.getCurrentPage('');
    }
  }

  onSearch(event) {
    event.preventDefault();
    this.getCurrentPage();
  }

  getCurrentPage(keyword = this.state.keyword, page = null) {

    this.props.handlers.showProgress();

    const params = {};
      if (keyword && keyword !== "") {
          params['keywords'] = keyword;
    }
    if (this.state.start_at && this.state.start_at !== "") {
      params['start_at'] = this.state.start_at.format('YYYY/MM/DD');
    }
    if (this.state.end_at && this.state.end_at !== "") {
      params['end_at'] = this.state.end_at.format('YYYY/MM/DD');
    }

    const service = new Service();
    service.meetingsStatistics(page || this.state.current, params).subscribe(
      payload => {
        this.setState({
          meetings_statistics: payload
        });
        this.props.handlers.hideProgress();
      },
      error => {
        console.log(error);
        this.props.handlers.hideProgress();
        this.props.handlers.error(error);
      }
    );
  }

  render() {
    if(!Auth.isAuthenticated()) {
      return null;
    }

    return (
      <div className="customer-statistics">
        <div className="customer-statistics-header">
          <div className="customer-statistics-header-top">
            <div className="title">会議分析</div>
            <div className="search">
              <input type="search" ref="keyword" maxLength={100} onKeyDown={ this.onKeyDownKeyword.bind(this) } onChange={ this.onChangeKeyword.bind(this) } placeholder="タイトル、参加者の検索" />
              <button onClick={this.onSearch.bind(this)} className="button-select"><div className="icon-search" /></button>
            </div>
          </div>
          <div className="customer-statistics-header-bottom">
            <div className="date-selector-title">会議日</div>
            <div className="date-selector">
                <CustomDatePicker
                    id="startDate"
                    selected={this.state.start_at}
                    onChange={ this.onChangeStartDate.bind(this) }
                    disabled={ this.props.disabled }
                />
                <div className="hyphen">
                  <label className="select-label">〜</label>
                </div>
                <CustomDatePicker
                    id="endDate"
                    selected={this.state.end_at}
                    onChange={ this.onChangeEndDate.bind(this) }
                    disabled={ this.props.disabled }
                />
            </div>
          </div>
        </div>
        {this.details()}
      </div>
    );
  }

  details() {
    if (this.state.meetings_statistics == null) {
      return (
        <div/>
      );
    }
    if (this.state.meetings_statistics.total === 0) {
      return (
        <p>該当する会議はありません。</p>
      );
    }

    return(
      <div className="customer-statistics-detail">
          <MeetingStatisticsScore
            current_page={this.state.meetings_statistics.current_page}
            last_page={this.state.meetings_statistics.last_page}
            meetings={this.state.meetings_statistics.data}
            getCurrentPage={this.getCurrentPage.bind(this)}
            showMeetingInfo={true}
          />
      </div>
    );
  }
}

CustomerStatistics.PropType = {
  handlers: PropTypes.object,
};
