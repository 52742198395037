import React, { Component } from 'react';
import Spinner from "react-md-spinner";

import './OpenClient.css';
import {getSearchParamsForReactRouter} from "./utilities";

export class OpenClient extends Component {

  constructor(props) {
    super(props);

    this.state = {
      waiting: false,
    }
  }

  componentDidMount() {
    window.location.href = this.getOpenClientURL(this.getMeetingID());
  }

  render() {
    return (
        <div className="open-client">
          <div className="logo">
            <div className="img-top-logo"/>
          </div>
          <div className="desc">
            <p>
              Windows版マイクアプリを開きます。<br/>
              Windows以外のOSでは開くことができません。<br/>
              あらかじめ、Windows版マイクアプリ(Ver.2.2.0以降)がインストールされている必要があります。<br/>
            </p>
          </div>
          <div className="buttons">
            <button className="button-1" type="button" onClick={ e => { this.submit(e) } }>Windows版マイクアプリを開く</button>
          </div>
          { this.spinner() }
        </div>
    );
  }

  submit() {
    window.location.href = this.getOpenClientURL(this.getMeetingID());
  }

  getMeetingID() {
    return getSearchParamsForReactRouter().get('id');
  }

  getOpenClientURL(meeting_id) {
    return "super-meeting-memo://v1/meetings/" + meeting_id;
  }

  spinner() {
    if (this.state.waiting) {
      return <Spinner />;
    }
    return null;
  }
}
