import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { DropTarget } from 'react-dnd-cjs';
import Service from '../services/MeetingService';
import { isIEBrowser } from './utilities';

import './Minutes.css';
/**
 * Implements the drag source contract.
 */
const commentTarget = {

  drop(props, monitor, component) {
    if (component.props.disabled) {
      return;
    }
    console.log('dropped ' + monitor.getItemType());
    if (monitor.getItemType() === 'Comment') {
      console.log(`text:${monitor.getItem().text}`);
      var range = document.getSelection().getRangeAt(0);
      range.insertNode(range.createContextualFragment('<div>' + monitor.getItem().text + '</div>'));
      component.emitChange();
      new Service().updateCommentByCommentDrop(monitor.getItem().id);
    } else {
      const url = monitor.getItem().picture;
      console.log(`url: ${url}`);
      var results = url.match(/meetings\/(\d+)\/pictures\/(\d+)/);
      if (results.length > 1) {
        const meetingId = results[1];
        const pictureId = results[2];
        console.log(`meeting id: ${meetingId} picture id: ${pictureId}`);
        new Service().duplicateMinutePicture(meetingId, pictureId)
          .subscribe(
            payload => {
              const dupUrl = url.replace(new RegExp(results[0]), `meetings/${meetingId}/pictures/${payload.id}`);
              console.log("copy picture url: " + dupUrl);
              const image = `<img src=${dupUrl} />`;
              var range = document.getSelection().getRangeAt(0);
              range.insertNode(range.createContextualFragment(image));
              component.emitChange();
            },
            error => {
              component.alert(error);
            }
          );
      }
    }
  }
};

/**
 * Specifies the props to inject into your component.
 */
function collect(connect, monitor) {
  return {
   // Call this function inside render()
   // to let React DnD handle the drag events:
   connectDropTarget: connect.dropTarget()
 };
}

class Minute extends Component {

  // How to create a Range Object from a point (X and Y Coordinates)?
  // http://stackoverflow.com/questions/12920225/text-selection-in-divcontenteditable-when-double-click/12924488#12924488
  // trim by kan.
  getMouseEventCaretRange(evt) {
    var range, x = evt.clientX, y = evt.clientY;

    // Try the simple IE way first
    if (document.body.createTextRange) {
      // CreateTextangeはIE固有メソッド。IEのみ、if文が成立する。

      range = document.body.createTextRange();

      // 範囲外の場合はrangeが取れないため、rangeが取れた場合のみ処理
      if(range) {
        range.moveToPoint(x, y);
      }
    } else if (typeof document.createRange !== undefined) {
      // Try Mozilla's rangeOffset and rangeParent properties,
      // which are exactly what we want
      if (typeof evt.rangeParent !== "undefined") {
        range = document.createRange();
        range.setStart(evt.rangeParent, evt.rangeOffset);
        range.collapse(true);
      } else if (document.caretPositionFromPoint) {
        // Try the standards-based way next
        var pos = document.caretPositionFromPoint(x, y);
        range = document.createRange();
        range.setStart(pos.offsetNode, pos.offset);
        range.collapse(true);
      } else if (document.caretRangeFromPoint) {
        // Next, the WebKit way
        range = document.caretRangeFromPoint(x, y);
      }
    }

    return range;
  }

  onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();

    var selection = window.getSelection();
    selection.removeAllRanges();
    var range = this.getMouseEventCaretRange(event);
    if (range.select) {
      range.select(); // IE11 way
    } else if (selection.addRange) {
      selection.addRange(range);
    }
  }

  onDragLeave(event) {
    event.stopPropagation();
    event.preventDefault();
    findDOMNode(this).blur();
  }

  constructor(props) {
    super(props);
    this.emitChange = this.emitChange.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
  }

  render() {
    const { connectDropTarget, disabled } = this.props;
    return connectDropTarget(
      // thanks to Lovasoa
      // refer to https://github.com/lovasoa/react-contenteditable
      <div contentEditable={ !disabled } className="minute"
        onInput={ this.emitChange }
        onKeyUp={
          e => {
            if (isIEBrowser()) {
              this.emitChange();
            }
          }
        }
        onDragOver={ this.onDragOver }
        onDragLeave={ this.onDragLeave }
      >
      </div>
    );
  }

  componentDidUpdate() {
    var innerHTML = findDOMNode(this).innerHTML;
    if (this.props.html !== innerHTML) {
      findDOMNode(this).innerHTML = this.props.html;
    }
  }

  /**
   * onChangeプロパティが設定されていれば、変更の通知
   */
  emitChange() {
    console.log("emitChange");
    var innerHTML = findDOMNode(this).innerHTML;
    if (this.props.onChange) {
      this.props.onChange(innerHTML);
    }
  }

  alert(error) {
    this.props.onError('画像コピー', '画像が複写できませんでした。');
  }
}

export default DropTarget(['Comment', 'Picture'], commentTarget, collect)(Minute);
