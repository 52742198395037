import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import CustomDatePicker from '../components/CustomDatePicker';
import { Observable } from 'rx';

import Service from '../services/MeetingService';
import Spinner from "react-md-spinner";
import { AlertOneButton, AlertTwoButton } from '../components/Alert';
import RemovableList from '../components/RemovableList';

import './MeetingPopup.css';
import 'react-datepicker/dist/react-datepicker.css';

import MeetingTimePicker from '../containers/MeetingTimePicker';
import AgendaComment from './AgendaComment';
import MemberTab from './MemberTab';
import ClassNames from "classnames";
import {isUnsignedIntegerString, zenkakuAlphanumeric2hankaku} from "./utilities";

class AttachDocuments extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dragging: false,
      message: 'ドラッグまたはクリックして選択する',
      uploading: false,
    };
    this.bounce = false;
  }

  shield(event) {
    this.bounce = true;
  }

  onDragEnter(event) {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      dragging: true,
      message: 'ここにファイルをドロップ'
    });
  }

  onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      dragging: true,
    });
  }

  onDragLeave(event) {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      dragging: false,
      message: 'ドラッグまたはクリックして選択する'
     });
  }

  onDrop(event) {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      dragging: false,
      message: 'ドラッグまたはクリックして選択する'
    });
    let files = event.dataTransfer.files;
    this.addDocuments(files);
  }

  onTapSelectFile(event) {
    event.preventDefault();
    if (!this.bounce) {
      this.refs.file.click();
    }
    this.bounce = false;
  }

  onChangeFile(event) {
    let files = event.target.files;
    if (files.length > 0) { // IE way, get same events 3times(files is zero after 2times) on IE11
      this.addDocuments(files);
    }
    this.refs.file.value = null;
  }

  message() {
    if (!this.uploading) {
      if (this.props.documents.length !== 0) {
        return (
          <div className="documents" onClick={ this.shield.bind(this) }>
            <RemovableList items={ this.props.documents } onRemove={ this.onRemoveDocument.bind(this) } />
          </div>
        );
      }
    } else {
      return <Spinner />;
    }
  }

  addDocuments(files) {
    let added = [];
    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      added.push({ id: null, name: file.name, file: file });
    }
    this.props.onChangeDocuments([].concat(this.props.documents).concat(added));
  }

  render() {
    let draggingStyles = this.state.dragging ? 'drag-and-drop enter' : 'drag-and-drop';
    return(
        <div className="attach-documents">
          <input type="file" ref="file" onChange={ this.onChangeFile.bind(this) } />
          <div className="attachment">
            <div className={ draggingStyles }
              onDrop={ this.onDrop.bind(this) }
              onDragEnter={ this.onDragEnter.bind(this) }
              onDragOver={ this.onDragOver.bind(this) }
              onDragLeave={ this.onDragLeave.bind(this) }
              onClick={ this.onTapSelectFile.bind(this) }
            >
              <span>配布資料</span>
              <span>{ this.state.message }</span>
              { this.message() }
            </div>
          </div>
        </div>
    );
  }

  onRemoveDocument(index) {
    if (index >= 0) {
      let documents = [
        ...this.props.documents.slice(0, index),
        ...this.props.documents.slice(index + 1)
      ];
      this.props.onChangeDocuments(documents);
    }
  }
}

AttachDocuments.PropType = {
  documents: PropTypes.array,
  onChangeDocuments:PropTypes.func
};

export function fieldToLabel(field) {
  const names = {
    title: 'タイトル',
    estimated_start_at: '開始予定時刻',
    estimated_end_at: '終了予定時刻',
    room: '場所',
    members: '参加者',
    engine_id: 'エンジン',
    dictionary_id: '単語帳',
    documents: '配布資料',
  };
  return names[field];
}

export default class MeetingPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      meeting: {
        id: this.props.id,
        title: '',
        start_date: Moment(),
        estimated_start_at: null,
        estimated_end_at: null,
        room: '',
        members: [],
        engine_id: '',
        dictionary_id: '',
        documents: [],
        status: 0,
        forced_termination: true,
        send_mail: false,
        minutes_type: '0',
        agenda: {
          agenda_parts: [
            {
              id: '',
              title: '',
              presenter: '',
              time_required: '',
              time_required_input_value: '',
              time_required_error: null,
              display_order: 1
            },
            {
              id: '',
              title: '',
              presenter: '',
              time_required: '',
              time_required_input_value: '',
              time_required_error: null,
              display_order: 2
            },
            {
              id: '',
              title: '',
              presenter: '',
              time_required: '',
              time_required_input_value: '',
              time_required_error: null,
              display_order: 3
            }
          ]
        }
      },
      errors: {
        title: null,
        start_date: null,
        estimated_start_at: null,
        estimated_end_at: null,
        room: null,
        members: null,
        engine_id: null,
        dictionary_id: null,
        documents: null,
        status: null,
        forced_termination: null,
        send_mail: null,
        minutes_type: null,
        agenda_parts: null
      },
      engines: [],
      dictionaries: [],
      minute: null
    };

    this.skipCheckingNumOfMembers = false;
  }

  static get popupType() {
    return {
      CREATE: 1,
      EDIT: 2,
      COPY: 3,
    }
  }

  componentDidMount() {

    this.props.handlers.showProgress();

    let service = new Service();
    let meetingObservable;
    switch(this.props.type) {
      case MeetingPopup.popupType.EDIT:
      case MeetingPopup.popupType.COPY:
        meetingObservable = service.meeting(this.props.id, false);
        break;
      case MeetingPopup.popupType.CREATE:
      default:
        // meetingObservableはnullを返すようにする
        meetingObservable = Observable.just(null);
        break;
    }

    Observable.zip(meetingObservable, service.engines(), service.dictionaries(), (meeting, engines, dictionaries) => {
        return { meeting: meeting, engines: engines, dictionaries: dictionaries.data };
      }).subscribe(
        // zip はすべての Observable が値を流すまで値を流さない。
        payloads => {

          let meeting = payloads.meeting;
          let minute = (meeting && meeting.minute) ? meeting.minute : null;

          if (meeting) {

            let members = meeting.members.map(member => {
              return { id: member.id, name: member.user_name };
            });
            let documents = meeting.documents.map(document => {
              return { id: document.id, name: document.name };
            });
            let agenda_parts = [];
            if (meeting.agenda && meeting.agenda.agenda_parts) {
              agenda_parts = meeting.agenda.agenda_parts.map(agenda => {
                return {
                    id: agenda.id,
                    title: agenda.title,
                    presenter: agenda.presenter,
                    time_required: agenda.time_required,
                    time_required_input_value: agenda.time_required,
                    time_required_error: null,
                    display_order: agenda.display_order};
              });
            }

            // 過去の経緯が不明ですが、以前より変換してから再代入していたため、同様に変換する
            let start_date = meeting.start_date ? Moment(meeting.start_date) : Moment();
            let estimated_start_at = meeting.estimated_start_at ? Moment(meeting.estimated_start_at) : null;
            let estimated_end_at = meeting.estimated_end_at ? Moment(meeting.estimated_end_at) : null;
            let dictionary_id = meeting.dictionary_id ? meeting.dictionary_id : '';
            let forced_termination = (meeting.forced_termination !== 0);
            let send_mail = (meeting.send_mail === 1);
            let minutes_type = String(meeting.minutes_type);

            let status = meeting.status;

            // 複製の場合には、コピー不要な項目は初期値で上書きする
            if(this.props.type === MeetingPopup.popupType.COPY) {
                status = 0;
                documents = [];
                agenda_parts.forEach(parts => {
                    parts.id = '';
                });
                minute = null;
            }

            // Object.assignで合成オブジェクトを作る。コピー元は{}なので無し。第2引数以降を合成。
            meeting = Object.assign(
                {},
                this.state.meeting,
                {
                    title: meeting.title,
                    start_date: start_date,
                    estimated_start_at: estimated_start_at,
                    estimated_end_at: estimated_end_at,
                    room: meeting.room,
                    engine_id: meeting.engine_id,
                    dictionary_id: dictionary_id,
                    members: members,
                    documents: documents,
                    status: status,
                    forced_termination: forced_termination,
                    send_mail: send_mail,
                    minutes_type: minutes_type,
                    agenda: {
                        agenda_parts: agenda_parts
                    }
                }
            );
          } else {
            meeting = this.state.meeting;
            meeting.engine_id = payloads.engines[0].id;

          }
          this.setState({ meeting: meeting, engines: payloads.engines, dictionaries: payloads.dictionaries, minute: minute });
          this.props.handlers.hideProgress();
        },
        error => {
          this.props.handlers.hideProgress();
          this.alert();
        }
      );
  }

  engineOptions() {
    let options = [];
    this.state.engines.forEach((engine, index) => {
      options.push(<option key={ index } value={ engine.id }>{ engine.name }</option>);
    });
    return options;
  }

  dictionaryOptions() {
    let options = [];
    options.push(<option key="0" value="">辞書を選択してください。</option>);
    this.state.dictionaries.forEach((dictionary, index) => {
      if (!dictionary.prepared) {
        options.push(<option key={ index+1 } value={ dictionary.id }>{ dictionary.name }</option>);
      }
    });
    return options;
  }

  moveUpAgenda(index) {
    // if (index === 0 || !this.canEditAgenda()) {
    //   return <span className="disabled">∧</span>;
    // } else {
    //   return <span onClick={ this.onClickMoveUpAgenda.bind(this, index) }>∧</span>
    // }
    return <button
      className="button-icon-transparent"
      disabled={ index === 0 || !this.canEditAgenda() }
      onClick={ e => {this.onClickMoveUpAgenda(e, index) }}>
      <div className='icon-down-large up'/>
    </button>
  }

  moveDownAgenda(index) {
    // if (index === this.state.meeting.agenda.agenda_parts.length - 1 || !this.canEditAgenda()) {
    //   return <span className="disabled">∨</span>
    // } else {
    //   return <span onClick={ this.onClickMoveDownAgenda.bind(this, index) }>∨</span>
    // }
    return <button
      className="button-icon-transparent"
      disabled={ index === this.state.meeting.agenda.agenda_parts.length - 1 || !this.canEditAgenda() }
      onClick={ e => {this.onClickMoveDownAgenda(e, index) }}>
      <div className='icon-down-large'/>
    </button>
  }

  deleteAgenda(index) {
    return <button
      className="button-icon-transparent"
      disabled={ !this.canEditAgenda() }
      onClick={ e => {this.onClickDeleteAgenda(e, index) }}>
      <div className='icon-trash-large'/>
    </button>
  }

  addAgenda() {
    let isDisabled = this.state.meeting.agenda.agenda_parts.length >= 50 || !this.canEditAgenda();
    return <button
      className="button-1-gray button-add"
      disabled={ isDisabled }
      onClick={ e => {this.onClickAddAgenda(e) }}>
      <div className={
        ClassNames({
          'icon-plus' : !isDisabled,
          'icon-plus-disabled' : isDisabled
        })}>議題を追加</div>
    </button>
  }

  agendaParts() {
    const agenda_parts = [];
    this.state.meeting.agenda.agenda_parts.forEach((parts, index) => {
      agenda_parts.push(
        <div className="agenda" key={ parts.display_order }>
          <div className="agenda-header">
            <div className="agenda-index"><label>議題{index+1}</label></div>
            <div className="agenda-icons">
              <div className="agenda-icon">{ this.moveDownAgenda(index) }</div>
              <div className="agenda-icon">{ this.moveUpAgenda(index) }</div>
              <div className="agenda-icon">{ this.deleteAgenda(index) }</div>
            </div>
          </div>
          <div className="agenda-body">
            <div className="agenda-title">
              <AgendaComment
                index={ index }
                item={ 'title' }
                name={ 'タイトル' }
                maxLength={ 100 }
                defaultValue={ parts.title }
                disabled={ !this.canEditAgenda() }
                onChangeText={ this.onChangeAgendaCommentText.bind(this) }
                onError={ this.alert.bind(this) }
              />
            </div>
            <div className="agenda-row">
              <div className="agenda-presenter">
                <AgendaComment
                  index={ index }
                  item={ 'presenter' }
                  name={ '発言者' }
                  maxLength={ 100 }
                  defaultValue={ parts.presenter }
                  disabled={ !this.canEditAgenda() }
                  onChangeText={ this.onChangeAgendaCommentText.bind(this) }
                  onError={ this.alert.bind(this) }
                />
              </div>
              <div className="agenda-time-required">
                <div><label>所要時間</label></div>
                <div><input maxLength={3} ref="time_required" type="text" value={ parts.time_required_input_value } disabled={ !this.canEditAgenda() }
                   onChange={ e => { this.onChangeAgendaNumberText(e, index, 'time_required', 'time_required_input_value') } }
                   className="text" />分</div>
              </div>
            </div>
            { this.agendaErrorMessage(index) }
          </div>
        </div>
      );
    });
    return agenda_parts;
  }

  agenda() {
    if (this.state.meeting.minutes_type === '0') {
      return null;
    } else {
      return (
        <div className="agendas">
          <div>
            { this.addAgenda() }
          </div>
          { this.agendaParts() }
        </div>
      );
    }
  }

  onClickAddAgenda(event) {
    event.preventDefault();
    event.stopPropagation();
    const meeting = Object.assign({}, this.state.meeting);
    const agenda_parts = meeting.agenda.agenda_parts;
    meeting.agenda.agenda_parts.push(
      {
        id: '',
        title: '',
        presenter: '',
        // サーバーに送付する所要時間。半角数字の文字列
        time_required: '',
        // 入力中の所要時間。仕様変更により数値以外の値も入りうる。全角の場合もある
        time_required_input_value: '',
        // 所要時間の入力エラー表示用。議題は可変長のため、仕方なく他のエラーとは別の方法で扱う
        time_required_error: null,
        display_order: (agenda_parts && agenda_parts.length > 0) ? agenda_parts[agenda_parts.length - 1].display_order + 1 : 1
      },
    );
    this.setState({ meeting: meeting });
  }

  onClickMoveUpAgenda(event, index) {
    event.preventDefault();
    event.stopPropagation();
    const meeting = Object.assign({}, this.state.meeting);
    const agenda_parts = [...meeting.agenda.agenda_parts];
    const preAgenda = Object.assign({}, agenda_parts[index - 1]);
    const currentAgenda = Object.assign({}, agenda_parts[index]);
    preAgenda.display_order = agenda_parts[index].display_order;
    currentAgenda.display_order = agenda_parts[index - 1].display_order;
    agenda_parts[index - 1] = currentAgenda;
    agenda_parts[index] = preAgenda;
    meeting.agenda.agenda_parts = agenda_parts;
    this.setState({ meeting: meeting });
  }

  onClickMoveDownAgenda(event, index) {
    event.preventDefault();
    event.stopPropagation();
    const meeting = Object.assign({}, this.state.meeting);
    const agenda_parts = [...meeting.agenda.agenda_parts];
    const afterAgenda = Object.assign({}, agenda_parts[index + 1]);
    const currentAgenda = Object.assign({}, agenda_parts[index]);
    afterAgenda.display_order = agenda_parts[index].display_order;
    currentAgenda.display_order = agenda_parts[index + 1].display_order;
    agenda_parts[index + 1] = currentAgenda;
    agenda_parts[index] = afterAgenda;
    meeting.agenda.agenda_parts = agenda_parts;
    this.setState({ meeting: meeting });
  }

  onClickDeleteAgenda(event, index) {
    event.preventDefault();
    event.stopPropagation();
    const meeting = Object.assign({}, this.state.meeting);
    meeting.agenda.agenda_parts.splice(index, 1);
    this.setState({ meeting: meeting });
  }

  onChangeText(e, item) {
    let meeting = this.state.meeting;
    meeting[item] = e.target.value;
    this.setState({ meeting: meeting });
  }

  onChangeAgendaCommentText(value, item, index) {
    const meeting = this.state.meeting;
    meeting.agenda.agenda_parts[index][item] = value;
    this.setState({ meeting: meeting });
  }

  onChangeAgendaNumberText(e, index, item_name, input_value_item_name) {
    const meeting = this.state.meeting;
    let value = e.target.value;

    // input_value_item_nameは、長さ以外の内容に関係なく入力されたそのままを代入
    meeting.agenda.agenda_parts[index][input_value_item_name] = value;

    // item_nameは、全角でもいいので数値か空文字列の場合のみ代入
    value = zenkakuAlphanumeric2hankaku(value);
    if(isUnsignedIntegerString(value) || value === '') {
      meeting.agenda.agenda_parts[index][item_name] = value;
    } else {
      // 数値や空文字列以外は、とりえあず空文字列扱い
      meeting.agenda.agenda_parts[index][item_name] = '';
    }
    this.setState({ meeting: meeting });
  }

  onChangeSelection(e, item) {
    let meeting = this.state.meeting;
    meeting[item] = e.target.value;
    this.setState({ meeting: meeting });
  }

  okayButton() {
    switch(this.props.type) {
      case MeetingPopup.popupType.CREATE:
        return '作成する';
      case MeetingPopup.popupType.EDIT:
        return '変更する';
      case MeetingPopup.popupType.COPY:
      default:
        return '作成する';
    }
  }

  onChangeStartDate(date) {
    let meeting = this.state.meeting;
    meeting.start_date = date;
    this.setState({ meeting: meeting });
  }

  onChangeStartTime(time) {
    let meeting = this.state.meeting;
    meeting.estimated_start_at = time;
    this.setState({ meeting: meeting });
  }

  onChangeEndTime(time) {
    let meeting = this.state.meeting;
    meeting.estimated_end_at = time;
    this.setState({ meeting: meeting });
  }

  onAddMembers(members) {
    const member_names = members.map(member => {
      member.name = member.user_name;
      return member;
    });
    let send_mail = this.state.meeting.send_mail;
    const member_length = this.state.meeting.members.length + member_names.length;
    if(member_length > this.maximumNumberOfSenders()) {
      send_mail = false;
    }
    this.setState({ meeting: Object.assign({}, this.state.meeting, {
      members: this.state.meeting.members.concat(member_names),
      send_mail: send_mail,
    }) });
  }

  onClearMembers() {
    this.setState({ meeting: Object.assign({}, this.state.meeting, { members: [] }) });
  }

  onChangeDocuments(documents) {
    this.setState({ meeting: Object.assign({}, this.state.meeting, { documents: documents }) });
  }

  onClickCheckbox(event, item) {
    let meeting = this.state.meeting;
    meeting[item] = event.target.checked;
    this.setState({ meeting: meeting });
  }

  onChangeCheckbox(event, item) {
    let meeting = this.state.meeting;
    if (meeting[item] !== event.target.checked) {
      meeting[item] = event.target.checked;
      this.setState({ meeting: meeting });
    }
  }

  clearErrors() {
    let errors = this.state.errors;
    let newErrors = [];
    Object.keys(errors).forEach(function(key){
      newErrors[key] = null;
    });
    this.setState({ errors: newErrors });
  }


  validateAgenda() {
    // 自由記述型の場合、議題のエラーはスルーする
    if(this.state.meeting.minutes_type === '0') {
      return true;
    }

    const meeting = this.state.meeting;

    let error_count = 0;
    this.state.meeting.agenda.agenda_parts.forEach((parts, index) => {
      let input_value = parts.time_required_input_value;
      input_value = zenkakuAlphanumeric2hankaku(input_value);
      if(isUnsignedIntegerString(input_value) === false && input_value !== '') {
        error_count += 1;
        meeting.agenda.agenda_parts[index]['time_required_error'] = '所要時間には数値以外を入力できません。';
      } else {
        meeting.agenda.agenda_parts[index]['time_required_error'] = null;
      }
    });

    if(error_count) {
      this.clearErrors();
      this.setState({ meeting: meeting });
      return false;
    }

    return true;
  }

  submit(event) {

    event.preventDefault();
    event.stopPropagation();
    this.props.handlers.showProgress();

    if(this.validateAgenda() === false) {
      this.props.handlers.hideProgress();

      return;
    }

    this.submitBody()
      .subscribe(
        payload => {
          if (this.errorsToState(payload)) {
            this.props.handlers.hideProgress();
            return;
          }
          if (payload.error === 'available_num_of_concurrent_meeting_members') {
            this.props.handlers.hideProgress();
            if (this.state.meeting.status === 1) {
              this.alertDoNotOverConcurrentMeetingMembers();
            } else {
              this.alertOverConcurrentMeetingMembers();
            }
            return;
          }
          this.submitDocuments(payload.id)
            .finally(() => { this.props.handlers.hideProgress(); })
            .subscribe(
              results => {
                if (results.length <= 0) {
                  this.props.close();
                  return;
                }
                if (results.findIndex(result => { return result.error !== undefined; }) >= 0) {
                  this.alert('アップロードエラー', 'ファイルのアップロードに失敗しました。少し待ってから行うか、一旦ログアウトしてください。');
                  return;
                }
                let documents = this.state.meeting.documents.slice();
                results.forEach(uploaded => {
                  documents[uploaded.index].id = uploaded.id;
                });
                this.setState({ meeting: Object.assign({}, this.state.meeting, { documents: documents }) });
                this.props.close();
              },
              error => {
                this.props.close();
                this.alert();
              }
            );
        },
        error => {
          this.props.handlers.hideProgress();
          this.props.close();
          this.alert();
        }
      );
  }

  submitBody() {
    let form = new FormData();
    form.append('title', this.state.meeting.title);
    if (this.state.meeting.start_date) {
      form.append('start_date', this.state.meeting.start_date.format('YYYY/MM/DD'));
    }
    if (this.state.meeting.start_date && this.state.meeting.estimated_start_at) {
      form.append('estimated_start_at', this.state.meeting.start_date.format('YYYY/MM/DD') + this.state.meeting.estimated_start_at.format(' HH:mm'));
    } else {
      form.append('estimated_start_at', ''); // set null
    }
    if (this.state.meeting.start_date && this.state.meeting.estimated_end_at) {
      form.append('estimated_end_at', this.state.meeting.start_date.format('YYYY/MM/DD') + this.state.meeting.estimated_end_at.format(' HH:mm'));
    } else {
      form.append('estimated_end_at', ''); // set null
    }
    form.append('room', this.state.meeting.room);
    this.state.meeting.members.forEach(member => { form.append('members[]', member.id); });
    if (!this.isMeetingStarted()) {
      form.append('engine_id', this.state.meeting.engine_id);
      if (this.state.meeting.dictionary_id) {
        form.append('dictionary_id', this.state.meeting.dictionary_id);
      }
    }
    this.state.meeting.documents.forEach(document => { form.append('documents[]', document.id); });
    form.append('agenda[agenda_parts][]', '');
    this.state.meeting.agenda.agenda_parts.forEach((parts, index) => {
      if (parts.id !== '') {
        form.append(`agenda[agenda_parts][${index}][id]`, parts.id);
      }
      form.append(`agenda[agenda_parts][${index}][title]`, parts.title);
      form.append(`agenda[agenda_parts][${index}][presenter]`, parts.presenter);
      form.append(`agenda[agenda_parts][${index}][time_required]`, parts.time_required);
      form.append(`agenda[agenda_parts][${index}][display_order]`, parts.display_order);
    });

    if (this.skipCheckingNumOfMembers) {
      form.append('skip_checking_number_of_concurrent_meeting_members', '1');
    }
    form.append('forced_termination', this.state.meeting.forced_termination ? '1' : '0');
    if(this.canSendMail()) {
      form.append('send_mail', this.state.meeting.send_mail ? '1' : '0');
    }
    form.append('minutes_type', this.state.meeting.minutes_type);

    const service = new Service();

    if (this.props.type === MeetingPopup.popupType.EDIT) {
      return service.updateMeeting(this.props.id, form);
    } else {
      return service.storeMeeting(form);
    }
  }

  submitDocuments(id) {

    let documents = [];
    let uploadings = [];
    this.state.meeting.documents.forEach((document, index) => {
      if (document.file) {
        document.index = index;
        documents.push(document.file);
        uploadings.push(document);
      }
    });

    if (documents.length > 0) {
      let service = new Service();

      let observables = documents.map(document => {
        let form = new FormData();
        form.append('file', document);
        return service.uploadDocument(id, form);
      });

      return Observable.zip(observables, (...args) => {
        for (let i = 0; i < args.length; ++i) {
          uploadings[i].id = args[i].id;
          uploadings[i].error = args[i].error;
        }
        return uploadings;
      });
    }

    return Observable.just([]);
  }

  enableSkipCheckingNumOfMembersAndSubmit(event) {
    this.skipCheckingNumOfMembers = true;
    this.props.handlers.hideAlertOnly();
    this.submit(event);
  }

  cancel(event) {
    this.props.handlers.hidePopup();
  }

  minutesTypeMessage() {
    if (this.canEditAgenda()) {
      return null;
    } else {
      return (
        <div className="desc">
          議題は編集開始をクリックした人のみ修正できます。
        </div>
      );
    }
  }

  isMeetingStarted() {
    return this.state.meeting.status !== 0;
  }

  /**
   * 議題を編集できるかどうかの判定関数。
   * 議題の内容変更だけでなく、追加、移動、削除にも影響する。
   *
   * 補足：
   * ・会議詳細画面から呼び出された場合、自分が編集者なら、
   *  議題の追加、移動、削除を許容している。
   *   (中途半端な編集内容があっても、ポップアップ表示時に強制保存しているため、矛盾しない)
   * ・他の人が議事録編集中の場合、会議詳細画面から会議編集ポップアップに遷移できない
   * ・TODO: 会議開催中以外＆自分が議事録編集中に、会議一覧から会議編集ポップアップに遷移すると矛盾が発生するため改善が必要
   * @returns {boolean}
   */
  canEditAgenda() {
    const user_id_string = sessionStorage.getItem('id');
    // 会議の新規作成等、必要な変数にアクセスできない場合は編集可能
    if(!this.state.minute || !this.state.minute.editor || user_id_string === null) {
      return true;
    }
    // 議事録編集中の場合
    if(this.state.minute.start_at && !this.state.minute.end_at) {
      // 自分が編集者なら編集可能。違う場合は、編集不可
      return Number(user_id_string) === this.state.minute.editor.id;
    }
    return true;
  }

  maximumNumberOfSenders() {
    return 19;
  }

  canSendMail() {
    return this.state.meeting.members.length <= this.maximumNumberOfSenders();
  }

  render() {
    return(
      <div className="meeting-popup">
        <div className="title">{ this.props.title }</div>
        <div className="inner">
          <form autoComplete="off">
            <div className="main-area">
              <div className="meeting-title"><label className="required">タイトル</label><input maxLength={100} ref="title" type="text" value={ this.state.meeting.title } onChange={ e => { this.onChangeText(e, 'title') } } autoFocus className="text" /></div>
              { this.errorMessage('title') }
              <div className="start-date">
                <div className="date">
                  <label className="icon-calendar-navy-small">開催予定日</label>
                  <CustomDatePicker
                      selected={ this.state.meeting.start_date }
                      onChange={ this.onChangeStartDate.bind(this) }
                      isClearable={ false }
                  />
                </div>
                <div className="time">
                  <label>開始</label>
                  <MeetingTimePicker time={ this.state.meeting.estimated_start_at } notUseSecond={true} intervalOfMinutesOption={5} onChangeTime={ this.onChangeStartTime.bind(this) }  />
                </div>
                <div className="tilde">
                  <span className="label">〜</span>
                </div>
                <div className="time">
                  <label className="required">終了</label>
                  <MeetingTimePicker time={ this.state.meeting.estimated_end_at } notUseSecond={true} intervalOfMinutesOption={5} onChangeTime={ this.onChangeEndTime.bind(this) }  />
                </div>
              </div>
              { this.errorMessage('start_date') }
              { this.errorMessage('estimated_start_at') }
              { this.errorMessage('estimated_end_at') }
              <div className="end-check">
                <label className="checkbox_label">
                  <input type="checkbox" className="btnpt2" value={ this.state.meeting.forced_termination } onClick={ e => { this.onClickCheckbox(e, 'forced_termination') }} onChange={ e => { this.onChangeCheckbox(e, 'forced_termination') }} checked={ this.state.meeting.forced_termination }/>
                  <div className="check"/>
                  終了予定時刻を過ぎた場合、会議を自動終了する
                </label>
              </div>
              { this.warningMessage('forced_termination') }
              <div className="message-auto-off"><div className="warning">注意：23:59時点で会議が開いたままの場合、会議は自動終了されます。</div></div>
              <div className="room">
                <label className="icon-place-navy-small">開催場所</label>
                <input maxLength={100} ref="room" type="text" value={ this.state.meeting.room } onChange={ e => { this.onChangeText(e, 'room') } } className="text" />
                { this.errorMessage('room') }
              </div>
              <div className="minutes-type">
                <label className="icon-minutes-navy-small">議事録の形式</label>
                <div className="select">
                  <select value={ this.state.meeting.minutes_type } onChange={ e => { this.onChangeSelection(e, 'minutes_type') } } disabled={ !this.canEditAgenda() } >
                    <option value="0">自由記述型</option>
                    <option value="1">議題登録型</option>
                  </select>
                  <button className="button-select" disabled={ !this.canEditAgenda() } >
                    <div className={ !this.canEditAgenda() ? "icon-inverted-triangle-disabled" : "icon-inverted-triangle" }/>
                  </button>
                </div>
                { this.minutesTypeMessage() }
              </div>
              { this.agenda() }
              <AttachDocuments documents={ this.state.meeting.documents } onChangeDocuments={ this.onChangeDocuments.bind(this) } />
              <div className="engine">
                <label className="icon-microphone-navy-small">音声認識エンジン</label>
                <div className="select">
                  <select value={ this.state.meeting.engine_id } onChange={ e => { this.onChangeSelection(e, 'engine_id') } } disabled={ this.isMeetingStarted() } >
                    { this.engineOptions() }
                  </select>
                  <button className="button-select" disabled={ this.isMeetingStarted() } >
                    <div className={ this.isMeetingStarted() ? "icon-inverted-triangle-disabled" : "icon-inverted-triangle" }/>
                  </button>
                </div>
                <div className="desc">
                  会議の内容にあったエンジンを設定してください。
                </div>
                { this.errorMessage('engine_id') }
              </div>
              <div className="dictionary">
                <label className="icon-book-navy-small">単語帳選択</label>
                <div className="select">
                  <select value={ this.state.meeting.dictionary_id } onChange={ e => { this.onChangeSelection(e, 'dictionary_id') } } disabled={ this.isMeetingStarted() } >
                    { this.dictionaryOptions() }
                  </select>
                  <button className="button-select" disabled={ this.isMeetingStarted() } >
                    <div className={ this.isMeetingStarted() ? "icon-inverted-triangle-disabled" : "icon-inverted-triangle" }/>
                  </button>
                </div>
                <div className="desc">
                  共通単語帳は、デフォルトで選択されております。
                </div>
                { this.errorMessage('dictionary_id') }
              </div>
            </div>
            <div className="members-area">
              <div className="member-list">
                <label className="icon-users-navy-small required">参加メンバー</label>
                <div>
                  <MemberTab
                    members={ this.state.meeting.members }
                    onAddMembers={ this.onAddMembers.bind(this) }
                    onClearMembers={ this.onClearMembers.bind(this) }
                    onRemoveMember={ this.onRemoveMember.bind(this) }
                    canNotRemoveMembers={ this.props.canNotRemoveMembers }
                  />
                </div>
                { this.errorMessage('members') }
                <div>
                  <label className="checkbox_label" disabled={ this.canSendMail() === false }>
                    <input type="checkbox" className="btnpt2" value={ this.state.meeting.send_mail } onClick={ e => { this.onClickCheckbox(e, 'send_mail') }}  onChange={ e => { this.onChangeCheckbox(e, 'send_mail') }} checked={ this.state.meeting.send_mail } disabled={ this.canSendMail() === false }/>
                    <div className="check" disabled={ this.canSendMail() === false }/>
                    参加者に会議情報メールを送付する
                  </label>
                  <div className="send_mail_note">※19名以下の場合のみ送付できます</div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="buttons">
          <button onClick={ this.cancel.bind(this) } className="cancel btnpt1" >キャンセル</button>
          <button onClick={ this.submit.bind(this) } className="okay btnpt4" >{ this.okayButton() }</button>
        </div>
      </div>
    );
  }

  errorsToState(payload) {
    if (payload.error === 'validation') {
      delete(payload.error);
      let errors = [];
      Object.keys(payload).forEach(key => {
        let error = payload[key][0];
        errors[key] = error.split('.')[1];
      });
      this.setState({ errors: errors });
      return true;
    }
    return false;
  }

  agendaErrorMessage(index) {
    let error = this.state.meeting.agenda.agenda_parts[index]['time_required_error'];
    if(error) {
      return <div><div className="error">{ error }</div></div>;
    }
    return null;
  }

  errorMessage(field) {
    let error = this.state.errors[field];
    if (error) {
      let label = fieldToLabel(field);
      let messages = {
        required: `${ label }は必須入力です。`,
        exists: `${ label }は存在しません。`,
        date: `${ label }が誤っています。`,
        after_now: `${ label }は現在日時以降を設定してください。`,
        'after_estimated start at': `${ label }は開始予定日時以降を設定してください。`,
      };
      return <div><div className="error">{ messages[error] }</div></div>;
    }
    return null;
  }

  warningMessage(field) {
    if(field === 'forced_termination') {
      if(this.state.meeting.forced_termination === false) {
        let message = `注意：自動終了がOFFになっています。会議を開始・再開した場合、「会議終了ボタン」を押して会議を終了させないと従量課金が発生します。`;
        return <div className="message-billing"><div className="warning">{ message }</div></div>;
      }
    }
    return null;
  }

  onRemoveMember(index) {
    if (this.props.canNotRemoveMembers) {
      return;
    }
    if (index >= 0) {
      let meeting = Object.assign({}, this.state.meeting);
      meeting.members = [
         ...meeting.members.slice(0, index),
         ...meeting.members.slice(index + 1)
      ];
      this.setState({ meeting: meeting });
    }
  }

  alert(title = 'ネットワークエラー', message = '少し待ってから行うか、一旦ログアウトしてください。') {
    this.props.handlers.showAlert(
      <AlertOneButton title={ title } message={ message } okay={ this.props.handlers.hideAlert } />
    );
  }

  alertOverConcurrentMeetingMembers() {
    const title = '確認';
    const message = `指定の開催期間に同時開催する会議の参加者数の合計が制限を超える恐れがあります。会議を${ this.okayButton() }しますか？`;
    this.props.handlers.showAlert(
      <AlertTwoButton title={ title } message={ message } cancel={ this.props.handlers.hideAlertOnly } okay={ this.enableSkipCheckingNumOfMembersAndSubmit.bind(this) } />
    );
  }

  alertDoNotOverConcurrentMeetingMembers() {
    const title = '確認';
    const message = '同時会議参加者数を超えるので、会議を変更できません。';
    this.props.handlers.showAlert(
      <AlertOneButton title={ title } message={ message } okay={ this.props.handlers.hideAlertOnly } />
    );
  }
}

MeetingPopup.PropType = {
  id: PropTypes.number,
  type: PropTypes.number,
  title: PropTypes.string,
  canNotRemoveMembers: PropTypes.bool,
  close:PropTypes.func,
  handlers: PropTypes.object
};
