import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd-cjs';
import Moment from 'moment';
import ClassNames from 'classnames';

import {absolutedUrl, isChromeIOSBrowser} from './utilities';

/**
 * Implements the drag source contract.
 */
const commentSource = {
  beginDrag(props) {
    return {
      picture: absolutedUrl(props.detail.url)
    };
  }
};

/**
 * Specifies the props to inject into your component.
 */
function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

class Picture extends Component {

  constructor(props) {
    super(props);

    this.state = {
      mark: this.props.detail.mark,
      editable: false,
      timestamp: this.props.detail.timestamp,
      url: this.props.detail.url,
    }

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.detail.mark !== undefined) {
      this.setState({ mark: nextProps.detail.mark });
    }
  }

  buttons() {
    // 次の条件を全て満たすときのみ削除アイコンを表示
    // ・会議を自分が編集中
    // ・該当画像を選択している
    // ・配布資料の画像ではない
    // ・iPadではない
    if (this.props.enabled
        && this.props.isSelected
        && this.props.detail.type === 1
        && !isChromeIOSBrowser()
    ) {
      return <div className="buttons">
        <div className="button-icon-transparent icon-trash-medium" onClick={ this.onClickDeleting.bind(this) } />
      </div>;
    }

    return <div className="buttons"/>
  }

  render() {
    const connectDragSource = this.props.connectDragSource;
    const timestamp = Moment(this.state.timestamp).format('HH:mm:ss');
    const token = encodeURIComponent(sessionStorage.getItem('session'));

    let divClass = ClassNames({ "picture-box": true, selected: (this.props.enabled && this.props.isSelected) });

    return connectDragSource(
      <div className={ divClass } onClick={ this.onClickRow.bind(this) }>
        <div className="picture">
          <img src={ this.state.url + '?api_token=' + token } alt="" />
        </div>
        <div className="filename">{ this.props.detail.filename }</div>
        <div className="functions">
          <div className="zoom">
            <div onClick={ this.onClickImage.bind(this) } className="button-icon-transparent icon-zoom-medium" />
          </div>
          <div className="mark">
            <div
              onClick={ e => { this.onClickMark(e, this.props.detail.id) } }
              className={ ClassNames({'button-icon-transparent': true, 'icon-star-enabled-medium': this.isMarked(), 'icon-star-not-selected-medium': !this.isMarked()}) } />
          </div>
          { this.buttons() }
          <div className="timestamp">
            <div>{ timestamp }</div>
          </div>
        </div>
      </div>
    );
  }

  // クリック後、IEではmarkが常に文字列型で設定されるため、この関数で判定。。。(Chromeでもクリック後の初回は文字列型)
  // 文字列型になる原因は未調査
  isMarked() {
    if(this.state.mark === 1) {
      return true;
    }
    return this.state.mark === "1";
  }

  onClickRow(event) {
    if (!this.props.enabled) {
      return;
    }

    let selected = !this.props.isSelected ? this.props.detail.id : -1;
    this.props.onSelect(selected);
  }

  onClickMark(event, id) {
    event.preventDefault();
    event.stopPropagation();

    const mark = (+this.state.mark) !== 0 ? 0 : 1;
    this.props.onChange({ id: this.props.detail.id, mark: mark });
  }

  onClickDeleting(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.onDelete(this.props.detail.id);
  }

  onClickImage(event, id) {
    event.preventDefault();
    event.stopPropagation();
    window.open(absolutedUrl(this.props.detail.url));
  }

}

Picture.propTypes = {
  // 画像データ
  detail: PropTypes.object,
  onChange: PropTypes.func,
  // 自分が編集中かどうか
  enabled: PropTypes.bool,
  rowColor: PropTypes.string,
  isSelected: PropTypes.bool,
  // Injected by React DnD:
  isDragging: PropTypes.bool.isRequired,
  connectDragSource: PropTypes.func.isRequired
};

// Export the wrapped component:
export default DragSource('Picture', commentSource, collect)(Picture);
