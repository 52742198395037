import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import Service from '../services/MeetingService';

import './MyMemoPopup.css';

function fieldToLabel(field) {
  var memos = {
    memo: '',
  }
  return memos[field];
}

class MyMemoPopup extends Component {

  constructor(props) {
    super(props);

    var now = Moment();
    var now_date_time = now.format("YYYY/MM/DD HH:MM:SS");

    if(this.props.tag_memo)
    {
      this.state = {
        meeting_id: this.props.tag_memo.meeting_id,
        user_id: this.props.tag_memo.user_id,
        memo: this.props.tag_memo.memo,
        is_public: this.props.tag_memo.is_public,
        timestamp: this.props.tag_memo.timestamp,
        inputMemoValue: 0,
        errors: {
          meeting_id: null,
          user_id: null,
          memo: null,
          publiced: null,
          timestamp: null,
          inputMemoValue: 0
        }
      };
    }
    else
    {
      this.state = {
        meeting_id: this.props.meeting_id,
        user_id: 0,
        memo: "",
        is_public: false,
        timestamp: now_date_time,
        inputMemoValue: 0,
        errors: {
          meeting_id: null,
          user_id: null,
          memo: null,
          publiced: null,
          timestamp: null,
          inputMemoValue: 0
        }
      };
    }
  }

  componentDidMount() {

    if (this.props.tag_memo) {
      const service = new Service();
      service.MyMemos(this.props.tag_memo)
        .subscribe(
          payload => {
            this.setState({
              my_memo: Object.assign({}, this.state.my_memo, { memo: payload.memo })
            });
          },
          error => {
            this.props.close();
            this.props.error(error);
          }
        );

    }
  }

  submit(event) {
    var form = new FormData();

    form.append('meeting_id', this.state.meeting_id);
    form.append('user_id', this.state.user_id);
    form.append('name', this.state.name);
    form.append('memo', this.state.memo);
    form.append('is_public', this.state.is_public);
    form.append('timestamp', this.state.timestamp);

    var service = new Service();

    if (this.props.tag_memo) {
      service.updateMyMemo(this.props.tag_memo.id, form)
        .subscribe(
          payload => {
            if (this.errorsToState(payload)) {
              return;
            }
            this.props.close();
          },
          error => {
            this.props.close();
            this.props.error(error);
          }
        );
    } else {
      service.storeMyMemo(form)
        .subscribe(
          payload => {
            if (this.errorsToState(payload)) {
              return;
            }
            this.props.close();
          },
          error => {
            this.props.close();
            this.props.error(error);
          }
        );
    }
  }

  cancel(event) {
    this.props.close();
  }

  okayButton() {
    return this.props.tag_memo ? '変更する' : '登録する';
  }

  onChangeText(e) {
    const memo = e.target.value;
    this.setState({ memo: memo});
  }

  onChangePublic(e, SelectValue) {
    this.setState({ is_public: SelectValue});
  }

  isEnabledPositiveButton() {
    return this.state.memo.length > 0;
  }

  render() {
    return(
      <div className="mymemo-popup">
        <div className="title">{ this.props.title }</div>
        <div className="inner">
          <form className="form">
            <div className="content_memo">
              <label className="item_name_memo required">メモ内容</label>
              <textarea className="input_memo" autoFocus maxLength={500}
                     ref="memo"
                     onChange={ e => { this.onChangeText (e) }}
                     defaultValue={this.state.memo}
              />
              <label className="input_value" id="length_value">{this.state.memo.length + ' / 500'}</label>
            </div>
            <div className="content_etc">
              <div className="content_date">
                <label className="item-title">作成日時</label>
                <div className="now-date-time">{ this.state.timestamp }</div>
              </div>

              <div className="content_publiced">
                <label className="item-title">公開設定</label>
                { this.setPublic(this.state.meeting_id) }
              </div>
            </div>
            { this.errorMessage('memo') }
          </form>
        </div>
        <div className="buttons">
          <button className="btnpt1" onClick={ e => { this.cancel(e) } } >キャンセル</button>
          <button className="btnpt4" disabled={ !this.isEnabledPositiveButton() } onClick={ e => { this.submit(e) } } >{ this.okayButton() }</button>
        </div>
      </div>
    );
  }

  setPublic(meeting_id) {
    if( meeting_id === 0 ) {
      return (
          <div>
            <input className="radio_button" disabled={true}
                   type="radio" name="publiced" value="0"
                   id="my-memo-pop-public"
                   onChange={ (e) => this.onChangePublic(e, 'true')} />
            <label htmlFor="my-memo-pop-public" className="label_disabled" aria-disabled={true}>公開</label>
            <input className="radio_button" disabled={true}
                   type="radio" name="publiced" value="1"
                   defaultChecked
                   id="my-memo-pop-private"
                   onChange={ (e) => this.onChangePublic(e, 'false')} />
            <label htmlFor="my-memo-pop-private" className="label_disabled" aria-disabled={false}>非公開</label>
          </div>
      );
    }
    else
    {
      return (
          <div>
            <input className="radio_button" disabled={false}
                   type="radio" name="publiced" value="0"
                   defaultChecked={this.state.is_public}
                   id="my-memo-pop-public"
                   onChange={ (e) => this.onChangePublic(e, 'true')} />
            <label htmlFor="my-memo-pop-public" className="label_undisabled">公開</label>
            <input className="radio_button" disabled={false}
                   type="radio" name="publiced" value="1"
                   defaultChecked={!this.state.is_public}
                   id="my-memo-pop-private"
                   onChange={ (e) => this.onChangePublic(e, 'false')} />
            <label htmlFor="my-memo-pop-private" className="label_undisabled">非公開</label>
          </div>
      );
    }
  }

  errorsToState(payload) {
    if (payload.error === 'validation') {
      delete(payload.error);
      var errors = [];
      Object.keys(payload).forEach(key => {
        var error = payload[key][0];
        errors[key] = error.split('.')[1];
      });
      this.setState({ errors: errors });
      return true;
    }
    return false;
  }

  errorMessage(field) {
    var error = this.state.errors[field];
    if (error) {
      var label = fieldToLabel(field);
      var messages = {
        max: `${ label }は500文字以下で入力してください。`,
        required: `${ label }は必須入力です。`,
      }
      return <div><label></label><div className="error">{ messages[error] }</div></div>;
    }
    return null;
  }

}

MyMemoPopup.PropType = {
  tag_memo: PropTypes.number,
  title: PropTypes.string,
  meeting_id: PropTypes.number,
  close:PropTypes.func,
  error:PropTypes.func
};

export default MyMemoPopup;
