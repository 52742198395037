import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './TranslatedCommentPopup.css';

class TranslatedCommentPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      errors: []
    }
  }

  render() {
    return(
      <div className="translated-comment-popup">
        <div className="title">翻訳文編集</div>
        <div className="inner">
          <form onSubmit={ e => { e.preventDefault(); }}>
            <div>
              <label>発言時間</label>
              <div>{ this.props.timestamp }</div>
            </div>
            <div>
              <label>発言者</label>
              <div>{ this.props.name }</div>
            </div>
            <div>
              <label>原文</label>
              <textarea value={this.props.comment} disabled={true} />
            </div>
            <div>
              <label className="required">翻訳</label>
              <textarea autoFocus ref="translatedComment" />
            </div>
          </form>
        </div>
        <div className="errors">
          { this.errors() }
        </div>
        <div className="buttons">
          <button className="btnpt1" onClick={ this.cancel.bind(this) }>キャンセル</button>
          <button className="btnpt4" onClick={ this.update.bind(this) }>変更する</button>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.refs.translatedComment.value = this.props.translatedComment;
  }

  cancel(event) {
    this.props.close();
  }

  update(event) {
    if (this.validate()) {
      this.props.close();
      this.props.onChange({
        id: this.props.id,
        translatedComment: this.refs.translatedComment.value
      });
    }
  }

  validate() {
    const errors = [];
    if (!this.refs.translatedComment.value) {
      errors.push('翻訳を入力してください。');
    }
    if (errors.length > 0) {
      this.setState({ errors: errors });
      return false;
    }
    return true;
  }

  errors() {
    if (this.state.errors.length > 0) {
      const rows = [];
      this.state.errors.forEach((message, index) => {
        rows.push(<div key={ index } className="error">{ message }</div>);
      });
      return rows;
    }
  }

}

TranslatedCommentPopup.PropType = {
  id: PropTypes.number,
  timestamp: PropTypes.string,
  name: PropTypes.string,
  comment: PropTypes.string,
  translatedComment: PropTypes.string,
  close: PropTypes.func,
  onChange: PropTypes.func
};

export default TranslatedCommentPopup;
