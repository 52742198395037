import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Observable } from 'rx';
import Spinner from "react-md-spinner";
import ClassNames from 'classnames';

import Comment from './Comment';
import CommentPopup from '../components/CommentPopup';
import TranslatedCommentPopup from '../components/TranslatedCommentPopup';
import Service from '../services/MeetingService';
import { AlertOneButton, AlertTwoButton } from '../components/Alert';
import {setUserInfo} from "./Login";

import AudioPlayer from './AudioPlayer';

import './Comments.css';

class OrderMenu extends Component {

  constructor(props) {
    super(props);

    this.state = {
      toggle: false,
    };
    this.bounce = false;
  }

  UNSAFE_componentWillMount() {
    this.handler = this.onClickOutside.bind(this);
    document.addEventListener('click', this.handler);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handler);
  }

  onClickOutside(event) {
    if (!this.bounce && this.state.toggle) {
      this.toggleSelectBox(event);
    }
    this.bounce = false;
  }

  toggleSelectBox(event) {
    const toggle = this.state.toggle;
    this.setState({ toggle: !this.state.toggle });
    return toggle;
  }

  onClickButton(event) {
    event.stopPropagation();
    event.preventDefault();
    this.shield();
    this.toggleSelectBox(event);
  }

  shield() {
    this.bounce = true;
  }

  render() {
    if (this.props.finished) {
      return (
        <div className="order" onClick={ this.onClickButton.bind(this) }>
          <div className="icon-order"/><span>{ this.props.order === 'desc' ? '降順' : '昇順' }</span>
          <div className="menus" hidden={ !this.state.toggle }>
            <div className="order-desc" onClick={ e => { this.props.onOrder(e, 'desc') }}>
              <div className={ ClassNames({'icon-check-small' : this.props.order === 'desc' }) }/>降順
            </div>
            <div className="order-asc" onClick={ e => { this.props.onOrder(e, 'asc') }}>
              <div className={ ClassNames({'icon-check-small' : this.props.order === 'asc' }) }/>昇順
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

OrderMenu.PropType = {
  order: PropTypes.string,
  finished: PropTypes.bool,
  onOrder:PropTypes.func
};

class Comments extends Component {

  constructor(props) {
    super(props);

    this.state = {
      // loadBottomCommentsでコメント取得中かどうか
      waiting: false,
      comments: [],
      filter: props.filter,
      keywords: props.keywords,
      selected: -1,
      direction: props.direction,
      // コメントのリセットを要求中かどうか。
      requestingToResetComment: false,
      withplayer: false,
      commentlengthFilter: props.commentLengthFilter,
      commentlengthFilterDigit: props.commentLengthFilterDigit,
      speakerSelectionFilter: false,
      translate: '',
      autoCommentUpdate: true,
      hasError: false,
      userInfo: null,
      customer_id: null,
      commentEditMode: false,
      meetingStatus: props.status,
    };
    // console.log("Comments.constructor filter=" + props.filter
    //     + " keywords=" + props.keywords
    //     + " direction=" + props.direction
    //     + " commentLengthFilter=" + props.commentLengthFilter
    //     + " commentLengthFilterDigit=" + props.commentLengthFilterDigit
    // );

    // スクロール中かどうか
    this.scrolling = false;

    // スクロール停止検出用のタイマー
    this.scrollingTimer = null;

    // スクロール時に呼び出すメソッド
    this.onScrolling = this.onScrolling.bind(this);

    // コメント編集時に呼び出すメソッド
    this.onChange = this.onChange.bind(this);

    // 翻訳コメント編集時に呼び出すメソッド
    this.onChangeTranslatedComment = this.onChangeTranslatedComment.bind(this);
  }

  componentDidMount() {
    // 「this.refs.scroller」が何を指すかは、「ref="scroller"」を検索すれば見つかる。
    this.scroller = this.refs.scroller;
    this.scroller.addEventListener('scroll', this.onScrolling, false);

    let service = new Service();

    service.user(sessionStorage.getItem('id')).subscribe(
        payload => {
          this.userInfo(payload);
          this.setState({ userInfo: payload });
          this.setState({ customer_id: this.state.userInfo.customer_id });
        },
        error => {
          this.error(error);
        }
    );

    this.loadTopComments();
  }

  componentWillUnmount() {
    this.scroller.removeEventListener('scroll', this.onScrolling);
    this.unsubscribe();
  }

  UNSAFE_componentWillUpdate() {
  }

  componentDidUpdate() {
  }

  /**
   * コメント購読開始。
   */
  subscribe() {
    if (!this.subscriber) {
      this.subscriber = Observable.interval(3000).subscribe(
        () => {
          if (this.scrolling || this.state.hasError) {
            return;
          }
          const state = this.shouldTryScroll();
          if (state === 1
              && this.state.autoCommentUpdate === true
              && this.state.commentEditMode === false) {
                this.loadTopComments();
          } else {
            this.loadCurrentComments();
          }
        }
      );
    }

  }

  /**
   * コメント購読停止。
   */
  unsubscribe() {
    if (this.subscriber) {
      this.subscriber.dispose();
    }
  }

  userInfo(user) {
    this.setState({ username: user.user_name });
    setUserInfo(user);
  }

  /**
   * スクロール時に呼ばれる関数
   * @param event
   */
  onScrolling(event) {
    this.scrolling = true;
    if (this.scrollingTimer) {
      clearTimeout(this.scrollingTimer);
    }
    const self = this;
    this.scrollingTimer = setTimeout(() => {
      self.scrolling = false;
      self.scrollingTimer = null;
    }, 500);

    // スクロールが一番下付近なら、次の内容を取得
    const state = this.shouldTryScroll();
    if (state < 0) {
      this.loadBottomComments();
    }
  }

  /**
   * スクロール状況の取得
   * @returns {number} 1は一番上の状態。-1は一番下付近？。0はスクロール途中
   */
  shouldTryScroll() {
    // 表示上の高さとコンテンツの高さを比較し、スクロールする必要があるかどうか判断
    if (this.scroller.offsetHeight < this.scroller.scrollHeight) {
      if (this.scroller.scrollTop <= 0) {
        // 一番上
        return 1;
      } else if ((this.scroller.scrollTop + this.scroller.offsetHeight + 3000) >= this.scroller.scrollHeight) {
        // 一番下付近
        return -1;
      }
    } else if (this.scroller.scrollTop <= 0) {
      return 1;
    }
    return 0;

  }

  /**
   * 半端な表示も含めて、画面上の一番上に表示しているコメントのインデックスを返す
   *
   * @returns {number}
   */
  findUpdateFromIndex() {
    // scroller_topはViewport座標系です。
    // Viewportはブラウザのウィンドウの左上が常に原点です。
    // ブラウザのウィンドウの高さが低い場合、ページ全体がスクロールします。
    // そのとき、viewport原点はdocument原点と一致しないので注意が必要です。
    let scroller_top = this.scroller.getBoundingClientRect().top;
    // console.log(`scroller_top: ${scroller_top}`);
    // console.log(`scroller_scrollTop: ${this.scroller.scrollTop}`);

    // 元々のアルゴリズムでは this.scroller.children[i].clientHeight を足してscrollTopと比較していが、計算が合わない。
    // Viewport(≒画面)上のどこに配置されているかを表す getBoundingClientRect() を使うアルゴリズムに変更
    for (let i = 0; i < this.scroller.children.length; i++) {
      let children_bottom = this.scroller.children[i].getBoundingClientRect().bottom;
      // let comment = this.state.comments[i];
      // console.log(`children: ${i} ${children_bottom} ${comment.id} ${comment.comment.substr(0, 20)}`);

      if (children_bottom >= scroller_top) {
        return i;
      }
    }
    return -1;
  }

  /**
   * 途中領域のコメントを取得し、取得した内容で置き換える。
   */
  loadCurrentComments() {
    if (this.state.waiting) {
      return;
    }

    // 半端な表示も含め、一番上に表示しているindexを取得
    const index = this.findUpdateFromIndex();
    if (index < 0) {
      return;
    }

    let id;
    // 検索条件変更時にはコメントクリアされる　→ commentsの中身がない状態。その場合はidを0にする
    if(this.state.comments.length !== 0) {
      id = this.state.comments[index].id;
    } else {
      id = 0;
    }

    // 一番上に表示しているidから一定数(現状ではサーバー側でデフォルト100件)のコメントを取得
    new Service().comments(this.props.id,
        id,
        this.filter2mark(this.state.filter),
        this.state.keywords,
        this.state.direction,
        this.state.translate,
        this.state.commentlengthFilter,
        this.state.commentlengthFilterDigit,
        this.state.speakerSelectionFilter)
      .subscribe(
        payload => {
          if (payload.data.length > 0) {

            // console.log(`loaded count: ${payload.data.length}`);
            this.savePlayingComment(payload);

            // 既存コメント(0〜index未満)と取得したコメントを連結して、更新
            // sliceするのでindex以降は破棄されるので注意
            var comments = [
              ...this.state.comments.slice(0, index),
              ...payload.data,
            ];

            // console.log(`コメント更新: index=${index}以降${payload.data.length}件のコメントを更新`);

            this.setState({
              waiting: false,
              requestingToResetComment: false,
              comments: comments,
              hasError: false
            });

          } else {
            this.setState({
              waiting: false,
              hasError: false
            });
          }
        },
        error => {
          this.setState({
            waiting: false,
            hasError: true
          });
          this.props.handlers.error(error);
        }
      );

  }

  /**
   * 一番下の領域よりも先のコメントを取得し、現在保持してるコメントに追加する。
   */
  loadBottomComments() {

    if (this.state.waiting) {
      return;
    }
    const id = this.state.comments.length > 0 ? this.state.comments[this.state.comments.length - 1].id : null;
    this.setState({ waiting: true });

    new Service().comments(this.props.id,
        -id,
        this.filter2mark(this.state.filter),
        this.state.keywords,
        this.state.direction,
        this.state.translate,
        this.state.commentlengthFilter,
        this.state.commentlengthFilterDigit,
        this.state.speakerSelectionFilter)
      .subscribe(
        payload => {
          if (payload.data.length > 0) {
            // console.log(`loaded count: ${payload.data.length}`);

            var comments = this.state.comments;
            this.savePlayingComment(payload);

            // 既存コメントと取得データを結合して、更新
            comments = comments.concat(payload.data);
            this.setState({
              waiting: false,
              requestingToResetComment: false,
              comments: comments,
              hasError: false
            });

          } else {
            this.setState({
              waiting: false,
              hasError: false
            });
          }
        },
        error => {
          this.setState({
            waiting: false,
            hasError: true
          });
          this.props.handlers.error(error);
        }
      );
  }

  /**
   * 一番上の領域のコメントを取得し、取得した内容で置き換える。
   * @param from
   * @param filter
   * @param keywords
   * @param direction コメント取得のオーダー順(asc:昇順。新しいIDが下 desc:降順。新しいIDが上)
   * @param translate
   * @param commentlengthFilter
   * @param commentlengthFilterDigit
   * @param speakerSelectionFilter
   */
  loadTopComments(from = 0,
                  filter = this.state.filter,
                  keywords = this.state.keywords,
                  direction = this.state.direction,
                  translate = this.state.translate,
                  commentlengthFilter = this.state.commentlengthFilter,
                  commentlengthFilterDigit = this.state.commentlengthFilterDigit,
                  speakerSelectionFilter = this.state.speakerSelectionFilter) {

    if (this.scrolling || this.state.waiting) {
      return;
    }
    const mark = this.filter2mark(filter);
    new Service().comments(this.props.id,
        from,
        mark,
        keywords,
        direction,
        translate,
        commentlengthFilter,
        commentlengthFilterDigit,
        speakerSelectionFilter)
      .subscribe(
        payload => {
          this.subscribe();
          this.savePlayingComment(payload);

          // コメントは取得したデータで更新
          this.setState({
            requestingToResetComment: false,
            comments: payload.data,
            hasError: false
          });
        },
        error => {
          this.props.handlers.error(error);
          this.setState({
            hasError: true
          });
        }
      );
  }

  /**
   * 発話再生位置の維持
   * this.state.commentsで発話再生中のコメントがあれば、
   * 対になるpayloadデータの発話再生中をtrueにする。
   * @param payload
   */
  savePlayingComment(payload) {
    // コメントが空なら終了
    if (!this.state.comments) {
      return;
    }

    // 発話再生中コメントの検索
    let playing_comment_index = this.state.comments.findIndex(comment => {
      return comment.playing === true;
    });
    if (playing_comment_index === -1) {
      return;
    }

    let playing_comment_id = this.state.comments[playing_comment_index].id;

    // 発話再生中のコメントと同一コメントIDのpayloadデータを検索
    let payload_data_index = payload.data.findIndex(comment => {
      return comment.id === playing_comment_id;
    });
    if (payload_data_index === -1) {
      return;
    }

    // 発話再生中フラグを立てる
    payload.data[payload_data_index].playing = true;
  }

  filter2mark(filter) {
    switch (filter) {
      case 0: // all
        return null;
      case 1: // marked
        return 1;
      case 2: // not marked
        return 0;
      default:
        return null; // all
    }
  }

  comments() {
    var comments = [];
    if (!this.state.comments || this.state.comments.length <= 0) {
      if (this.state.requestingToResetComment) {
        return(
            <div className="background">
              <div/>
            </div>
        );
      }
      if ((this.state.keywords !== null && this.state.keywords !== "") || this.state.filter > 0) {
        return(
          <div className="background">
            <div>一致する発話は見つかりませんでした。</div>
          </div>
        );
      } else {
        return(
          <div className="background">
            <div>このエリアに、発話の内容と、<br />送信された写真の一覧が表示されます。</div>
          </div>
        );
      }
    } else {
      let prevName = '';
      let isEven = true;
      this.state.comments.forEach(comment => {
        if (comment.name !== prevName) {
          isEven = !isEven;
        }
        prevName = comment.name;

        comments.push(
          <div key={ comment.id } className="comment-area">
            <Comment
            key={ comment.id }
            id={ comment.id }
            mark={ +(comment.mark) }
            name={ comment.name }
            comment={ comment.comment }
            translatedComment={ comment.translated_comment }
            timestamp={ comment.timestamp }
            utterance={ comment.utterance }
            playing={ comment.playing === true }
            onStartPlaying={ this.onStartPlaying.bind(this) }
            onPopup={ this.onPopupCommentEditor.bind(this) }
            onChange={ this.onChange }
            onPopupTranslatedComment={ this.onPopupTranslatedCommentEditor.bind(this) }
            onChangeTranslatedComment={ this.onChangeTranslatedComment }
            onDelete={ this.onDelete.bind(this) }
            enabled={ this.props.enabled }
            rowColor={ ClassNames({even: isEven, odd: !isEven}) }
            isSelected={ ( comment.id === this.state.selected ) }
            onSelect={ this.onSelect.bind(this) }
            isBroadcaster={ this.props.isBroadcaster }
            minutesType={ this.props.minutesType }
            mic_type = { comment.mic_type}
            selected_speaker_user_id = { comment.selected_speaker_user_id }
            identified_speaker_user_id = { comment.identified_speaker_user_id }
            speaker_similarity = { comment.speaker_similarity }
            isDropped={ comment.is_dropped }
            commentEditMode={ this.state.commentEditMode }
            onCommentEditModeChanged={ this.onCommentEditModeChanged.bind(this) }
            meetingStatus={ this.props.status }
        />
            <hr/>
          </div>);
      });
    }
    return comments;
  }

  spinner() {
    if (this.state.waiting) {
      return <Spinner />;
    }
    return null;
  }

  /**
   * 降順/昇順変更時処理
   * @param event
   * @param order
   */
  onChangeOrder(event, order) {
    event.preventDefault();
    this.setState({ direction: order });
    this.props.memoOrder();
    this.resetComments();
  }

  resetComments(){
    this.refs.player.stop();
    this.setState({
      comments: [],
      requestingToResetComment: true
    });
  }

  /**
   * 「X文字以上の発言のみ表示」のチェックボックス変更時処理
   * @param event
   */
  onClickCheckbox(event) {
    this.setState({ commentlengthFilter: event.target.checked });
    this.resetComments();
  }

  /**
   * 「話者選択した発話のみ表示」のチェックボックス変更時処理
   * @param event
   */
  onClickSpeakerSelectionCheckbox(event) {
    this.setState({ speakerSelectionFilter: event.target.checked });
    this.resetComments();
  }

  /**
   * 「X文字以上の発言のみ表示」の数値変更時処理
   * @param event
   */
  onChangeLengthText(event) {
    let value = event.target.value;
    if((value.length === 0) || isNaN(value)) {
      value = "";
    } else {
      value = String(Number(value));
    }
    this.setState({ commentlengthFilterDigit: value });
    this.resetComments();
  }

  onChangeTranslate(e) {
    this.setState({ translate: e.target.value });
  }

  onClickAutoCommentUpdateButton(e) {
    this.setState({ autoCommentUpdate: !this.state.autoCommentUpdate });
  }

  autoCommentUpdateButton() {
    let classname;
    if(this.state.autoCommentUpdate) {
      // 自動の場合は、手動にできることを表示する
      classname　= 'button-2 auto-comment-update-button';
    } else {
      classname　= 'button-2-a25 auto-comment-update-button';
    }
    return <button onClick={ this.onClickAutoCommentUpdateButton.bind(this) } className={ classname }>自動</button>
  }

  translate() {
    if (this.props.canTranslate && (this.props.isBroadcaster === false)) {
      if (this.state.customer_id === 340)
      {
        return (
          <div className="select">
            <select value={ this.state.translate } onChange={ e => { this.onChangeTranslate(e) } } >
            <option value="">翻訳</option>
            <option value="en">English</option>
            <option value="ja">日本語</option>
            <option value="ko">한국어</option>
            </select>
            <button className="button-select" ><div className="icon-inverted-triangle"></div></button>
          </div>
        )
      } else {
        return (
          <div className="select">
            <select value={ this.state.translate } onChange={ e => { this.onChangeTranslate(e) } } >
            <option value="">翻訳</option>
            <option value="en">English</option>
            <option value="ja">日本語</option>
            </select>
            <button className="button-select" ><div className="icon-inverted-triangle"></div></button>
          </div>
      )
      }
    } else {
      return null;
    }
  }

  render() {
    this.props.notifyParameter(
        this.state.filter,
        this.state.keywords,
        this.state.direction,
        this.state.commentlengthFilter,
        this.state.commentlengthFilterDigit
    );

    return (
      <div className="comments">
        <div className="header">
          <div className="functions">
            <div className="search">
              <input type="search" ref="keywords" placeholder="発言内容、発言者" onKeyDown={ this.onKeyDownKeywords.bind(this) } onChange={ this.onChangeKeywords.bind(this) }
                defaultValue={ this.state.keywords }
              />
              <button onClick={ this.onSearch.bind(this) } className="button-select"><div className="icon-search"></div></button>
            </div>
          </div>
          <div className="functions">
            <div className="commentfilter filter">
                <label><input type="checkbox" className="btnpt2" defaultChecked={ this.state.commentlengthFilter } onClick={ e => { this.onClickCheckbox(e) } }/>
                <div className="check"></div>
                </label>
                <input type="text" defaultValue={ this.state.commentlengthFilterDigit } size="2" maxLength="2" onChange={ this.onChangeLengthText.bind(this) }/>
                <label>文字以上の発言のみ表示</label>
            </div>
            { this.translate() }
          </div>
          <div className="functions">
            <div className="speaker-selection-filter filter">
              <label><input type="checkbox" className="btnpt2" defaultChecked={ this.state.speakerSelectionFilter } onClick={ e => { this.onClickSpeakerSelectionCheckbox(e) } }/>
                <div className="check"/>
              </label>
              <label>話者識別登録した発言のみ表示</label>
            </div>
            { this.autoCommentUpdateButton() }
          </div>
          <AudioPlayer ref="player"
            hidden={ this.state.withplayer }
            simpleIf={false}
            existCommentHasUtterance={ this.existCommentHasUtterance.bind(this) }
            loadUtteranceTask={ this.loadCommentHasUtteranceTask.bind(this) }
            onStartFinishUtterance={ this.onStartFinishUtterance.bind(this) }
            onFinish={ this.onFinishPlaying.bind(this) }
            />
        </div>
        <div className="body" ref="scroller">
          { this.comments() }
        </div>
        <div className="footer">
          <div className="comment-all" onClick={ e => { this.onChangeFilter(e, 0) }}>
            <div className={
              ClassNames({
                'icon-comment-all' : this.state.filter === 0 ? true : false,
                'icon-comment-all-not-selected' : this.state.filter === 1 ? true : false
              })}/><span>すべて</span>
          </div>
          <div className="comment-mark" onClick={ e => { this.onChangeFilter(e, 1) }}>
            <div className={
              ClassNames({
                'icon-star-enabled' : this.state.filter === 1 ? true : false,
                'icon-star-disabled' : this.state.filter === 0 ? true : false
              })}/><span>重要発言</span>
          </div>
          <OrderMenu
            order={ this.state.direction }
            finished={ this.props.finished }
            onOrder={ this.onChangeOrder.bind(this) }
          />
        </div>
      </div>
    );
  }

  onPopupCommentEditor(detail) {
    this.props.handlers.showPopup(
      <CommentPopup
        id={ detail.id }
        timestamp={ detail.timestamp }
        name={ detail.name }
        comment={ detail.comment }
        utterance={ detail.utterance }
        close={ this.props.handlers.hidePopup }
        onChange={ this.onChange }
        mic_type={ detail.mic_type }
        selected_speaker_user_id={ detail.selected_speaker_user_id }
        handlers={ this.props.handlers }
      />
    );
  }

  onPopupTranslatedCommentEditor(detail) {
    this.props.handlers.showPopup(
      <TranslatedCommentPopup
        id={ detail.id }
        timestamp={ detail.timestamp }
        name={ detail.name }
        comment={ detail.comment }
        translatedComment={ detail.translatedComment }
        close={ this.props.handlers.hidePopup }
        onChange={ this.onChangeTranslatedComment }
      />
    );
  }

  onChange(detail) {

    var index = this.state.comments.findIndex(comment => {
      return comment.id === detail.id;
    });

    if (index >= 0) {
      var comment = this.state.comments[index];
      var form = new FormData();

      if (detail.mark !== undefined) {
        form.append('mark', detail.mark);
      }
      if (detail.name) {
        form.append('name', detail.name);
      }
      if (detail.comment) {
        form.append('comment', detail.comment);
      }
      // selected_speaker_user_idは0のケースもあるため、mit_typeで区別
      if (detail.mic_type) {
        form.append('selected_speaker_user_id', detail.selected_speaker_user_id);
      }
      if (detail.should_create_guest) {
        form.append('should_create_guest', "true");
        form.append('guest_name', detail.guest_name);
      }
      if (detail.is_dropped !== undefined) {
        form.append('is_dropped', detail.is_dropped);
      }
      new Service().updateComment(this.props.id, comment.id, form)
        .subscribe(
          payload => {

            // 該当コメントを入れ替えて、更新
            // 注意：
            // ・翻訳文があると翻訳文が消える不具合がある(commentsリクエストが複雑化しているが、追従できていない)
            // ・おそらくthis.state.commentsが更新されると表示がおかしくなる不具合がある
            // ・下記処理を消すと、重要マーククリック時の反映が遅くなる(別にケアしたほうがいい？)
            var comments = [
              ...this.state.comments.slice(0, index),
              payload,
              ...this.state.comments.slice(index + 1)
            ];
            this.setState({ comments: comments });

          },
          error => {
            this.props.handlers.error(error);
          }
        );
    }
  }

  onChangeTranslatedComment(detail) {

    const index = this.state.comments.findIndex(comment => {
      return comment.id === detail.id;
    });

    if (index >= 0) {
      const comment = this.state.comments[index];
      const form = new FormData();
      if (detail.translatedComment) {
        form.append('translated_comment', detail.translatedComment);
      }
      this.props.handlers.showProgress();
      new Service().updateTranslatedComment(this.props.id, comment.translated_comments_id, form)
        .finally(() => { this.props.handlers.hideProgress(); })
        .subscribe(
          payload => {
            //
          },
          error => {
            this.props.handlers.error(error);
          }
        );
    }
  }

  /**
   * すべて/重要発言 フィルタークリック時の処理
   * @param event
   * @param filterValue
   */
  onChangeFilter(event, filterValue) {
    this.setState({ filter: filterValue });
    this.resetComments();
  }

  onSelect(id) {
    this.setState({ selected: id });
  }

  onCommentEditModeChanged(mode) {
    this.refs.player.stop();
    this.setState({ commentEditMode: mode })
  }

  onDelete(id) {
    this.props.handlers.showPopup(
      <AlertTwoButton title="発言の削除" message="発言を削除しますか？" cancel={ this.props.handlers.hidePopup } okay={ () => { this.deleteComment(id) } }  okayButtonText="削除する" />
    );
  }

  deleteComment(id) {
    this.props.handlers.hidePopup();
    var index = this.state.comments.findIndex(comment => {
      return comment.id === id;
    });

    if (index < 0) {
      return;
    }

    new Service().deleteComment(this.props.id, id)
      .subscribe(
        payload => {

          // 該当コメントを取り除いて、更新
          var comments = [
            ...this.state.comments.slice(0, index),
            ...this.state.comments.slice(index + 1)
          ];
          this.setState({ comments: comments });

        },
        error => {
          this.props.handlers.error(error);
        }
      );
  }

  onChangeKeywords(event) {
    event.preventDefault();
    const keywords = this.refs.keywords.value || null;
    if(keywords === null) {
      this.setState({ keywords: keywords });
      this.resetComments();
      // 他の検索処理では、keywordsがnullの場合に明示的にリスト更新するケースがある。
      // コメントについては、定期定期に再取得しているため手動更新は不要
    }
  }

  onKeyDownKeywords(event) {
    if (event.which === 0xd) {
      event.preventDefault();
      this.setState({ keywords: this.refs.keywords.value });
      this.resetComments();
    }
  }

  /**
   * 検索アイコンクリック時の処理
   * @param event
   */
  onSearch(event) {
    event.preventDefault();
    const keywords = this.refs.keywords.value || null;
    this.setState({ keywords: keywords });
    this.resetComments();
  }

  // コンポーネント外からrefs経由で呼ばれる
  onCopyAndPasteAllComments(event) {
    event.preventDefault();
    if (this.hasSelectionInMinute()) {
      this.props.handlers.showPopup(
        <AlertTwoButton title="発言のコピー" message="表示している発言を議事録にコピーします。表示内容と挿入位置を確認してください。" cancel={ this.props.handlers.hidePopup } okay={ this.copyAndPasteAllComments.bind(this) } />
      );
    } else {
      this.props.handlers.showPopup(
        <AlertOneButton title="発言のコピー" message="挿入位置を設定してください。" okay={ this.props.handlers.hidePopup } />
      );
    }
  }

  hasSelectionInMinute() {

    var selection = window.getSelection();

    if (selection != null) {
      var node = selection.baseNode ? selection.baseNode : selection.focusNode; // selection.focusNode for IE11
      if (node) {
        return node.parentNode.offsetParent.className === "body select";
      }
    }
    return false;
  }

  copyAndPasteAllComments() {
    this.props.handlers.hidePopup();
    new Service().text_comments(this.props.id,
        this.filter2mark(this.state.filter),
        this.state.keywords,
        this.state.translate,
        this.state.commentlengthFilter,
        this.state.commentlengthFilterDigit,
        this.state.speakerSelectionFilter)
      .subscribe(
        payload => {
          if (this.hasSelectionInMinute() && payload.comments) {
            var range = document.getSelection().getRangeAt(0);
            range.insertNode(range.createContextualFragment(payload.comments));
            this.props.paste();
          }
        },
        error => {
          this.props.handlers.error(error);
        }
      );
  }

  onStartPlaying(utterance) {
    this.setState({ withplayer: true });
    this.refs.player.play(utterance);
  }

  /**
   * AudioPlayerのplay呼び出し時のコールバック。
   * 連続再生時に次の発話がplayされたときも呼ばれる。
   * 連続再生で最後の場合などには、このメソッドではなく、onFinishPlayingが呼ばれる。
   * @param finished_comment_id 再生が終了したコメントID。連続再生OFF、連続再生の初回はnull
   * @param play_comment_id これから再生するべきコメントID
   * @param scrollTo 現状の処理では常にtrueになる？
   */
  onStartFinishUtterance(finished_comment_id, play_comment_id, scrollTo) {
    if (finished_comment_id === play_comment_id) {
      return;
    }
    const play_comment_index = this.state.comments.findIndex(comment => {
      return comment.id === play_comment_id;
    });
    const play_comment = this.state.comments[play_comment_index];
    if (!play_comment) {
      console.warn(`not found the target index: ${play_comment_index}`);
      return;
    }

      // コメントは対象をplaying=trueにして置き換えて、更新
      // stateを直接さわれないため、回りくどい方法を採用
      play_comment.playing = true;
      let comments = [
        ...this.state.comments.slice(0, play_comment_index),
        play_comment,
        ...this.state.comments.slice(play_comment_index + 1)
      ];
      if (finished_comment_id) {
        const finished_comment_index = this.state.comments.findIndex(comment => {
          return comment.id === finished_comment_id;
        });
        const finished_comment = this.state.comments[finished_comment_index];
        if (finished_comment) {
          // コメントは対象をplaying=falseにして置き換えて、更新
          // stateを直接さわれないため、回りくどい方法を採用
          finished_comment.playing = false;
          // this.state.commentsを対象に再スライスしているけど大丈夫？
          // UIを見る限りでは、動作しているように見えるものの。。。
          comments = [
            ...this.state.comments.slice(0, finished_comment_index),
            finished_comment,
            ...this.state.comments.slice(finished_comment_index + 1)
          ];
        } else {
          console.warn(`not found the target index: ${finished_comment_index}`);
        }
      }
      this.setState({ comments: comments });

      if (finished_comment_id !== null && scrollTo) {
        this.scrollToComment(play_comment_id);
      }
  }

  /**
   * 指定コメントへのスクロール
   * @param comment_id
   */
  scrollToComment(comment_id) {
    let comment_index = this.state.comments.findIndex(comment => {
      return comment.id === comment_id;
    });
    if (comment_index === -1) {
      // 手持ちのコメントに該当がIDがない場合の処理なし。
      // 一定間隔でコメントを更新していることもあり、-1になることは無いはず。
      return;
    }
    // スクロールする要素のRect。Viewport座標系。
    const scroll_area_rect = this.scroller.getBoundingClientRect();

    // コメントのRect。Viewport座標系
    // (this.state.comments[]とthis.scroller.children[]の要素数と並びが一致する前提のコード)
    const comment_rect = this.scroller.children[comment_index].getBoundingClientRect();

    if (scroll_area_rect.top <= comment_rect.top
        && comment_rect.bottom <= scroll_area_rect.bottom) {
      // 指定されたコメントは画面内に最初から最後まで表示されているので、スクロール不要
      return;
    }

    // スクロールが表示される要素の高さ
    const scroll_area_height = scroll_area_rect.bottom - scroll_area_rect.top;

    // target_topは移動先の目標となるtop。Viewport座標系。
    // 目標は、移動先のコメントのbottomから、「スクロールが表示される要素の高さ」の半分だけ上の部分
    const target_top = comment_rect.bottom - scroll_area_height / 2;

    // relative_yは、scroll_area_rect.topを基準にした相対Y座標 ＝ スクロールするべき量
    const relative_y = target_top - scroll_area_rect.top

    // console.log(`scroll_area_rect.top=${scroll_area_rect.top}`);
    // console.log(`scroll_area_rect.bottom=${scroll_area_rect.bottom}`);
    // console.log(`scroll_area_height=${scroll_area_height}`);
    // console.log(`comment_rect.bottom=${comment_rect.bottom}`);
    // console.log(`target_top=${target_top}`);
    // console.log(`relative_y=${relative_y}`);
    // console.log(`scrollTop=${this.scroller.scrollTop}`);

    // スクロール
    this.scroller.scrollTop = this.scroller.scrollTop + relative_y;
  }

  /**
   * AudioPlayerでstopが呼ばれたときのコールバック
   */
  onFinishPlaying() {
    var index = this.state.comments.findIndex(comment => {
      return comment.playing === true;
    });
    if (index >= 0) {

      // コメントは対象をplaying=trueにして置き換えて、更新
      // stateを直接さわれないため、回りくどい方法を採用
      var target = this.state.comments[index];
      target.playing = false;
      var comments = [
        ...this.state.comments.slice(0, index),
        target,
        ...this.state.comments.slice(index + 1)
      ];
      this.setState({ withplayer: false, comments: comments });

    } else {
      this.setState({ withplayer: false });
    }
  }

  existCommentHasUtterance(comment_id, direction) {
    var index = this.state.comments.findIndex(comment => {
      return comment.id === comment_id;
    });
    if (index >= 0) {
      var i = 0;
      if ((direction === 'next' && this.state.direction === 'desc')
        || (direction === 'prev' && this.state.direction !== 'desc')) {
        i = index - 1;
        index = -1;
        for (; i >= 0; --i) {
          const comment = this.state.comments[i];
          if (comment.utterance && comment.utterance.url) {
            index = i;
            break;
          }
        }
      } else {
        i = index + 1;
        index = -1;
        for (; i < this.state.comments.length; ++i) {
          const comment = this.state.comments[i];
          if (comment.utterance && comment.utterance.url) {
            index = i;
            break;
          }
        }
      }
      if (index >= 0) {
        return this.state.comments[index];
      }
    }
    return null;
  }

  loadCommentHasUtteranceTask(comment_id, direction) {
    return new Service().comment_has_utterance(
      this.props.id,
      comment_id,
      direction,
      this.filter2mark(this.state.filter),
      this.state.keywords
    );
  }

}

Comments.PropType = {
  // 会議ID
  id: PropTypes.number,

  // 基礎的な共通処理を呼ぶためのハンドラー
  handlers: PropTypes.object,

  // 会議終了済みかどうか
  finished: PropTypes.bool,

  enabled: PropTypes.bool,

  // 顧客種別が放送局かどうか
  isBroadcaster: PropTypes.bool,

  // 議事録の形式
  minutesType: PropTypes.number,

  // 翻訳ができるかどうか
  canTranslate: PropTypes.bool,

  // パラメータを通知するコールバック
  // コールバックは描画毎に呼ばれる。GUI上で選んでいる内容を親に通知
  notifyParameter: PropTypes.func.isRequired,

  // フィルター状態の初期値(写真タブとフィルター状態を共有するため、受け取る)
  filter: PropTypes.number.isRequired,

  // 検索キーワードの初期値(※初期値は写真タブに切り替えても維持するために受け取る)
  keywords: PropTypes.string.isRequired,

  // 昇順/降順の初期値
  direction: PropTypes.string.isRequired,

  // X文字以下を表示する/しないの初期値
  commentLengthFilter: PropTypes.bool.isRequired,

  // X文字以下の文字数の初期値
  commentLengthFilterDigit: PropTypes.number.isRequired,

  // 順番変更時のコールバック。。。
  memoOrder: PropTypes.func,

  // SS依頼時にコメントの編集・削除を行わせない為、会議ステータスを子のコンポーネントに引き継ぐ形で処理している
  status: PropTypes.number
};

export default Comments;
