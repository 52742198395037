import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Service from '../services/MeetingService';
import { AlertTwoButton } from '../components/Alert';
import CalculationDictionaryWordPopup from './CalculationDictionaryWordPopup';
import { isAdmin } from './AccountPopup';
import PaginationBar from "../components/PaginationBar";

import './CalculationDictionaryWords.css';

class CalculationDictionaryWord extends Component {

  render() {
    return (
      <tr>
        <td className="word">{ this.props.word }</td>
        <td className="functions">
          <div>
            <button style={{ visibility: this.props.canEdit ? 'visible' : 'hidden' }} className="icon-pencil btnicn" onClick={ e => { this.props.popupUpdateWord(e, this.props.id) } } />
            <button style={{ visibility: this.props.canEdit ? 'visible' : 'hidden' }} className="icon-trash btnicn" onClick={ e => { this.props.popupDeleteWord(e, this.props) } } />
          </div>
        </td>
      </tr>
      );
  }
}

CalculationDictionaryWord.PropType = {
  id: PropTypes.number,
  word: PropTypes.string,
  popupUpdateWord:PropTypes.func,
  popupDeleteWord:PropTypes.func,
  canEdit: PropTypes.number
};

export class CalculationDictionaryWords extends Component {

  constructor(props) {
    super(props);

    this.state = {
      id: this.props.id,
      dictionary_prepared: null,
      total: 0,
      per_page: 20,
      current: 0,
      last: 0,
      details: null
    };
    this.getCurrentPage();
  }

  canEditWord() {
    if (isAdmin()) {
      return true;
    }

    return false;
  }

  isButtonEnabled() {
    if(this.state.total >= 100) {
      return false;
    }
    return this.canEditWord();
  }

  render() {
    return (
      <div className="calculation-dictionary-words">
        <div className="words-header">
          <div className="title">{ this.props.dictionaryName }</div>
          <button disabled={ !this.isButtonEnabled() } className="button-1" onClick={ this.popupCreateWord.bind(this) } >単語を追加</button>
        </div>
        { this.details() }
        <PaginationBar
            total={ this.state.total }
            per_page={ this.state.per_page }
            current={ this.state.current }
            last={ this.state.last }
            onClick={ this.getCurrentPage.bind(this) }
        />
      </div>

    );
  }

  componentDidUpdate() {
    if (this.props.id !== this.state.id) {
      this.setState({
        id: this.props.id,
        current: 0
      });
      this.getCurrentPage();
    }
  }

  getCurrentPage(page = null) {
    this.props.handlers.showProgress();
    new Service().calculationDictionaryWords(this.props.id, page === null ? this.state.current : page)
      .finally(() => { this.props.handlers.hideProgress(); })
      .subscribe(
        payload => {
          this.setState({
            total: payload.total,
            per_page: payload.per_page,
            current: payload.current_page,
            last: payload.last_page,
            details: payload.data
          });
        },
        error => {
          if (error.response.status === 404) {
            this.setState({
              total: 0,
              per_page: 20,
              current: 0,
              last: 0,
              details: []
            });
          } else {
            this.props.handlers.error(error);
          }
        }
      );
  }

  words() {
    const words = [];
    this.state.details.forEach(word => {
      words.push(
        <CalculationDictionaryWord
          key={ word.id }
          id={ word.id }
          word={ word.word }
          popupUpdateWord={ this.popupUpdateWord.bind(this) }
          popupDeleteWord={ this.popupDeleteWord.bind(this) }
          canEdit={ this.canEditWord() }
        />);
    });
    return words;
  }

  details() {
    if (this.state.details) {
      if (this.state.details.length > 0) {
        return (
          <div className="words-list">
            <table>
              <thead>
                <tr>
                  <th className="word">単語</th>
                  <th className="functions"/>
                </tr>
              </thead>
              <tbody>
                { this.words() }
              </tbody>
            </table>
          </div>
        );
      } else {
        return (
          <div>
            <p>登録済み単語はありません。</p>
            </div>
          );
      }
    }
    return null;
  }

  popupCreateWord(event) {
    event.preventDefault();
    this.props.handlers.showPopup(<CalculationDictionaryWordPopup title={ '単語の追加' } dictionaryId={ this.props.id } close={ this.closePopup.bind(this) } error={ this.props.handlers.error }  handlers={ this.props.handlers } />);
  }

  popupUpdateWord(event, id) {
    event.preventDefault();
    this.props.handlers.showPopup(<CalculationDictionaryWordPopup title={ '単語の変更' } dictionaryId={ this.props.id } id={ id } close={ this.closePopup.bind(this) } error={ this.props.handlers.error }  handlers={ this.props.handlers } />);
  }

  popupDeleteWord(event, detail) {
    event.preventDefault();
    const message = `「${detail.word}」を削除しますか？`;
    this.props.handlers.showPopup(
      <AlertTwoButton title="単語の削除" message={ message } cancel={ this.props.handlers.hidePopup } okay={ this.deleteWord(this.props.id, detail.id) } okayButtonText="削除する"/>
    );
  }

  deleteWord(dictonary_id, id) {
    return () => {
      this.props.handlers.showProgress();
      const service = new Service();

      service.deleteCalculationDictionaryWord(dictonary_id, id)
        .finally(() => { this.props.handlers.hideProgress(); })
        .subscribe(
          payload => {
            this.props.handlers.hidePopup();
            this.props.onChange();
            this.getCurrentPage();
          },
          error => {
            this.props.handlers.hidePopup();
            this.props.handlers.error(error);
          }
        );
    };
  }

  closePopup() {
    this.props.handlers.hidePopup();
    this.props.onChange();
    this.getCurrentPage();
  }
}

CalculationDictionaryWords.PropType = {
  id: PropTypes.number,
  dictionaryName: PropTypes.string,
  onChange:PropTypes.func,
  handlers: PropTypes.object
};

export default CalculationDictionaryWords;
