import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Alert.css';

export class AlertOneButton extends Component {

  render() {
    return(
      <div className="alert-popup">
        <div className="title">{ this.props.title }</div>
        <div className="inner">
          <p>{ this.props.message }</p>
        </div>
        <div className="buttons">
          <button className="btnpt4" onClick={ this.props.okay } >OK</button>
        </div>
      </div>
    );
  }
}

AlertOneButton.PropType = {
  title: PropTypes.string,
  message: PropTypes.string,
  okay:PropTypes.func
};

export class AlertTwoButton extends Component {
  render() {
    return(
      <div className="alert-popup">
        <div className="title">{ this.props.title }</div>
        <div className="inner">
          <p>{ this.props.message }</p>
        </div>
        <div className="buttons">
          <button className="btnpt1" onClick={ this.props.cancel } >キャンセル</button>
          <button className="btnpt4" onClick={ this.props.okay } >{ this.props.okayButtonText || "OK" }</button>
        </div>
      </div>
    );
  }
}

AlertTwoButton.PropType = {
  title: PropTypes.string,
  message: PropTypes.string,
  cancel:PropTypes.func,
  okay:PropTypes.func,
  okayButtonText: PropTypes.string
};

export class AlertNetworkError extends Component {

  render() {
    return(
      <div className="alert-popup">
        <div className="title">ネットワークエラー</div>
        <div className="inner">
          <p>通信に失敗しました。しばらくしてからもう一度行うか、ログアウトしてください。</p>
        </div>
        <div className="buttons">
          <button className="btnpt4" onClick={ this.props.okay } >OK</button>
        </div>
      </div>
    );
  }
}

AlertNetworkError.PropType = {
  okay:PropTypes.func
};

export class AlertForcingLogout extends Component {

  render() {
    return(
      <div className="alert-popup">
        <div className="title">認証エラー</div>
        <div className="inner">
          <p>他の端末からログインされていませんか？一旦、ログアウトします。</p>
        </div>
        <div className="buttons">
          <button className="btnpt4" onClick={ this.props.okay } >OK</button>
        </div>
      </div>
    );
  }
}

AlertForcingLogout.PropType = {
  okay:PropTypes.func
};

export class AlertForbiddenForcingLogout extends Component {

  render() {
    return(
      <div className="alert-popup">
        <div className="title">権限エラー</div>
        <div className="inner">
          <p>アカウントが無効です。ログアウトします。</p>
        </div>
        <div className="buttons">
          <button className="btnpt4" onClick={ this.props.okay } >OK</button>
        </div>
      </div>
    );
  }
}

AlertForbiddenForcingLogout.PropType = {
  okay:PropTypes.func
};

export class Alert4XXForcingLogout extends Component {

  render() {
    return(
        <div className="alert-popup">
          <div className="title">アクセスエラー</div>
          <div className="inner">
            <p>
              アクセスに失敗しました(ステータス:{this.props.status_number})。<br/>
              ログアウトします。
            </p>
          </div>
          <div className="buttons">
            <button className="btnpt4" onClick={ this.props.okay } >OK</button>
          </div>
        </div>
    );
  }
}

Alert4XXForcingLogout.PropType = {
  okay: PropTypes.func,
  status_number: PropTypes.number
};


export class AlertLogout extends Component {

  render() {
    return(
      <div className="alert-popup">
        <div className="title">ログアウト</div>
        <div className="inner">
          <p>本当にログアウトしますか？</p>
        </div>
        <div className="buttons">
          <button className="btnpt1" onClick={ this.props.cancel } >キャンセル</button>
          <button className="btnpt4" onClick={ this.props.okay } >ログアウト</button>
        </div>
      </div>
    );
  }
}

AlertLogout.PropType = {
  cancel:PropTypes.func,
  okay:PropTypes.func
};

class Alert extends Component {

  render() {
    if (this.props.visibled) {
      return (
        <div className="alert">
          { this.props.content }
        </div>
      );
    } else {
      return null;
    }
  }
}

Alert.PropType = {
  visibled: PropTypes.bool,
  content: PropTypes.element
};

export default Alert;
