import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Service from '../services/MeetingService';

import './MyGroupPopup.css';

function fieldToLabel(field) {
  let names = {
    name: 'グループ名',
  };
  return names[field];
}

class MyGroupPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      my_group: {
        name: ''
      },
      errors: {
        name: null
      }
    };
  }

  componentDidMount() {

    if (this.props.id) {
      const service = new Service();
      service.myGroup(this.props.id)
        .subscribe(
          payload => {
            this.setState({
              my_group: Object.assign({}, this.state.my_group, { name: payload.name })
            });
          },
          error => {
            this.props.close();
            this.props.error(error);
          }
        );

    }
  }

  submit(event) {
    let form = new FormData();
    form.append('name', this.state.my_group.name);

    let service = new Service();

    if (this.props.id) {
      service.updateMyGroup(this.props.id, form)
        .subscribe(
          payload => {
            if (this.errorsToState(payload)) {
              return;
            }
            this.props.close();
          },
          error => {
            this.props.close();
            this.props.error(error);
          }
        );
    } else {
      service.storeMyGroup(form)
        .subscribe(
          payload => {
            if (this.errorsToState(payload)) {
              return;
            }
            this.props.close();
          },
          error => {
            this.props.close();
            this.props.error(error);
          }
        );
    }
  }

  cancel(event) {
    this.props.close();
  }

  okayButton() {
    return this.props.id ? '変更する' : '作成する';
  }

  onChangeText(e, item) {
    let my_group = this.state.my_group;
    my_group[item] = e.target.value;
    this.setState({ my_group: my_group });
  }

  isEnabledPositiveButton() {
    return this.state.my_group.name.length > 0;
  }

  render() {
    return(
      <div className="my-group-popup">
        <div className="title">{ this.props.title }</div>
        <div className="inner">
          <form>
            <div><label className="required">グループ名</label>
              <input autoFocus
                     maxLength={30}
                     ref="name"
                     type="text"
                     value={ this.state.my_group.name }
                     onChange={ e => { this.onChangeText(e, 'name') } } />
            </div>
            { this.errorMessage('name') }
          </form>
        </div>
        <div className="buttons">
          <button className="button-text" onClick={ e => { this.cancel(e) } } >キャンセル</button>
          <button className="button-1" disabled={ !this.isEnabledPositiveButton() } onClick={ e => { this.submit(e) } } >{ this.okayButton() }</button>
        </div>
      </div>
    );
  }

  errorsToState(payload) {
    if (payload.error === 'validation') {
      delete(payload.error);
      let errors = [];
      Object.keys(payload).forEach(key => {
        let error = payload[key][0];
        errors[key] = error.split('.')[1];
      });
      this.setState({ errors: errors });
      return true;
    }
    return false;
  }

  errorMessage(field) {
    let error = this.state.errors[field];
    if (error) {
      let label = fieldToLabel(field);
      let messages = {
        unique: `その${ label }は既に存在します。`,
        required: `${ label }は必須入力です。`,
      };
      return <div><label/><div className="error">{ messages[error] }</div></div>;
    }
    return null;
  }

}

MyGroupPopup.PropType = {
  id: PropTypes.number,
  title: PropTypes.string,
  close:PropTypes.func,
  error:PropTypes.func
};

export default MyGroupPopup;
