import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Popup.css';

class Popup extends Component {

  render() {
    if (this.props.visibled) {
      return (
        <div className="popup">
          { this.props.content }
        </div>
      );
    } else {
      return null;
    }
  }
}

Popup.PropType = {
  visibled: PropTypes.bool,
  content: PropTypes.element
};

export default Popup;
