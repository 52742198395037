import React, { Component } from 'react';
import Service from '../services/MeetingService';

import './UsagePopup.css';

class UsagePopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customer: null,
    };
  }

  componentDidMount() {
    let service = new Service();
    service.customer().subscribe(
        payload => {
          this.setState({ customer: payload });
        },
        error => {
          this.error(error);
        }
    );
  }

  componentWillMount() {
    this.handler = this.onClickOutside.bind(this);
  }

  componentWillUnmount() {
  }

  onClickOutside(event) {
  }

  renderUsageTimeLastMonth() {
    if(this.state.customer) {
      return (this.state.customer.usage_time_last_month).toFixed();
    }
    return "";
  }

  renderUsageTimeThisMonth() {
    if(this.state.customer) {
      return (this.state.customer.usage_time_this_month).toFixed();
    }
    return "";
  }

  renderMaxStorage() {
    if(this.state.customer) {
      return (this.state.customer.max_storage_this_month / 1024 / 1024).toFixed(1);
    }
    return "";
  }

  renderMeetingMember() {
    if(this.state.customer) {
      return (this.state.customer.current_number_of_concurrent_meeting_members).toFixed();
    }
    return "";
  }

  render() {
    return (
          <div className="top-usage">
            <div className="top-usage-item">
              利用状況
            </div>
            <div className="top-usage-item">
              <div>現在の会議参加人数</div>
              <div/>
              <div>{ this.renderMeetingMember() }</div>
              <div>人</div>
            </div>
            <div className="top-usage-item">
              <div>合計使用容量</div>
              <div/>
              <div>{ this.renderMaxStorage() }</div>
              <div>GB</div>
            </div>
            <div className="top-usage-item">
              <div>合計使用時間</div>
              <div>今月</div>
              <div>{ this.renderUsageTimeThisMonth() }</div>
              <div>分</div>
            </div>
            <div className="top-usage-item">
              <div/>
              <div>先月</div>
              <div>{ this.renderUsageTimeLastMonth() }</div>
              <div>分</div>
            </div>
          </div>
    );
  }
}

export default UsagePopup;