import React, { Component } from 'react';
import Moment from 'moment';
import Service from '../services/MeetingService';
import Informations, { Information } from '../components/Informations';
import Meetings from './Meetings';
import Auth from '../components/Auth';

class Dashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      information: []
    };
  }

  componentDidMount() {
    let service = new Service();

    let information = [];

    service.informations()
    .subscribe(
      payload => {
        payload.forEach(item => {
          information.push(<Information key={ item.id } title={ item.title } desc={ item.desc } publish_at={ Moment(item.publish_at).format('Y/M/D') } />);
        });
        this.setState({ information: information });
      },
      error => {
        console.log(error);
        this.props.handlers.error(error);
      }
    );

  }

  render() {
    if(!Auth.isAuthenticated()) {
      return null;
    }

    return (
      <div>
        <Informations details={ this.state.information } />
        <Meetings handlers={ this.props.handlers } />
      </div>
    );
  }

}


export default Dashboard;
