import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './MeetingStatisticsScore.css';
import PaginationBar from "../components/PaginationBar";
import moment from 'moment';
import { Link } from 'react-router-dom';

export default class MeetingStatisticsScore extends Component {

  getMeetingStatisticsScore() {
    const rowList = [];
    this.props.meetings.forEach((meeting, i) => {
      if(this.props.showMeetingInfo) {
        rowList.push(
            <Link to={"/meeting-statistics?id=" + meeting.id} key={i}>
              <div className="score-row score-row-button" key={i}>
                {this.getLeftColumn(meeting)}
                {this.getTotalColumn(meeting)}
                {this.getDocumentColumn(meeting)}
                {this.getTimeColumn(meeting)}
                {this.getProgressColumn(meeting)}
                {this.getConclusionColumn(meeting)}
                {this.getNextMeetingColumn(meeting)}
                {this.getSpeakerColumn(meeting)}
              </div>
            </Link>
        );
      } else {
        rowList.push(
            <div className="score-row" key={i}>
              {this.getLeftColumn(meeting)}
              {this.getTotalColumn(meeting)}
              {this.getDocumentColumn(meeting)}
              {this.getTimeColumn(meeting)}
              {this.getProgressColumn(meeting)}
              {this.getConclusionColumn(meeting)}
              {this.getNextMeetingColumn(meeting)}
              {this.getSpeakerColumn(meeting)}
            </div>
        );
      }
    });

    return (
        <div className="score-detail">
          {rowList}
        </div>
    );
  }

  getLeftColumn(meeting) {
    if(this.props.showMeetingInfo) {
      return (
          <div className="left-column">
            <div>{moment(meeting.start_at).format('YYYY/MM/DD HH:mm')} 〜 {moment(meeting.end_at).format('HH:mm')}</div>
            <div>{meeting.title}</div>
            <div>会議作成者</div>
            <div>{meeting.creator.user_name}</div>
            {/*<Link to={"/meeting-statistics?id=" + meeting.id}>*/}
            {/*  <button className="button-1-green">メンバー分析</button>*/}
            {/*</Link>*/}
            {/*<Link to={"/meeting-extraction?id=" + meeting.id}>*/}
            {/*  <button className="button-1">トピックワード分析</button>*/}
            {/*</Link>*/}
          </div>
      );
    }
  }

  getStartDiv(score, criterion) {
    if(score >= criterion) {
      return (
          <div className="icon-star-enabled"/>
      );
    }
    return (
        <div className="icon-star-disabled"/>
    );
  }

  getTotalColumn(meeting) {
    const score = meeting.overall_score;

    return (
        <div className="total-column">
          <div>全体達成率</div>
          <div className="meeting-total">{ score }<div><div>%</div></div></div>
          <div className="meeting-starts">
            {this.getStartDiv(score, 20)}
            {this.getStartDiv(score, 40)}
            {this.getStartDiv(score, 60)}
          </div>
          <div className="meeting-starts">
            {this.getStartDiv(score, 80)}
            {this.getStartDiv(score, 100)}
          </div>
        </div>
    );
  }

  getDocumentColumn(meeting) {
    const score = meeting.document_score;
    let detail = meeting.document_score_detail;
    return (
        <div className="document-column">
          <div className="bar-area">
            <div className="percent">{ score + '％' }</div>
            <div className="bar" style={{ height: score / 2 + "px"}}/>
          </div>
          <div className="column-title">資料の事前登録</div>
          <div>{ this.arrayToHTML(detail) }</div>
        </div>
    );
  }

  getTimeColumn(meeting) {
    const score = meeting.time_score;
    let detail = meeting.time_score_detail;
    return (
        <div className="time-column">
          <div className="bar-area">
            <div className="percent">{ score + '％' }</div>
            <div className="bar" style={{ height: score / 2 + "px"}}/>
          </div>
          <div className="column-title">開始と終了</div>
          <div>{ this.arrayToHTML(detail) }</div>
        </div>
    );
  }

  getProgressColumn(meeting) {
    const score = meeting.progress_score;
    const detail = meeting.progress_score_detail;
    return (
        <div className="progress-column">
          <div className="bar-area">
            <div className="percent">{ score + '％' }</div>
            <div className="bar" style={{ height: score / 2 + "px"}}/>
          </div>
          <div className="column-title">議題の遂行</div>
          <div>{ this.arrayToHTML(detail) }</div>
        </div>
    );
  }

  getConclusionColumn(meeting) {
    const score = meeting.conclusion_score;
    const detail = meeting.conclusion_score_detail;
    return (
        <div className="conclusion-column">
          <div className="bar-area">
            <div className="percent">{ score + '％' }</div>
            <div className="bar" style={{ height: score / 2 + "px"}}/>
          </div>
          <div className="column-title">決定事項の共有</div>
          <div>{ this.arrayToHTML(detail) }</div>
        </div>
    );
  }

  getNextMeetingColumn(meeting) {
    const score = meeting.next_meeting_score;
    const detail = meeting.next_meeting_score_detail;
    return (
        <div className="next-meeting-column">
          <div className="bar-area">
            <div className="percent">{ score + '％' }</div>
            <div className="bar" style={{ height: score / 2 + "px"}}/>
          </div>
          <div className="column-title">次回会議の設定</div>
          <div>{ this.arrayToHTML(detail) }</div>
        </div>
    );
  }

  getSpeakerColumn(meeting) {
    const score = meeting.speaker_score;
    const detail = meeting.speaker_score_detail;
    return (
        <div className="speaker-column">
          <div className="bar-area">
            <div className="percent">{ score + '％' }</div>
            <div className="bar" style={{ height: score / 2 + "px"}}/>
          </div>
          <div className="column-title">発言者の割合</div>
          <div>{ this.arrayToHTML(detail) }</div>
        </div>
    );
  }

  arrayToHTML(data) {
    if (data.length > 0) {
      const rows = [];
      data.forEach(( value, i ) => {
        rows.push(<div key={ i }>{ value }</div>);
      });
      return rows;
    }
  }

  renderPaginationBar() {
    if(this.props.showMeetingInfo) {
      return (
          <PaginationBar
              total={ this.props.total }
              per_page={ this.props.per_page }
              current={ this.props.current_page }
              last={ this.props.last_page }
              onClick={ this.props.getCurrentPage }
          />
      );
    }
    return null;
  }

  render() {
    return (
      <div className="meeting-statistics-score">
        {this.getMeetingStatisticsScore()}
        {this.renderPaginationBar()}
      </div>
    );
  }
}

MeetingStatisticsScore.PropType = {
  current_page: PropTypes.number,
  last_page: PropTypes.number,
  meetings: PropTypes.object,
  showMeetingInfo: PropTypes.bool,
  getCurrentPage:PropTypes.func,
};
