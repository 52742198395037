import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from "moment/moment";
import { Portal } from 'react-overlays'
import DatePicker, { registerLocale } from 'react-datepicker'
import ClassNames from 'classnames';
import DateFormat from 'date-fns/format'
import ja from 'date-fns/locale/ja'

import './CustomDatePicker.css';

// jaのロケールの設定が週頭が月曜始まりになっているので日曜始まりにする
ja.options.weekStartsOn = 0;
// ReactDatepickerのロケール登録
registerLocale('ja', ja);

const CalendarContainer = ({children}) => {
  const el = document.getElementById('calendar-portal')
  return (
      <Portal container={el}>
        {children}
      </Portal>
  )
}

export class CustomDatePicker extends Component {
  render() {
    let selected = this.props.selected;
    if(selected !== null) {
      selected = selected.toDate();
    } else {
      selected = null;
    }

    return (
        <div className={ClassNames({
          "custom-date-picker": this.props.forAgenda !== true,
          "custom-date-picker-for-agenda": this.props.forAgenda === true
        })}  onClick={ this.onClick.bind(this) }>
          <DatePicker
              id={ this.props.id }
              locale="ja"
              isClearable={ (this.props.isClearable !== false) && (this.props.disabled !== true) }
              selected={ selected }
              onChange={ this.onChange.bind(this) }
              dateFormatCalendar="yyyy年 MM月"
              disabled={ this.props.disabled }
              dateFormat="yyyy/MM/dd"
              customInput={
                <button className={ClassNames({
                  "custom-date-picker-button": (this.props.isClearable !== false) && (this.props.forAgenda !== true),
                  "custom-date-picker-short-button": this.props.isClearable === false,
                  "custom-date-picker-button-for-agenda": this.props.forAgenda === true,
                })} >
                  {(selected===null)? "" : DateFormat(selected, 'yyyy/MM/dd')}
                </button>
              }
              // 現状のDatePickerは以前と違い祖先にあるoverflowに従う。
              // 議題の場合にはoverflowに従うと見づらいため、react-overflowを使用して配置する
              popperContainer={ (this.props.forAgenda === true)? CalendarContainer : null }
          />
          <button className="icon button-select">
            <div className={ClassNames({
              "icon-inverted-triangle" : !this.props.disabled,
              "icon-inverted-triangle-disabled" : this.props.disabled
            })} />
          </button>
        </div>
      );
  }

  onClick(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  onChange(date) {
    if(date === null) {
      this.props.onChange(null);
    } else {
      this.props.onChange(new Moment(date));
    }
  }
}

CustomDatePicker.PropType = {
  id: PropTypes.string,
  // 以前の処理との互換性のため、selectedはMomentクラス
  selected: PropTypes.object,
  //以前の処理との互換性のため、onChangeの引数はMomentクラス or null
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  forAgenda: PropTypes.bool
};

export default CustomDatePicker;
