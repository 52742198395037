// 定数
const PERMISSION_CODE_GUEST = 4;
const GUEST_NAME_SUFFIX = '(guest)';

const Const = {
  PERMISSION_CODE_GUEST: PERMISSION_CODE_GUEST,
  GUEST_NAME_SUFFIX: GUEST_NAME_SUFFIX,
  MIC_TYPE_NORMAL_MIC: 0,
  MIC_TYPE_MULTI_USER_MIC: 1,
};

export default Const;

