import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './MeetingInitialScore.css';
import Service from '../services/MeetingService';
import MeetingScore from '../components/MeetingScore'
import { setCustomerSettingInfo } from "./Login";

export default class MeetingInitialScore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customer_settings: null,
      customerDataLoaded: false
    };
  }

  componentDidMount() {
    this.props.handlers.showProgress();
    this.getCustomerSettings();
    this.getCustomerData();
  }

  tryHideProgress() {
    if (this.state.customer_settings === null) {
      return;
    }
    if (this.state.customerDataLoaded === false) {
      return;
    }
    this.props.handlers.hideProgress();
  }

  getCustomerData() {
    const service = new Service();
    service.customer()
      .subscribe(
        customer => {
          this.setState({
            customerDataLoaded: true
          });
          this.tryHideProgress();
        },
        error => {
          console.log(error);
          this.props.handlers.hideProgress();
          this.props.handlers.error(error);
        }
      );
  }

  getCustomerSettings() {
    const service = new Service();
    service.customerSettings().subscribe(
      payload => {
        this.setState({
          customer_settings: payload,
        });
        this.tryHideProgress();
      },
      error => {
        console.log(error);
        this.props.handlers.hideProgress();
        this.props.handlers.error(error);
      }
    );
  }

  submit() {
    const state = this.refs.meetingScore.state;
    const form = new FormData();
    if(!this.refs.meetingScore.validate()) {
      return;
    }

    form.append('document_score_assignment',
      state.document_score_assignment);
    form.append('time_score_assignment',
      state.time_score_assignment);
    form.append('progress_score_assignment',
      state.progress_score_assignment);
    form.append('conclusion_score_assignment',
      state.conclusion_score_assignment);
    form.append('next_meeting_score_assignment',
      state.next_meeting_score_assignment);
    form.append('speaker_score_assignment',
      state.speaker_score_assignment);

    const service = new Service();

    this.props.handlers.showProgress();
    //noinspection JSUnusedLocalSymbols
    service.updateCustomerSettings(form).subscribe(
      payload => {
        setCustomerSettingInfo(payload);
        this.props.handlers.hideProgress();
      },
      error => {
        console.log(error);
        this.props.handlers.hideProgress();
        this.props.handlers.error(error);
      }
    );
  }

  render() {
    return (
      <div className="meeting-initial-score">
        <div className="header">
          <div className="title">会議分析の初期配点</div>
          <div className="operations">
          </div>
        </div>
        <div className="meeting-initial-score-area">
          <div className="scroll">
            <div className="meeting-initial-score-desc">会議分析の配点を設定することができます。</div>
            {this.details()}
            <div className="buttons">
              <button className="okay button-1" type="button" onClick={ this.submit.bind(this) }>
                設定を保存
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  details() {
    if (!this.state.customer_settings) {
      return (
        <div/>
      );
    }

    const settings = this.state.customer_settings;
    return(
      <div>
        <div className="body">
          <MeetingScore
            ref='meetingScore'
            document_score_assignment={ settings.document_score_assignment }
            time_score_assignment={ settings.time_score_assignment }
            progress_score_assignment={ settings.progress_score_assignment }
            conclusion_score_assignment={ settings.conclusion_score_assignment }
            next_meeting_score_assignment={ settings.next_meeting_score_assignment }
            speaker_score_assignment={ settings.speaker_score_assignment }
          />
        </div>
      </div>
    );
  }
}

MeetingInitialScore.PropType = {
  handlers: PropTypes.object
};
