import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Service from '../services/MeetingService';

import './CalculationDictionaryWordPopup.css';

function fieldToLabel(field) {
  const names = {
    word: '単語',
    count: '登録単語数'
  };
  return names[field];
}

class CalculationDictionaryWordPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      word: '',
      errors: {
        word: null,
        count: null
      }
    };
  }

  componentDidMount() {

    if (this.props.id) {
      this.props.handlers.showProgress();
      const service = new Service();

      service.calculationDictionaryWord(this.props.dictionaryId, this.props.id)
        .finally(() => { this.props.handlers.hideProgress(); })
        .subscribe(
          payload => {
            this.setState({
              word: payload.word
            });
          },
          error => {
            this.props.close();
            this.props.error(error);
          }
        );
    }

  }

  submit(event) {
    this.props.handlers.showProgress();

    const form = new FormData();
    form.append('word', this.state.word);

    const service = new Service();

    if (this.props.id) {
      service.updateCalculationDictionaryWord(this.props.dictionaryId, this.props.id, form)
        .finally(() => { this.props.handlers.hideProgress(); })
        .subscribe(
          payload => {
            if (this.errorsToState(payload)) {
              return;
            }
            this.props.close();
          },
          error => {
            this.props.close();
            this.props.error(error);
          }
        );
    } else {
      service.storecalCulationDictionaryWord(this.props.dictionaryId, form)
        .finally(() => { this.props.handlers.hideProgress(); })
        .subscribe(
          payload => {
            if (this.errorsToState(payload)) {
              return;
            }
            this.props.close();
          },
          error => {
            this.props.close();
            this.props.error(error);
          }
        );
    }
  }

  cancel(event) {
    this.props.close();
  }

  descriptionLabel() {
    return this.props.id ? '変更' : '追加';
  }

  okayButton() {
    return this.props.id ? '変更する' : '追加する';
  }

  onChangeWord(e) {
    this.setState({ word: e.target.value });
  }

  isEnabledPositiveButton() {
    return this.state.word.length > 0;
  }

  render() {
    return(
      <div className="calculation-dictionary-word-popup">
        <div className="title">{ this.props.title }</div>
        <div className="inner">
          <form>
            <div>単語を{ this.descriptionLabel() }します。</div>
            { this.errorMessage('count') }
            <div><label className="required">単語</label><input autoFocus maxLength={100} ref="word" type="text" value={ this.state.word } onChange={ e => { this.onChangeWord(e) } } /></div>
            { this.errorMessage('word') }
            <div><label/><input type="text" name="dummy" className={ 'no-display' } /></div>
            <div><label/>１つの単語帳に最大100件まで単語を登録できます。</div>
          </form>
        </div>
        <div className="buttons">
          <button className="btnpt1" onClick={ e => { this.cancel(e) } } >キャンセル</button>
          <button className="btnpt4" disabled={ !this.isEnabledPositiveButton() } onClick={ e => { this.submit(e) } } >{ this.okayButton() }</button>
        </div>
      </div>
    );
  }

  errorsToState(payload) {
    if (payload.error === 'validation') {
      delete(payload.error);
      const errors = [];
      Object.keys(payload).forEach(key => {
        const error = payload[key][0];
        errors[key] = error.split('.')[1];
      });
      this.setState({ errors: errors });
      return true;
    }
    return false;
  }

  errorMessage(field) {
    const error = this.state.errors[field];
    if (error) {
      const label = fieldToLabel(field);
      const messages = {
        unique: `その${ label }は既に存在します。`,
        regex: `${ label }はひらがなもしくは長音で入力してください。`,
        min: `${ label }は二文字以上入力してください。`,
        required: `${ label }は必須入力です。`,
        exceeded_num_of_words_in_dictionary: `${ label }が100を超えています。`
      };
      return <div><label/><div className="error">{ messages[error] }</div></div>;
    }
    return null;
  }

}

CalculationDictionaryWordPopup.PropType = {
  dictionaryId: PropTypes.number,
  id: PropTypes.number,
  title: PropTypes.string,
  close:PropTypes.func,
  error:PropTypes.func
};

export default CalculationDictionaryWordPopup;
