import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Switch, Route } from "react-router-dom";

import App from './containers/App';
import Login from './containers/Login';
import {ResetPassword, SettingPassword, SendResetPassword} from './containers/ResetPassword';
import {OpenClient} from "./containers/OpenClient";

import 'normalize.css';
import './index.css';
import './images.css';

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
}
/* polyfill for IE11 */
if (!Array.prototype.findIndex) {
  // eslint-disable-next-line
  Array.prototype.findIndex = function(predicate) {
    if (this === null) {
      throw new TypeError('Array.prototype.findIndex called on null or undefined');
    }
    if (typeof predicate !== 'function') {
      throw new TypeError('predicate must be a function');
    }
    var list = Object(this);
    var length = list.length >>> 0;
    var thisArg = arguments[1];
    var value;

    for (var i = 0; i < length; i++) {
      value = list[i];
      if (predicate.call(thisArg, value, i, list)) {
        return i;
      }
    }
    return -1;
  };
}

ReactDOM.render((
    <Router>
      <Switch>
        <Route path="/login" component={ Login } />
        <Route path="/password/setting" component={ SettingPassword } />
        <Route path="/password/send" component={ SendResetPassword } />
        <Route path="/password/reset" component={ ResetPassword } />
        <Route path="/open-client" component={ OpenClient } />
        {/* コンポーネントに親子関係があるため、ひとまず親のAppに振り分け。
            Appの中で再度振り分けている。 */}
        <Route path="/" exact component={ App } />
        <Route path="/dictionaries" component={ App } />
        <Route path="/meeting" component={ App } />
        <Route path="/customer-statistics" component={ App } />
        <Route path="/meeting-statistics" component={ App } />
        <Route path="/meeting-extraction" component={ App } />
        <Route path="/settings" component={ App } />
        <Route path="/help" component={ App } />
        <Route path="/my_memos" component={ App } />
        <Route path="/member_menu" component={ App } />
      </Switch>
    </Router>
  ),
  document.getElementById('root')
);
