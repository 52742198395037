import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Link, Redirect, Route, Switch} from "react-router-dom";
import { DndProvider } from 'react-dnd-cjs';
import HTML5Backend from 'react-dnd-html5-backend-cjs';

import Service from '../services/MeetingService';
import Progress from '../components/Progress';
import Shield from '../components/Shield';
import Auth from '../components/Auth';
import Alert, {
  AlertNetworkError,
  AlertForcingLogout,
  AlertForbiddenForcingLogout,
  AlertLogout,
  Alert4XXForcingLogout
} from '../components/Alert';
import Popup from '../components/Popup';
import AccountPopup from './AccountPopup';
import { setUserInfo, unsetUserInfo } from './Login';
import {compareRoutePathForReactRouter, isChromeIOSBrowser} from './utilities';
import './App.css';
import ClassNames from "classnames";
import Dictionaries from "./Dictionaries";
import MeetingEditor from "./MeetingEditor";
import CustomerStatistics from "./CustomerStatistics";
import MeetingStatistics from "./MeetingStatistics";
import Settings from "./Settings";
import Help from "./Help";
import MyMemos from "./MyMemos";
import MemberMenu from "./MemberMenu";
import Dashboard from "./Dashboard";
import UsagePopup from "./UsagePopup";
import MeetingExtraction from "./MeetingExtraction";

class SideMenuItem extends Component {

    render() {
      if (this.props.enabled) {
        return (<Link to={{
          pathname: this.props.to,
          state: { from: window.location.pathname + window.location.search }
        }}>
          <div className="side-menu-item">
            <div className={ this.props.iconClass }/>
            <span>{ this.props.label }</span>
          </div>
        </Link>);
      } else {
        return (<div>
          <div className="side-menu-item">
            <div className={ this.props.iconClass }/>
            <span>{ this.props.label }</span>
          </div>
        </div>);
      }
    }
}

class TopOptionMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggle: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.handler = this.onClickOutside.bind(this);
    // リスナーが優先的によばれるようにキャプチャーフェーズを指定(第３引数にtrueを設定)
    document.addEventListener('click', this.handler, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handler, true);
  }

  onClickOutside(event) {
    // 表示中なら、このタイミングで消去
    if(this.state.toggle) {
      this.setState({ toggle: false });
      this.bounce = true;
    } else {
      this.bounce = false;
    }
  }

  onClickButton(event) {
    event.stopPropagation();
    event.preventDefault();
    // 直前にonClickOutsideによる消去でない&&非表示なら、表示
    if(!this.bounce && !this.state.toggle) {
      this.setState({ toggle: true });
    }
    this.bounce = false;
  }

  onEditGroup(e) {
    this.setState({
      toggle: false,
    });
    this.props.onEditGroup(e);
  }

  onDeleteGroup(e) {
    this.setState({
      toggle: false,
    });
    this.props.onDeleteGroup(e);
  }

  render() {
    const iconClass = ClassNames({
      'icon-top-user': true,
      'button-top-icon': true,
      'selected': this.state.toggle
    });

    return (
        <div className="top-option-menu">
          <div className={ iconClass } onClick={ this.onClickButton.bind(this) }/>
          <div className="top-option-items" hidden={ !this.state.toggle }>
            <div className="first-group" onClick={ this.props.popupAccount }>プロフィールを編集</div>
            <div className="second-group" onClick={ this.props.popupLogout }>ログアウト</div>
          </div>
        </div>
    );
  }
}

TopOptionMenu.PropType = {
  popupAccount:PropTypes.func,
  popupLogout:PropTypes.func
};

class UsageMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggle: false
    };
  }

  componentDidMount() {
    this.handler = this.onClickOutside.bind(this);
    // リスナーが優先的によばれるようにキャプチャーフェーズを指定(第３引数にtrueを設定)
    document.addEventListener('click', this.handler, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handler, true);
  }

  onClickOutside(event) {
    // 表示中なら、このタイミングで消去
    if(this.state.toggle) {
      this.setState({ toggle: false });
      this.bounce = true;
    } else {
      this.bounce = false;
    }
  }

  onClickButton(event) {
    event.stopPropagation();
    event.preventDefault();
    // 直前にonClickOutsideによる消去でない&&非表示なら、表示
    if(!this.bounce && !this.state.toggle) {
      this.setState({ toggle: true });
    }
    this.bounce = false;
  }

  renderUsagePopup() {
    if(!this.state.toggle) {
      return null;
    }
    return (
      <UsagePopup/>
    );
  }

  render() {
    return (
        <div className="top-usage-menu">
          <div className="icon-top-usage button-top-icon" onClick={ this.onClickButton.bind(this) }/>
          { this.renderUsagePopup() }
        </div>
    );
  }
}

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      visibled_navi: true,
      visibled_shield: false,
      visibled_popup: false,
      visibled_alert: false,
      visibled_progress: false,
      popup: null,
      alert: null
    };
    this.scrollLock = 0;
    this.gloalProgressCount = 0;
    this.handlers = {
      userInfo: this.userInfo.bind(this),
      setVisibledNavi: this.setVisibledNavi.bind(this),
      showPopup: this.showPopup.bind(this),
      hidePopup: this.hidePopup.bind(this),
      showAlert: this.showAlert.bind(this),
      hideAlert: this.hideAlert.bind(this),
      hideAlertOnly: this.hideAlertOnly.bind(this),
      showProgress: this.showProgress.bind(this),
      hideProgress: this.hideProgress.bind(this),
      showGlobalProgress: this.showGlobalProgress.bind(this),
      hideGlobalProgressIfNeeded: this.hideGlobalProgressIfNeeded.bind(this),
      error: this.error.bind(this)
    };
  }

  componentDidMount() {
    if (isChromeIOSBrowser()) {
      document.body.classList.add('ipad');
    }
    if(!Auth.isAuthenticated()) {
      return;
    }

    var service = new Service();
    service.me().subscribe(
      payload => {
        this.userInfo(payload);
        this.setState({ user: payload });
      },
      error => {
        this.error(error);
      }
    );
  }

  lockScrolling(flag = true) {
    if (flag) {
      ++this.scrollLock;
      document.body.style.overflow = 'hidden';
    } else {
      if (--this.scrollLock === 0) {
        document.body.style.overflow = 'auto';
      }
    }
  }

  userInfo(user) {
    this.setState({ username: user.user_name });
    setUserInfo(user);
  }

  showPopup(content) {
    if (!this.state.visibled_popup) {
      this.lockScrolling();
      this.setState({ popup: content, visibled_shield: true, visibled_popup: true });
    }
  }

  hidePopup() {
    this.lockScrolling(false);
    this.setState({ visibled_shield: false, visibled_popup: false });
  }

  showAlert(content) {
    if (!this.state.visibled_alert) {
      this.lockScrolling();
      this.setState({ alert: content, visibled_shield: true, visibled_alert: true });
    }
  }

  hideAlert() {
    this.lockScrolling(false);
    this.setState({ visibled_shield: false, visibled_alert: false });
  }

  hideAlertOnly() {
    this.lockScrolling(false);
    this.setState({ visibled_alert: false });
  }

  /**
   * 広域プログレスサークルを表示
   */
  showGlobalProgress() {
    this.gloalProgressCount += 1;
    this.showProgress();
  }

  /**
   * 広域プログレスサークルを消去。
   */
  hideGlobalProgressIfNeeded() {
    if(this.gloalProgressCount <= 0) {
      return;
    }
    this.gloalProgressCount = 0;
    this.hideProgress();
  }

  showProgress() {
    this.lockScrolling();
    this.setState({ visibled_progress: true });
  }

  hideProgress() {
    this.lockScrolling(false);
    this.setState({visibled_progress: false });
  }

  popupAccount(event) {
    event.preventDefault();
    this.showPopup(<AccountPopup handlers={ this.handlers } />);
  }

  logout(event) {
    event.preventDefault();

    this.showAlert(
      (<AlertLogout
        cancel={ this.hideAlert.bind(this) }
        okay={ this.logouting.bind(this) }
         />)
    );
  }

  logouting() {
    var service = new Service();
    this.setState({ visibled_shield: true });

    service.logout()
      .subscribe(
        payload => {
          this.hideAlert();
          unsetUserInfo();
          this.setState({ visibled_shield: false });
          this.props.history.push({
            pathname: '/login',
            state: { from: window.location.pathname + window.location.search }
          });
        },
        error => {
          if (error instanceof TypeError) {
            console.log('TypeError:' + error.message);
          } else {
            console.log('HTTP: ' + error.message);
            console.log(error.response);
          }
          this.hideAlert();
          unsetUserInfo();
          this.setState({ visibled_shield: false });
          this.props.history.push({
            pathname: '/login',
            state: { from: window.location.pathname + window.location.search }
          });
        }
      );
  }

  error(response) {
    let status_number = response.response.status;

    if (status_number === 401) {
      // 401 Unauthorized
      // 主な要因は別タブや別ブラウザによるログイン
      this.showAlert(<AlertForcingLogout okay={ this.logouting.bind(this) }/>);
    } else if (status_number === 403) {
      // 403 Forbidden
      // 主な要因はアクセス権がない
      this.showAlert(<AlertForbiddenForcingLogout okay={ this.logouting.bind(this) }/>);
    } else if (status_number >= 400 && status_number < 500) {
      // 4XX その他のクライアントエラー
      // 要因は様々
      // 404の場合は、該当データがない(削除済み等)
      // 429の場合は、APIリスエストが多すぎ(同一ユーザーで大量にタブを開いている等)
      //
      // Elastic Beanstalk環境では4XXレスポンスの割合が多すぎるとエラーメールが飛ぶ。
      // 4XXレスポンスが多くならないように、発生時にはログアウトさせる。
      this.showAlert(<Alert4XXForcingLogout status_number={ status_number } okay={ this.logouting.bind(this) }/>);
    } else {
      console.log(response.response);
      this.showAlert(<AlertNetworkError okay={ this.hideAlert.bind(this) }/>);
    }
  }

  headerStyles() {
    return (this.state.visibled_navi) ? 'menu' : 'hide';
  }

  setVisibledNavi(visibled) {
    this.setState({ visibled_navi: visibled });
  }

  getCustomerStatisticsItem() {
    return (
        <SideMenuItem to="/customer-statistics"
                      enabled={ !compareRoutePathForReactRouter("/customer-statistics") }
                      iconClass="icon-nav-analysis"
                      label="会議分析"/>
    );
  }

    render() {
      if(!Auth.isAuthenticated()) {
        sessionStorage.setItem('intended', window.location.href);
        return (
            <Redirect
                to={ {
                  pathname: '/login',
                  state: { from: window.location.pathname + window.location.search }
                } }
            />
        );
      }

      const mainContentClassName = ClassNames({
        'main-content': true,
        'scrollable-main-content': compareRoutePathForReactRouter("/"),
      });

      return (
      <DndProvider backend={HTML5Backend}>
      <div className="app">
        <div className={ this.headerStyles() }>
          <div className="logo">
            <div className="img-logo"/>
          </div>
          <div className="space">
          </div>
          <div className="user">
            <UsageMenu handlers={ this.handlers }/>
            <span>利用状況</span>
            <TopOptionMenu
                popupAccount={ this.popupAccount.bind(this) }
                popupLogout={ this.logout.bind(this) }
            />
            <span>{ this.state.username }</span>
          </div>
        </div>
        <div className="main-wrapper">
          <div className="side-menu">
            <SideMenuItem to="/"
                          enabled={ !compareRoutePathForReactRouter("/") }
                          iconClass="icon-nav-home"
                          label="会議一覧"/>
            <SideMenuItem to="/my_memos"
                          enabled={ !compareRoutePathForReactRouter("/my_memos") }
                          iconClass="icon-nav-memo"
                          label="Myメモ"/>
            { this.getCustomerStatisticsItem() }
            <SideMenuItem to="/member_menu"
                          enabled={ !compareRoutePathForReactRouter("/member_menu") }
                          iconClass="icon-nav-member"
                          label="メンバー"/>
            <SideMenuItem to="/dictionaries"
                          enabled={ !compareRoutePathForReactRouter("/dictionaries") }
                          iconClass="icon-nav-words"
                          label="単語帳"/>
            <SideMenuItem to="/settings"
                          enabled={ !compareRoutePathForReactRouter("/settings") }
                          iconClass="icon-nav-settings"
                          label="設定"/>
            <SideMenuItem to="/help"
                          enabled={ !compareRoutePathForReactRouter( "/help") }
                          iconClass="icon-nav-help"
                          label="ヘルプ"/>
          </div>
          <div className={ mainContentClassName }>
            {/* コンポーネントに親子関係があるため、index.js 内で親(App)に一次振り分けを行っている。
                ここではAppから子への二次振り分けを行う。
                handlersを渡したいため、componetプロパティではなく、renderプロパティを使用。
                デメリットは、この処理のままでは、history, locationが渡されない。
                */}
                <Switch>
                  <Route path="/" exact render={() =>
                      <Dashboard handlers={ this.handlers } />
                  } />
                  <Route path="/dictionaries" render={() =>
                      <Dictionaries handlers={ this.handlers } />
                  }/>
                  <Route path="/meeting" render={() =>
                      <MeetingEditor handlers={ this.handlers } />
                  } />
                  <Route path="/customer-statistics" render={() =>
                      <CustomerStatistics handlers={ this.handlers } />
                  } />
                  <Route path="/meeting-statistics" render={() =>
                      <MeetingStatistics handlers={ this.handlers } />
                  } />
                  <Route path="/meeting-extraction" render={() =>
                      <MeetingExtraction handlers={ this.handlers } />
                  } />
                  <Route path="/settings" render={() =>
                      <Settings handlers={ this.handlers } />
                  } />
                  <Route path="/help" render={() =>
                      <Help handlers={ this.handlers } />
                  } />
                  <Route path="/my_memos" render={() =>
                      <MyMemos handlers={ this.handlers } />
                  } />
                  <Route path="/member_menu" render={() =>
                      <MemberMenu handlers={ this.handlers } />
                  } />
                </Switch>
          </div>
        </div>
        <Shield visibled={ this.state.visibled_shield }/>
        <Popup visibled={ this.state.visibled_popup} content={ this.state.popup }/>
        <Alert visibled={ this.state.visibled_alert } content={ this.state.alert }/>
        <Progress visibled={ this.state.visibled_progress }/>
      </div>
      </DndProvider>
    );
  }
}


export default App;
