import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class RemovableList extends Component {

  render() {
    var items = [];
    for (let i = 0; i < this.props.items.length; ++i) {
      if (this.props.canNotRemove) {
        items.push(<li key={ i } >{ this.props.items[i].name }<span className="close rounded" onClick={ this.bindOnRemove(i) } ></span></li>);
      } else {
        items.push(<li key={ i } >{ this.props.items[i].name }<span className="icn close rounded" onClick={ this.bindOnRemove(i) } ></span></li>);
      }
    }
    return(
      <ul>
        { items }
      </ul>
    );
  }

  bindOnRemove(index) {
    return () => {
      this.props.onRemove(index);
    };
  }
}

RemovableList.PropType = {
  items: PropTypes.array,
  onRemove:PropTypes.func
};

export default RemovableList;
