import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Service from '../services/MeetingService';
import './MyGroupUsers.css';
import UserPicker from "./UserPicker";
import PaginationBar from "../components/PaginationBar";

class MyGroupUser extends Component {

  render() {
    return (
      <tr>
        <td className="name">{ this.props.detail.sei }&nbsp;{ this.props.detail.mei }</td>
        <td className="disp">{ this.props.detail.user_name }</td>
        <td className="dept">{ this.props.detail.dept }</td>
        <td className="functions">
          <div>
            { this.getDeleteButton() }
          </div>
        </td>
      </tr>
      );
  }

  getDeleteButton() {
    return (
        <button className="icon-trash button-icon" onClick={ e => { this.props.deleteUser(this.props.detail) } } />
    );
  }
}

MyGroupUser.PropType = {
  detail: PropTypes.object,
    deleteUser:PropTypes.func,
};

class MyGroupUsers extends Component {

  constructor(props) {
    super(props);

    this.state = {
      id: this.props.id,
      total: 0,
      per_page: 20,
      current: 0,
      last: 0,
      details: undefined
    };
  }

  componentDidMount() {
    this.getCurrentPage();
  }

  componentDidUpdate() {
    if (this.props.id !== this.state.id) {
      this.setState({
        id: this.props.id,
        name: this.props.name
      });
      this.getCurrentPage(0);
    } else if (this.props.name !== this.state.name) {
      this.setState({
        name: this.props.name
      });
    }
  }

  getCurrentPage(page = null) {
    this.props.handlers.showProgress();
    new Service().myGroupUsers(this.props.id, page === null ? this.state.current : page).subscribe(
      payload => {
        this.props.handlers.hideProgress();
        this.setState({
          total: payload.total,
          per_page: payload.per_page,
          current: payload.current_page,
          last: payload.last_page,
          details: payload.data,
        });
      },
      error => {
        this.props.handlers.hideProgress();
        this.props.handlers.error(error);
      }
    );
    this.updateUserPickerMember(this.props.id);
  }

  users() {
    let users = [];
    this.state.details.forEach(user => {
      users.push(<MyGroupUser key={ user.id } detail={ user }
                       deleteUser={ this.deleteUser.bind(this) }/>);
    });
    return users;
  }

  details() {
    if (this.state.details) {
     if (this.state.details.length > 0) {
        return (
          <div className="data">
            <div  className="data-detail" ref={ node => this.dataDetail = node } >
              <table>
                <thead>
                <tr>
                  <th className="name">名前</th>
                  <th className="disp">表示名</th>
                  <th className="dept">所属</th>
                  <th className="functions"/>
                </tr>
                </thead>
                <tbody>
                  { this.users() }
                </tbody>
              </table>
            </div>
          </div>
        );
      } else {
        return (
          <div className="no-data">
            <p>メンバーを追加して下さい。</p>
          </div>
        );
      }
    }
    return null;
  }

  getAddUserButton() {
    return <UserPicker members={ [] }
                       onAddMembers={ this.onAddMembers.bind(this) }
                       onClearMembers={ null }
                       canClear={false}
                       buttonTitle="メンバーを追加"
                       largeButton={false}
                       isEmbedded={false}
                       exclusionMyGroupId={ this.state.id }
                       ref='userPicker'
           />;
  }

  updateUserPickerMember(group_id) {
    this.refs.userPicker.updateMembers(group_id);
  }

  onAddMembers(members) {
    if(members.length === 0) {
      return;
    }

    let form = new FormData();
    members.forEach(member => {
      form.append('members[]', member.id);
    });

    let service = new Service();
    service.storeMyGroupUsers(this.props.id, form)
        .subscribe(
            payload => {
              this.props.onChange();
              this.getCurrentPage();
            },
            error => {
              this.props.handlers.error(error);
            }
        );
  }

  render() {
    return (
      <div className="my-group-users">
        <div className="header">
          <div className="title">{ this.props.name}</div>
          <div className="operations">
            { this.getAddUserButton() }
          </div>
        </div>
        { this.details() }
        <PaginationBar
            total={ this.state.total }
            per_page={ this.state.per_page }
            current={ this.state.current }
            last={ this.state.last }
            onClick={ this.getCurrentPage.bind(this) }
        />
      </div>
    );
  }

  deleteUser(user) {
    let form = new FormData();
    form.append('members[]', user.id);

    let service = new Service();
    service.deleteMyGroupUsers(this.props.id, form)
        .subscribe(
            payload => {
              this.props.onChange();
              this.getCurrentPage();
            },
            error => {
              this.props.handlers.error(error);
            }
        );
  }
}

MyGroupUsers.PropType = {
  id: PropTypes.number,
  name: PropTypes.string,
  onChange:PropTypes.func,
  handlers: PropTypes.object
};

export default MyGroupUsers;
