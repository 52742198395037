import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Service from '../services/MeetingService';
import './UploadWordsPopup.css';

export default class UploadWordsPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dragging: false,
      message: 'ここにCSVファイルをドラッグしてください。',
      upload_file: null
    }
  }

  onDragEnter(event) {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      dragging: true,
      message: 'ここにCSVファイルをドロップしてください。'
    });
  }

  onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      dragging: true,
    });
  }

  onDragLeave(event) {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      dragging: false,
      message: 'ここにCSVファイルをドラッグしてください。'
     });
  }

  onDrop(event) {
    event.stopPropagation();
    event.preventDefault();
    this.setState({
      dragging: false,
    });
    let files = event.dataTransfer.files;
    this.setUploadFile(files[0]);
  }

  onClickSelectFile(event) {
    event.stopPropagation();
    event.preventDefault();
    this.refs.file.click();
  }

  onChangeFile(event) {
    let files = event.target.files;
    this.setUploadFile(files[0]);
  }

  message() {
    return <p>{ this.state.message }</p>;
  }

  setUploadFile(file) {
    if (file) {
      if (file.name.split('.').pop().toLowerCase() === 'csv') {
        this.setState({ upload_file: file });
      } else {
        this.setState({ message: 'CSVファイルではありません。', upload_file: null });
      }
    }
  }

  upload_file() {
    if (this.state.upload_file) {
      return <div className="upload-file" >{ this.state.upload_file.name }</div>
    }
    return null
  }

  isEnabledUploadButton() {
    return this.state.upload_file != null;
  }

  render() {
    var draggingStyles = this.state.dragging ? 'drag-and-drop enter' : 'drag-and-drop';

    return(
      <div className="dictionaries-upload-csv-file">
        <div className="title">単語CSVインポート</div>
        <div className="inner">
          <div className="explanation">単語のCSVファイルをインポートします。</div>
          <div className={ draggingStyles }
               onDrop={ this.onDrop.bind(this) }
               onDragEnter={ this.onDragEnter.bind(this) }
               onDragOver={ this.onDragOver.bind(this) }
               onDragLeave={ this.onDragLeave.bind(this) }　>
            { this.message() }
            <div className="select-file">
              <div>または</div>
              <button className="btnpt2" onClick={ this.onClickSelectFile.bind(this) } >ファイルを選択</button>
            </div>
          </div>
          { this.upload_file() }
        </div>
        <div className="buttons">
          <button className="btnpt1" onClick={ this.onClickCancel.bind(this) } >キャンセル</button>
          <input type="file" ref="file" accept=".csv" onChange={ this.onChangeFile.bind(this) } />
          <button className="btnpt4" onClick={ this.upload.bind(this) } disabled={ !this.isEnabledUploadButton() } >インポートする</button>
        </div>
      </div>
    );
  }

  onClickCancel(event) {
    event.preventDefault();
    this.props.close(null)
  }

  upload() {
    let service = new Service();
    var form = new FormData();

    form.append('csvfile', this.state.upload_file);

    this.props.handlers.showProgress();

    service.uploadWords(this.props.id, form)
      .finally(() => { this.props.handlers.hideProgress(); })
      .subscribe(
      payload => {
        this.props.close(payload);
      },
      error => {
        this.props.close();
        this.props.handlers.error(error);
      }
    );
  }
}

UploadWordsPopup.PropType = {
  id: PropTypes.number,
  close:PropTypes.func,
  handlers: PropTypes.object
};

export class ResultsUploadWordsPopup extends Component {

  fieldToLabel(field) {
    var names = {
      word: '単語',
      yomi: '読み',
      category: 'カテゴリー'
    }
    return names[field];
  }

  errorMessage(field, error) {
    let label = this.fieldToLabel(field);
    if (label) {
      var messages = {
        unique: `その${ label }は既に存在します。`,
        required: `${ label }は必須入力です。`,
        exists: `その${ label }は存在しません。`,
        regex: `${ label }はひらがなもしくは長音で入力してください。`,
        min: `${ label }は二文字以上入力してください。`,
        max: `${ label }の値が範囲外です。`,
        numeric: `${ label }が存在しません。`
      }
      return `${messages[error]}`;
    } else { // all
      return '項目に過不足があります。'
    }
  }

  errors() {
    var rows = [];
    this.props.results.lines.forEach(line => {
      var message = '';
      Object.keys(line).forEach(key => {
        if (key !== 'no') {
          message += this.errorMessage(key, line[key]);
        }
      });
      rows.push(<div key={ line.no }>{ line.no }: { message }</div>)
    });
    return rows;
  }

  results() {
    if (this.props.results.error === 'validation') {
      if (this.props.results.file) {
        var key = this.props.results.file;
        var messages = {
          notfound: 'CSVファイルが選択されていません。',
          name: '単語帳名がありません。',
          unique: 'その単語帳名は利用されています。',
          csv: 'CSVファイルではありません。',
          exceeded_num_of_words_in_dictionary: '登録単語数が500を超えています。',
        };
        var message = messages[key] ? messages[key] : '';
        return (
          <div className="inner">
            <div>{ message }</div>
          </div>
        );
      } else {
        return (
          <div className="inner">
            <div>CSVファイルにエラーがあります（{ this.props.results.lines.length }/{ this.props.results.total }）。</div>
            <div className="errors">
              { this.errors() }
            </div>
            <div>CSVファイルを修正して、再度アップロードしてください。</div>
          </div>
        );
      }
    } else {
      return <div className="inner">CSVファイルの単語帳は全て登録しました（{ this.props.results.total }件）。</div>
    }
  }

  render() {
    return(
      <div className="users-results-upload">
        <div className="title">単語帳CSVインポート</div>
        { this.results() }
        <div className="buttons">
          <button className="btnpt4" onClick={ this.props.okay } >OK</button>
        </div>
      </div>
    );
  }
}

ResultsUploadWordsPopup.PropType = {
  results: PropTypes.array,
  okay:PropTypes.func
};
