import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './MeetingStatisticsWordCloud.css';

// react-wordcloud
//   React向けD3-cloud表示ライブラリ
//   Webページ：https://github.com/chrisrzhou/react-wordcloud
//   サンプル：https://react-wordcloud.netlify.app/
//   ライセンス情報： MIT
import ReactWordcloud from 'react-wordcloud';

export default class MeetingStatisticsWordCloud extends Component {
  constructor(props) {
    super(props);
    this.word_count = this.props.data.length;
  }

  render() {
    // let words = [
    //   { text: "そこそこ",    value: 14 },
    //   { text: "場合場合",    value: 11 },
    //   { text: "うそうそ",    value: 22 },
    //   { text: "病気病気",    value: 28 },
    //   { text: "らら",      value: 17 },
    //   { text: "旨旨旨旨旨",      value: 10 },
    //   { text: "時",      value: 43 },
    //   { text: "しんしん",    value: 10 },
    //   { text: "実事実事実",    value: 10 },
    //   { text: "使用使用",    value: 30 },
    //   { text: "方",      value: 21 },
    //   { text: "応用応用",    value: 30 },
    //   { text: "ないない",    value: 15 },
    //   { text: "思う",    value: 39 },
    //   { text: "なる",    value: 29 },
    //   { text: "発展",    value: 13 },
    //   { text: "思い",    value: 20 },
    //   { text: "くる",    value: 20 },
    //   { text: "差",      value: 30 },
    //   { text: "向く",    value: 20 },
    //   { text: "そこそこ",    value: 14 },
    //   { text: "場合場合",    value: 11 },
    //   { text: "うそうそ",    value: 22 },
    //   { text: "病気病気",    value: 28 },
    //   { text: "らら",      value: 17 },
    //   { text: "旨旨旨旨旨",      value: 10 },
    //   { text: "時",      value: 43 },
    //   { text: "しんしん",    value: 10 },
    //   { text: "実事実事実",    value: 10 },
    //   { text: "使用使用",    value: 30 },
    //   { text: "方",      value: 21 },
    //   { text: "応用応用",    value: 30 },
    //   { text: "ないない",    value: 15 },
    //   { text: "思う",    value: 39 },
    //   { text: "なる",    value: 29 },
    //   { text: "発展",    value: 13 },
    //   { text: "思い",    value: 20 },
    //   { text: "くる",    value: 20 },
    //   { text: "差",      value: 30 },
    //   { text: "向く",    value: 20 },
    //   { text: "そこそこ",    value: 14 },
    //   { text: "場合場合",    value: 11 },
    //   { text: "うそうそ",    value: 22 },
    //   { text: "病気病気",    value: 28 },
    //   { text: "らら",      value: 17 },
    //   { text: "旨旨旨旨旨",      value: 10 },
    //   { text: "時",      value: 43 },
    //   { text: "しんしん",    value: 10 },
    //   { text: "実事実事実",    value: 10 },
    //   { text: "使用使用",    value: 30 },
    //   { text: "方",      value: 21 },
    //   { text: "応用応用",    value: 30 },
    //   { text: "ないない",    value: 15 },
    //   { text: "思う",    value: 39 },
    //   { text: "なる",    value: 29 },
    //   { text: "発展",    value: 13 },
    //   { text: "思い",    value: 20 },
    //   { text: "くる",    value: 20 },
    //   { text: "差",      value: 30 },
    //   { text: "向く",    value: 20 },
    //   { text: "そこそこ",    value: 14 },
    //   { text: "場合場合",    value: 11 },
    //   { text: "うそうそ",    value: 22 },
    //   { text: "病気病気",    value: 28 },
    //   { text: "らら",      value: 17 },
    //   { text: "旨旨旨旨旨",      value: 10 },
    //   { text: "時",      value: 43 },
    //   { text: "しんしん",    value: 10 },
    //   { text: "実事実事実",    value: 10 },
    //   { text: "使用使用",    value: 30 },
    //   { text: "方",      value: 21 },
    //   { text: "応用応用",    value: 30 },
    //   { text: "ないない",    value: 15 },
    //   { text: "思う",    value: 39 },
    //   { text: "なる",    value: 29 },
    //   { text: "発展",    value: 13 },
    //   { text: "思い",    value: 20 },
    //   { text: "くる",    value: 20 },
    //   { text: "差",      value: 30 },
    //   { text: "向く",    value: 20 },
    // ];
    let options = {
      // colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b'],
      enableTooltip: false,
      // deterministic: false,
      fontFamily: 'impact',
      // fontSizes: [5, 60],
      // fontStyle: 'normal',
      fontWeight: 'bold',
      // padding: 1,
      rotations: 1,
      rotationAngles: [0, 0],
      // scale: 'sqrt',
      // spiral: 'archimedean',
      // transitionDuration: 1000,
    };

    const colors = [
      '#d50000',  // 上位10%
      '#ff9100',  // 10%〜20%
      '#ffc400',  //
      '#ffea00',  //
      '#c6ff00',  //
      '#00c853',  //
      '#00b8d4',  //
      '#2962ff',  //
      '#303f9f',  //
      '#bdbdbd',  // 90%〜100%
    ];

    // ワードクラウドの描画
    // wordsで渡した内容については、直接加工されfontや向きの情報が付与されるため注意
    return (
        <div className="meeting-statistics-word-cloud">
          <ReactWordcloud
              words={ this.props.data }
              size={[560,400]}
              options={options}
              callbacks={{
                getWordColor: ({ id }) => {
                  // idは 0〜"word件数-1"の連番。
                  // そのため、indexは 「0〜1.0未満の値 * 10段階」で端数を切り捨てた値 = 0〜9
                  let index = Math.floor(id / this.word_count * colors.length);
                  return colors[index];
                },
              }}
        />
        </div>
    );
  }
}

MeetingStatisticsWordCloud.PropType = {
  data: PropTypes.object,
  labels: PropTypes.array
};
