import React, { Component } from 'react';
import Spinner from "react-md-spinner";
import Service from '../services/MeetingService';

import './ResetPassword.css';
import {getSearchParamsForReactRouter, routePath2href} from "./utilities";

export class SendResetPassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: null,
      wating: false,
      message: null,
      errors: {}
    }
  }

  render() {
    return (
      <div className="password-reset">
      <div className="logo">
        <div className="img-top-logo"></div>
      </div>
        <div className="desc">
          <p>ご登録いただいているメールアドレスを入力してください。<br/>
          入力いただいたメールアドレスへ、パスワードリセットするURLを送信します。<br/>
          そのメールに従ってパスワードをリセットしてください。
          </p>
        </div>
        <form onSubmit={ this.submit.bind(this) }>
          <div>メールアドレス</div>
          <div><input ref="email" type="email" autoFocus /></div>
          { this.errorMessage('email') }
          <p className="message ">{ this.state.message }</p>
          <div className="buttons">
            <a className="btnpt1" href={ routePath2href("/login") }>ログイン画面へ</a>
            <input className="btnpt4" type="submit" value="送信" />
          </div>
        </form>
        { this.spinner() }
      </div>
    );
  }

  spinner() {
    if (this.state.waiting) {
      return <Spinner />;
    }
    return null;
  }

  submit(event) {
    event.preventDefault();

    if (!this.refs.email.value) {
      this.setState({ errors: { email: 'required'} });
      return;
    }

    var form = new FormData();
    form.append('email', this.refs.email.value);

    this.setState({ waiting: true });
    new Service().sendPasswordResetMail(form)
      .subscribe(
        payload => {
          //console.log(payload);
          this.setState({ waiting: false });
          if (!this.errorsToState(payload)) {
            if (!payload.error) {
              console.log('send a reset mail to your account.');
            } else {
              console.log(`error: [${payload.error}]may not be member account.`);
            }
            this.setState({ message: 'リセットメールを送信しました。' });
          }
        },
        error => {
          console.log(error);
          this.setState({
            waiting: false,
            message: 'ネットワークエラーが発生しました。'
          });
        }
      );

  }

  errorsToState(payload) {
    var errors = [];

    if (payload.email !== undefined) {
      errors.email = payload.email[0].split('.')[1];
      this.setState({ errors: errors });
      return true;
    }
    return false;
  }

  fieldToLabel(field) {
    var names = {
      email: 'メールアドレス',
    }
    return names[field];
  }

  errorMessage(field) {
    var error = this.state.errors[field];
    if (error) {
      var label = this.fieldToLabel(field);
      var messages = {
        required: `${ label }は必須入力です。`,
        email: `${ label }の形式に誤りがあります。`,
      }
      return <div><label></label><div className="error">{ messages[error] }</div></div>;
    }
    return null;
  }

}

// パスワード設定画面(2回目以降用)
// 類似のクラスに継承先の SettingPassword があるため、修正する場合は注意が必要。
export class ResetPassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: null,
      disabled: true,
      wating: false,
      message: null,
      errors: {}
    }
  }

  UNSAFE_componentWillMount() {
    let params = getSearchParamsForReactRouter();
    let token = params.get("token");
    let email = params.get("email");
    if (token === undefined
    || email === undefined) {
      //TODO: 以前から処理がないけど、リダイレクト必要？
      console.log('redirect to login');
      //this.context.router.push('#login');
    }
  }

  // 継承したクラス SettingPassword でもrenderは実装しているため、
  // 内容を修正する場合には注意が必要
  render() {
    /* eslint jsx-a11y/anchor-is-valid: 0 */
    return (
      <div className="password-reset">
        <div className="logo">
          <div className="img-top-logo"></div>
        </div>
        <div className="desc">
          <p>パスワードをリセットします。<br/>
          メールアドレスと新しいパスワードを入力して、「パスワードを再設定」ボタンを押してください。
          </p>
        </div>
        <form onSubmit={ this.submit.bind(this) }>
          <div>メールアドレス</div>
          <div><input ref="email" type="email" autoFocus /></div>
          { this.errorMessage('email') }
          <div>新しいパスワード(英数字と一部の記号で、6文字以上)</div>
          <div><input maxLength={64} ref="password" type="password" /></div>
          { this.errorMessage('password') }
          <div>新しいパスワードの確認</div>
          <div><input maxLength={64} ref="password_confirmation" type="password" /></div>
          { this.errorMessage('password_confirmation') }
          <p className="message" dangerouslySetInnerHTML={{ __html: this.state.message }} ></p>
          <div className="buttons">
            <a className="btnpt1" href="">ログイン画面へ</a>
            <input className="btnpt4" type="submit" value="パスワードを再設定" />
          </div>
          { this.errorMessage('token') }
        </form>
        { this.spinner() }
      </div>
    );
  }

  onChangeText(event) {
    this.setState({ email: event.target.value, disabled: event.target.value.length <= 0 });
  }

  spinner() {
    if (this.state.waiting) {
      return <Spinner />;
    }
    return null;
  }

  submit(event) {
    event.preventDefault();

    console.log(`email: ${this.refs.email.value} password: ${this.refs.password.value} confirm: ${this.refs.password_confirmation.value}`);

    if (!this.refs.email.value) {
      this.setState({ errors: { email: 'required' }});
      return;
    }
    if (!this.refs.password.value) {
      this.setState({ errors: { password: 'required' }});
      return;
    }
    if (!this.refs.password_confirmation.value) {
      this.setState({ errors: { password_confirmation: 'required' }});
      return;
    }
    var form = new FormData();
    let token = getSearchParamsForReactRouter().get("token");
    form.append('token', token);
    form.append('email', this.refs.email.value);
    form.append('password', this.refs.password.value);
    form.append('password_confirmation', this.refs.password_confirmation.value);

    this.setState({ waiting: true });
    new Service().resetPassword(form)
      .subscribe(
        payload => {
          this.setState({ waiting: false });
          console.log(payload);
          if (!this.errorsToState(payload)) {
            if (!payload.error) {
              console.log('completed to reset your password.');
              let href = routePath2href('/login');
              this.setState({ message: 'パスワードを設定しました。<a href="' + href + '">ログイン画面へ</a>' });
            } else {
              let href = routePath2href('/password/send');
              this.setState({ message: '認証キーが無効になっています。お手数ですが<a href="' + href + '">パスワードリセット</a>を行ってください。' });
            }
          }
        },
        error => {
          console.log(error);
          this.setState({
            waiting: false,
            message: this.getPasswordResetErrorMessage(),
            errors: {}
          });
        }
      );
  }

  getPasswordResetErrorMessage() {
    return 'エラーです。(メールアドレスが間違っているか、パスワードが不正か、"新しいパスワード"と"新しいパスワードの確認"が一致しません)';
  }

  errorsToState(payload) {
    var errors = {};
    var n = 0;

    if (payload.token !== undefined) {
      ++n;
      errors.token = payload.token[0].split('.')[1];
    }
    if (payload.email !== undefined) {
      ++n;
      errors.email = payload.email[0].split('.')[1];
    }
    if (payload.password !== undefined) {
      ++n;
      errors.password = payload.password[0].split('.')[1];
    }
    if (n > 0) {
      this.setState({ errors: errors });
      return true;
    }
    return false;
  }

  fieldToLabel(field) {
    var names = {
      token: '認証キー',
      email: 'メールアドレス',
      password: 'パスワード',
      password_confirmation: '確認用パスワード',
    }
    return names[field];
  }

  errorMessage(field) {
    var error = this.state.errors[field];
    if (error) {
      var label = this.fieldToLabel(field);
      var messages = {
        token: `認証キーが有効ではありません。お手数ですがパスワードリセットしてください。`,
        required: `${ label }は必須入力です。`,
        email: `${ label }の形式に誤りがあります。`,
        confirmed: `${ label }が確認用と一致しません。`,
        min: `${ label }は6文字以上で入力してください。`,
      }
      return <div><label></label><div className="error">{ messages[error] }</div></div>;
    }
    return null;
  }

}

// パスワード設定画面(初回用)
// 類似のクラスに継承元の ResetPassword があるため、修正する場合は注意が必要。
export class SettingPassword extends ResetPassword {
  render() {
    return (
        <div className="password-reset">
          <div className="logo">
            <div className="img-top-logo"/>
          </div>
          <div className="desc">
            <p>パスワードを登録します。<br/>
              メールアドレスとパスワードを入力して、「パスワードを設定」ボタンを押してください。
            </p>
          </div>
          <form onSubmit={ this.submit.bind(this) }>
            <div>メールアドレス</div>
            <div><input ref="email" type="email" autoFocus /></div>
            { this.errorMessage('email') }
            <div>パスワード(英数字と一部の記号で、6文字以上)</div>
            <div><input maxLength={64} ref="password" type="password" /></div>
            { this.errorMessage('password') }
            <div>パスワードの確認</div>
            <div><input maxLength={64} ref="password_confirmation" type="password" /></div>
            { this.errorMessage('password_confirmation') }
            <p className="message" dangerouslySetInnerHTML={{ __html: this.state.message }} />
            <div className="buttons">
              <a className="btnpt1" href="">ログイン画面へ</a>
              <input className="btnpt4" type="submit" value="パスワードを設定" />
            </div>
            { this.errorMessage('token') }
          </form>
          { this.spinner() }
        </div>
    );
  }

  getPasswordResetErrorMessage() {
    // 継承元とは一部文言が違うため注意。
    // 新しいパスワード → パスワード
    // 新しいパスワードの確認 → パスワードの確認
    return 'エラーです。(メールアドレスが間違っているか、パスワードが不正か、"パスワード"と"パスワードの確認"が一致しません)';
  }
}

