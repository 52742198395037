import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd-cjs';
import Moment from 'moment';
import ClassNames from 'classnames';
import {personalSettings, isIEBrowser, isChromeIOSBrowser} from "./utilities";
import Const from "../components/Const";
import TextareaAutosize from "react-textarea-autosize";

/**
 * Implements the drag source contract.
 */
const commentSource = {
  beginDrag(props) {
    if(props.isBroadcaster) {
      return {
        id: props.id,
        text: `${Moment(props.timestamp).format('HH:mm:ss')} ${props.comment}`
      };
    }
    let settings = personalSettings();
    let mark_str = "";
    let time_str = "";
    let name_str = "";
    let comment_str = "";
    let translation_str = "";
    let status_comment_separator = "";
    if(settings.can_copy_and_paste_mark) {
      if(props.mark) {
        mark_str = "★ ";
      } else {
        mark_str = "　 ";
      }
    }
    if(settings.can_copy_and_paste_time) {
      time_str = Moment(props.timestamp).format('HH:mm:ss ');
    }
    if(settings.can_copy_and_paste_name) {
      name_str = `（${props.name}）`;
    }
    if(settings.can_copy_and_paste_comment) {
      // Windows版Chromeの場合、
      // コメント編集中に素早くコメント以外の部分をドラッグ＆ドロップすると、
      // 編集前の内容がドロップされる課題がある。
      comment_str = props.comment;
    }
    if(settings.can_copy_and_paste_mark
        || settings.can_copy_and_paste_time
        || settings.can_copy_and_paste_name) {
      if(props.minutesType === 1) {
        // 議題登録型のため、改行は¥n
        status_comment_separator = '\n';
      } else {
        // 自由記述型のため、改行は<br/>
        status_comment_separator = '<br/>';
      }
    }

    if(settings.can_copy_and_paste_translation) {
      if(props.translatedComment !== undefined) {
        let previous_separator = '';
        let after_separator = '';
        if(settings.can_copy_and_paste_comment) {
          // 翻訳エリアをコピーする かつ 発話エリアもコピーする場合には区切り文字を入れる
          if(props.minutesType === 1) {
            // 議題登録型のため、改行は¥n
            previous_separator = '\n[';
          } else {
            // 自由記述型のため、改行は<br/>
            previous_separator = '<br/>[';
          }
          after_separator = ']';
        }
        translation_str = previous_separator + props.translatedComment + after_separator;
      }
    }
    return {
      id: props.id,
      text: mark_str + time_str + name_str + status_comment_separator + comment_str + translation_str
    };
  }
};

/**
 * Specifies the props to inject into your component.
 */
function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

class Comment extends Component {

  constructor(props) {
    super(props);

    this.state = {
      mark: this.props.mark,
      name: this.props.name,
      comment: this.props.comment,
      translatedComment: this.props.translatedComment,
      editable: false,
      timestamp: this.props.timestamp,
      editing: false,
    }
  }

  componentDidUpdate() {
    if( this.state.editing ) {
      this.textarea.focus();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.enabled) {
      this.setState({ editable: false });
    }
    if (nextProps.mark !== undefined) {
      this.setState({ mark: nextProps.mark });
    }
    if (nextProps.name !== undefined) {
      this.setState({ name: nextProps.name });
    }
    if (nextProps.comment !== undefined) {
      this.setState({ comment: nextProps.comment });
    }
    if (nextProps.translatedComment !== undefined) {
      this.setState({ translatedComment: nextProps.translatedComment });
    }
  }

  translatedCommentButtons() {
    if (this.props.enabled && this.props.isSelected && this.props.translatedComment) {
      return (
          <div className="button-icon-transparent-blue icon-pencil-blue-medium"  onClick={ this.onClickTranslatedCommentEditing.bind(this) } />
        )
    } else {
      return null;
    }
  }
  buttons() {
    let output = [];
    let isButtonEnabled = this.isButtonEnabled();
    let isButtonDisabled = !this.isButtonEnabled();
    if(this.props.meetingStatus === -2)
    {
        output.push(<div className={ClassNames({
            "button-icon-transparent": isButtonDisabled,
            "icon-pencil-medium": isButtonDisabled,
            "icon-pencil-disabled-medium": isButtonDisabled,
        })} onClick={ this.onClickEditing.bind(this) } key="pencil" />);
        output.push(<div className={ClassNames({
            "button-icon-transparent": isButtonDisabled,
            "icon-trash-medium": isButtonDisabled,
            "icon-trash-disabled-medium": isButtonDisabled,
        })} onClick={ this.onClickDeleting.bind(this) } key="trash"/>);
        output.push(<div className={ClassNames({
            "button-icon-transparent": isButtonDisabled,
            "icon-fill-drip-solid": isButtonDisabled,
            "icon-fill-drip-solid-disabled": isButtonDisabled,
        })} onClick={ this.onClickCancelDropped.bind(this) } key="fill-drip" />);
    }
    // 次の条件を全て満たすときのみ削除アイコンを表示
    // ・会議を自分が編集中
    // ・該当画像を選択している
    // ・iPadではない
    else if(this.props.enabled
        && this.props.isSelected
        && !isChromeIOSBrowser()
    ) {
      output.push(<div className={ClassNames({
                                               "button-icon-transparent": isButtonEnabled,
                                               "icon-pencil-medium": isButtonEnabled,
                                               "icon-pencil-disabled-medium": isButtonDisabled,
                                             })} onClick={ this.onClickEditing.bind(this) } key="pencil" />);
      output.push(<div className={ClassNames({
                                               "button-icon-transparent": isButtonEnabled,
                                               "icon-trash-medium": isButtonEnabled,
                                               "icon-trash-disabled-medium": isButtonDisabled,
                                             })} onClick={ this.onClickDeleting.bind(this) } key="trash"/>);
      output.push(<div className={ClassNames({
                                               "button-icon-transparent": isButtonEnabled,
                                               "icon-fill-drip-solid": isButtonEnabled,
                                               "icon-fill-drip-solid-disabled": isButtonDisabled,
                                             })} onClick={ this.onClickCancelDropped.bind(this) } key="fill-drip" />);
    }
    return output;
  }

  isButtonEnabled() {
    return this.props.commentEditMode !== true;
  }

  utterance() {
    const utterance = this.props.utterance;
    const url = (utterance !== null && utterance.url !== null) ? utterance.url : null;
    if (url !== null) {
      let isButtonEnabled = this.isButtonEnabled();
      let isButtonDisabled = !this.isButtonEnabled();
      return (
        <div
          className={
            ClassNames({
              "button-icon-transparent": isButtonEnabled,
              "icon-playing": this.props.playing && isButtonEnabled,
              "icon-volume": !this.props.playing && isButtonEnabled,
              "icon-playing-disabled": this.props.playing && isButtonDisabled,
              "icon-volume-disabled": !this.props.playing && isButtonDisabled,
            })
        }
        onClick={ this.onClickUtterance.bind(this) }/>
      );
    }
    return (
        <div className="utterance"/>
    );
  }

  translatedByGoogle() {
    if(isIEBrowser()) {
      return (<div className="img-translated-by-google-ie"/>);
    }
    return (<div className="img-translated-by-google"/>);
  }

  translatedComment() {
    if (!this.props.translatedComment) {
      return null;
    }
    return (
        <div className="translated-comment">{ this.props.translatedComment }
          <div className="translate-footer">
            { this.translatedCommentButtons() }
            <a href="http://translate.google.com" target="_blank" rel="noopener noreferrer">
              { this.translatedByGoogle() }
            </a>
          </div>
        </div>
    );
  }

  renderLabel() {
    if (this.props.mic_type !== Const.MIC_TYPE_MULTI_USER_MIC) {
      return null;
    }
    if (Number(this.props.selected_speaker_user_id)) {
      return(<div className="label-sr label-selected-speaker">登録</div>);
    }
    return null;
  }

  renderName() {
    if (this.props.mic_type === Const.MIC_TYPE_MULTI_USER_MIC) {
      let class_name = 'name_suspicious';
      if(this.props.speaker_similarity >= 0.6 || Number(this.props.selected_speaker_user_id)) {
        class_name = 'name_confident';
      }
      return (<div className={class_name}>{this.props.name}</div>);
    }
    return (<div className="name"> {this.props.name}</div>);
  }

  renderCommentBody()
  {
    let commentClass = ClassNames({
      "comment": true,
      "is_dropped": this.props.isDropped,
      "comment-editing": this.state.editing,
    });
    if(this.state.editing === false) {
        return (<div className={ commentClass } onDoubleClick={ this.onDoubleClickComment.bind(this) }>
          { this.state.comment }
        </div>);
    }
    return (
        // <textarea className={ commentClass } ref="comment-editor" defaultValue={this.state.comment} onBlur={this.onBlurTextarea.bind(this) }/>
        // );
        <TextareaAutosize
    className={ commentClass }
    inputRef={ tag => (this.textarea = tag) } //textarea要素へのアクセス。公式サンプルのFAQ参照
    id={ this.props.item }
    onBlur={ this.onBlurTextarea.bind(this) }
    minRows={ 1 }
    defaultValue={ this.state.comment }
    />);
  }

  renderTimestamp() {
    const timestamp = this.getTimestampString(this.props.timestamp);
    return (
        <div className="timestamp">
          { this.renderLabel() }
          { this.renderName() }
          <div>{ timestamp }</div>
        </div>
    );
  }

  renderFunctions() {
    let isButtonEnabled = this.isButtonEnabled();
    let isButtonDisabled = !this.isButtonEnabled();
    return (
      <div className="functions">
        { this.utterance() }
        <div
          onClick={ e => { this.onClickMark(e, this.props.id) } }
          className={ClassNames({
            'button-icon-transparent': isButtonEnabled,
            'icon-star-enabled-medium': this.state.mark !== 0 && isButtonEnabled,
            'icon-star-not-selected-medium': this.state.mark === 0 && isButtonEnabled,
            'icon-star-disabled-medium': this.state.mark !== 0 && isButtonDisabled,
            'icon-star-not-selected-disabled-medium': this.state.mark === 0 && isButtonDisabled,
          }) } />
        { this.buttons() }
        { this.renderTimestamp() }
      </div>
    );
  }

  renderCommentArea() {
    let divClass = ClassNames({ "comment-box": true, selected: (this.props.enabled && this.props.isSelected) });

    return(
        <div className={ divClass } onClick={ this.onClickRow.bind(this) } >
        { this.renderCommentBody() }
        { this.renderFunctions() }
        { this.translatedComment() }
      </div>
    );
  }

  render() {
    if(this.state.editing) {
      return this.renderCommentArea();
    }
    const connectDragSource = this.props.connectDragSource;
    return connectDragSource(
        this.renderCommentArea()
    );
  }

  getTimestampString(timestamp) {
    const momentTimestamp = Moment(this.props.timestamp);
    return momentTimestamp.format('HH:mm:ss');
  }

  onClickRow(event) {
    if (!this.props.enabled) {
      return;
    }

    let selected = !this.props.isSelected ? this.props.id : -1;
    this.props.onSelect(selected);
  }

  onDoubleClickComment(event) {
    event.preventDefault();
    if(this.props.meetingStatus !== -2)
    {
      if (!this.props.enabled) {
        return;
      }
      this.setState({ editing: true },
          () => {
            this.textarea.selectionStart = this.state.comment.length;
            this.textarea.selectionEnd = this.state.comment.length;
          });
      this.props.onCommentEditModeChanged(true);
    }
  }

  onBlurTextarea(event) {
    event.preventDefault();
    this.setState({
      editing: false
    });
    this.props.onChange({
      id: this.props.id,
      comment: event.target.value,
      is_dropped: '1',
    });
    this.props.onCommentEditModeChanged(false);
  }

  onClickMark(event, id) {
    event.preventDefault();

    if(this.props.meetingStatus !== -2)
    {
      if(this.isButtonEnabled() === false) {
        return;
      }
      event.stopPropagation();

      const mark = (+this.state.mark) !== 0 ? 0 : 1;
      this.props.onChange({ id: this.props.id, mark: mark });
    }
  }

  onClickEditing(event) {
    event.preventDefault();
    if(this.isButtonEnabled() === false) {
      return;
    }
    event.stopPropagation();
    this.props.onSelect(-1);
    this.props.onPopup({
      id: this.props.id,
      timestamp: this.getTimestampString(this.props.timestamp),
      mark: this.state.mark,
      name: this.state.name,
      comment: this.state.comment,
      utterance: this.props.utterance,
      mic_type: this.props.mic_type,
      selected_speaker_user_id: this.props.selected_speaker_user_id,
    });
  }

  onClickDeleting(event) {
    event.preventDefault();
    if(this.isButtonEnabled() === false) {
      return;
    }
    event.stopPropagation();
    this.props.onDelete(this.props.id);
  }

  onClickCancelDropped(event) {
    event.preventDefault();
    if(this.isButtonEnabled() === false) {
      return;
    }
    event.stopPropagation();
    this.props.onChange({ id: this.props.id, is_dropped: 0 });
  }

  onClickTranslatedCommentEditing(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelect(-1);
    this.props.onPopupTranslatedComment({ id: this.props.id, timestamp: this.getTimestampString(this.props.timestamp), name: this.state.name, comment: this.state.comment, translatedComment: this.state.translatedComment });
  }

  onChangeName(html) {
    this.setState({ name: html });
  }

  onChangeComment(html) {
    this.setState({ comment: html });
  }

  onClickUtterance(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.onStartPlaying(this.props.utterance);
  }

  onProgressDownload() {
    if (this.props.onProgressDownload) {
      this.props.onProgressDownload();
    }
  }

  onUpdateTimePlaying(current) {
    if (this.props.onUpdateTimePlaying) {
      this.props.onUpdateTimePlaying(current);
    }
  }

  onStopPlaying() {
    if (this.props.onStopPlaying) {
      this.props.onStopPlaying();
    }
  }
}

Comment.propTypes = {
  id: PropTypes.number,
  mark: PropTypes.number,
  name: PropTypes.string,
  comment: PropTypes.string,
  translatedComment: PropTypes.string,
  utterance: PropTypes.object,
  playing: PropTypes.bool,
  timestamp: PropTypes.string,
  onPopupCommentEditor: PropTypes.func,
  onChange: PropTypes.func,
  onPopupTranslatedComment: PropTypes.func,
  onChangeTranslatedComment: PropTypes.func,
  enabled: PropTypes.bool,
  onStartPlaying: PropTypes.func,
  rowColor: PropTypes.string,
  isSelected: PropTypes.bool,
  // Injected by React DnD:
  isDragging: PropTypes.bool.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  isBroadcaster: PropTypes.bool,
  minutesType: PropTypes.number,
  commentEditMode: PropTypes.bool.isRequired,
  onCommentEditModeChanged: PropTypes.func.isRequired,
  meetingStatus: PropTypes.number,
};

// Export the wrapped component:
export default DragSource('Comment', commentSource, collect)(Comment);
