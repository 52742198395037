import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import CustomDatePicker from '../components/CustomDatePicker';
import Service from '../services/MeetingService';
import AlertOneButton from '../components/Alert';

import './MeetingTimeEditorPopup.css';
import 'react-datepicker/dist/react-datepicker.css';

import MeetingTimePicker from '../containers/MeetingTimePicker';

export function fieldToLabel(field) {
  var names = {
    base_time: '開催日時',
  }
  return names[field];
}

export default class MeetingTimeEditorPopup extends Component {

  constructor(props) {
    super(props);
    let time = this.props.base_time ? this.props.base_time : this.props.start_at;
    time = time ? time : Moment();

    this.state = {
      id: this.props.id,
      base_date: time,
      base_time: time,
      use_base_time: this.props.use_base_time,
      errors: {
        base_date: null,
        base_time: null,
        use_base_time: false,
      },
    };
  }

  componentDidMount() {
  }

  updateUseBaseTime(value) {
    this.setState({ use_base_time: value });
  }

  onClickLabel(value) {
    this.updateUseBaseTime(value);
  }

  onChangeRadio(value) {
    this.updateUseBaseTime(value);
  }

  onChangeUserBaseDate(date) {
    this.setState({ base_date: date });
  }

  onChangeBaseTime(time) {
    this.setState({ base_time: time });
  }

  submit(event) {

    event.preventDefault();
    event.stopPropagation();

    this.props.handlers.showProgress();
    this.submitBody()
      .subscribe(
        payload => {
          if (this.errorsToState(payload)) {
            this.props.handlers.hideProgress();
            return;
          }
          this.props.handlers.hideProgress();
          this.props.close();
        },
        error => {
          this.props.handlers.hideProgress();
          this.props.close();
          this.alert();
        }
      );
  }

  submitBody() {
    var form = new FormData();

    form.append('use_base_time', this.state.use_base_time);

    if(this.state.use_base_time) {
      if (this.state.base_date && this.state.base_time) {
        form.append('base_time', this.state.base_date.format('YYYY/MM/DD') + this.state.base_time.format(' HH:mm:ss'));
      } else if (this.state.base_date) {
        form.append('base_time', this.state.base_date.format('YYYY/MM/DD'));
      } else {
        //どちらも無効な場合はセットしない
      }
    }

    const service = new Service();
    return service.updateMeetingBaseTime(this.props.id, form);
  }

  cancel(event) {
    this.props.close();
  }

  render() {
    return(
      <div className="meeting-time-editor-popup">
        <div className="title">任意の会議開始日時を設定</div>
        <div className="inner">
          <form autoComplete="off">
            <div onClick={ e => { this.onClickLabel(false) } } >
              <input type="radio"  name="use_base_time" onChange={ e => { this.onChangeRadio(false) } } checked={ ! this.state.use_base_time } className="radio" />
              実際の会議開始日時を使用する
            </div>
            <div onClick={ e => { this.onClickLabel(true) } } >
              <input type="radio"  name="use_base_time" onChange={ e => { this.onChangeRadio(true)  } } checked={   this.state.use_base_time } className="radio" />
              任意の会議開始日時を使用する
            </div>
            <div>
              <label>開始日</label>
              <CustomDatePicker
                  selected={ this.state.base_date }
                  onChange={ this.onChangeUserBaseDate.bind(this) }
                  disabled={ !this.state.use_base_time }
                  isClearable={ false }
              />
            </div>
            { this.errorMessage('start_date') }
            <div>
              <label>開始時刻</label>
              <MeetingTimePicker time={ this.state.base_time } notUseSecond={false} intervalOfMinutesOption={1} onChangeTime={ this.onChangeBaseTime.bind(this) } disabled={ !this.state.use_base_time } />
            </div>
            { this.errorMessage('base_time') }
            <div>
              <label>注：</label>
              「任意の会議開始日時」が適用される対象は、タイムライン上の発言時刻のみです。
            </div>
          </form>
        </div>
        <div className="buttons">
          <button onClick={ this.cancel.bind(this) } className="cancel btnpt1" >キャンセル</button>
          <button onClick={ this.submit.bind(this) } className="okay btnpt4" >変更</button>
        </div>
      </div>
    );
  }

  errorsToState(payload) {
    if (payload.error === 'validation') {
      delete(payload.error);
      var errors = [];
      Object.keys(payload).forEach(key => {
        var error = payload[key][0];
        errors[key] = error.split('.')[1];
      });
      this.setState({ errors: errors });
      return true;
    }
    return false;
  }

  errorMessage(field) {
    var error = this.state.errors[field];
    if (error) {
      var label = fieldToLabel(field);
      var messages = {
        required: `${ label }は必須入力です。`,
        exists: `${ label }は存在しません。`,
        date: `${ label }が誤っています。`,
        after: `${ label }は開始日時以降を設定してください。`
      }
      return <div><label></label><div className="error">{ messages[error] }</div></div>;
    }
    return null;
  }

  alert(title = 'ネットワークエラー', message = '少し待ってから行うか、一旦ログアウトしてください。') {
    this.props.handlers.showAlert(
      <AlertOneButton title={ title } message={ message } okay={ this.props.handlers.hideAlert } />
    );
  }

}

MeetingTimeEditorPopup.PropType = {
  id: PropTypes.number.isRequired,
  start_at: PropTypes.object.isRequired,
  base_time: PropTypes.object.isRequired,
  use_base_time: PropTypes.bool.isRequired,
  close:PropTypes.func,
  handlers: PropTypes.object
};
