import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from "react-dnd-cjs";
import ClassNames from "classnames";
import TextareaAutosize from 'react-textarea-autosize';
import Service from "../services/MeetingService";

class AgendaComment extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
  }

  onDrop(component, text) {
    let textArea = component.textarea;
    textArea.focus();
    let textRange = null;
    if (document.selection && document.selection.createRange) {
      textRange = document.selection.createRange();
    }
    if (textRange == null) {
      const index = textArea.selectionStart;
      const length = textArea.selectionEnd - index;
      textArea.value = textArea.value.substr(0, index) + text + textArea.value.substr(index + length);
      const newCaretPosition = index + text.length;
      textArea.setSelectionRange(index, newCaretPosition);
    } else {
      textRange.text = text;
      textRange.select();
    }

    this.onChange(textArea.value);
  }

  onDragLeave(event) {
    event.stopPropagation();
    event.preventDefault();

    // フォーカスを取り除く
    this.textarea.blur();
  }

  handleChange(e) {
    this.onChange(e.target.value);
  }

  onChange(value) {
    if (this.props.onChangeText) {
      this.props.onChangeText(value, this.props.item, this.props.index)
    }
  }

  render() {
    const { connectDropTarget } = this.props;
    const className = ClassNames({'disabled': this.props.disabled, 'target': true});
    return (
      <div className={ 'agenda-' + this.props.item }>
        <label>{ this.props.name }</label>
        { connectDropTarget(
            <div
                disabled={ this.props.disabled }
            >
              <TextareaAutosize
                  className={ className }
                  inputRef={ tag => (this.textarea = tag) } //textarea要素へのアクセス。公式サンプルのFAQ参照
                  disabled={ this.props.disabled }
                  id={ this.props.item }
                  maxLength={this.props.maxLength}
                  onChange={ this.handleChange }
                  onDragLeave={ this.onDragLeave }
                  minRows={ this.props.minRows ? this.props.minRows : 2 }
                  defaultValue={ this.props.defaultValue }
              />
            </div>
        )}
      </div>
    );
  }
}

AgendaComment.PropType = {
  index: PropTypes.number,
  item: PropTypes.string,
  name: PropTypes.string,
  minRows: PropTypes.number,
  maxLength: PropTypes.number,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  onChangeText:PropTypes.func,
  onError:PropTypes.func
};

const commentTarget = {

  drop(props, monitor, component) {

    if (component.props.disabled) {
      return;
    }
    if (monitor.getItemType() === 'Comment') {
      component.onDrop(component, monitor.getItem().text);
      new Service().updateCommentByCommentDrop(monitor.getItem().id);
    } else {
      props.onError('写真コピー', '写真を複写することはできません。');
    }
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget()
  };
}

export default DropTarget(['Comment', 'Picture'], commentTarget, collect)(AgendaComment);
