import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Observable } from 'rx';
import ClassNames from 'classnames';

import Picture from './Picture';
import Service from '../services/MeetingService';
import { AlertTwoButton } from '../components/Alert';
import { absolutedUrl } from './utilities';

import './Pictures.css';

class Pictures extends Component {

  constructor(props) {
    super(props);

    this.state = {
      waiting: false,
      documents: [],
      pictures: [],
      filter: props.filter,
      keywords: null,
      selected: -1
    };
    this.scrolling = false;
    this.scrollingTimer = null;
    this.onScrolling = this.onScrolling.bind(this);
  }

  componentDidMount() {
    this.scroller = this.refs.scroller;
    this.scroller.addEventListener('scroll', this.onScrolling, false);

    const service = new Service();
    Observable.zip(service.documents(this.props.id), service.pictures(this.props.id), (documents, pictures) => {
      return { documents: documents, pictures: pictures };
    }).subscribe(
      payload => {
        this.setState({
          documents: payload.documents,
          total: payload.pictures.total,
          count: payload.pictures.count,
          pictures: payload.pictures.data
        });
        this.subscribe();
      },
      error => {
        this.props.handlers.error(error);
      }
    );

  }

  componentWillUnmount() {
    this.scroller.removeEventListener('scroll', this.onScrolling);
    this.unsubscribe();
  }

  subscribe() {
    if (!this.subscriber) {
      this.subscriber = Observable.interval(1000).subscribe(
        () => {
          if (!this.scrolling) {
            const state = this.shouldTryScroll();
            if (state === 1) {
              this.loadNewerPictures();
            } else {
              this.loadCurrentPictures();
            }
          }
        }
      );
    }

  }

  unsubscribe() {
    if (this.subscriber) {
      this.subscriber.dispose();
    }
  }

  onScrolling(event) {
    this.scrolling = true;
    if (this.scrollingTimer) {
      clearTimeout(this.scrollingTimer);
    }
    const self = this;
    this.scrollingTimer = setTimeout(() => {
      self.scrolling = false;
      self.scrollingTimer = null;
    }, 500);

    const state = this.shouldTryScroll();
    if (state < 0) {
      this.loadOlderPictures();
    }
  }

  shouldTryScroll() {

    if (this.scroller.offsetHeight < this.scroller.scrollHeight) {
      if (this.scroller.scrollTop <= 0) {
        // Be at the top
        return 1;
      } else if ((this.scroller.scrollTop + this.scroller.offsetHeight + 3000) >= this.scroller.scrollHeight) {
        return -1;
      }
    } else if (this.scroller.scrollTop <= 0) {
      // Be at the top
      return 1;
    }
    return 0;

  }

  filter2mark(filter) {
    switch (filter) {
      case 0: // all
        return null;
      case 1: // marked
        return 1;
      case 2: // not marked
        return 0;
      default:
        return null; // all
    }
  }

  findUpdateFromIndex() {
    var height = 0;
    for (var i = 0; i < this.scroller.children.length; ++i) {
      if ((height + this.scroller.children[i].clientHeight) >= this.scroller.scrollTop) {
        return i;
      }
      height += this.scroller.children[i].clientHeight + 2; // with padding
    }
    return -1;
  }

  loadCurrentPictures() {

    if (this.state.waiting) {
      return;
    }
    const index = this.findUpdateFromIndex();
    if (index < 0) {
      return;
    }
    const id = this.state.pictures[index].id;

    new Service().pictures(this.props.id, -id, this.filter2mark(this.state.filter), this.state.keywords)
      .subscribe(
        payload => {
          if (payload.data.length > 0) {
            var pictures = [
              ...this.state.pictures.slice(0, index + 1),
              ...payload.data,
            ];
            this.setState({
              waiting: false,
              pictures: pictures
            });
          } else {
            this.setState({ waiting: false });
          }
        },
        error => {
          this.setState({ waiting: false });
          this.props.handlers.error(error);
        }
      );

  }

  loadOlderPictures() {

    if (this.state.waiting) {
      return;
    }
    const id = this.state.pictures.length > 0 ? this.state.pictures[this.state.pictures.length - 1].id : null;
    this.setState({ waiting: true });

    new Service().pictures(this.props.id, -id, this.filter2mark(this.state.filter), this.state.keywords)
      .subscribe(
        payload => {
          if (payload.data.length > 0) {
            var pictures = this.state.pictures;
            pictures = pictures.concat(payload.data);
            this.setState({
              waiting: false,
              pictures: pictures
            });
          } else {
            this.setState({ waiting: false });
          }
        },
        error => {
          this.setState({ waiting: false });
          this.props.handlers.error(error);
        }
      );
  }

  loadNewerPictures(from = 0, filter = this.state.filter, keywords = this.state.keywords) {

    if (this.scrolling || this.state.waiting) {
      return;
    }
    const mark = this.filter2mark(filter);
    new Service().pictures(this.props.id, from, mark, keywords)
      .subscribe(
        payload => {
          this.subscribe();
          this.setState({
            pictures: payload.data
          });
        },
        error => {
          this.props.handlers.error(error);
        }
      );
  }

  appendPictures() {
    new Service().pictures(this.props.id, this.state.count)
      .subscribe(
        payload => {
          if (payload.count > 0) {
            var pictures = [
              ...this.state.pictures, ...payload.data
            ];
            this.setState({
              total: payload.total,
              count: this.state.count + payload.count,
              pictures: pictures
            });
          }
        },
        error => {
          this.props.handlers.error(error);
        }
      );
  }

  isVisibled(picture) {
    switch (this.state.filter) {
      case 0:
        return true;
      case 1:
        return (+picture.mark) === 1;
      case 2:
        return (+picture.mark) === 0;
      default:
        break;
    }
    return true;
  }

  documents() {
    return this.state.documents.map(doc => {
      if (['jpg', 'jpeg', 'png', 'svg'].findIndex(ext => { return ext === doc.ext }) >= 0) {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        return <div key={ doc.id }><a href="#" onClick={ e => { this.onClickImage(e, doc.id) } } >{ doc.name }</a></div>
      } else {
        return <div key={ doc.id }><a href={ absolutedUrl(`${doc.url}/download?`) }>{ doc.name }</a></div>
      }
    });
  }

  pictures() {
    var pictures = [];
    if (this.state.pictures.length <= 0) {
      return(
        <div className="background">
          <div>このエリアに、発話の内容と、<br />送信された写真の一覧が表示されます。</div>
        </div>
      );
    } else {
      let prevName = '';
      let isEven = true;
      this.state.pictures.forEach(picture => {
        if (this.isVisibled(picture)) {
          if (picture.name !== prevName) {
            isEven = !isEven;
          }
          prevName = picture.name;

          pictures.push(
            <div key={ picture.id } className="picture-area">
              <Picture key={ picture.id } detail={ picture }
                onChange={ this.onChange.bind(this) }
                enabled={ this.props.enabled }
                rowColor={ ClassNames({even: isEven, odd: !isEven}) }
                isSelected={ ( picture.id === this.state.selected ) }
                onSelect={ this.onSelect.bind(this) }
                onDelete={ this.onDelete.bind(this) } />
              <hr/>
            </div>
          );
        }
      });
    }
    return pictures;
  }

  render() {
    this.props.notifyParameter(this.state.filter);

    return (
      <div className="pictures">
        <div className="header"/>
        <div className="body" ref="scroller">
          { this.pictures() }
        </div>
        <div className="footer">
          <div className="comment-all" onClick={ e => { this.onChangeFilter(e, 0) }}>
            <div className={
              ClassNames({
                'icon-comment-all' : this.state.filter === 0 ? true : false,
                'icon-comment-all-not-selected' : this.state.filter === 1 ? true : false
              })}/><span>すべて</span>
          </div>
          <div className="comment-mark" onClick={ e => { this.onChangeFilter(e, 1) }}>
            <div className={
              ClassNames({
                'icon-star-enabled' : this.state.filter === 1 ? true : false,
                'icon-star-disabled' : this.state.filter === 0 ? true : false
              })}/><span>重要画像</span>
          </div>
        </div>
      </div>
    );
  }

  onSelect(id) {
    this.setState({ selected: id });
  }

  onChange(detail) {

    var index = this.state.pictures.findIndex(picture => {
      return picture.id === detail.id;
    });

    if (index >= 0) {
      var picture = this.state.pictures[index];
      var form = new FormData();
      if (detail.mark !== undefined) {
        form.append('mark', detail.mark);
      }
      if (detail.name) {
        form.append('name', detail.name);
      }
      new Service().updatePicture(this.props.id, picture.id, form)
        .subscribe(
          payload => {
            var pictures = [
              ...this.state.pictures.slice(0, index),
              payload,
              ...this.state.pictures.slice(index + 1)
            ];
            this.setState({ pictures: pictures });
          },
          error => {
            this.props.handlers.error(error);
          }
        )
    }
  }

  onChangeFilter(event, filterValue) {
    const filter = filterValue;
    this.setState({ filter: filter });
    // const keywords = this.refs.keywords.value || null;
    const keywords = null;
    this.loadNewerPictures(0, filter, keywords);
  }

  onClickImage(event, id) {
    event.preventDefault();

    const index = this.state.documents.findIndex(doc => { return doc.id === id; });
    if (index >= 0) {
      const image = this.state.documents[index];

      if (['jpg', 'jpeg', 'png', 'svg'].findIndex(ext => { return ext === image.ext }) >= 0) {
        window.open(absolutedUrl(`${image.url}/preview`));
      }
    }
  }

  onDelete(id) {
    this.props.handlers.showPopup(
      <AlertTwoButton title="写真の削除" message="写真を削除しますか？" cancel={ this.props.handlers.hidePopup } okay={ () => { this.deletePicture(id) } }  okayButtonText="削除する" />
    );

  }

  deletePicture(id) {
    this.props.handlers.hidePopup();
    var index = this.state.pictures.findIndex(picture => {
      return picture.id === id;
    });
    if (index < 0) {
      return;
    }

    new Service().deletePicture(this.props.id, id)
      .subscribe(
        payload => {
          var pictures = [
            ...this.state.pictures.slice(0, index),
            ...this.state.pictures.slice(index + 1)
          ];
          this.setState({ pictures: pictures });
        },
        error => {
          this.props.handlers.error(error);
        }
      );

  }

}

Pictures.PropType = {
  id: PropTypes.number,
  handlers: PropTypes.object,
  // 自分が編集中かどうか
  enabled: PropTypes.bool,
  notifyParameter: PropTypes.func.isRequired,
  filter: PropTypes.number.isRequired,
};

export default Pictures;
