import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Observable } from 'rx';

import Service from '../services/MeetingService';

import './WordPopup.css';

function fieldToLabel(field) {
  var names = {
    word: '表記',
    yomi: '読み',
    category: 'カテゴリー',
    count: '登録単語数',
  }
  return names[field];
}

class WordPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      word: {
        word: '',
        yomi: '',
        category_id: 0,
      },
      errors: {
        name: null,
        category: null,
      }
    };
  }

  componentDidMount() {

    const service = new Service();

    if (this.props.id) {
      Observable.zip(service.categories(), service.word(this.props.dictionaryId, this.props.id), (categories, word) => {
          return { categories: categories, word: word };
        }).subscribe(
            payload => {
              console.log(payload);
              this.setState({
                categories: payload.categories,
                word: payload.word
              });
            },
            error => {
              this.props.close();
              this.props.error(error);
            }
          );

    } else {
      service.categories()
        .subscribe(
          payload => {
            this.setState({
              categories: payload
            });
          },
          error => {
            this.props.close();
            this.props.error(error);
          }
        );

    }

  }

  submit(event) {
    var form = new FormData();
    form.append('word', this.state.word.word);
    form.append('yomi', this.state.word.yomi);
    form.append('category_id', this.state.word.category_id);

    var service = new Service();

    if (this.props.id) {
      service.updateWord(this.props.dictionaryId, this.props.id, form)
        .subscribe(
          payload => {
            if (this.errorsToState(payload)) {
              return;
            }
            this.props.close();
          },
          error => {
            this.props.close();
            this.props.error(error);
          }
        );
    } else {
      service.storeWord(this.props.dictionaryId, form)
        .subscribe(
          payload => {
            if (this.errorsToState(payload)) {
              return;
            }
            this.props.close();
          },
          error => {
            this.props.close();
            this.props.error(error);
          }
        );
    }
  }

  cancel(event) {
    this.props.close();
  }

  okayButton() {
    return this.props.id ? '変更する' : '追加する';
  }

  onChangeText(e, item) {
    var word = this.state.word;
    word[item] = e.target.value;
    this.setState({ word: word });
  }

  onChangeCategory(e) {
    var word = this.state.word;
    word.category_id = e.target.value;
    this.setState({ word: word });
  }

  isEnabledPositiveButton() {
    return this.state.word.word.length > 0 && this.state.word.yomi.length > 0 && this.state.word.category_id > 0;
  }

  categoryOptions() {
    var options = [];
    options.push(<option key="0" value="">カテゴリーを選択してください。</option>);
    for (let i = 0; i < this.state.categories.length; ++i) {
      options.push(<option key={ i+1 } value={ this.state.categories[i].id }>{ this.state.categories[i].name }</option>);
    }
    return options;
  }

  render() {
    return(
      <div className="word-popup">
        <div className="title">{ this.props.title }</div>
        <div className="inner">
          <form>
            <div><label></label>表示している単語帳に単語を追加・変更します。</div>
            { this.errorMessage('count') }
            <div><label className="required">表記</label><input autoFocus maxLength={32} ref="word" type="text" value={ this.state.word.word } onChange={ e => { this.onChangeText(e, 'word') } } /></div>
            { this.errorMessage('word') }
            <div><label className="required">読み</label><input maxLength={32} ref="yomi" type="text" value={ this.state.word.yomi } onChange={ e => { this.onChangeText(e, 'yomi') } } /></div>
            { this.errorMessage('yomi') }
            <div>
              <label className="required">分類</label>
              <div className="select">
                <select value={ this.state.word.category_id } onChange={ e => { this.onChangeCategory(e) } }>
                  { this.categoryOptions() }
                </select>
                <button tabIndex="-1" className="button-select" ><div className="icon-inverted-triangle"></div></button>
              </div>
            </div>
            { this.errorMessage('category') }
            <div><label></label></div>
            <div><label></label>１つの単語帳に最大500件まで単語を登録できます。</div>
          </form>
        </div>
        <div className="buttons">
          <button className="btnpt1" onClick={ e => { this.cancel(e) } } >キャンセル</button>
          <button className="btnpt4" disabled={ !this.isEnabledPositiveButton() } onClick={ e => { this.submit(e) } } >{ this.okayButton() }</button>
        </div>
      </div>
    );
  }

  errorsToState(payload) {
    if (payload.error === 'validation') {
      delete(payload.error);
      var errors = [];
      Object.keys(payload).forEach(key => {
        var error = payload[key][0];
        errors[key] = error.split('.')[1];
      });
      this.setState({ errors: errors });
      return true;
    }
    return false;
  }

  errorMessage(field) {
    var error = this.state.errors[field];
    if (error) {
      var label = fieldToLabel(field);
      var messages = {
        unique: `その${ label }は既に存在します。`,
        regex: `${ label }はひらがなもしくは長音で入力してください。`,
        min: `${ label }は二文字以上入力してください。`,
        required: `${ label }は必須入力です。`,
        exceeded_num_of_words_in_dictionary: `${ label }が500を超えています。`,
      }
      return <div><label></label><div className="error">{ messages[error] }</div></div>;
    }
    return null;
  }

}

WordPopup.PropType = {
  dictionaryId: PropTypes.number,
  id: PropTypes.number,
  title: PropTypes.string,
  close:PropTypes.func,
  error:PropTypes.func
};

export default WordPopup;
