import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './PaginationBar.css';

class PaginationBar extends Component {
  render() {
    return (<div className="pagination-bar">{ this.pagination() }</div>);
  }

  pageLink(page) {
    if (page === this.props.current) {
      return <div key={ page } className="current">{ page }</div>;
    } else {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      return <div key={ page } ><a onClick={ this.bindOnClick(page) } className="button-paginate">{ page }</a></div>;
    }
  }

  pagination() {
    if (this.props.last > 1) {
      let pages = [];

      if (this.props.current > 1) {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        pages.push(<div key="0" className="prev"><a onClick={ this.bindOnClick(this.props.current - 1) } className="button-paginate">&lt;&nbsp;</a></div>);
      } else {
        pages.push(<div key="0" className="prev">&lt;&nbsp;</div>);
      }

      let start = this.props.current - 2;
      if ((this.props.current + 3) > this.props.last) {
        start = this.props.last - 5;
      }
      if (start < 2) {
        start = 2;
      }
      let end = start + 4;
      if (end >= this.props.last) {
        end = this.props.last - 1;
      }

      pages.push(this.pageLink(1));
      if (start > 2) {
        pages.push(<div key="2">…</div>);
      }
      for (let i = start; i <= end; ++i) {
        pages.push(this.pageLink(i));
      }
      if (end < (this.props.last - 1)) {
        pages.push(<div key={ this.props.last - 1 }>…</div>);
      }
      pages.push(this.pageLink(this.props.last));

      if (this.props.current < this.props.last) {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        pages.push(<div key={ this.props.last + 1 } className="next"><a onClick={ this.bindOnClick(this.props.current + 1) } className="button-paginate">&nbsp;&gt;</a></div>);
      } else {
        pages.push(<div key={ this.props.last + 1 } className="next">&nbsp;&gt;</div>);
      }
      return pages;
    }
    return null;
  }

  bindOnClick(index) {
    return () => {
      this.props.onClick(index);
    };
  }
}

PaginationBar.PropType = {
  total: PropTypes.number,
  per_page: PropTypes.number,
  current: PropTypes.number,
  last: PropTypes.number,
  onClick:PropTypes.func,
};

export default PaginationBar;