import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Service from '../services/MeetingService';

import './MeetingGuestRenamePopup.css';

function fieldToLabel(field) {
  let names = {
    name: '変更後のゲスト名',
  };
  return names[field];
}

class MeetingGuestRenamePopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      guest: {
        new_name: '',
        user_id: '',
      },
      errors: {
        name: null,
      }
    };
  }

  componentDidMount() {
  }

  // noinspection JSUnusedLocalSymbols
  submit(event) {
    let form = new FormData();
    form.append('user_id', this.state.guest.user_id);
    form.append('new_name', this.state.guest.new_name);

    this.props.handlers.showProgress();
    let service = new Service();
    service.renameGuest(this.props.meeting_id, form)
        .finally(() => { this.props.handlers.hideProgress(); })
        .subscribe(
            payload => {
              if (this.errorsToState(payload)) {
                return;
              }
              this.props.close();
            },
            error => {
              this.props.close();
              this.props.handlers.error(error);
            }
        );
  }

  // noinspection JSUnusedLocalSymbols
  cancel(event) {
    this.props.close();
  }

  onChangeText(e, item) {
    let guest = this.state.guest;
    guest[item] = e.target.value;
    this.setState({ guest: guest });
  }

  onChangeSelection(e, item) {
    let guest = this.state.guest;
    guest[item] = e.target.value;
    this.setState({ guest: guest });
  }

  isEnabledPositiveButton() {
    if(this.state.guest.new_name.length <= 0){
      return false;
    }
    if(this.state.guest.user_id === ''){
      return false;
    }
    return this.state.guest.user_id !== 0;
  }

  guestOptions() {
    let options = [];
    options.push(<option key="0" value="">ゲストを選択してください。</option>);
    this.props.guest_members.forEach((member, index) => {
      options.push(<option key={ index + 1 } value={ member.user_id }>{ member.user_name }</option>);
    });
    return options;
  }

  render() {
    return(
        <div className="meeting-guest-rename-popup">
          <div className="title">ゲスト名編集</div>
          <div className="inner">
            <form>
              <div>
                <label className="required">変更対象ゲスト</label>
                <div className="select">
                  <select value={ this.state.guest.user_id } onChange={ e => { this.onChangeSelection(e, 'user_id') } } >
                    { this.guestOptions() }
                  </select>
                  <button className="button-select" ><div className="icon-inverted-triangle"/></button>
                </div>
                <label className="required">変更後のゲスト名</label>
                <input
                    autoFocus
                    maxLength={ 8 }
                    type="text"
                    value={ this.state.guest.new_name }
                    onChange={ e => { this.onChangeText(e, 'new_name') } } />
              </div>
              { this.errorMessage('name') }
            </form>
          </div>
          <div className="buttons">
            <button className="button-text" onClick={ e => {this.cancel(e)} } >キャンセル</button>
            <button className="button-1" disabled={ !this.isEnabledPositiveButton() } onClick={ e => {this.submit(e)} } >変更する</button>
          </div>
        </div>
    );
  }

  errorsToState(payload) {
    if (payload.error === 'validation') {
      delete(payload.error);
      const errors = [];
      Object.keys(payload).forEach(key => {
        const error = payload[key][0];
        errors[key] = error.split('.')[1];
      });
      this.setState({ errors: errors });
      return true;
    }
    return false;
  }

  errorMessage(field) {
    const error = this.state.errors[field];
    if (error) {
      const label = fieldToLabel(field);
      const messages = {
        max: `${label}は8文字以下で入力してください。`,
        required: `${label}は必須入力です。`,
      };
      return <div><label/><div className="error">{ messages[error] }</div></div>;
    }
    return null;
  }
}

MeetingGuestRenamePopup.PropType = {
  meeting_id: PropTypes.number,
  close:PropTypes.func,
  handlers: PropTypes.object,
  guest_members: PropTypes.object,
};

export default MeetingGuestRenamePopup;
