import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './CommentPopup.css';
import AudioPlayer from '../containers/AudioPlayer';
import Const from "./Const";
import Service from "../services/MeetingService";
import {getSearchParamsForReactRouter} from "../containers/utilities";

class CommentPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      selected_speaker_user_id: props.selected_speaker_user_id,
      meeting_users: [],
      with_player: true,
      did_selected_guest: false,
    }
  }

  componentDidMount() {
    this.refs.name.value = this.props.name;
    this.refs.guest_name.value = '';
    this.refs.comment.value = this.props.comment;
    this.refs.player.setUtterance(this.props.utterance);
    this.getCurrentPage();
  }

  getCurrentPage() {
    if (this.props.mic_type === Const.MIC_TYPE_NORMAL_MIC) {
      return;
    }
    this.props.handlers.showProgress();
    let meeting_id = getSearchParamsForReactRouter().get("id");
    new Service().meeting_users(meeting_id)
        .finally(() => {
          this.props.handlers.hideProgress();
        })
        .subscribe(
            payload => {
              this.setState({ meeting_users: payload });
            },
            error => {
              this.props.close();
              this.props.handlers.error(error);
            }
        );
  }

  onChangeSelection(e) {
    this.setState({
      selected_speaker_user_id: e.target.value,
      did_selected_guest: e.target.value === "-1",
    });
  }

  renderUsers() {
    let output = [];
    output.push(<option key="0" value="0">発話したユーザーを選択してください。</option>);
    this.state.meeting_users.forEach((member, index) => {
      output.push(<option key={ index + 1 } value={ member.id }>{ member.user_name }</option>);
    });
    output.push(<option key="-1" value="-1">ゲスト(新規)</option>);
    return output;
  }

  renderNameArea() {
    if(this.props.mic_type === Const.MIC_TYPE_NORMAL_MIC) {
      return (
          <div>
            <label className="required">発言者</label>
            <input autoFocus ref="name" type="text" maxLength="8"/>
          </div>
      );
    }
    return (
        <div>
          <label className="required">発言者</label>
          <input autoFocus ref="name" type="text" hidden/>
          <div className="select">
            <select value={ this.state.selected_speaker_user_id }
                    onChange={ e => { this.onChangeSelection(e) } }>
              { this.renderUsers() }
            </select>
            <button className="button-select" ><div className="icon-inverted-triangle"/></button>
          </div>
        </div>
    );
  }

  renderGuestNameArea() {
    return (
        <div hidden={ !this.state.did_selected_guest }>
          <label className="required">ゲスト名</label>
          <input ref="guest_name" type="text" placeholder="ゲスト名(8文字以内)" maxLength={8}/>
        </div>
    );
  }

  render() {
    return(
      <div className="comment-popup">
        <div className="title">コメント編集</div>
        <div className="inner">
          <form onSubmit={ e => { e.preventDefault(); }}>
            <div>
              <label>発言時間</label>
              <div>{ this.props.timestamp }</div>
            </div>
            { this.renderNameArea() }
            { this.renderGuestNameArea() }
            <div>
              <label className="required">内容</label>
              <textarea ref="comment"></textarea>
            </div>
            <div hidden={ !this.props.utterance }>
              <label>音声</label>
              {/*<AudioPlayer ref="player"*/}
              {/*hidden={ this.state.with_player }*/}
              {/*existCommentHasUtterance={ this.existCommentHasUtterance.bind(this) }*/}
              {/*loadUtteranceTask={ this.loadCommentHasUtteranceTask.bind(this) }*/}
              {/*onStartFinishUtterance={ this.onStartFinishUtterance.bind(this) }*/}
              {/*onFinish={ this.onFinishPlaying.bind(this) }*/}
              {/*/>*/}
              <AudioPlayer  ref="player"
                            hidden={ true }
                            simpleIf={ true }
                            existCommentHasUtterance={ this.existCommentHasUtterance.bind(this) }
                            loadUtteranceTask={ this.loadCommentHasUtteranceTask.bind(this) }
                            onStartFinishUtterance={ this.onStartFinishUtterance.bind(this) }
                            onFinish={ this.onFinishPlaying.bind(this) }
              />
            </div>
          </form>
        </div>
        <div className="errors">
          { this.errors() }
        </div>
        <div className="buttons">
          <button className="btnpt1" onClick={ this.cancel.bind(this) }>キャンセル</button>
          <button className="btnpt4" onClick={ this.update.bind(this) }>変更する</button>
        </div>
      </div>
    );
  }

  onStartFinishUtterance(finish, next, scrollTo) {
    return;
  }

  onFinishPlaying() {
    return;
  }

  existCommentHasUtterance(comment_id, direction) {
    return null;
  }

  loadCommentHasUtteranceTask(comment_id, direction) {
    return null;
  }

  cancel(event) {
    this.props.close();
  }

  update(event) {
    if (this.validate()) {
      this.props.close();
      if (this.props.mic_type === Const.MIC_TYPE_NORMAL_MIC) {
        this.props.onChange({
          id: this.props.id,
          name: this.refs.name.value,
          comment: this.refs.comment.value,
          is_dropped: '1',
        });
      } else {
        this.props.onChange({
          id: this.props.id,
          comment: this.refs.comment.value,
          mic_type: this.props.mic_type,
          selected_speaker_user_id: Number(this.state.selected_speaker_user_id),
          should_create_guest: this.state.did_selected_guest,
          guest_name: this.refs.guest_name.value,
          is_dropped: '1',
        });
      }
    }
  }

  validate() {
    var errors = [];
    if (!this.refs.name.value) {
      errors.push('発言者を入力してください。');
    }
    if (!this.refs.comment.value) {
      errors.push('内容を入力してください。');
    }
    if (errors.length > 0) {
      this.setState({ errors: errors });
      return false;
    }
    return true;
  }

  errors() {
    if (this.state.errors.length > 0) {
      var rows = [];
      this.state.errors.forEach(message => {
        rows.push(<div className="error">{ message }</div>);
      });
      return rows;
    }
  }

}

CommentPopup.PropType = {
  id: PropTypes.number,
  timestamp: PropTypes.string,
  name: PropTypes.string,
  comment: PropTypes.string,
  utterance: PropTypes.object,
  close: PropTypes.func,
  onChange: PropTypes.func,
  mic_type: PropTypes.number,
  selected_speaker_user_id: PropTypes.number,
  // 基礎的な共通処理を呼ぶためのハンドラー
  handlers: PropTypes.object,
};

export default CommentPopup;
