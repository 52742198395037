import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import UserPicker from './UserPicker';
import MyGroupPicker from './MyGroupPicker';

import './MemberTab.css';
import RemovableList from "../components/RemovableList";

class MemberTab extends Component {

  constructor(props) {
    super(props);

    this.state = {
      toggle: false,
      selected: '',
      members: this.props.members
    };
  }

  UNSAFE_componentWillMount() {
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({ members: this.props.members });
    }
  }

  componentWillUnmount() {
  }

  onClickButton(event) {
    event.preventDefault();
      this.setState({
        toggle: true,
        selected: 'users'
      });
  }

  onAddMembers(members) {
    this.setState({
      toggle: false,
      selected: ''
    });
    this.props.onAddMembers(members);
  }

  onCancel() {
    this.setState({
      toggle: false,
      selected: ''
    });
  }

  onClear() {
    this.props.onClearMembers();
  }

  clearButton() {
    if (this.props.members && this.props.members.length > 0) {
      return <button onClick={ this.onClear.bind(this) } className="clear btnpt1" >すべてクリアする</button>
    }
    return null;
  }

  renderMemberContent() {
    if(this.state.toggle) {
      const isUsersSelected = this.state.selected === 'users';
      const usersClass = classNames({
        'selected': isUsersSelected,
        'selectable': !isUsersSelected
      });
      const groupsClass = classNames({
        'selected': !isUsersSelected,
        'selectable': isUsersSelected
      });

      return (
          <div className="member-content" style={{ display: this.state.toggle ? 'block' : 'none' }}>
            <div className="header">
              <div className={ usersClass } onClick={ this.onClickUsers.bind(this) }>ユーザを選択</div>
              <div className={ groupsClass } onClick={ this.onClickGroups.bind(this) }>Myグループ</div>
            </div>
            <div className="body">
              <div className="user-picker" hidden={ this.state.selected !== 'users'}>
                <UserPicker
                    members={ this.state.members }
                    onAddMembers={ this.onAddMembers.bind(this) }
                    onCancel={ this.onCancel.bind(this) }
                    canClear={ false }
                    isEmbedded={true}
                />
              </div>
              <div className="my-group-picker" hidden={ this.state.selected !== 'groups'}>
                <MyGroupPicker
                    members={ this.state.members }
                    onAddMembers={ this.onAddMembers.bind(this) }
                    onCancel={ this.onCancel.bind(this) }
                    canClear={ false }
                    isEmbedded={true}
                />
              </div>
            </div>
          </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div className="member-tab">
        <button className="button-2" onClick={ this.onClickButton.bind(this) } >メンバーを追加</button>
        <div className="member-tabs">
          { this.renderMemberContent() }
        </div>
        <div className="members">
          <RemovableList items={ this.state.members } onRemove={ this.props.onRemoveMember.bind(this) } canNotRemove={ this.props.canNotRemoveMembers } />
        </div>
        { this.clearButton() }
      </div>
    );
  }

  onClickUsers(event) {
    this.setState({ selected: 'users' });
  }

  onClickGroups(event) {
    this.setState({ selected: 'groups' });
  }
}

MemberTab.PropType = {
  members: PropTypes.array.isRequired,
  onAddMembers:PropTypes.func,
  onClearMembers:PropTypes.func,
  onRemoveMember:PropTypes.func,
  canNotRemoveMembers: PropTypes.bool
};

export default MemberTab;
